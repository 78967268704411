import {NgModule} from '@angular/core';
import {CloseCropModalComponent} from './close-crop-modal.component';
import {CommonModule} from '@angular/common';
import {PipesModule} from '../../../pipes/pipes.module';
import {DatePickerModule} from '../../date-picker/date-picker.module';
import {TimePickerModule} from '../../time-picker/time-picker.module';

@NgModule({
  declarations: [CloseCropModalComponent],
  imports: [CommonModule, PipesModule, DatePickerModule, TimePickerModule],
  exports: [CloseCropModalComponent],
})
export class CloseCropModalModule {}
