import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { TranslationService } from '../../../services/translations/translation.service';
import * as _ from 'lodash';
import { UtilsService } from '../../../services/utils/utils.service';
import { HarvestFullResponse } from '../../../services/api/models/harvest-full-response';
import { PermissionService } from '../../../services/user/permission-service';
import { PermissionName } from '../../../services/api/models/permission-name';
import { HarvestEvent } from '../../../services/api/models/harvest-event';
import { HarvestService } from '../../../services/api/services/harvest.service';
import { take } from 'rxjs/operators';
import { EventType } from './add-event-modal/add-event-modal.component';
import { Router } from '@angular/router';
import { EventsService } from '../../../services/event/events.service';
import { CurrentCropBehaviourService } from '../../../services/harvest/current-crop-behaviour.service';
import * as moment from 'moment/moment';
import { INotificationContent } from '../modals/notification/notification.component';
import { FacilityDataDto } from '../../../services/api/models/facility-data-dto';
import { CustomerSelectorsService } from '../../../services/customer-selectors/customer-selectors.service';
import { HouseType } from '../../../services/api/models/house-type';
import { EVENT_DATE_FORMAT } from '../../../utils/string-utils';

export enum EventProperties {
  date = 'date',
  birdAge = 'birdAge',
  type = 'type',
  creator = 'creator',
}

export enum SortingDirection {
  Asc = 'asc',
  Desc = 'desc',
  Normal = 'Normal',
}

interface IHarvestEvent {
  date?: string;
  birdAge?: number;
  type?: string;
}

export enum EModes {
  general = 'general',
  report = 'report',
}

export enum EventFunctionalModes {
  wizard = 'wizard',
  addEvent = 'addEvent',
  editEvent = 'editEvent',
}

@Component({
  selector: 'events',
  templateUrl: 'events.component.html',
  styleUrls: ['events.component.scss'],
})
export class EventsComponent implements OnChanges {
  @Input() public currentHarvest: HarvestFullResponse;
  @Input() public newEvents: HarvestEvent[];
  @Input() public mode: EModes = EModes.general;
  @Output()
  public onEventCreated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public onEventUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public onThinningEventComplete: EventEmitter<HarvestEvent> = new EventEmitter<HarvestEvent>();

  public eventTypesEnum: typeof EventType = EventType;

  public isAddNewEventModal: boolean = false;
  public isEditEventModal: boolean = false;
  public isCompleteEvent: boolean = false;
  public originalEvents: any;
  public events: HarvestEvent[];
  public currentEvent: HarvestEvent;
  public currentEventIndex: number;

  public EventProperties = EventProperties;
  public SortingDirection = SortingDirection;
  private sortingDirection = SortingDirection.Normal;
  private sortingColumn: EventProperties;

  //PERMISSIONS
  public myFarmCropCardAddEvents: PermissionName =
    PermissionName.MyFarmCropCardAddEvents;
  public myFarmCropCardEditEvents: PermissionName =
    PermissionName.MyFarmCropCardEditEvents;
  public myFarmCropCardDeleteEvents: PermissionName =
    PermissionName.MyFarmCropCardDeleteEvents;
  public myFarmCropCardCompleteEvents: PermissionName =
    PermissionName.MyFarmCropCardCompleteEvents;
  public myFarmCropCardAddBrooding: PermissionName =
    PermissionName.MyFarmCropCardAddBrooding;
  public myFarmCropCardEditBrooding: PermissionName =
    PermissionName.MyFarmCropCardEditBrooding;
  public myFarmCropCardDeleteBrooding: PermissionName =
    PermissionName.MyFarmCropCardDeleteBrooding;

  public get containsData() {
    return this.events?.length > 0;
  }

  public notificationContent: INotificationContent = {
    color: null,
    fontColor: null,
    message: null,
    IconName: null,
    name: null,
  };

  public isShowNotificationModal: boolean = false;

  constructor(
    public permissionService: PermissionService,
    public utilsService: UtilsService,
    public readonly translationService: TranslationService,
    public harvestService: HarvestService,
    private router: Router,
    private eventsService: EventsService,
    public currentCropBehaviourService: CurrentCropBehaviourService,
    public customerSelectorsService: CustomerSelectorsService
  ) {}

  public closeNotificationModal() {
    this.isShowNotificationModal = false;
  }

  public get getHouseCloseDate() {
    const closeDate = moment(new Date()).diff(
      moment(this.getLastCloseCropsDate()).format('YYYY-MM-DDTHH:mm:ssZ'),
      'days'
    );
    return closeDate <= 7;
  }

  public getLastCloseCropsDate() {
    const crops = this.customerSelectorsService.currentHouse.crops;
    const cropDate = crops.map((crop) => crop.closeDate)[crops.length - 1];
    return cropDate ? cropDate : undefined;
  }

  public get isOutsideHouse() {
    return (
      this.customerSelectorsService.currentHouse.houseType === HouseType.Outside
    );
  }

  public initValues() {
    this.originalEvents = this.newEvents;
    if (this.originalEvents && this.currentHarvest?.startDate) {
      this.originalEvents = _.orderBy(
        JSON.parse(JSON.stringify(this.originalEvents)),
        ['birdAge'],
        'desc'
      );
      this.originalEvents.forEach((event) => {
        if (
          event.values.find(
            (item) => item.name === 'isRecurring' && item.value === 'true'
          )
        ) {
          event.values[this.idByParameterName('startDate', event)].value =
            event.date;
        }
      });
      this.events = _.cloneDeep(this.originalEvents);
      console.log('events', this.events);
    } else {
      this.events = undefined;
    }
  }

  public deleteEvent(event: HarvestEvent) {
    this.harvestService
      .deleteEvent({
        eventId: event.id,
        harvestId: this.currentHarvest.harvestId,
      })
      .pipe(take(1))
      .subscribe((res) => {
        this.onEventUpdated.emit();
      });
  }

  public redirectToPerformnceReport(ev: HarvestEvent) {
    this.eventsService.setSelectedEvent(ev);
    this.router.navigate(['performance', 'true']);
  }

  public isEventRecurrent(event: HarvestEvent) {
    return (
      event?.values[
        event?.values.findIndex((item) => item.name === 'isRecurring')
      ]?.value === 'true'
    );
  }

  public idByParameterName(name: string, event?) {
    if (event) {
      return event.values.findIndex((value) => {
        return value.name === name;
      });
    }
  }

  public markEventAsDone(event: HarvestEvent, type: EventType) {
    if (type === EventType.PlannedThinningAges) {
      this.onThinningEventComplete.emit(event);
    } else if (type === EventType.PlannedClearingAge) {
      this.redirectToPerformnceReport(event);
    } else if (
      type === EventType.PlannedHandWeighingOfBirds ||
      type === EventType.PlannedFootpadScoringOfBirds
    ) {
      console.log(event);
      this.currentEvent = event;
      this.isCompleteEvent = true;
      this.isEditEventModal = true;
    } else if (
      (type === EventType.FlushWaterLine || type === EventType.OtherEvents) &&
      event.values[this.idByParameterName('isRecurring')]?.value === 'true'
    ) {
      event.values[this.idByParameterName('endDate', event)].value =
        moment().isAfter(
          moment(event.values[this.idByParameterName('endDate', event)].value)
        )
          ? event.values[this.idByParameterName('endDate', event)].value
          : moment().isAfter(
              moment(
                event.values[this.idByParameterName('startDate', event)].value
              )
            )
          ? moment().format(EVENT_DATE_FORMAT)
          : moment(
              event.values[this.idByParameterName('startDate', event)].value
            )
              .add(1, 'days')
              .format(EVENT_DATE_FORMAT);
      console.log('-----EVENT-------', event);

      this.harvestService
        .updateEvent({
          eventId: event.id,
          harvestId: this.currentHarvest.harvestId,
          body: {
            date: event.date,
            isDone: true,
            isSystem: event.createdBy === 'system',
            type: event.type,
            values: event.values,
          },
        })
        .pipe(take(1))
        .subscribe((res) => this.onEventUpdated.emit());
    } else {
      this.harvestService
        .updateEvent({
          eventId: event.id,
          harvestId: this.currentHarvest.harvestId,
          body: {
            date: event.date,
            isDone: true,
            isSystem: event.createdBy === 'system',
            type: event.type,
            values: event.values,
          },
        })
        .pipe(take(1))
        .subscribe((res) => this.onEventUpdated.emit());
    }
  }

  public ngOnChanges() {
    this.initValues();
  }

  public eventCreated() {
    this.onEventCreated.emit(true);
    this.sortRows(this.sortingColumn);
    this.sortingDirection = SortingDirection.Normal;
  }

  public showError(errorContent) {
    this.isShowNotificationModal = true;
    this.notificationContent = errorContent;
  }

  public showNotification(notificationContent) {
    this.isShowNotificationModal = true;
    this.notificationContent = notificationContent;
  }

  public eventUpdated() {
    this.onEventUpdated.emit();
    this.sortRows(this.sortingColumn);
    this.sortingDirection = SortingDirection.Normal;
  }

  public search(value: string): void {
    this.sortingDirection = SortingDirection.Normal;
    this.events = this.utilsService.filterByConcreteProperties(
      value,
      _.cloneDeep(this.originalEvents),
      [EventProperties.type]
    );
  }

  public sortRows(eventProperties: EventProperties) {
    if (EventProperties[eventProperties] === this.sortingColumn) {
      switch (this.sortingDirection) {
        case SortingDirection.Asc:
          this.sortingDirection = SortingDirection.Desc;
          break;
        case SortingDirection.Desc:
          this.sortingDirection = SortingDirection.Normal;
          break;
        case SortingDirection.Normal:
          this.sortingDirection = SortingDirection.Asc;
          break;
      }
    } else {
      this.sortingColumn = EventProperties[eventProperties];
      this.sortingDirection = SortingDirection.Asc;
    }

    this.events = this.sortByColumn(
      this.events,
      this.sortingColumn,
      this.sortingDirection
    );
  }

  public sortByColumn(
    events: IHarvestEvent[],
    eventProperty: string,
    sortingDirection: string
  ) {
    if (sortingDirection !== SortingDirection.Normal) {
      return _.orderBy(
        events,
        [
          (event) =>
            Number.isInteger(event[eventProperty])
              ? event[eventProperty]
              : event[eventProperty]?.toLowerCase(),
        ],
        [sortingDirection]
      );
    }
    return this.originalEvents;
  }

  public isDeleteButtonActive(event: HarvestEvent, index: number) {
    if (this.isLastBroodingEvent(index) && !event.isDone) {
      return (
        this.events.filter((item) => item.type === EventType.Brooding)
          .length !== 1 && this.currentCropBehaviourService.isEditable
      );
    } else if (event.type === EventType.PlannedClearingAge) {
      return false;
    } else if (event.isDone) {
      return false;
    } else if (!this.currentCropBehaviourService.isEditable) {
      return false;
    }
  }

  public isEditBroodingButtonActive(event: HarvestEvent, index: number) {
    if (this.isLastBroodingEvent(index) && !event.isDone) {
      return (
        this.events.filter((item) => item.type === EventType.Brooding)
          .length !== 1 && this.currentCropBehaviourService.isEditable
      );
    }
  }

  public isLastBroodingEvent(index: number) {
    if (this.events.length > 0) {
      let lastBroodingEventIndex: number;
      let i = this.events.length;
      while (i >= 0) {
        if (this.events[i - 1].type === 'Brooding') {
          lastBroodingEventIndex = i - 1;
          break;
        }
        i -= 1;
      }
      return lastBroodingEventIndex === index;
    } else {
      return true;
    }
  }

  public editEvent(event, index) {
    this.isCompleteEvent = false;
    console.log(event);
    this.currentEvent = event;
    this.currentEventIndex = index;
    this.isEditEventModal = true;
  }

  public isShowActions() {
    if (this.mode === 'report') {
      return false;
    } else if (this.permissionService.currentRole === 'SUPER_ADMIN') {
      return true;
    } else if (
      this.permissionService.currentRole !== 'SUPER_ADMIN' &&
      !!this.currentCropBehaviourService.isEditable
    ) {
      return true;
    }
  }

  public isCurrentColumn(
    sortingColumn: EventProperties,
    sortingDirection: SortingDirection
  ) {
    return (
      sortingDirection === this.sortingDirection &&
      sortingColumn === this.sortingColumn
    );
  }
}
