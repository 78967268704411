import { NgModule } from '@angular/core';
import { AddEditHouseComponent } from './add-edit-house.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../../../pipes/pipes.module';
import { ConfirmationModalModule } from '../confirmation-modal/confirmation.module';
import { DatePickerModule } from '../../date-picker/date-picker.module';
import { ErrorModalModule } from '../error-modal/error-modal.module';
import { DropDownListsModule } from '../../dropdown-list/dropdown-list.module';
import { AutonumericModule } from '@angularfy/autonumeric';
@NgModule({
  declarations: [AddEditHouseComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ConfirmationModalModule,
    PipesModule,
    DatePickerModule,
    ErrorModalModule,
    DropDownListsModule,
    AutonumericModule
  ],
  exports: [AddEditHouseComponent],
})
export class AddEditHouseModule {}
