import {NgModule} from '@angular/core';
import {DataGridComponent} from './data-grid.component';
import {ShDatatableModule} from '../sh-datatable/sh-datatable.module';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [DataGridComponent],
  imports: [
    ShDatatableModule,
    CommonModule
  ],
  exports: [DataGridComponent]
})
export class DataGridModule {

}
