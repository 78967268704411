import {NgModule} from '@angular/core';
import {SharedModule} from '../../common/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {CreatePasswordComponent} from './create-password.component';
import {EssentialLogoModule} from '../../common/components/essential-logo/essential-logo.module';

@NgModule({
  declarations: [CreatePasswordComponent],
  imports: [
    SharedModule,
    ReactiveFormsModule,
    EssentialLogoModule,
  ]
})
export class CreatePasswordModule {

}
