import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationsComponent } from './notifications.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import {PipesModule} from '../../pipes/pipes.module';

@NgModule({
  imports: [CommonModule, SlickCarouselModule, PipesModule],
  exports: [NotificationsComponent],
  declarations: [NotificationsComponent],
})
export class NotificationsModule {}
