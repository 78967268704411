import {NgModule} from '@angular/core';
import {PsSensorsComponent} from './ps-sensors.component';
import {PipesModule} from '../../../pipes/pipes.module';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';

@NgModule({
    declarations: [PsSensorsComponent],
  imports: [
    PipesModule,
    RouterModule,
    CommonModule
  ],
    exports: [PsSensorsComponent]
})
export class PsSensorsModule {

}
