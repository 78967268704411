<input
  name="dateInput"
  [owlDateTime]="dateInput"
  [owlDateTimeTrigger]="dateInput"
  [min]="minConverted"
  [max]="max"
  [(ngModel)]="value"
  placeholder=""
  class="datepicker-input"
  (dateTimeChange)="onValueChanged($event.value)"
  [selectMode]="'single'"
  [disabled]="disabled"
/>
<owl-date-time pickerType="calendar" [disabled]="disabled" #dateInput></owl-date-time>
