<div class="menus__menu">
  <div class="menus__menu-label">{{'COMMON.SELECTORS.House' | translate}}</div>
  <div class="multiselect" (click)="openDropdown()">
    <div class="selected-values">{{sortSelectedValues(selectedValues)}}</div>
    <div class="drop-icon" [ngClass]="isOpen ? 'fold-up': ''"></div>
    <div class="values-list" [style.max-height]="maxHeight" #list [class.none]="!isOpen">
      <div class="checkboxes">
        <label class="checkboxes__checkbox">
          <input type="checkbox"
                 [checked]="isChecked('all')"
                 (click)="toggle('all')">
          <span class="checkmark"></span>
          <span class="checkbox-name">{{'CUSTOMERS.All' | translate}}</span>
        </label>
        <label class="checkboxes__checkbox"
               [class.cursor-not-allowed]="!currentCropBehaviourService.isSuperSupportAdmin && (house.houseType === houseTypeOutside || ((!getHouseCLoseDate(house) || house.reportInProgress || isNotPartOfCurrentCycle(house)) && isEndOfCropMode) || (getHouseCLoseDate(house) && !isEndOfCropMode))"
               *ngFor="let house of sortHouses(houses)">
          <input type="checkbox"
                 [disabled]="!currentCropBehaviourService.isSuperSupportAdmin && (house.houseType === houseTypeOutside || ((!getHouseCLoseDate(house) || house.reportInProgress || isNotPartOfCurrentCycle(house)) && isEndOfCropMode) || (getHouseCLoseDate(house) && !isEndOfCropMode))"
                 [checked]="isChecked(house)"
                 (click)="toggle(house)">
          <span class="checkmark"
                [class.disabled-checkmark]="!currentCropBehaviourService.isSuperSupportAdmin && (house.houseType === houseTypeOutside || ((!getHouseCLoseDate(house) || house.reportInProgress || isNotPartOfCurrentCycle(house)) && isEndOfCropMode) || (getHouseCLoseDate(house) && !isEndOfCropMode))">
          </span>
          <div class="tooltip">
                      <span class="checkbox-name"
                            [class.width-100]="isFullWidth(house)"
                            [class.color-disabled]="!currentCropBehaviourService.isSuperSupportAdmin && (house.houseType === houseTypeOutside || ((!getHouseCLoseDate(house) || house.reportInProgress) && isEndOfCropMode) || (getHouseCLoseDate(house) && !isEndOfCropMode))"
                            [class.color-red]="isNotPartOfCurrentCycle(house)">
            {{house.facilityName}}
             </span>
            <div *ngIf="isEndOfCropMode" class="tooltip-text">
              {{house.facilityName}}
            </div>
          </div>
          <span *ngIf="isEndOfCropMode">
            <span class="warn-value"
                  *ngIf="!currentCropBehaviourService.isSuperSupportAdmin && (!getHouseCLoseDate(house) && house.houseType !== houseTypeOutside)">{{ isDisableAllowed ? ('NAVIGATION.Crop_is_closed' | translate) : ('NAVIGATION.Crop_is_closed_more_7_days' | translate)}}</span>
            <span class="warn-value"
                  *ngIf="house.reportInProgress && house.houseType !== houseTypeOutside">{{'NAVIGATION.Report_in_progress' | translate}}</span>
            <span class="warn-value"
                  *ngIf="isNotPartOfCurrentCycle(house) && house.houseType !== houseTypeOutside && !house.reportInProgress">{{'NAVIGATION.Not_part_of_cycle' | translate}}</span>
          </span>
          <span *ngIf="!isEndOfCropMode">
            <span class="warn-value"
                  *ngIf="!currentCropBehaviourService.isSuperSupportAdmin && getHouseCLoseDate(house)">{{ isDisableAllowed ? ('NAVIGATION.Crop_is_closed' | translate) : ('NAVIGATION.Crop_is_closed_more_7_days' | translate)}}</span>
          </span>
        </label>
      </div>
    </div>
  </div>
</div>
