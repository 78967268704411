import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MockSessionPlaybackService} from './mock-session-playback.service';
import {MockSessionRecordingService} from './mock-session-recording.service';
import {WINDOW} from '../../../utils/window-utils';
import {ClientSessionRecorderStorageService} from './client-session-recorder-storage.service';
import {ClientSessionRecorderMessagingService} from './client-session-recorder-messaging.service';
import {CommonUiService} from '../../../services/ui/common-ui.service';
import {
  ClientSessionRecorderMessage,
  ClientSessionRecorderMessageDirection,
  ClientSessionRecorderMessageType,
  ClientSessionRecorderModeType,
  ClientSessionRecorderSession
} from "../../common-model/csr-message.model";
import {
  ChangeModeClientSessionRecorderStatus,
  ClientSessionRecorderStatus,
  ClientSessionRecorderStatusType,
  EndOfAutoPlaybackActionType,
  MessageReceivedClientSessionRecorderStatus,
  PlaybackChangeModeClientSessionRecorderStatus
} from "../../common-model/csr-message-status.model";
import {ActivatedRoute} from "@angular/router";
import {ConfigService} from "../../../services/config/config.service";
import {ClientSessionTestingService} from "./client-session-testing.service";

@Injectable({
  providedIn: 'root'
})
export class ClientSessionRecorderService {

  constructor(@Inject(WINDOW) private _window: Window,
              private readonly mockSessionPlaybackService : MockSessionPlaybackService,
              private readonly mockSessionRecordingService : MockSessionRecordingService,
              private readonly clientSessionRecorderStorageService: ClientSessionRecorderStorageService,
              private readonly clientSessionRecorderMessagingService : ClientSessionRecorderMessagingService,
              private readonly commonUiService : CommonUiService,
              private readonly configService: ConfigService,
              private readonly httpClient:HttpClient,
              private readonly clientSessionTestingService: ClientSessionTestingService) {

  }

  public async init() {
    await this.clientSessionTestingService.init();
    this._window.addEventListener('message', (ev: MessageEvent) => this.onMessage(ev));
    if (this.clientSessionRecorderStorageService.recorderStatus.changeMode != ClientSessionRecorderModeType.Idle) {
      document.addEventListener('click', (ev: MouseEvent) => this.onDocumentClick(ev), {capture: true});
      document.addEventListener('focus', (ev: FocusEvent)  => this.onDocumentFocus(ev), {capture: true});
      document.addEventListener('keydown', (ev: KeyboardEvent) => this.onDocumentKeyboardEvent(ev), {capture: true});
      document.addEventListener('keyup', (ev: KeyboardEvent) => this.onDocumentKeyboardEvent(ev), {capture: true});
      document.addEventListener('input', (ev: Event) => this.onDocumentInput(ev), {capture: true});
      window.addEventListener('resize', (ev: UIEvent) => this.onWindowResize(), {capture: true});
      this.commonUiService.scrollSubject.subscribe(ev => this.onScroll(ev));
    }
    if (this.clientSessionRecorderStorageService.recorderStatus.changeMode == ClientSessionRecorderModeType.Recording) {
      this.mockSessionRecordingService.init();
    } else if (this.clientSessionRecorderStorageService.recorderStatus.changeMode == ClientSessionRecorderModeType.Playback) {
      await this.mockSessionPlaybackService.init();
    }
  }

  public get needToHandleHttpEvent() : boolean {
    return this.clientSessionRecorderStorageService.recorderStatus.changeMode != ClientSessionRecorderModeType.Idle;
  }

  public handleHttpEvent(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this.clientSessionRecorderStorageService.recorderStatus.changeMode == ClientSessionRecorderModeType.Recording) {
      return this.mockSessionRecordingService.handleHttpEvent(request, next);
    } else if(this.clientSessionRecorderStorageService.recorderStatus.changeMode == ClientSessionRecorderModeType.Playback) {
      return this.mockSessionPlaybackService.handleHttpEvent(request, next);
    }
  }

  private onMessage(evt: MessageEvent) {
    if (evt.source != window)
      return;
    let message = <ClientSessionRecorderMessage>evt.data;
    if(message == null || message.direction != ClientSessionRecorderMessageDirection.ExtensionToClient) {
      return;
    }
    switch (message.data.type) {
      case ClientSessionRecorderMessageType.Status:{
        this.onStatusMessage({statusMessage: <ClientSessionRecorderStatus>message.data});
        break;
      }
    }
  }

  private onStatusMessage({statusMessage}: { statusMessage: ClientSessionRecorderStatus }) {
    switch (statusMessage.statusType) {
      case ClientSessionRecorderStatusType.ChangeMode: {
        this.onChangeModeMessage(<ChangeModeClientSessionRecorderStatus>statusMessage);
        break;
      }
      case ClientSessionRecorderStatusType.MessageReceived: {
        this.clientSessionRecorderMessagingService.onMessageReceived(<MessageReceivedClientSessionRecorderStatus>statusMessage);
        break;
      }
    }
  }

  private onChangeModeMessage(changeModeMessage:ChangeModeClientSessionRecorderStatus) {
    this.clientSessionRecorderStorageService.storeNewRecorderStatus(changeModeMessage);
    if(changeModeMessage.changeMode == ClientSessionRecorderModeType.Playback) {
      if(!MockSessionPlaybackService.initClientUrl(<PlaybackChangeModeClientSessionRecorderStatus>changeModeMessage)) {
        this.clientSessionRecorderStorageService.storeNewRecorderStatus(null);
      }
    } else {
      this._window.location.reload();
    }
  }

  private onDocumentFocus(evt : FocusEvent) {
    if(this.clientSessionRecorderStorageService.recorderStatus.changeMode == ClientSessionRecorderModeType.Recording) {
      this.mockSessionRecordingService.onDocumentFocus(evt);
    } else if(this.clientSessionRecorderStorageService.recorderStatus.changeMode == ClientSessionRecorderModeType.Playback) {
      this.mockSessionPlaybackService.onDocumentFocus(evt);
    }
  }

  private onDocumentClick(evt : MouseEvent) {
    if(this.clientSessionRecorderStorageService.recorderStatus.changeMode == ClientSessionRecorderModeType.Recording) {
      this.mockSessionRecordingService.onDocumentClick(evt);
    } else if(this.clientSessionRecorderStorageService.recorderStatus.changeMode == ClientSessionRecorderModeType.Playback) {
      this.mockSessionPlaybackService.onDocumentClick(evt);
    }
  }

  private onDocumentKeyboardEvent(evt : KeyboardEvent) {
    if(this.clientSessionRecorderStorageService.recorderStatus.changeMode == ClientSessionRecorderModeType.Recording) {
      this.mockSessionRecordingService.onDocumentKeyboardEvent(evt);
    } else if(this.clientSessionRecorderStorageService.recorderStatus.changeMode == ClientSessionRecorderModeType.Playback) {
      this.mockSessionPlaybackService.onDocumentKeyboardEvent(evt);
    }
  }

  private onDocumentInput(evt: Event) {
    if(this.clientSessionRecorderStorageService.recorderStatus.changeMode == ClientSessionRecorderModeType.Recording) {
      this.mockSessionRecordingService.onDocumentInput(evt);
    } else if(this.clientSessionRecorderStorageService.recorderStatus.changeMode == ClientSessionRecorderModeType.Playback) {
      this.mockSessionPlaybackService.onDocumentInput(evt);
    }
  }

  private onScroll(evt: Event) {
    if(this.clientSessionRecorderStorageService.recorderStatus.changeMode == ClientSessionRecorderModeType.Recording) {
      this.mockSessionRecordingService.onScroll(evt);
    } else if(this.clientSessionRecorderStorageService.recorderStatus.changeMode == ClientSessionRecorderModeType.Playback) {
      this.mockSessionPlaybackService.onScroll(evt);
    }
  }

  private onWindowResize() {
    if(this.clientSessionRecorderStorageService.recorderStatus.changeMode == ClientSessionRecorderModeType.Recording) {
      this.mockSessionRecordingService.onWindowResize(false);
    } else if(this.clientSessionRecorderStorageService.recorderStatus.changeMode == ClientSessionRecorderModeType.Playback) {
      this.mockSessionPlaybackService.onWindowResize();
    }
  }
}
