import { NgModule } from '@angular/core';
import { HatcheryVaccinesComponent } from './hatchery-vaccines.component';
import { DatePickerModule } from '../date-picker/date-picker.module';
import { DropDownListsModule } from '../dropdown-list/dropdown-list.module';
import { PipesModule } from '../../pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { SimpleDropdownModule } from '../dropdowns/simple-dropdown/simple-dropdown.module';

@NgModule({
  declarations: [HatcheryVaccinesComponent],
  imports: [
    DatePickerModule,
    DropDownListsModule,
    PipesModule,
    CommonModule,
    SimpleDropdownModule,
  ],
  exports: [HatcheryVaccinesComponent],
})
export class HatcheryVaccinesModule {}
