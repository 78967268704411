import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { HarvestEvent } from '../api/models/harvest-event';
import { EventFunctionalModes } from '../../common/components/events/events.component';

@Injectable({ providedIn: 'root' })
export class BroodingService implements OnDestroy {
  public broodingDurationRemaining: string = '30';
  public sumOfBroodingDuration: number;
  private readonly maxBroodingDuration = 30;
  private destroyed$ = new Subject();

  constructor() {}

  public setBroodingDurationRemaining(
    broodingEvents: HarvestEvent[],
    additionalDurationForAddEvent?: number
  ) {
    console.log('broodingEvents in SERVICE', broodingEvents);
    const broodingDurations = broodingEvents.map(
      (item) => item.values[1].value
    );
    this.sumOfBroodingDuration = broodingDurations.reduce(
      (sum, elem) => sum + +elem,
      0
    );
    if (!!additionalDurationForAddEvent) {
      this.broodingDurationRemaining = String(
        +this.maxBroodingDuration -
          +this.sumOfBroodingDuration -
          +additionalDurationForAddEvent
      );
    } else {
      this.broodingDurationRemaining = String(
        +this.maxBroodingDuration - +this.sumOfBroodingDuration
      );
    }
    console.log('broodingEvents', broodingEvents);
    console.log('broodingDurationRemaining', this.broodingDurationRemaining);
  }

  public getMaxBroodingDuration(value?, functionalMode?) {
    if (
      functionalMode === EventFunctionalModes.addEvent ||
      functionalMode === EventFunctionalModes.editEvent
    ) {
      return +this.broodingDurationRemaining;
    } else {
      return +this.broodingDurationRemaining + +value;
    }
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
