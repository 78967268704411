import {NgModule} from '@angular/core';
import {InputFilterDirective} from './input-filter.directive';

const directives = [
  InputFilterDirective
];

@NgModule({
  declarations: [...directives],
  exports: [...directives]
})
export class DirectivesModule {

}
