import {ClientSessionRecorderEvent, ClientSessionRecorderEventType} from "./csr-message.model";

export enum TestRelatedEventType {
  TestCaseDefinition = 'TestCaseDefinition',
  TestExpectationDefinition = 'TestExpectationDefinition'
}

export abstract class ClientSessionRecorderTestRelatedEvent extends ClientSessionRecorderEvent{
  public abstract testEventType: TestRelatedEventType;
  public eventType: ClientSessionRecorderEventType;

  protected constructor() {
    super();
    this.eventType = ClientSessionRecorderEventType.TestRelated;
  }
}

export class TestCaseDefinitionClientSessionRecorderEvent extends ClientSessionRecorderTestRelatedEvent{
  public testEventType: TestRelatedEventType;
  public testCaseName: string;

  constructor() {
    super();
    this.testEventType = TestRelatedEventType.TestCaseDefinition;
  }
}

export enum TestExpectationType {
  DomElement='DomElement',
  WebApi='WebApi'
}

export abstract class TestExpectationDefinitionClientSessionRecorderEvent extends ClientSessionRecorderTestRelatedEvent {
  public abstract expectationType : TestExpectationType;
  public testEventType: TestRelatedEventType;
  public waitTime: number;

  protected constructor() {
    super();
    this.testEventType = TestRelatedEventType.TestExpectationDefinition;
  }
}

export enum DomElementExpectationType {
  DomElementState = 'DomElementState',
  DomElementProperty = 'DomElementProperty'
}

//DomElement expectations
export abstract class DomElementExpectationClientSessionRecorderEvent extends TestExpectationDefinitionClientSessionRecorderEvent{
  public abstract domElementExpectationType: DomElementExpectationType;
  public expectationType: TestExpectationType;
  public elementXpath : string;
  public elementShId : string;
  public elementType : string;

  protected constructor() {
    super();
    this.expectationType = TestExpectationType.DomElement;
  }
}

export enum DomElementStateType {
  Exist='Exist',
  Visible='Visible'
}

export class DomElementStateExpectationClientSessionRecorderEvent extends DomElementExpectationClientSessionRecorderEvent{
  public domElementExpectationType: DomElementExpectationType;
  public stateType : DomElementStateType;
  public falseExpectation : boolean;

  constructor() {
    super();
    this.domElementExpectationType = DomElementExpectationType.DomElementState;
  }
}

export class DomElementPropertyExpectationClientSessionRecorderEvent extends DomElementExpectationClientSessionRecorderEvent{
  public domElementExpectationType: DomElementExpectationType;
  public domElementPropertyEvaluation: string;

  constructor() {
    super();
    this.domElementExpectationType = DomElementExpectationType.DomElementProperty;
  }
}

//WebApi expectations
export class WebApiExpectationClientSessionRecorderEvent extends TestExpectationDefinitionClientSessionRecorderEvent{
  public expectationType: TestExpectationType;

  // tslint:disable-next-line:no-any
  public messagePattern: any;

  constructor() {
    super();
    this.expectationType = TestExpectationType.WebApi;
  }
}

