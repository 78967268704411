import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { TimeSelectionHoursMode } from '../time-selection/time-selection.component';
import { TranslationService } from '../../../../services/translations/translation.service';
import * as moment from 'moment';
import { EpochStateModel } from '../epoch-state-model';

export enum AmPmModeEnum {
  Am,
  Pm,
}

@Component({
  selector: 'am-pm-selection',
  templateUrl: './am-pm-selection.component.html',
  styleUrls: ['./am-pm-selection.component.scss'],
})
export class AmPmSelectionComponent implements OnInit {
  private static readonly DefaultThemeColor = '#000';

  private static readonly DefaultAmPmSelectedColor: string = '#1c92ca';

  private static readonly AmPmUnselectedColor: string = '#9b9b9b';

  @Input()
  public themeColor: string;

  @Input()
  public selectedEpoch: EpochStateModel;

  @Input()
  @HostBinding('class.disabled')
  public disabled: boolean;

  @Output()
  public onAmPmModeChanged: EventEmitter<void> = new EventEmitter();

  constructor(public readonly translationService: TranslationService) {}

  public TimeSelectionHoursMode = TimeSelectionHoursMode;

  public AmPmModeEnum = AmPmModeEnum;

  public ngOnInit() {}

  public getAmPmSelectedColor(mode: AmPmModeEnum) {
    if (this.amPmMode == mode) {
      if (
        this.themeColor.toLowerCase() ==
        AmPmSelectionComponent.DefaultThemeColor.toLowerCase()
      ) {
        return AmPmSelectionComponent.DefaultAmPmSelectedColor;
      } else {
        return this.themeColor;
      }
    } else {
      return AmPmSelectionComponent.AmPmUnselectedColor;
    }
  }

  public get amPmMode(): AmPmModeEnum {
    if (this.selectedEpoch.epochTime == null) {
      return AmPmModeEnum.Am;
    }
    let amPmFormattedResult = moment
      .unix(this.selectedEpoch.epochTime)
      .utc()
      .format('a');
    if (amPmFormattedResult == 'am') {
      return AmPmModeEnum.Am;
    } else {
      return AmPmModeEnum.Pm;
    }
  }

  public amPmModeSelectClick(mode: AmPmModeEnum) {
    if (this.selectedEpoch.epochTime == null) {
      return null;
    }
    let amPmText = '';
    if (mode == AmPmModeEnum.Am) {
      amPmText += ' AM';
    } else if (mode == AmPmModeEnum.Pm) {
      amPmText += ' PM';
    }
    let currentTimeAsText = moment
      .unix(this.selectedEpoch.epochTime)
      .utc()
      .format('hh:mm');
    this.selectedEpoch.epochTime = moment(
      currentTimeAsText + amPmText,
      this.translationService.shortTimeFormat,
      false
    ).unix();
    this.onAmPmModeChanged.emit();
  }
}
