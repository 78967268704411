import {NgModule} from '@angular/core';
import {FooterComponent} from './footer.component';
import {CopyrightModule} from '../copyright/copyright.module';
import {EssentialLogoModule} from '../essential-logo/essential-logo.module';

@NgModule({
  declarations: [FooterComponent],
  exports: [FooterComponent],
  imports: [CopyrightModule, EssentialLogoModule],
})
export class FooterModule {
}
