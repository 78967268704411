<div class="popup">
  <div class="popup__body">
    <div class="popup__body-header">
      {{ title }}
    </div>
    <div class="popup__body-close" *ngIf="isShowCross">
      <span class="svg close-svg-blue" (click)="cancel()"></span>
    </div>
    <div class="popup__body-content">
      {{ text }}
    </div>
    <div class="center width-100">
      <button
        *ngIf="mode === 'alert'"
        type="submit"
        class="blue-rounded-button new-farm-button"
        (click)="closeModal()"
      >{{'COMMON.BUTTONS.OK' | translate}}</button>
      <button
        *ngIf="mode === 'regular'"
        class="blue-rounded-button new-farm-button"
        (click)="cancel()"
      >{{'COMMON.BUTTONS.CANCEL' | translate}}</button>
      <button
        type="submit"
        *ngIf="mode === 'regular'"
        class="blue-rounded-button new-farm-button"
        [disabled]="disabledAfterClick"
        (click)="confirm()"
      >{{'COMMON.BUTTONS.Confirm' | translate}}</button>
      <button
        *ngIf="mode === 'confirmation'"
        class="blue-rounded-button new-farm-button"
        (click)="cancel()"
      >{{'COMMON.BUTTONS.No' | translate}}</button>
      <button
        type="submit"
        *ngIf="mode === 'confirmation'"
        class="blue-rounded-button new-farm-button"
        [disabled]="disabledAfterClick"
        (click)="confirm()"
      >{{'COMMON.BUTTONS.Yes' | translate}}</button>
      <button
        type="submit"
        *ngIf="mode === 'gotIt'"
        class="blue-rounded-button new-farm-button"
        [disabled]="disabledAfterClick"
        (click)="confirm()"
      >{{'COMMON.BUTTONS.got_it' | translate}}</button>

    </div>
  </div>
</div>
