import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddDeviceByQrModalComponent } from './add-device-by-qr-modal.component';
import { PipesModule } from '../../../pipes/pipes.module';
import { DropDownListsModule } from '../../dropdown-list/dropdown-list.module';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { AddBulkModule } from '../add-bulk/add-bulk.module';
import { SimpleDropdownModule } from '../../dropdowns/simple-dropdown/simple-dropdown.module';

@NgModule({
  declarations: [AddDeviceByQrModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule,
    DropDownListsModule,
    NgxScannerQrcodeModule,
    AddBulkModule,
    SimpleDropdownModule,
  ],
  exports: [AddDeviceByQrModalComponent],
})
export class AddDeviceByQrModalModule {}
