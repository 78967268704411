import { Injectable } from '@angular/core';
import { HarvestEvent } from '../api/models/harvest-event';

@Injectable({ providedIn: 'root' })
export class EventsService {
  private currentEvent: HarvestEvent;

  constructor() {}

  public setSelectedEvent(ev: HarvestEvent) {
    this.currentEvent = ev;
  }

  public get event(): HarvestEvent {
    return this.currentEvent;
  }
}
