import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { PipesModule } from "src/app/common/pipes/pipes.module";
import { CustomerSelectorsService } from "src/app/services/customer-selectors/customer-selectors.service";
import { DropDownListsModule } from "../../dropdown-list/dropdown-list.module";
import { CropDropdownComponent } from "./crop-dropdown.component";

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
        FormsModule,
        DropDownListsModule,
    ],
    exports: [CropDropdownComponent],
    declarations: [CropDropdownComponent],
    providers: [ DatePipe ],
})
export class CropDropdownModule { }