import {Directive, ElementRef, HostListener, Input} from '@angular/core';

export enum InputFilterMode {
  AlphaNumeric = 'AlphaNumeric',
  Numeric = 'Numeric',
}

@Directive({
  selector: '[inputFilter]'
})
export class InputFilterDirective {

  @Input()
  public inputFilterMode: InputFilterMode = InputFilterMode.AlphaNumeric;

  @Input()
  public inputFilterMaxLength: number = 0;

  constructor(private el: ElementRef) {

  }

  @HostListener('input', ['$event'])
  public onChange() {
    if (event.isTrusted) {
      event.stopPropagation();
      event.preventDefault();
      this.clearInput(this.el.nativeElement.value);
      this.el.nativeElement.dispatchEvent( new Event( "input" ) );
    }
  }

  @HostListener('paste', ['$event'])
  public onPaste() {
    event.stopPropagation();
    event.preventDefault();
    this.clearInput((event as ClipboardEvent).clipboardData.getData('text'));
    this.el.nativeElement.dispatchEvent( new Event( "input" ) );
  }

  private clearInput(stringToClear: string) {
    if (this.inputFilterMode == InputFilterMode.AlphaNumeric) {
      this.el.nativeElement.value = InputFilterDirective.removeForbiddenCharactersByRegExp(stringToClear, /\W/g);
    } else if (this.inputFilterMode == InputFilterMode.Numeric) {
      this.el.nativeElement.value = InputFilterDirective.removeForbiddenCharactersByRegExp(stringToClear, /[^0-9]/g);
    }
    if (this.inputFilterMaxLength > 0) {
      if ((this.el.nativeElement as HTMLInputElement).value.length > this.inputFilterMaxLength) {
        (this.el.nativeElement as HTMLInputElement).value = (this.el.nativeElement as HTMLInputElement).value.substr(0, this.inputFilterMaxLength);
      }
    }
  }

  private static removeForbiddenCharactersByRegExp(stringToClear: string, forbiddenCharactersRegExp: RegExp): string {
    return stringToClear.replace(forbiddenCharactersRegExp, '');
  }
}
