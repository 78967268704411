import { NgModule } from '@angular/core';
import { CropDropdownModule } from './crop-dropdown/crop-dropdown.module';
import { CustomerDropdownModule } from './customer-dropdown/customer-dropdown.module';
import { FarmDropdownModule } from './farm-dropdown/farm-dropdown.module';
import { HouseDropdownModule } from './house-dropdown/house-dropdown.module';
import { GroupDropdownModule } from './group-dropdown/group-dropdown.module';

@NgModule({
  imports: [
    CustomerDropdownModule,
    FarmDropdownModule,
    HouseDropdownModule,
    CropDropdownModule,
    GroupDropdownModule,
  ],
  exports: [
    CustomerDropdownModule,
    FarmDropdownModule,
    HouseDropdownModule,
    CropDropdownModule,
    GroupDropdownModule,
  ],
})
export class SelectorsModule {}
