<div class="display-flex position-relative wrapper">
  <div class="menus__menu">
    <div class="menus__menu-selector" *ngIf="isDateChanged">
      <div class="menus__menu-selector-label">{{ 'COMMON.Date' | translate }}</div>
      <date-picker
        *ngIf="isLastBroodingEvent()"
        [min]="minDate"
        [max]="maxDate"
        [value]="selectedDate"
        (valueChange)="changeSelectedDate($event)"
      ></date-picker>
      <div class="disabled disabled-input" *ngIf="!isLastBroodingEvent()">
        {{
        selectedDate
          | date: translationService.translationShortDateFormat
        }}
      </div>
    </div>
    <div class="menus__menu-selector height66" *ngIf="!isDateChanged">
      <div class="menus__menu-selector-label">{{ 'COMMON.Date' | translate }}</div>
      <input type="text" value="NA" (click)="isDateChanged = true; changeSelectedDate(selectedDate)"/>
      <div class="disabled disabled-input" *ngIf="!isLastBroodingEvent()">
        {{
          selectedDate
            | date: translationService.translationShortDateFormat
        }}
      </div>
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector height66" *ngIf="!isDateChanged">
      <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_Age' | translate }}</div>
      <input
        [options]="{
              digitGroupSeparator: translationService.digitGroupSeparator,
              decimalCharacter: translationService.decimalCharacter,
              decimalPlaces: 0,
              minimumValue: '0',
              minimumValue: '0',
              maximumValue: '80'
            }"
        autonumeric
        [value]="birdAge"
        (change)="changeBirdAge($event.target.value)"
      >
    </div>
  <div class="menus__dash">&mdash;</div>
  <div class="menus__menu">
    <div class="menus__menu-selector height66 custom-select custom-select__disabled">
      <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_age' | translate }}</div>
      <input disabled [value]="birdAge">
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector height66 custom-select">
      <div class="menus__menu-selector-label">{{"EVENT.duration" | translate}}</div>
      <input
        type="number"
        *ngIf="isLastBroodingEvent() && savedInitialDuration !== null"
        [min]="0"
        [(ngModel)]="selectedDuration"
        (input)="onDurationChanged($event.target.value)"
        (focus)="onDurationChanged($event.target.value)"
        (blur)="setValueIfLessThanOne($event.target.value)">
      <div class="disabled disabled-input" *ngIf="!isLastBroodingEvent()">
        {{selectedDuration}}
      </div>
    </div>
  </div>
  <div class="zones-wrapper">
    <selectable-zones
      [zonesInUse]="populatedZonesInUse"
      [isLastBroodingEvent]="isLastBroodingEvent()"
      (onSelectedZones)="setSelectedBroodingZones($event)"
    >
    </selectable-zones>
  </div>
</div>
