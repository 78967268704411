import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/common/pipes/pipes.module';
import { DropDownListsModule } from '../../../dropdown-list/dropdown-list.module';
import { GroupLocationComponent } from './group-location.component';

@NgModule({
  imports: [CommonModule, PipesModule, FormsModule, DropDownListsModule],
  exports: [GroupLocationComponent],
  declarations: [GroupLocationComponent],
  providers: [DatePipe],
})
export class GroupLocationModule {}
