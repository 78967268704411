import { NgModule } from '@angular/core';
import { EventsComponent } from './events.component';
import { NoDataAvailableModule } from '../no-data-available/no-data-available.module';
import { CommonModule } from '@angular/common';
import { DataGridModule } from '../data-grid/data-grid.module';
import { AddEventModalComponent } from './add-event-modal/add-event-modal.component';
import { PipesModule } from '../../pipes/pipes.module';
import { DatePickerModule } from '../date-picker/date-picker.module';
import {
  AutoCompleteModule,
  MultiSelectModule,
} from '@syncfusion/ej2-angular-dropdowns';
import { FormsModule } from '@angular/forms';
import { ConfirmationModalModule } from '../modals/confirmation-modal/confirmation.module';
import { VaccinationEventComponent } from './add-event-modal/vaccination-event/vaccination-event.component';
import { DropDownListsModule } from '../dropdown-list/dropdown-list.module';
import { FlushWaterLineComponent } from './add-event-modal/flush-water-line/flush-water-line.component';
import { WaterSanitationComponent } from './add-event-modal/water-sanitation/water-sanitation.component';
import { PlannedThinningAgesComponent } from './add-event-modal/planned-thinning-ages/planned-thinning-ages.component';
import { PlannedClearingAgeComponent } from './add-event-modal/planned-clearing-age/planned-clearing-age.component';
import { PlannedHandWeightComponent } from './add-event-modal/planned-hand-weight/planned-hand-weight.component';
import { PlannedFootpadScoringComponent } from './add-event-modal/planned-footpad-scoring/planned-footpad-scoring.component';
import { DefaultMultiselectModule } from '../dropdowns/default-multiselect/default-multiselect.module';
import { EditEventModalComponent } from './edit-event-modal/edit-event-modal.component';
import { FeedEventComponent } from './add-event-modal/feed-event/feed-event.component';
import { NutraceuticalEventComponent } from './add-event-modal/nutraceutical-event/nutraceutical-event.component';
import { AutonumericModule } from '@angularfy/autonumeric';
import { MedicationEventComponent } from './add-event-modal/medication-event/medication-event.component';
import { BroodingEventComponent } from './add-event-modal/brooding-event/brooding-event.component';
import { SelectableZonesModule } from '../selectable-zones/selectable-zones.module';
import { SimpleDropdownModule } from '../dropdowns/simple-dropdown/simple-dropdown.module';
import { TimePickerModule } from '../time-picker/time-picker.module';
import { DropdownsModule } from '../dropdowns/dropdowns.module';
import { ThinningEventComponent } from './add-event-modal/thinning-event/thinning-event.component';
import { ErrorModalModule } from '../modals/error-modal/error-modal.module';
import { NotificationModule } from '../modals/notification/notification.module';
import { OtherEventComponent } from './add-event-modal/other-event/other-event.component';

@NgModule({
  declarations: [
    EventsComponent,
    AddEventModalComponent,
    EditEventModalComponent,
    VaccinationEventComponent,
    FlushWaterLineComponent,
    WaterSanitationComponent,
    PlannedThinningAgesComponent,
    PlannedClearingAgeComponent,
    PlannedHandWeightComponent,
    PlannedFootpadScoringComponent,
    FeedEventComponent,
    NutraceuticalEventComponent,
    MedicationEventComponent,
    ThinningEventComponent,
    BroodingEventComponent,
    OtherEventComponent,
  ],
  imports: [
    NoDataAvailableModule,
    CommonModule,
    DataGridModule,
    PipesModule,
    DatePickerModule,
    MultiSelectModule,
    FormsModule,
    AutoCompleteModule,
    ConfirmationModalModule,
    DropDownListsModule,
    DropdownsModule,
    DefaultMultiselectModule,
    AutonumericModule,
    SelectableZonesModule,
    SimpleDropdownModule,
    TimePickerModule,
    ErrorModalModule,
    NotificationModule,
  ],
  exports: [
    EventsComponent,
    VaccinationEventComponent,
    FeedEventComponent,
    NutraceuticalEventComponent,
    MedicationEventComponent,
    ThinningEventComponent,
    FlushWaterLineComponent,
    WaterSanitationComponent,
    PlannedHandWeightComponent,
    PlannedFootpadScoringComponent,
    PlannedFootpadScoringComponent,
    BroodingEventComponent,
    OtherEventComponent,
    ErrorModalModule,
  ],
})
export class EventsModule {}
