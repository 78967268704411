/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { KpiDto } from '../models/kpi-dto';

@Injectable({
  providedIn: 'root',
})
export class KpiService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation runCron
   */
  static readonly RunCronPath = '/api/kpi/cron';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `runCron()` instead.
   *
   * This method doesn't expect any request body.
   */
  runCron$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, KpiService.RunCronPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `runCron$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  runCron(params?: {
  }): Observable<void> {

    return this.runCron$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getHealthWelfareKpi
   */
  static readonly GetHealthWelfareKpiPath = '/api/kpi/crop/{cropId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHealthWelfareKpi()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHealthWelfareKpi$Response(params: {
    cropId: number;
  }): Observable<StrictHttpResponse<KpiDto>> {

    const rb = new RequestBuilder(this.rootUrl, KpiService.GetHealthWelfareKpiPath, 'get');
    if (params) {
      rb.path('cropId', params.cropId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KpiDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHealthWelfareKpi$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHealthWelfareKpi(params: {
    cropId: number;
  }): Observable<KpiDto> {

    return this.getHealthWelfareKpi$Response(params).pipe(
      map((r: StrictHttpResponse<KpiDto>) => r.body as KpiDto)
    );
  }

  /**
   * Path part for operation sendHealthWelfareKpi
   */
  static readonly SendHealthWelfareKpiPath = '/api/kpi/crop/{cropId}/send';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendHealthWelfareKpi()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendHealthWelfareKpi$Response(params: {
    cropId: number;
  }): Observable<StrictHttpResponse<KpiDto>> {

    const rb = new RequestBuilder(this.rootUrl, KpiService.SendHealthWelfareKpiPath, 'get');
    if (params) {
      rb.path('cropId', params.cropId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KpiDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendHealthWelfareKpi$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendHealthWelfareKpi(params: {
    cropId: number;
  }): Observable<KpiDto> {

    return this.sendHealthWelfareKpi$Response(params).pipe(
      map((r: StrictHttpResponse<KpiDto>) => r.body as KpiDto)
    );
  }

}
