import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AuthService } from '../auth.service';
import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigService } from '../../config/config.service';
import { ErrorsService } from '../../errors-service/errors.service';
import {
  UserDetailsService,
  WAF_HEADER_USER_EMAIL,
} from '../../user/user-details.service';
import * as uuid from 'uuid';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private userDetailsService: UserDetailsService,
    private readonly configService: ConfigService,
    private errorsService: ErrorsService
  ) {}

  // tslint:disable-next-line:no-any
  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.internalIntercept(request, next));
  }

  // tslint:disable-next-line:no-any
  private async internalIntercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    if (
      this.configService.configuration &&
      request.url.startsWith(this.configService.configuration.apiUrl)
    ) {
      let accessToken = await this.authService.getAccessToken();
      if (accessToken !== undefined) {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + accessToken,
            uuid: uuid.v4(),
          },
        });
      } else {
        // for requests that should be requested without bearer
        request = request.clone({
          setHeaders: {},
        });
      }
      if (!!localStorage.getItem(WAF_HEADER_USER_EMAIL)) {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + accessToken,
            'x-sensehubpoultry-user': localStorage
              .getItem(WAF_HEADER_USER_EMAIL)
              ?.toLowerCase(),
            uuid: uuid.v4(),
          },
        });
      } else {
        if (!!this.userDetailsService?.userRes?.email) {
          localStorage.setItem(
            WAF_HEADER_USER_EMAIL,
            JSON.stringify(this.userDetailsService?.userRes?.email)
          );
        } else {
          request = request.clone({
            setHeaders: {
              'x-sensehubpoultry-user': 'unrecognized',
              uuid: uuid.v4(),
            },
          });
        }
      }
    }
    return next
      .handle(request)
      .pipe(
        tap(
          () => {},
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.authService.logout();
              } else {
                console.log('err', err);
                const parsedError =
                  typeof err?.error === 'string'
                    ? JSON.parse(err?.error)
                    : err?.error;
                console.log('parsedError', parsedError);
                if (
                  parsedError?.length > 0 &&
                  parsedError[0]?.hasOwnProperty('errorName') &&
                  parsedError[0]?.hasOwnProperty('formattedMessage') &&
                  parsedError[0]?.hasOwnProperty('message') &&
                  parsedError[0]?.hasOwnProperty('params')
                ) {
                  if (parsedError[0]?.errorName !== 'CUSTOM_ERROR_MESSAGE') {
                    this.errorsService.errorContentList = parsedError;
                    this.errorsService.isShowError$.next(true);
                  }
                } else {
                  this.errorsService.errorContentList = [
                    {
                      errorName: 'OPERATION_FAILED',
                      formattedMessage:
                        'Operation failed. Please try again. In case this is an on going issue please contact support',
                      message:
                        'Operation failed. Please try again. In case this is an on going issue please contact support',
                      params: [],
                    },
                  ];
                  this.errorsService.isShowError$.next(true);
                }
              }
              // else if (err.status === 409) {
              //   this.errorsService.isShowError$.next(true);
              // }
              return;
            }
          }
        )
      )
      .toPromise();
  }
}
