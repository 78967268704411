import { Injectable } from '@angular/core';
import { ConfigService } from '../../services/config/config.service';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
  HttpResponseBase,
} from '@angular/common/http';
import * as _ from 'lodash';

export class MockHttpMessageTemplate {
  method: string;
  requestHeaders: any;
  requestBody: any;
  responseHeaders: any;
  responseBody: any;
  responseStatus: any;
  isErrorResponse: boolean;
  errorResponseBody?: any;
}

export class MockHttpEndpointTemplateState {
  defaultEndpointTemplate: MockHttpEndpointTemplate;
  currentEndpointTemplate: MockHttpEndpointTemplate;
  file: string;
}

export class MockHttpEndpointTemplate {
  endpoint: string;
  messages: MockHttpMessageTemplate[];
}

export class HttpMessageTrace {
  endpoint: string;
  method: string;
  requestHeaders: any;
  requestBody: any;
  responseHeaders: any;
  responseStatus: string;
  date: Date;
}

@Injectable({
  providedIn: 'root',
})
export class MockHttpService {
  private templatesMap: Map<string, MockHttpEndpointTemplateState> = new Map<
    string,
    MockHttpEndpointTemplateState
  >();

  public httpMessagesHistory: HttpMessageTrace[] = [];

  private static readonly maxHistoryMessages: number = 20;

  public handleMessageDelayMs: number = 0;

  constructor(
    private httpClient: HttpClient,
    public configService: ConfigService
  ) {}

  public traceWebApiMessage(
    request: HttpRequest<any>,
    response: HttpResponseBase,
    outputToConsole: boolean
  ) {
    const endpointTemplate = new MockHttpEndpointTemplate();
    endpointTemplate.endpoint = this.resolveEndpointName(request);
    endpointTemplate.messages = [new MockHttpMessageTemplate()];
    endpointTemplate.messages[0].method = request.method;
    endpointTemplate.messages[0].requestHeaders = this.serializeHttpHeaders(
      request.headers
    );
    endpointTemplate.messages[0].requestBody = request.body;
    endpointTemplate.messages[0].responseHeaders = this.serializeHttpHeaders(
      response.headers
    );
    if (response instanceof HttpResponse) {
      endpointTemplate.messages[0].responseBody = response.body;
    } else if (response instanceof HttpErrorResponse) {
      endpointTemplate.messages[0].responseBody = response.error;
    }
    endpointTemplate.messages[0].responseStatus = response.status;
    if (outputToConsole) {
      console.log(JSON.stringify(endpointTemplate));
    }
    this.httpMessagesHistory.push({
      date: new Date(),
      endpoint: endpointTemplate.endpoint,
      method: request.method,
      requestHeaders: endpointTemplate.messages[0].requestHeaders,
      requestBody: _.cloneDeep(request.body),
      responseHeaders: endpointTemplate.messages[0].responseHeaders,
      responseStatus: endpointTemplate.messages[0].responseStatus,
    });
    if (this.httpMessagesHistory.length > MockHttpService.maxHistoryMessages) {
      this.httpMessagesHistory = this.httpMessagesHistory.slice(
        1,
        MockHttpService.maxHistoryMessages + 1
      );
    }
  }

  public async handleRequest(
    request: HttpRequest<any>
  ): Promise<HttpResponseBase> {
    let endpointName = this.resolveEndpointName(request);
    if (!this.templatesMap.has(endpointName)) {
      let candidateEndpointName = '';
      const keys = Array.from(this.templatesMap.keys());
      for (const key of keys) {
        if (
          endpointName.indexOf(key) == 0 &&
          key.length > candidateEndpointName.length
        ) {
          candidateEndpointName = key;
        }
      }
      if (candidateEndpointName.length == 0) {
        return null;
      }
      console.log(
        'The request exact endpoint mock not found, it replaced with following mock\nREQUEST: ' +
          endpointName +
          '\n   MOCK:' +
          candidateEndpointName
      );
      endpointName = candidateEndpointName;
    }
    const templateState = this.templatesMap.get(endpointName);
    await this.loadEndpointTemplate(templateState, endpointName);
    const messageTemplate = templateState.currentEndpointTemplate.messages.find(
      (msg) => {
        return msg.method === request.method;
      }
    );
    if (messageTemplate != null) {
      if (messageTemplate.isErrorResponse) {
        return new HttpErrorResponse({
          headers: this.deserializeHttpHeaders(messageTemplate.responseHeaders),
          status: messageTemplate.responseStatus,
          error: _.cloneDeep(
            messageTemplate.responseBody || messageTemplate.errorResponseBody
          ),
        });
      } else {
        return new HttpResponse({
          headers: this.deserializeHttpHeaders(messageTemplate.responseHeaders),
          status: messageTemplate.responseStatus,
          body: _.cloneDeep(messageTemplate.responseBody),
        });
      }
    }
  }

  public async getTemplate(
    endpoint: string
  ): Promise<MockHttpEndpointTemplate> {
    if (this.templatesMap.has(endpoint)) {
      const templateState = this.templatesMap.get(endpoint);
      await this.loadEndpointTemplate(templateState, endpoint);
      return templateState.currentEndpointTemplate;
    }
    return null;
  }

  public async setTemplate(template: MockHttpEndpointTemplate) {
    const templateState = this.templatesMap.get(template.endpoint);
    if (templateState != null) {
      templateState.currentEndpointTemplate = template;
    }
  }

  public resetTemplatesToDefault() {
    if (this.configService.configuration.useMockHttp) {
      this.initTemplates();
    }
  }

  public get endpoints(): string[] {
    return Array.from(this.templatesMap.keys());
  }

  public serializeHttpHeaders(httpHeaders: HttpHeaders): any {
    return httpHeaders.keys().map((headerKey) => {
      return { [headerKey]: httpHeaders.getAll(headerKey) };
    });
  }

  public deserializeHttpHeaders(httpHeadersArray: any[]): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    httpHeadersArray.forEach((httpHeaderObject) => {
      Object.keys(httpHeaderObject).forEach((key) => {
        httpHeaders = httpHeaders.append(key, httpHeaderObject[key]);
      });
    });
    return httpHeaders;
  }

  public resolveEndpointName(request: HttpRequest<any>): string {
    return request.urlWithParams.replace(
      this.configService.configuration.apiUrl,
      ''
    );
  }

  private async loadEndpointTemplate(
    templateState: MockHttpEndpointTemplateState,
    endpointName: string
  ) {
    if (templateState.defaultEndpointTemplate == null) {
      templateState.defaultEndpointTemplate = await this.httpClient
        .get<MockHttpEndpointTemplate>(templateState.file)
        .toPromise();
      if (templateState.defaultEndpointTemplate.endpoint != endpointName) {
        throw new Error(
          'Mapped endpoint: ' +
            endpointName +
            ' not match to:' +
            templateState.defaultEndpointTemplate.endpoint +
            ' in file:' +
            templateState.file
        );
      }
    }
    if (templateState.currentEndpointTemplate == null) {
      templateState.currentEndpointTemplate =
        _.cloneDeep<MockHttpEndpointTemplate>(
          templateState.defaultEndpointTemplate
        );
    }
  }

  private initTemplates() {
    this.initTemplateState(
      '/login',
      `assets/testing/templates/login-response.json`
    );
    this.initTemplateState(
      '/customer/7/customers',
      `assets/testing/templates/get-customer-customers.json`
    );
    this.initTemplateState(
      '/telemetry/aggregates/70/byDate/1',
      `assets/testing/templates/get-event-aggregatess-by-date.json`
    );
    this.initTemplateState(
      '/facilitygroup?customerId=7&recursive=true',
      `assets/testing/templates/get-facilitygroup.json`
    );

    // Forgot Password Verification Code
    // this.initTemplateState('/rest/api/user/resetpassword', `assets/testing/templates/user-reset-password.json`);
  }

  private initTemplateState(endpoint: string, file: string) {
    //  let value = await this.httpClient.get<MockHttpEndpointTemplate>(file).toPromise();
    if (this.templatesMap.has(endpoint)) {
      const endpointState = this.templatesMap.get(endpoint);
      if (endpointState.file != file) {
        throw new Error(
          'Cannot add template from file:' +
            file +
            '. Endpoint already exist:' +
            endpoint
        );
      }
      endpointState.currentEndpointTemplate = null;
    } else {
      const endpointState = new MockHttpEndpointTemplateState();
      endpointState.file = file;
      this.templatesMap.set(endpoint, endpointState);
    }
  }
}
