import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ConfigService } from '../config/config.service';
import * as moment from 'moment';

import { registerLocaleData } from '@angular/common';

import localeBg from '@angular/common/locales/bg';
import localeCs from '@angular/common/locales/cs';
import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeEl from '@angular/common/locales/el';
import localeEnGb from '@angular/common/locales/en-GB';
import localeEsMx from '@angular/common/locales/es-MX';
import localeEsAr from '@angular/common/locales/es-AR';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeLt from '@angular/common/locales/lt';
import localeLv from '@angular/common/locales/lv';
import localeNb from '@angular/common/locales/nb';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRo from '@angular/common/locales/ro';
import localeRu from '@angular/common/locales/ru';
import localeSk from '@angular/common/locales/sk';
import localeSl from '@angular/common/locales/sl';
import localeSv from '@angular/common/locales/sv';
import localeTr from '@angular/common/locales/tr';
import localeEe from '@angular/common/locales/ee';
import localeVi from '@angular/common/locales/vi';
import localeId from '@angular/common/locales/id';
import localeZhHans from '@angular/common/locales/zh-Hans';
import localeZhHansHk from '@angular/common/locales/zh-Hans-HK';
import localeTh from '@angular/common/locales/th';
// import {RestApiService} from '../rest-api/rest-api.service';
import { AuthService } from '../auth/auth.service';
import { TimeSelectionHoursMode } from '../../common/components/time-picker/time-selection/time-selection.component';
import { LanguageEnum } from '../api/models/language-enum';
// import {TimeSelectionHoursMode} from '../../common/components/calendar/time-selection/time-selection.component';

registerLocaleData(localeBg);
registerLocaleData(localeCs);
registerLocaleData(localeDa);
registerLocaleData(localeDe);
registerLocaleData(localeEl);
registerLocaleData(localeEnGb);
registerLocaleData(localeEsMx);
registerLocaleData(localeEsAr);
registerLocaleData(localeFi);
registerLocaleData(localeFr);
registerLocaleData(localeHu);
registerLocaleData(localeIt);
registerLocaleData(localeJa);
registerLocaleData(localeKo);
registerLocaleData(localeLt);
registerLocaleData(localeLv);
registerLocaleData(localeNb);
registerLocaleData(localeNl);
registerLocaleData(localePl);
registerLocaleData(localePt);
registerLocaleData(localeRo);
registerLocaleData(localeRu);
registerLocaleData(localeSk);
registerLocaleData(localeSl);
registerLocaleData(localeSv);
registerLocaleData(localeTr);
registerLocaleData(localeEe, 'et-ee');
registerLocaleData(localeVi, 'vi-vn');
registerLocaleData(localeZhHans, 'zh-chs');
registerLocaleData(localeZhHansHk, 'zh-cht');
registerLocaleData(localeTh, 'th-th');
registerLocaleData(localeId, 'id-id');

export const DEFAULT_LANGUAGE_TO_CREATE_CUSTOMER = LanguageEnum.EnUs;

export class UpdateMessagingLanguageModel {
  public messagingLanguage: string;
}

const imperialLocales = [LanguageEnum.EnUs];

export class TranslationDetails {
  public language: string;
  // tslint:disable-next-line:no-any
  public translationObject: any;
}

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  // tslint:disable-next-line:naming-convention
  private static readonly selectedLanguageKey: string = 'appLanguage';

  // tslint:disable-next-line:naming-convention
  private static readonly languagesJsonFile: string = 'assets/i18n/langs.json';

  private static readonly UpdateUserLanguageRoute: string =
    '/rest/api/system/users/{userId}/language';

  private static readonly DefaultLanguage: LanguageEnum = LanguageEnum.EnUs;

  private fallbackTranslation: TranslationDetails;

  private selectedTranslation: TranslationDetails;

  private defaultTranslation: TranslationDetails;

  private readonly translationCacheMap: Map<string, string> = new Map<
    string,
    string
  >();

  public static readonly ShTranslationServiceKey: string =
    'shTranslationService';

  public selectedLanguage: LanguageEnum = TranslationService.DefaultLanguage;

  // public isLanguageDirectlyChanged: boolean = false;

  public languageChangeSubject = new Subject();

  public digitGroupSeparator: string = ',';

  public decimalCharacter: string = '.';

  //              private readonly restApiService: RestApiService,
  constructor(
    private httpClient: HttpClient,
    public readonly configService: ConfigService,
    private authService: AuthService
  ) {
    this.translate[TranslationService.ShTranslationServiceKey] = this;
  }

  public get isImperial() {
    return imperialLocales.includes(this.selectedLanguage);
  }

  public get shortTimeFormat(): string {
    if (this.selectedLanguage === LanguageEnum.EnUs) {
      return moment
        .localeData(this.selectedLanguage)
        .longDateFormat('LT')
        .toLowerCase();
    } else {
      return moment.localeData(this.selectedLanguage).longDateFormat('LT');
    }
  }

  public get longTimeFormat(): string {
    return moment.localeData(this.selectedLanguage).longDateFormat('LTS');
  }

  public get hoursMode(): TimeSelectionHoursMode {
    if (this.selectedLanguage == LanguageEnum.EnUs) {
      return TimeSelectionHoursMode.Mode12Hours;
    } else {
      return TimeSelectionHoursMode.Mode24Hours;
    }
  }

  public get localeLongDateTimeFormat(): string {
    return this.translationShortDateFormat + ' ' + this.longTimeFormat;
  }

  public get localeLongDateTimeFormatMinutesOnly(): string {
    return this.translationShortDateFormat + ' / ' + this.shortTimeFormat;
  }

  public get translationShortDateFormat(): string {
    return this.translate('LOCALES.DATE_FORMAT.DEFAULT.DATE_TEMPLATE');
  }

  // public get hoursMode() : TimeSelectionHoursMode {
  //   if(this.selectedLanguage == 'en-us') {
  //     return TimeSelectionHoursMode.Mode12Hours;
  //   } else {
  //     return TimeSelectionHoursMode.Mode24Hours;
  //   }
  // }

  public getLanguages(): Observable<string[]> {
    return this.httpClient.get<string[]>(
      TranslationService.languagesJsonFile +
        `?${this.configService.configuration.clientVersion}`
    );
  }

  public transformLanguageEnum(lang: LanguageEnum): string {
    return lang.toLowerCase().replace('_', '-');
  }

  public async selectLanguage(
    selectedLanguage: LanguageEnum,
    updateLanguage: boolean
  ) {
    await this.updateLanguage(selectedLanguage, updateLanguage);
    if (this.defaultTranslation == null) {
      this.defaultTranslation = await this.loadTranslation(
        TranslationService.DefaultLanguage
      );
    }
    this.selectedLanguage = selectedLanguage;
    moment.locale(this.selectedLanguage);
    this.fallbackTranslation = null;
    if (this.selectedLanguage == TranslationService.DefaultLanguage) {
      this.selectedTranslation = this.defaultTranslation;
    } else {
      this.selectedTranslation = await this.loadTranslation(
        this.selectedLanguage
      );
      this.fallbackTranslation = await this.loadTranslation(
        TranslationService.DefaultLanguage
      );
    }
    this.translationCacheMap.clear();

    this.setSeparators();
    this.languageChangeSubject.next();
  }

  public setSeparators() {
    const separators = (1000.1)
      .toLocaleString(this.transformLanguageEnum(this.selectedLanguage))
      .match(/\D/g);

    this.digitGroupSeparator = separators[0];
    this.decimalCharacter = separators[1];
  }

  public translate(
    key: string,
    defaultValue?: string,
    ...params: string[]
  ): string {
    if (key == null) {
      return null;
    }
    if (!this.translationCacheMap.has(key)) {
      let foundTranslation = this.resolveMissingTranslation(key, defaultValue);
      if (foundTranslation == null) {
        // this condition will never execute
        this.translationCacheMap.set(key, defaultValue ?? key);
      } else {
        this.translationCacheMap.set(key, foundTranslation);
      }
    }
    let result = this.translationCacheMap.get(key);
    if (params && params.length) {
      params.forEach((p, i) => {
        result = result.replace(new RegExp(`\\{${i}\\}`, 'g'), p);
      });
    }
    return result;
  }

  public resolveMissingTranslation(key: string, defaultValue?: string) {
    let translationResult = this.findMissingTranslation(
      key,
      this.selectedTranslation.translationObject,
      defaultValue
    );
    if (translationResult == key) {
      if (this.fallbackTranslation != null) {
        translationResult = this.findMissingTranslation(
          key,
          this.fallbackTranslation.translationObject,
          defaultValue
        );
      }
    }
    return translationResult;
  }

  public async initTranslations() {
    this.selectedLanguage = localStorage.getItem(
      TranslationService.selectedLanguageKey
    ) as LanguageEnum;
    if (this.selectedLanguage == null) {
      this.selectedLanguage = TranslationService.DefaultLanguage;
    }
    await this.selectLanguage(this.selectedLanguage, false);
  }

  // tslint:disable-next-line:no-any
  private findMissingTranslation(
    key: string,
    translationObj: any,
    defaultValue?: string
  ) {
    let translation = this.findMissingKeyTranslation(key, translationObj);
    if (translation != null) {
      return translation;
    } else {
      return defaultValue ?? key;
    }
  }

  // tslint:disable-next-line:no-any
  private findMissingKeyTranslation(key: string, translationObj: any): string {
    if (key == null) {
      return null;
    }
    let keyParts = key.split('.');
    let leftKeyPart = key;
    for (let i = keyParts.length - 1; i >= 0; i--) {
      let propertyNameInvariantCase = Object.getOwnPropertyNames(
        translationObj
      ).find((value) => value.toLowerCase() == leftKeyPart.toLowerCase());
      if (propertyNameInvariantCase != null) {
        let subTranslationObj = translationObj[propertyNameInvariantCase];
        if (subTranslationObj != null) {
          if (leftKeyPart == key) {
            if (typeof subTranslationObj === 'string') {
              return <string>subTranslationObj;
            } else {
              continue;
            }
          } else {
            if (!(typeof subTranslationObj === 'string')) {
              let foundSubTranslation = this.findMissingKeyTranslation(
                key.substr(leftKeyPart.length + 1),
                subTranslationObj
              );
              if (foundSubTranslation != null) {
                return foundSubTranslation;
              }
            }
          }
        }
      }
      leftKeyPart = leftKeyPart.substr(
        0,
        leftKeyPart.length - ('.' + keyParts[i]).length
      );
    }
    return null;
  }

  private async loadTranslation(
    language: LanguageEnum
  ): Promise<TranslationDetails> {
    let translationDetails = new TranslationDetails();
    translationDetails.language = language;
    translationDetails.translationObject = await this.getTranslation(
      translationDetails.language
    );
    return translationDetails;
  }

  private async updateLanguage(
    language: string,
    updateLanguageStorage: boolean
  ) {
    if (!updateLanguageStorage) {
      return;
    } else {
      localStorage.setItem(TranslationService.selectedLanguageKey, language);
    }
  }

  // tslint:disable-next-line:no-any
  public async getTranslation(language: string): Promise<any> {
    language = language.toLowerCase().replace('_', '-');
    return this.httpClient
      .get(
        `assets/i18n/poultry/${language}.json?` +
          this.configService.configuration.clientVersion
      )
      .toPromise();
  }
}
