import {Directive, ElementRef, Input, Output} from '@angular/core';
import {ClickPressEventsService} from '../../services/click-press-events/click-press-events.service';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {PopupViewStateService} from '../components/dropdown-list/popups/popup-template/popup-view-state.service';

@Directive({
  selector: '[outsideClickHandler]'
})
export class OutsideClickHandlerDirective {

  @Input()
  public outsideClickIgnoreListComponents: string[] = [];

  @Input()
  public outsideClickIncludeListComponents: string[] = [];

  @Output()
  public onOutsideClick: Observable<MouseEvent>;

  constructor(private popupElement: ElementRef,
              private popupViewStateService : PopupViewStateService,
              private readonly clickPressEventsService: ClickPressEventsService) {
    this.onOutsideClick = this.clickPressEventsService.documentClick
      .pipe(
        filter((event) => this.filterClick(event)),
      );
  }

  public filterClick(event): boolean {
    if (event.target.parentElement != null &&
        !this.popupElement.nativeElement.contains(event.target)) {

      if(this.outsideClickIncludeListComponents != null &&
         this.outsideClickIncludeListComponents.length > 0) {

        for (let includeComponent of this.outsideClickIncludeListComponents) {
          let includeElement = this.popupViewStateService.getComponent(includeComponent);
          if (includeElement != null &&
            includeElement.nativeElement.contains(event.target)) {
            return true;
          }
        }

      } else {

        if (this.outsideClickIgnoreListComponents != null &&
            this.outsideClickIgnoreListComponents.length > 0) {
          for (let ignoreComponent of this.outsideClickIgnoreListComponents) {
            let ignoreElement = this.popupViewStateService.getComponent(ignoreComponent);
            if (ignoreElement != null &&
              ignoreElement.nativeElement.contains(event.target)) {
              return false;
            }
          }
        }
        if(this.popupViewStateService.partOfOutsideClickIgnoreElement(event.target)) {
          return false;
        }

        let cdkOverlayContainers = document.getElementsByClassName('cdk-overlay-container');
        if (cdkOverlayContainers.length > 0 &&
          cdkOverlayContainers.item(0).contains(event.target)) {
          return false;
        }

        //validate if calendar component
        if(event.target.className.toString().startsWith('owl-') ||
           event.target.className.toString().startsWith('cdk-overlay') ||
          (event.target.parentElement != null &&
          (event.target.parentElement.className.toString().startsWith('owl-') ||
           event.target.parentElement.className.toString().startsWith('cdk-overlay')))){
          return false;
        }

        return true;
      }
    }
  }

}
