import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AddDeviceModalComponent} from './add-device-modal.component';
import {PipesModule} from '../../../pipes/pipes.module';
import { DropDownListsModule } from '../../dropdown-list/dropdown-list.module';

@NgModule({
  declarations: [AddDeviceModalComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        PipesModule,
        DropDownListsModule,
    ],
  exports: [AddDeviceModalComponent]
})
export class AddDeviceModalModule { }
