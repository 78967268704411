import {Injectable} from '@angular/core';
import {ConfigService} from '../config/config.service';
import {MockHttpService} from '../../testing/services/mock-http.service';
import {TranslationService} from '../translations/translation.service';
import {ClientSessionRecorderService} from '../../testing/services/client-session-recorder/client-session-recorder.service';
import {ApiConfiguration} from '../api/api-configuration';
import {AuthService} from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BootService {
  private static readonly OktaUnknownIssuer: string = 'https://unknown/';

  private static readonly OktaUnknownClientId: string = 'unknown';

  constructor(
    public configService: ConfigService,
    private mockHttpService: MockHttpService,
    private translationService: TranslationService,
    private clientSessionRecorderService: ClientSessionRecorderService,
    private apiConfiguration: ApiConfiguration,
    private authService: AuthService) {
  }

  public init() {
    return new Promise(async (resolve, reject) => {
      await this.configService.loadApplicationConfiguration();
      this.apiConfiguration.rootUrl = this.configService.configuration.apiUrl;
      await this.clientSessionRecorderService.init();
      await this.translationService.initTranslations();
      await this.mockHttpService.resetTemplatesToDefault();
      await this.authService.init();
      // await this.setupService.loadServerSetup();
      // this.webAppVersionProxyService.start();
      resolve(true);
    });
  }

  public initOktaConfig()  {
    const config = this.configService.storedConfiguration;
    const issuer = config != null ? config.oktaIssuerUrl : BootService.OktaUnknownIssuer;
    const clientId = config != null ? config.oktaClientId : BootService.OktaUnknownClientId;
    return {
      issuer: issuer,
      clientId: clientId,
      pkce: true,
      redirectUri: location.origin + '/implicit/callback',
      postLogoutRedirectUri: location.origin + '/auth'
    };
  }
}
