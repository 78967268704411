import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { TranslationService } from '../../../services/translations/translation.service';
import { PopupTemplateComponent } from '../dropdown-list/popups/popup-template/popup-template.component';
import { EpochStateModel } from './epoch-state-model';
import { TimeSelectionHoursMode } from './time-selection/time-selection.component';

export enum AmPmModeEnum {
  Am,
  Pm,
}

@Component({
  selector: 'time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit, OnChanges, OnDestroy {
  private static readonly DefaultThemeColor = '#000';

  private static readonly DefaultBackgroundColor = '#FFF';

  @Input()
  public selectedEpoch: EpochStateModel;

  @Input()
  public disabled: boolean;

  @Input()
  public isFieldError: boolean = false;

  @Input()
  public shIdPostfix: string = '';

  @Input()
  public fontSize: string;

  @Input()
  public themeColor: string = '#2a2a2a';

  @Input()
  public backgroundColor: string = TimePickerComponent.DefaultBackgroundColor;

  @Input()
  public shownRemovableIcon: boolean = true;

  @Input()
  public noLeftPadding: boolean;

  @Input()
  public useDropdown: boolean = true;

  @Output()
  public epochChanged = new EventEmitter<EpochStateModel>();

  public isOpen: boolean;

  public selectedEpochSubscription;

  public timeText: string = '';

  private inputFocused: boolean;

  private langChangedSubscription: Subscription;

  @ViewChild(PopupTemplateComponent, { static: true })
  public popupTemplate: PopupTemplateComponent;

  constructor(public readonly translationService: TranslationService) {}

  public ngOnInit() {
    this.langChangedSubscription =
      this.translationService.languageChangeSubject.subscribe(() => {
        this.updateSelectedTimeText();
      });
    this.selectedEpochSubscription = this.selectedEpoch.updated.subscribe(
      (value: string) => {
        if (value) {
          this.updateSelectedTimeText();
        }
      }
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.updateSelectedTimeText();
  }

  public ngOnDestroy(): void {
    this.selectedEpochSubscription.unsubscribe();
    this.langChangedSubscription.unsubscribe();
  }

  public get isDisabled(): boolean {
    return this.disabled || this.selectedEpoch.errorMinMax;
  }

  public get isRemoveIconShown(): boolean {
    return (
      !this.isDisabled &&
      this.shownRemovableIcon &&
      this.timeText != null &&
      this.timeText.length > 0
    );
  }

  public get markAsError(): boolean {
    if (this.isFieldError) {
      return true;
    }
    return (
      !this.inputFocused &&
      this.timeText != null &&
      this.timeText.length > 0 &&
      this.selectedEpoch.epochTime == null
    );
  }

  public onOutsideClick() {
    this.isOpen = false;
  }

  public onHeaderClick() {
    this.openPopup();
  }

  private openPopup() {
    if (this.isDisabled || !this.useDropdown) {
      return;
    }
    this.isOpen = true;
  }

  public clearDateValue(event?: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.selectedEpoch.epochTime = null;
    this.updateSelectedTimeText();
    this.epochChanged.emit(this.selectedEpoch);
  }

  public onTimeEpochChanged() {
    this.updateSelectedTimeText();
    this.epochChanged.emit(this.selectedEpoch);
  }

  public onSelectedDateTimeTextChange(event: KeyboardEvent) {
    if (event.key == 'Tab' && event.type == 'keydown') {
      this.isOpen = false;
      return;
    }
    if (event.key == 'Control' || event.key == 'v') {
      return;
    }
    this.openPopup();
    if (this.timeText.length < this.displayTimeTextFormat.length) {
      this.selectedEpoch.epochTime = null;
    } else {
      let parsedTime = moment(
        this.timeText,
        this.translationService.shortTimeFormat,
        false
      );
      if (parsedTime.isValid()) {
        this.selectedEpoch.epochTime = parsedTime.unix();
        this.updateSelectedTimeText();
      } else {
        this.selectedEpoch.epochTime = null;
      }
    }
    this.epochChanged.emit(this.selectedEpoch);
  }

  public onPaste(event: ClipboardEvent) {
    this.openPopup();
    let clipboardData = event.clipboardData.getData('text');
    let parsedTime = moment(clipboardData, 'HH:mm a', false);
    if (parsedTime.isValid()) {
      this.selectedEpoch.epochTime = parsedTime.unix();
      this.updateSelectedTimeText();
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.selectedEpoch.epochTime = null;
    }
    this.epochChanged.emit(this.selectedEpoch);
  }

  public onFocus() {
    this.openPopup();
    this.inputFocused = true;
  }

  public onBlur() {
    this.inputFocused = false;
    this.epochChanged.emit(this.selectedEpoch);
  }

  private updateSelectedTimeText() {
    if (this.selectedEpoch.epochTime != null) {
      this.timeText = moment
        .unix(this.selectedEpoch.epochTime)
        .utc()
        .format(this.displayTimeTextFormat);
    } else {
      this.timeText = '';
    }
  }

  private get displayTimeTextFormat(): string {
    if (
      this.translationService.hoursMode == TimeSelectionHoursMode.Mode12Hours
    ) {
      return 'hh:mm';
    } else {
      return 'HH:mm';
    }
  }
}
