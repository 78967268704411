<div class="privacy-wrapper" *ngIf="isShowPrivacy" (scroll)="isReadEverything($event, 'PRIVACY')">
  <div [innerHTML]="srcPrivacy"></div>
</div>

<div class="privacy-wrapper" *ngIf="isShowEULA" (scroll)="isReadEverything($event, 'EULA')">
  <div [innerHTML]="srcEULA"></div>
</div>

<div class="width-100 display-flex justify-center" *ngIf="mode === privacyModesEnum.info">
  <button class="blue-rounded-button new-farm-button" (click)="goToLogin()">{{'LOGIN.FORM.to_login' | translate}}</button>
</div>

<div class="width-100 display-flex justify-center" *ngIf="mode === privacyModesEnum.invite">
  <button class="blue-rounded-button new-farm-button" (click)="goToLogin()">{{'LOGIN.FORM.to_invite' | translate}}</button>
  <button class="blue-rounded-button new-farm-button"  [disabled]="!isReadAllPRIVACY"  (click)="acceptPrivacy()" *ngIf="isShowPrivacy">{{'LOGIN.FORM.accept_privacy' | translate}}</button>
  <button class="blue-rounded-button new-farm-button" [disabled]="!isReadAllEULA" (click)="acceptEULA()" *ngIf="isShowEULA">{{'LOGIN.FORM.accept_eula' | translate}}</button>
</div>
