import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PipesModule } from "src/app/common/pipes/pipes.module";
import { ConfirmationModalComponent } from "./confirmation.component";

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
    ],
    exports: [ConfirmationModalComponent],
    declarations: [ConfirmationModalComponent],
})
export class ConfirmationModalModule {}