import {NgModule} from '@angular/core';
import {AnalysisComponent} from './analysis.component';
import {PipesModule} from '../../../pipes/pipes.module';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';

@NgModule({
    declarations: [AnalysisComponent],
    imports: [
        PipesModule,
        RouterModule,
        CommonModule
    ],
    exports: [AnalysisComponent]
})
export class AnalysisModule {

}
