import {Inject, Injectable} from '@angular/core';
import {WINDOW} from '../../../utils/window-utils';
import {ClientSessionRecorderStorageService} from './client-session-recorder-storage.service';
import {v4 as uuidv4} from 'uuid';
import {
  ClientSessionRecorderMessage,
  ClientSessionRecorderMessageData, ClientSessionRecorderMessageDirection,
  ClientSessionRecorderModeType
} from "../../common-model/csr-message.model";
import {
  MessageReceivedClientSessionRecorderStatus,
  StartModeClientSessionRecorderStatus
} from "../../common-model/csr-message-status.model";

@Injectable({
  providedIn: 'root'
})
export class ClientSessionRecorderMessagingService {

  private static readonly RECORDING_CONNECTIVITY_TIMEOUT:number = 1000;

  private static readonly RECORDING_CONNECTIVITY_NUMBER_OF_ATTEMPTS:number = 10;

  // tslint:disable-next-line:no-any
  private responseTimeoutHandle: any;

  private currentAttempt: number = 0;

  private readonly pendingSendMessages: ClientSessionRecorderMessage[] = [];

  constructor(@Inject(WINDOW) private _window: Window,
              private readonly clientSessionRecorderStorageService: ClientSessionRecorderStorageService) {

  }

  public init() {
    this.sendStartModeMessage();
  }

  public onMessageReceived(messageReceivedMessage: MessageReceivedClientSessionRecorderStatus) {
    if(this.pendingSendMessages.length == 0 ||
      this.pendingSendMessages[0].messageId != messageReceivedMessage.receivedMessageId) {
      return;
    }
    clearTimeout(this.responseTimeoutHandle);
    this.responseTimeoutHandle = null;
    this.currentAttempt = 0;
    this.pendingSendMessages.splice(0,1);
    this.processPendingSendMessages();
  }

  public addMessageToSend(data:ClientSessionRecorderMessageData, processMessages: boolean) {
    let message : ClientSessionRecorderMessage = {
      direction: ClientSessionRecorderMessageDirection.ClientToExtension,
      data: data,
      tabId: null,
      messageId: uuidv4(),
      handledByBackground: false
    };
    this.pendingSendMessages.push(message);
    if(processMessages) {
      this.processPendingSendMessages();
    }
  }

  private processPendingSendMessages() {
    if(this.responseTimeoutHandle || this.pendingSendMessages.length == 0) {
      return;
    }
    this.pendingSendMessages[0].handledByBackground = false;
    this.responseTimeoutHandle = setTimeout(() => this.onConnectivityTimeout(), ClientSessionRecorderMessagingService.RECORDING_CONNECTIVITY_TIMEOUT);
    this._window.postMessage(this.pendingSendMessages[0], '*');
  }


  private onConnectivityTimeout() {
    if(this.currentAttempt < ClientSessionRecorderMessagingService.RECORDING_CONNECTIVITY_NUMBER_OF_ATTEMPTS) {
      this.responseTimeoutHandle =  null;
      this.currentAttempt++;
      this.processPendingSendMessages();
    } else {
      this.clientSessionRecorderStorageService.storeNewRecorderStatus(null);
      if(this.clientSessionRecorderStorageService.recorderStatus.changeMode == ClientSessionRecorderModeType.Recording) {
        location.reload();
      } else if(this.clientSessionRecorderStorageService.recorderStatus.changeMode == ClientSessionRecorderModeType.Playback) {
        window.close();
      }
    }
  }

  private sendStartModeMessage() {
    let connectedStatus = new StartModeClientSessionRecorderStatus();
    connectedStatus.startMode = this.clientSessionRecorderStorageService.recorderStatus.changeMode;
    this.addMessageToSend(connectedStatus, false);
  }
}
