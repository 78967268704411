/* tslint:disable */
/* eslint-disable */
export enum PermissionName {
  AddSuperAdmin = 'ADD_SUPER_ADMIN',
  SuperAdminUpdateRoles = 'SUPER_ADMIN_UPDATE_ROLES',
  CustomerDropdown = 'CUSTOMER_DROPDOWN',
  DashboardBirdCount = 'DASHBOARD_BIRD_COUNT',
  DashboardQuickInsight = 'DASHBOARD_QUICK_INSIGHT',
  DashboardReports = 'DASHBOARD_REPORTS',
  DashboardWaterConsumption = 'DASHBOARD_WATER_CONSUMPTION',
  DashboardWeight = 'DASHBOARD_WEIGHT',
  DashboardAlerts = 'DASHBOARD_ALERTS',
  PsSensorsOverview = 'PS_SENSORS_OVERVIEW',
  PsSensorsClimateMap = 'PS_SENSORS_CLIMATE_MAP',
  PsSensorsWaterConsumption = 'PS_SENSORS_WATER_CONSUMPTION',
  PsSensorsDarkTime = 'PS_SENSORS_DARK_TIME',
  MyFarmCropCardViewEnvironmentReadings = 'MY_FARM_CROP_CARD_VIEW_ENVIRONMENT_READINGS',
  MyFarmCropCardViewMortality = 'MY_FARM_CROP_CARD_VIEW_MORTALITY',
  MyFarmCropCardEditMortality = 'MY_FARM_CROP_CARD_EDIT_MORTALITY',
  MyFarmCropCardAddThinning = 'MY_FARM_CROP_CARD_ADD_THINNING',
  MyFarmCropCardCloseCrop = 'MY_FARM_CROP_CARD_CLOSE_CROP',
  MyFarmCropCardNewCrop = 'MY_FARM_CROP_CARD_NEW_CROP',
  MyFarmCropCardEditCropInformation = 'MY_FARM_CROP_CARD_EDIT_CROP_INFORMATION',
  MyFarmCropCardEditAdminCropInformation = 'MY_FARM_CROP_CARD_EDIT_ADMIN_CROP_INFORMATION',
  MyFarmCropCardViewEvents = 'MY_FARM_CROP_CARD_VIEW_EVENTS',
  MyFarmCropCardAddEvents = 'MY_FARM_CROP_CARD_ADD_EVENTS',
  MyFarmCropCardEditEvents = 'MY_FARM_CROP_CARD_EDIT_EVENTS',
  MyFarmCropCardDeleteEvents = 'MY_FARM_CROP_CARD_DELETE_EVENTS',
  MyFarmCropCardCompleteEvents = 'MY_FARM_CROP_CARD_COMPLETE_EVENTS',
  MyFarmPerformanceAddPerformance = 'MY_FARM_PERFORMANCE_ADD_PERFORMANCE',
  MyFarmPerformanceEditPerformance = 'MY_FARM_PERFORMANCE_EDIT_PERFORMANCE',
  MyFarmPerformanceDeletePerformance = 'MY_FARM_PERFORMANCE_DELETE_PERFORMANCE',
  MyFarmPerformancePerformanceMenu = 'MY_FARM_PERFORMANCE_PERFORMANCE_MENU',
  MyFarmMedicineBookAddMedicine = 'MY_FARM_MEDICINE_BOOK_ADD_MEDICINE',
  MyFarmMedicineBookEditMedicine = 'MY_FARM_MEDICINE_BOOK_EDIT_MEDICINE',
  MyFarmMedicineBookDeleteMedicine = 'MY_FARM_MEDICINE_BOOK_DELETE_MEDICINE',
  MyFarmMedicineBookMedicineMenu = 'MY_FARM_MEDICINE_BOOK_MEDICINE_MENU',
  FarmOverviewFarmViewFarmHouseDetails = 'FARM_OVERVIEW_FARM_VIEW_FARM_HOUSE_DETAILS',
  FarmOverviewFarmEditFarm = 'FARM_OVERVIEW_FARM_EDIT_FARM',
  FarmOverviewFarmAddNewFarm = 'FARM_OVERVIEW_FARM_ADD_NEW_FARM',
  FarmOverviewFarmDeleteFarm = 'FARM_OVERVIEW_FARM_DELETE_FARM',
  FarmOverviewHouseEditHouse = 'FARM_OVERVIEW_HOUSE_EDIT_HOUSE',
  FarmOverviewHouseAddHouse = 'FARM_OVERVIEW_HOUSE_ADD_HOUSE',
  FarmOverviewHouseDeleteHouse = 'FARM_OVERVIEW_HOUSE_DELETE_HOUSE',
  FarmOverviewHouseStartDateView = 'FARM_OVERVIEW_HOUSE_START_DATE_VIEW',
  FarmOverviewHouseStartDateEdit = 'FARM_OVERVIEW_HOUSE_START_DATE_EDIT',
  DeviceViewDevice = 'DEVICE_VIEW_DEVICE',
  DeviceAddDevice = 'DEVICE_ADD_DEVICE',
  DeviceAddDeviceBulk = 'DEVICE_ADD_DEVICE_BULK',
  DeviceAddBaseStation = 'DEVICE_ADD_BASE_STATION',
  DeviceEditDeviceAndBaseStation = 'DEVICE_EDIT_DEVICE_AND_BASE_STATION',
  DeviceDeleteDeviceAndBaseStation = 'DEVICE_DELETE_DEVICE_AND_BASE_STATION',
  AddDeviceViaQrCode = 'ADD_DEVICE_VIA_QR_CODE',
  CustomerMgmtCustomerViewCustomers = 'CUSTOMER_MGMT_CUSTOMER_VIEW_CUSTOMERS',
  CustomerMgmtCustomerAddCustomer = 'CUSTOMER_MGMT_CUSTOMER_ADD_CUSTOMER',
  CustomerMgmtCustomerEditCustomer = 'CUSTOMER_MGMT_CUSTOMER_EDIT_CUSTOMER',
  CustomerMgmtCustomerDeleteCustomer = 'CUSTOMER_MGMT_CUSTOMER_DELETE_CUSTOMER',
  CustomerMgmtCustomerDeactivateCustomer = 'CUSTOMER_MGMT_CUSTOMER_DEACTIVATE_CUSTOMER',
  CustomerMgmtCustomersSubmenu = 'CUSTOMER_MGMT_CUSTOMERS_SUBMENU',
  CustomerMgmtMyUsersSubmenu = 'CUSTOMER_MGMT_MY_USERS_SUBMENU',
  CustomerMgmtUserAddUser = 'CUSTOMER_MGMT_USER_ADD_USER',
  CustomerMgmtUserEditUser = 'CUSTOMER_MGMT_USER_EDIT_USER',
  CustomerMgmtUserDeleteUser = 'CUSTOMER_MGMT_USER_DELETE_USER',
  CustomerMgmtUserViewUsers = 'CUSTOMER_MGMT_USER_VIEW_USERS',
  CustomerHatcheryRead = 'CUSTOMER_HATCHERY_READ',
  CustomerHatcheryEdit = 'CUSTOMER_HATCHERY_EDIT',
  CustomerFeedMillRead = 'CUSTOMER_FEED_MILL_READ',
  CustomerFeedMillEdit = 'CUSTOMER_FEED_MILL_EDIT',
  AnalysisSensors = 'ANALYSIS_SENSORS',
  AnalysisComparisonReport = 'ANALYSIS_COMPARISON_REPORT',
  AnalysisMortality = 'ANALYSIS_MORTALITY',
  Reports = 'REPORTS',
  NotificationSettingUpdate = 'NOTIFICATION_SETTING_UPDATE',
  UserNotificationSettingUpdate = 'USER_NOTIFICATION_SETTING_UPDATE',
  FacilityGroupNotificationSettingUpdate = 'FACILITY_GROUP_NOTIFICATION_SETTING_UPDATE',
  FacilityGroupNotificationSettingRead = 'FACILITY_GROUP_NOTIFICATION_SETTING_READ',
  SystemNoteRead = 'SYSTEM_NOTE_READ',
  SystemNoteUpdate = 'SYSTEM_NOTE_UPDATE',
  CreateEndOfCropReport = 'CREATE_END_OF_CROP_REPORT',
  EndOfCropReportDelete = 'END_OF_CROP_REPORT_DELETE',
  EndOfCropReportEdit = 'END_OF_CROP_REPORT_EDIT',
  EndOfCropReportView = 'END_OF_CROP_REPORT_VIEW',
  DisableWarning = 'DISABLE_WARNING',
  CreateUserAllFarms = 'CREATE_USER_ALL_FARMS',
  MyFarmCropCardAddBrooding = 'MY_FARM_CROP_CARD_ADD_BROODING',
  MyFarmCropCardEditBrooding = 'MY_FARM_CROP_CARD_EDIT_BROODING',
  MyFarmCropCardDeleteBrooding = 'MY_FARM_CROP_CARD_DELETE_BROODING',
  WeightPredictionView = 'WEIGHT_PREDICTION_VIEW',
  UpdateHarvestCustomerDefaults = 'UPDATE_HARVEST_CUSTOMER_DEFAULTS',
  UpdateHarvestCustomerDefaultsWellfare = 'UPDATE_HARVEST_CUSTOMER_DEFAULTS_WELLFARE',
  ViewHarvestCustomerDefaults = 'VIEW_HARVEST_CUSTOMER_DEFAULTS',
  EditBreedSettings = 'EDIT_BREED_SETTINGS',
  EditVaccinationSetting = 'EDIT_VACCINATION_SETTING',
  EditListsSetting = 'EDIT_LISTS_SETTING',
  DefaultLuxRead = 'DEFAULT_LUX_READ',
  DefaultLuxWrite = 'DEFAULT_LUX_WRITE',
  ReadUiDefaults = 'READ_UI_DEFAULTS',
  UpdateUiDefaults = 'UPDATE_UI_DEFAULTS',
  MyFarmCropCardViewCropInformation = 'MY_FARM_CROP_CARD_VIEW_CROP_INFORMATION',
  UpdateHarvestCustomerDefaultsCv = 'UPDATE_HARVEST_CUSTOMER_DEFAULTS_CV',
  ReadHarvestCustomerDefaultsCv = 'READ_HARVEST_CUSTOMER_DEFAULTS_CV',
  UpdateHarvestCustomerDefaultsAutoExport = 'UPDATE_HARVEST_CUSTOMER_DEFAULTS_AUTO_EXPORT',
  DeviceDeactivate = 'DEVICE_DEACTIVATE',
  ViewByZoneRead = 'VIEW_BY_ZONE_READ',
  UpdateHarvestCustomerDefaultsGrouping = 'UPDATE_HARVEST_CUSTOMER_DEFAULTS_GROUPING',
  ReadHarvestCustomerDefaultsGrouping = 'READ_HARVEST_CUSTOMER_DEFAULTS_GROUPING',
  ReadGroup = 'READ_GROUP',
  UpdateGroup = 'UPDATE_GROUP'
}
