export enum RoleName {
  SuperAdmin = 'SUPER_ADMIN',
  FarmManager = 'FARM_MANAGER',
  RestrictedFarmer = 'RESTRICTED_FARMER',
  ReadOnlyFarmer = 'READ_ONLY_FARMER',
  SupportAdmin = 'SUPPORT_ADMIN',
  Integrator = 'INTEGRATOR',
  Retailer = 'RETAILER',
  CustomerManager = 'CUSTOMER_MANAGER',
}
