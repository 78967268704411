import { NgModule } from '@angular/core';
import { WeightWidgetComponent } from './weight-widget.component';
import { NoDataAvailableModule } from '../../no-data-available/no-data-available.module';
import { ChartAllModule } from '@syncfusion/ej2-angular-charts';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
  declarations: [WeightWidgetComponent],
  imports: [NoDataAvailableModule, ChartAllModule, CommonModule, PipesModule],
  exports: [WeightWidgetComponent],
})
export class WeightWidgetModule {}
