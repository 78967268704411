/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HarvestEventsLookup } from '../models/harvest-events-lookup';
import { HarvestEventsLookupDto } from '../models/harvest-events-lookup-dto';
import { MillDto } from '../models/mill-dto';

@Injectable({
  providedIn: 'root',
})
export class HarvestEventsLookupService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHarvestEventsLookupList
   */
  static readonly GetHarvestEventsLookupListPath = '/api/harvesteventslookup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHarvestEventsLookupList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHarvestEventsLookupList$Response(params?: {
  }): Observable<StrictHttpResponse<Array<HarvestEventsLookup>>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestEventsLookupService.GetHarvestEventsLookupListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HarvestEventsLookup>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHarvestEventsLookupList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHarvestEventsLookupList(params?: {
  }): Observable<Array<HarvestEventsLookup>> {

    return this.getHarvestEventsLookupList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<HarvestEventsLookup>>) => r.body as Array<HarvestEventsLookup>)
    );
  }

  /**
   * Path part for operation addHarvestEventsLookup
   */
  static readonly AddHarvestEventsLookupPath = '/api/harvesteventslookup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addHarvestEventsLookup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addHarvestEventsLookup$Response(params?: {
    body?: HarvestEventsLookupDto
  }): Observable<StrictHttpResponse<HarvestEventsLookup>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestEventsLookupService.AddHarvestEventsLookupPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestEventsLookup>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addHarvestEventsLookup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addHarvestEventsLookup(params?: {
    body?: HarvestEventsLookupDto
  }): Observable<HarvestEventsLookup> {

    return this.addHarvestEventsLookup$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestEventsLookup>) => r.body as HarvestEventsLookup)
    );
  }

  /**
   * Path part for operation getFeedMillList
   */
  static readonly GetFeedMillListPath = '/api/harvesteventslookup/feed/mill';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFeedMillList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeedMillList$Response(params?: {
  }): Observable<StrictHttpResponse<Array<HarvestEventsLookup>>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestEventsLookupService.GetFeedMillListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HarvestEventsLookup>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFeedMillList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFeedMillList(params?: {
  }): Observable<Array<HarvestEventsLookup>> {

    return this.getFeedMillList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<HarvestEventsLookup>>) => r.body as Array<HarvestEventsLookup>)
    );
  }

  /**
   * Path part for operation addMill
   */
  static readonly AddMillPath = '/api/harvesteventslookup/harvestevents/addMill';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addMill()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addMill$Response(params?: {
    body?: MillDto
  }): Observable<StrictHttpResponse<HarvestEventsLookup>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestEventsLookupService.AddMillPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestEventsLookup>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addMill$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addMill(params?: {
    body?: MillDto
  }): Observable<HarvestEventsLookup> {

    return this.addMill$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestEventsLookup>) => r.body as HarvestEventsLookup)
    );
  }

  /**
   * Path part for operation getHarvestEventsMap
   */
  static readonly GetHarvestEventsMapPath = '/api/harvesteventslookup/harvesteventsmap';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHarvestEventsMap()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHarvestEventsMap$Response(params?: {
  }): Observable<StrictHttpResponse<{
[key: string]: any;
}>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestEventsLookupService.GetHarvestEventsMapPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: any;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHarvestEventsMap$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHarvestEventsMap(params?: {
  }): Observable<{
[key: string]: any;
}> {

    return this.getHarvestEventsMap$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: any;
}>) => r.body as {
[key: string]: any;
})
    );
  }

  /**
   * Path part for operation getHarvestEventsLookup
   */
  static readonly GetHarvestEventsLookupPath = '/api/harvesteventslookup/{harvestEventsLookupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHarvestEventsLookup()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHarvestEventsLookup$Response(params: {
    harvestEventsLookupId: number;
  }): Observable<StrictHttpResponse<HarvestEventsLookup>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestEventsLookupService.GetHarvestEventsLookupPath, 'get');
    if (params) {
      rb.path('harvestEventsLookupId', params.harvestEventsLookupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestEventsLookup>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHarvestEventsLookup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHarvestEventsLookup(params: {
    harvestEventsLookupId: number;
  }): Observable<HarvestEventsLookup> {

    return this.getHarvestEventsLookup$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestEventsLookup>) => r.body as HarvestEventsLookup)
    );
  }

  /**
   * Path part for operation updateHarvestEventsLookup
   */
  static readonly UpdateHarvestEventsLookupPath = '/api/harvesteventslookup/{harvestEventsLookupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateHarvestEventsLookup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateHarvestEventsLookup$Response(params: {
    harvestEventsLookupId: number;
    body?: HarvestEventsLookupDto
  }): Observable<StrictHttpResponse<HarvestEventsLookup>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestEventsLookupService.UpdateHarvestEventsLookupPath, 'put');
    if (params) {
      rb.path('harvestEventsLookupId', params.harvestEventsLookupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestEventsLookup>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateHarvestEventsLookup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateHarvestEventsLookup(params: {
    harvestEventsLookupId: number;
    body?: HarvestEventsLookupDto
  }): Observable<HarvestEventsLookup> {

    return this.updateHarvestEventsLookup$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestEventsLookup>) => r.body as HarvestEventsLookup)
    );
  }

  /**
   * Path part for operation deleteHarvestEventsLookup
   */
  static readonly DeleteHarvestEventsLookupPath = '/api/harvesteventslookup/{harvestEventsLookupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHarvestEventsLookup()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHarvestEventsLookup$Response(params: {
    harvestEventsLookupId: number;
  }): Observable<StrictHttpResponse<HarvestEventsLookup>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestEventsLookupService.DeleteHarvestEventsLookupPath, 'delete');
    if (params) {
      rb.path('harvestEventsLookupId', params.harvestEventsLookupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestEventsLookup>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHarvestEventsLookup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHarvestEventsLookup(params: {
    harvestEventsLookupId: number;
  }): Observable<HarvestEventsLookup> {

    return this.deleteHarvestEventsLookup$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestEventsLookup>) => r.body as HarvestEventsLookup)
    );
  }

}
