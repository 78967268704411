<div class="mock-http-area">
  <div class="endpoint-editor">
    <div class="input-group">
      <div>
        <label>Message Delay Time (Milliseconds):</label>
      </div>
      <div>
        <input type="text" inputFilter [inputFilterMode]="InputFilterMode.Numeric"
               [(ngModel)]="mockHttpService.handleMessageDelayMs" class="message-delay-time" sh-id="message-delay-time">
      </div>
    </div>
    <div class="input-group">
      <div>
        <label>Endpoint:</label>
      </div>
      <div>
        <input [(ngModel)]="endpoint" class="mock-endpoint" sh-id="endpoint_input">
      </div>
    </div>
    <div class="input-group">
      <div>
        <label>Http Template:</label>
      </div>
      <div>
        <textarea [(ngModel)]="template" class="mock-http-template" sh-id="template_textarea"></textarea>
      </div>
    </div>
    <div class="input-group">
      <button (click)="load()"  sh-id="load_template">Load Template</button>
      <button (click)="save(false)"  sh-id="save_template">Save Template</button>
      <button (click)="save(true)"  sh-id="save_template_refresh">Save Template Refresh</button>
    </div>
    <div class="endpoint-list">
      <div class="endpoint-list-item" *ngFor="let endpoint of mockHttpService.endpoints">
        <button (click)="endpointSelected(endpoint)">Load</button>
        {{endpoint}}
      </div>
    </div>
  </div>
  <div class="web-api-trace">
    <div class="message-history">
      <div *ngFor="let messageHistory of mockHttpService.httpMessagesHistory" class="message-item">
        <b>Endpoint: </b><span>{{messageHistory.endpoint}}</span><br/>
        <b>Date: </b><span>{{messageHistory.date}}</span><br/>
        <b>Method: </b><span>{{messageHistory.method}}</span><br/>
        <b>Request Headers: </b><span>{{messageHistory.requestHeaders | json}}</span><br/>
        <b>Request Body: </b><span>{{messageHistory.requestBody | json}}</span><br/>
        <b>Response Status: </b><span>{{messageHistory.responseStatus}}</span><br/>
        <b>Response Headers: </b><span>{{messageHistory.responseHeaders | json}}</span>
      </div>
    </div>
    <div class="message-json" sh-id="http-message-history-json">{{mockHttpService.httpMessagesHistory | json}}</div>
  </div>
</div>
