import {Component, ElementRef, HostBinding, Input, OnInit, ViewEncapsulation} from '@angular/core';

export enum WordBreakEnum {
  breakAll = 'break-all',
  breakWord = 'break-word',
}

@Component({
  selector: 'ellipsisable-text',
  templateUrl: './ellipsisable-text.component.html',
  styleUrls: ['./ellipsisable-text.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EllipsisableTextComponent implements OnInit {

  @Input()
  public maxChars: number = null;

  @Input()
  public maxLines: number = 1;

  @Input()
  public wordBreak: WordBreakEnum = WordBreakEnum.breakAll;

  private _textContent: string;

  private _isTextUpdated: boolean = false;

  constructor(private host: ElementRef) { }

  public ngOnInit(): void {

  }

  public get ellipsisClassName(): string {
    this.saveInitialTextContent();
    this.updateContent();
    this.updateTitle();
    return 'ellipsis';
  }

  @HostBinding('class.break-word')
  public get isBreakWord() {
    return this.isMultiLine && this.wordBreak === WordBreakEnum.breakWord;
  }

  @HostBinding('class.multi-line')
  public get isMultiLine() {
    return this.maxLines > 1;
  }

  @HostBinding('class.single-line')
  public get isSingleLine() {
    return this.maxLines == 1;
  }

  @HostBinding('style')
  public get style(): CSSStyleDeclaration {
    if (this.maxLines > 1) {
      return {
        webkitLineClamp: this.maxLines.toString(),
      } as CSSStyleDeclaration;
    }
  }

  private updateTitle() {
    let isEllipsisActive: boolean;
    if (this.maxLines > 1) {
      isEllipsisActive = this.host.nativeElement.offsetHeight < this.host.nativeElement.scrollHeight;
    } else {
      isEllipsisActive = this.host.nativeElement.offsetWidth < this.host.nativeElement.scrollWidth;
    }
    const title: string = isEllipsisActive || this._isTextUpdated ? this._textContent : '';
    if (this.title !== title) {
      this.title = title;
    }
  }

  private set title(value: string) {
    this.host.nativeElement.setAttribute('title', value);
  }

  private get title() {
    return this.host.nativeElement.getAttribute('title');
  }

  private updateContent() {
    if (!!this.maxChars && this.host.nativeElement.textContent?.length > this.maxChars) {
      this.host.nativeElement.textContent = this.host.nativeElement.textContent.substr(0, this.maxChars) + '...';
      this._isTextUpdated = true;
    } else {
      this._isTextUpdated = false;
    }
  }

  private saveInitialTextContent() {
    if (!this._textContent) {
      this._textContent = this.host.nativeElement.textContent;
    }
  }
}
