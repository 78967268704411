import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SelectDeviceLocationModalComponent} from './select-device-location-modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '../../../pipes/pipes.module';
import {ErrorModalModule} from '../error-modal/error-modal.module';
import { DropDownListsModule } from '../../dropdown-list/dropdown-list.module';


@NgModule({
  declarations: [
    SelectDeviceLocationModalComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PipesModule,
        FormsModule,
        ErrorModalModule,
        DropDownListsModule,
    ],
  exports: [
    SelectDeviceLocationModalComponent
  ]
})
export class SelectDeviceLocationModalModule {
}
