<div class="login-wrapper">
  <div class="login-tab">
    <div class="login-tab__img"
         [class.none]="inviteView === inviteViewEnum.privacy || inviteView === inviteViewEnum.eula">
      <img src="./assets/img/login/chicken.46b8937b.png">
    </div>
    <div class="login-tab__form" [class.width-100]="inviteView === inviteViewEnum.privacy || inviteView === inviteViewEnum.eula">
      <div class="language-wrapper">
        <change-language-dropdown></change-language-dropdown>
      </div>
      <form class="login-tab__form-wrapper" [class.none]="inviteView !== inviteViewEnum.invite">
        <div class="login-tab__forgot-logo center">
          <img src="assets/img/login/forgot-logo.svg">
        </div>
        <div class="login-tab__form-title">{{'LOGIN.FORM.invite_user' | translate}} {{email}}</div>
        <div class="login-tab__form-subtitle">{{'LOGIN.FORM.enter_pass' | translate}}</div>
        <div class="login-tab__form-error"
             *ngIf="showAuthFail$ | async">{{'COMMON.ERRORS.' + errorMessage | translate}}</div>
        <div class="login-tab__form-input">
          <input [placeholder]="'COMMON.PLACEHOLDERS.New_password' | translate" [type]="passwordType" class="login-input"
                 (input)="passwordNew = $event.target.value">
          <img src="assets/img/login/eye_icon.009fd067.svg" (click)="showHidePass()">
        </div>
        <div class="login-tab__form-input">
          <input [placeholder]="'COMMON.PLACEHOLDERS.Confirm_password' | translate" [type]="passwordType" class="login-input"
                 (input)="passwordConfirm = $event.target.value">
          <img src="assets/img/login/eye_icon.009fd067.svg" (click)="showHidePass()">
        </div>
        <div>
          <small class="warn">{{'COMMON.PROFILE.password_hint' | translate }}</small>
        </div>
        <div class="justify-start">
          <label class="checkboxes__checkbox">
            <input type="checkbox"
                   [checked]="privacyAgreementsAccepted"
                   (click)="privacyAccepted(true)">
            <span class="checkmark"></span>
            <span class="checkbox-name">{{'LOGIN.FORM.accept_agreements' | translate}}</span>
          </label>
          <span class="privacy"
                (click)="showPrivacy(inviteViewEnum.privacy)">
          &nbsp;{{'LOGIN.FORM.Privacy_Policy' | translate}}
          </span>
        </div>
        <div class="justify-start">
          <label class="checkboxes__checkbox">
            <input type="checkbox"
                   [checked]="EULAAgreementsAccepted"
                   (click)="eulaAccepted(true)">
            <span class="checkmark"></span>
            <span class="checkbox-name">{{'LOGIN.FORM.accept_agreements' | translate}}</span>
          </label>
          <span class="privacy"
                (click)="showPrivacy(inviteViewEnum.eula)">
          &nbsp;{{'LOGIN.FORM.EULA' | translate}}
          </span>
        </div>
        <div class="login-tab__form-button">
          <button class="blue-rounded-button width-100" type="submit"
                  *ngIf="agreements?.Agreements?.length > 1"
                  [disabled]="!isValidPassword ||
                  passwordNew !== passwordConfirm ||
                  passwordNew.length < 8 ||
                  passwordConfirm.length < 8 ||
                  EULAAgreementsAccepted !== true || privacyAgreementsAccepted !== true"
                  (click)="completeInviteUser()">
            {{'LOGIN.FORM.continue' | translate}}
          </button>
        </div>
      </form>
      <div class="height-80 width-80" [class.none]="inviteView === inviteViewEnum.invite">
        <privacy-component
          [privacyAgreementText]="privacyAgreementText"
          [EULAAgreementText]="EULAAgreementText"
          [mode]="privacyModesEnum.invite"
          [inviteView]="inviteView"
          (onGoToLogin)="inviteView = inviteViewEnum.invite"
          (onAcceptEULA)="eulaAccepted(false)"
          (onAcceptPrivacy)="privacyAccepted(false)">
        </privacy-component>
      </div>
      <div class="login-tab__form-logo2">
        <img [src]="getLogo">
        <essential-logo></essential-logo>
      </div>
    </div>
  </div>
</div>
