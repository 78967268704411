import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { PipesModule } from '../../pipes/pipes.module';
import { EllipsisableTextModule } from '../elipsisable-text/ellispisable-text.module';
import { FormsDropdownListComponent } from './forms-dropdown-list/forms-dropdown-list.component';
import { LanguageDropdownListComponent } from './language-dropdown-list/language-dropdown-list.component';
import { PopupTemplateComponent } from './popups/popup-template/popup-template.component';
import { RegularDropdownListComponent } from './regular-dropdown-list/regular-dropdown-list.component';
import { RegularSelectComponent } from './regular-select/regular-select.component';
import { PortalModule } from '@angular/cdk/portal';
import {PopupTemplateModule} from './popups/popup-template/popup-template.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    OwlDateTimeModule,
    RouterModule,
    PipesModule,
    EllipsisableTextModule,
    PortalModule,
    PopupTemplateModule
  ],
  exports: [
    RegularDropdownListComponent,
    FormsDropdownListComponent,
    LanguageDropdownListComponent,
    RegularSelectComponent,
    PortalModule,
  ],
  declarations: [
    RegularDropdownListComponent,
    FormsDropdownListComponent,
    LanguageDropdownListComponent,
    RegularSelectComponent,
  ],
})
export class DropDownListsModule {}
