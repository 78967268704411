import {Pipe, PipeTransform} from '@angular/core';
import {TranslationService} from '../../services/translations/translation.service';

@Pipe({
  name: 'positionToTextPipe',
  pure: false
})
export class PositionToTextPipe implements PipeTransform {
  public transform(value: number): string {
    switch (value) {
      case 1:
        return 'Center';
      case 2:
        return 'Upper Left';
      case 3:
        return 'Upper Right';
      case 4:
        return 'Lower Right';
      case 5:
        return 'Lower Left';
      default:
        return '';
    }
  }

}
