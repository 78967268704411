/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../api/base-service-url';
import { ApiConfiguration } from '../api/api-configuration';
import { StrictHttpResponse } from '../api/strict-http-response';
import { RequestBuilder } from '../api/request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ResultStringDto } from '../api/models/result-string-dto';

@Injectable({
  providedIn: 'root',
})
export class AvatarService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCustomerAvatar
   */
  static readonly GetCustomerAvatarPath = '/api/avatar/customer/{customerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerAvatar()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAvatar$Response(params: {
    customerId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AvatarService.GetCustomerAvatarPath, 'get');
    if (params) {
      rb.path('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerAvatar$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAvatar(params: {
    customerId: number;
  }): Observable<void> {

    return this.getCustomerAvatar$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateCustomerAvatar
   */
  static readonly UpdateCustomerAvatarPath = '/api/avatar/customer/{customerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomerAvatar()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateCustomerAvatar$Response(params: {
    customerId: number;
    body?: {
    }
  }): Observable<StrictHttpResponse<ResultStringDto>> {

    const rb = new RequestBuilder(this.rootUrl, AvatarService.UpdateCustomerAvatarPath, 'post');
    if (params) {
      rb.path('customerId', params.customerId, {});
      rb.body(params.body);
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultStringDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCustomerAvatar$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateCustomerAvatar(params: {
    customerId: number;
    body?: {
    }
  }): Observable<ResultStringDto> {

    return this.updateCustomerAvatar$Response(params).pipe(
      map((r: StrictHttpResponse<ResultStringDto>) => r.body as ResultStringDto)
    );
  }

  /**
   * Path part for operation getCustomerFeedMillAvatar
   */
  static readonly GetCustomerFeedMillAvatarPath = '/api/avatar/customerFeedMill/{customerFeedMillId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerFeedMillAvatar()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerFeedMillAvatar$Response(params: {
    customerFeedMillId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AvatarService.GetCustomerFeedMillAvatarPath, 'get');
    if (params) {
      rb.path('customerFeedMillId', params.customerFeedMillId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerFeedMillAvatar$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerFeedMillAvatar(params: {
    customerFeedMillId: number;
  }): Observable<void> {

    return this.getCustomerFeedMillAvatar$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateCustomerFeedMillAvatar
   */
  static readonly UpdateCustomerFeedMillAvatarPath = '/api/avatar/customerFeedMill/{customerFeedMillId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomerFeedMillAvatar()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateCustomerFeedMillAvatar$Response(params: {
    customerFeedMillId: number;
    body?: {
    }
  }): Observable<StrictHttpResponse<ResultStringDto>> {

    const rb = new RequestBuilder(this.rootUrl, AvatarService.UpdateCustomerFeedMillAvatarPath, 'post');
    if (params) {
      rb.path('customerFeedMillId', params.customerFeedMillId, {});
      rb.body(params.body);
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultStringDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCustomerFeedMillAvatar$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateCustomerFeedMillAvatar(params: {
    customerFeedMillId: number;
    body?: {
    }
  }): Observable<ResultStringDto> {

    return this.updateCustomerFeedMillAvatar$Response(params).pipe(
      map((r: StrictHttpResponse<ResultStringDto>) => r.body as ResultStringDto)
    );
  }

  /**
   * Path part for operation getCustomerHatcheryAvatar
   */
  static readonly GetCustomerHatcheryAvatarPath = '/api/avatar/customerHatchery/{customerHatcheryId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerHatcheryAvatar()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerHatcheryAvatar$Response(params: {
    customerHatcheryId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AvatarService.GetCustomerHatcheryAvatarPath, 'get');
    if (params) {
      rb.path('customerHatcheryId', params.customerHatcheryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerHatcheryAvatar$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerHatcheryAvatar(params: {
    customerHatcheryId: number;
  }): Observable<void> {

    return this.getCustomerHatcheryAvatar$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateCustomerHatcheryAvatar
   */
  static readonly UpdateCustomerHatcheryAvatarPath = '/api/avatar/customerHatchery/{customerHatcheryId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomerHatcheryAvatar()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateCustomerHatcheryAvatar$Response(params: {
    customerHatcheryId: number;
    body?: {
    }
  }): Observable<StrictHttpResponse<ResultStringDto>> {

    const rb = new RequestBuilder(this.rootUrl, AvatarService.UpdateCustomerHatcheryAvatarPath, 'post');
    if (params) {
      rb.path('customerHatcheryId', params.customerHatcheryId, {});
      rb.body(params.body);
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultStringDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCustomerHatcheryAvatar$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateCustomerHatcheryAvatar(params: {
    customerHatcheryId: number;
    body?: {
    }
  }): Observable<ResultStringDto> {

    return this.updateCustomerHatcheryAvatar$Response(params).pipe(
      map((r: StrictHttpResponse<ResultStringDto>) => r.body as ResultStringDto)
    );
  }

  /**
   * Path part for operation getUserAvatar
   */
  static readonly GetUserAvatarPath = '/api/avatar/user/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserAvatar()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAvatar$Response(params: {
    userId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AvatarService.GetUserAvatarPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserAvatar$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAvatar(params: {
    userId: number;
  }): Observable<void> {

    return this.getUserAvatar$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateUserAvatar
   */
  static readonly UpdateUserAvatarPath = '/api/avatar/user/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserAvatar()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateUserAvatar$Response(params: {
    userId: number;
    body?: {
    }
  }): Observable<StrictHttpResponse<ResultStringDto>> {

    const rb = new RequestBuilder(this.rootUrl, AvatarService.UpdateUserAvatarPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body);
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResultStringDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUserAvatar$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateUserAvatar(params: {
    userId: number;
    body?: {
    }
  }): Observable<ResultStringDto> {

    return this.updateUserAvatar$Response(params).pipe(
      map((r: StrictHttpResponse<ResultStringDto>) => r.body as ResultStringDto)
    );
  }

}
