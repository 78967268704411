import { TimeSelectionComponent } from './time-selection.component';
import { PipesModule } from '../../../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EpochStateModel } from '../epoch-state-model';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [TimeSelectionComponent],
  imports: [PipesModule, CommonModule, FormsModule, ReactiveFormsModule],
  exports: [TimeSelectionComponent],
})
export class TimeSelectionModule {}
