import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslationService } from '../../../../services/translations/translation.service';
import {
  DropdownListAutoSelectionMode,
  DropdownListItem,
} from '../dropdown-list-model';
import { PopupTemplateComponent } from '../popups/popup-template/popup-template.component';

export enum DropdownColorScheme {
  greyGreen = 'greyGreen',
  whiteGreen = 'whiteGreen',
  greyBlue = 'greyBlue',
  regular = 'regular',
  blue = 'blue',
  whiteBlue = 'whiteBlue',
}

export enum DropdownCustomIcon {
  none = 'none',
  blueCalendar = 'blueCalendar',
}

@Component({
  selector: 'regular-dropdown-list',
  templateUrl: './regular-dropdown-list.component.html',
  styleUrls: ['./regular-dropdown-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RegularDropdownListComponent),
      multi: true,
    },
  ],
})
export class RegularDropdownListComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  @Input()
  public shIdPostfix: string = '';

  @Input()
  public items: DropdownListItem[] = [];

  @Input()
  public colorScheme: DropdownColorScheme = DropdownColorScheme.regular;

  @Input()
  public disabled: boolean;

  @Input()
  public isTranslated: boolean = true;

  @Input()
  public autoSelectionMode?: DropdownListAutoSelectionMode =
    DropdownListAutoSelectionMode.None;

  @Input()
  public allowClear: boolean = true;

  @Input()
  public placeholder: string = '';

  @Input()
  public selectedValue?: DropdownListItem;

  @Input()
  public isFieldError: boolean = false;

  @Input()
  public itemsMaxHeight: number = 240;

  @Input()
  public inputAreaHeight: number = 40;

  @Input()
  public hidePopupWhenOneOrLessItem: boolean;

  @Input()
  public customIcon: DropdownCustomIcon = DropdownCustomIcon.none;

  @Input()
  public maxLinesForRow: number = 1;

  @Input()
  public isHasTitle: boolean = false;

  @Output()
  public onItemSelected = new EventEmitter<DropdownListItem>();

  @ViewChild('itemsListElement')
  public itemsListElement: ElementRef;

  @ViewChild(PopupTemplateComponent, { static: true })
  public popupTemplate: PopupTemplateComponent;

  public isOpen: boolean;

  public markedItem: number;

  public selectedItem: DropdownListItem;

  public DropdownCustomIcon = DropdownCustomIcon;

  private _isFoldsUp: boolean;

  private isClickInside = false;

  public get canDisplayPopupAccordingNumberOfItems(): boolean {
    if (!this.hidePopupWhenOneOrLessItem) {
      return true;
    }
    return this.items.length > 1;
  }

  public get value() {
    return this.selectedValue.value;
  }

  constructor(
    private readonly detectorRef: ChangeDetectorRef,
    private readonly translationService: TranslationService
  ) {}

  private onChange = (value: any) => {};
  private onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  @HostListener('click')
  public clickInside() {
    this.isClickInside = true;
  }

  @HostListener('document:click')
  public clickOutside() {
    if (!this.isClickInside) {
      this.isOpen = false;
    }
    this.isClickInside = false;
  }

  @HostListener('window:scroll')
  public scroll() {
    if (!this.isClickInside) {
      this.isOpen = false;
    }
    this.isClickInside = false;
    this.detectorRef.detectChanges();
  }

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      const items = changes.items.currentValue || [];
      const selectedItem = this.selectedValue
        ? this.items?.find((item) => this.selectedValue.value === item.value)
        : null;
      if (this.selectedItem && !selectedItem) {
        this.innerSelectItem(null);
      } else if (
        !this.allowClear &&
        selectedItem &&
        this.selectedItem !== selectedItem
      ) {
        this.innerSelectItem(selectedItem);
      } else {
        switch (this.autoSelectionMode) {
          case DropdownListAutoSelectionMode.None: {
            break;
          }
          case DropdownListAutoSelectionMode.First: {
            if (items.length > 0 && !this.selectedItem) {
              this.innerSelectItem(items[0]);
            }
            break;
          }
          case DropdownListAutoSelectionMode.Single:
          default: {
            if (items.length === 1 && !this.selectedItem) {
              this.innerSelectItem(items[0]);
            }
            break;
          }
        }
      }
    }
    if (changes.selectedValue && changes.selectedValue.currentValue) {
      const selectedValue: DropdownListItem =
        changes.selectedValue.currentValue;
      this.selectedItem = this.items?.find(
        (item) => item.value === selectedValue.value
      );
    } else if (
      changes.selectedValue &&
      changes.selectedValue.currentValue == null
    ) {
      this.selectedItem = null;
    }
  }

  public get presentDropdownFoldUpIcon(): boolean {
    if (this.selectedItem != null && this.allowClear) {
      return false;
    }
    return true;
  }

  public showPlaceholder() {
    return !this.isOpen && this.placeholder && this.placeholder.trim();
  }

  public writeValue(value): void {
    if (this.items != null) {
      const selectedEl = this.items.find((item) => item.value === value);
      if (selectedEl) {
        this.selectedItem = selectedEl;
        return;
      }
    }
    this.selectedItem = null;
  }

  public toggleOpen(event?: MouseEvent) {
    if (event && event.x === 0 && event.y === 0) {
      return;
    }
    if (!this.canDisplayPopupAccordingNumberOfItems || this.disabled) {
      return;
    }
    this.isOpen = !this.isOpen;
    if (this.items.length > 0) {
      this.markedItem = 0;
    }
  }

  public closeDropdown() {
    this.isOpen = false;
  }

  public selectItem(item: DropdownListItem | undefined | null, event: Event) {
    if (event != null) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (item) {
      if (item.disabled && this.shIdPostfix !== 'customers') {
        return;
      }
      this.selectedItem = item;
      this.updateValue(item);
    } else {
      this.selectedItem = null;
      this.updateValue(null);
    }
    this.isOpen = false;
  }

  private updateValue(item: DropdownListItem | null) {
    this.onItemSelected.emit(item);
    this.onChange(item?.value);
    this.onTouched();
  }

  public onEnter(event: Event) {
    if (this.isOpen && this.markedItem > -1) {
      event.preventDefault();
      event.stopPropagation();
      this.selectedItem = this.items[this.markedItem];
      this.selectItem(this.selectedItem, event);
    }
  }

  public onArrowDown() {
    this.incrementSelectedIndex();
  }

  public onArrowUp() {
    this.decrementSelectedIndex();
  }

  public decrementSelectedIndex() {
    this.markedItem--;
    if (this.markedItem < 0) {
      this.markedItem = this.items.length - 1;
    }
    document
      .querySelector('#regular-item-' + this.markedItem)
      .scrollIntoView({ block: 'nearest' });
  }

  public incrementSelectedIndex() {
    this.markedItem++;
    if (this.markedItem === this.items.length) {
      this.markedItem = 0;
    }
    document
      .querySelector('#regular-item-' + this.markedItem)
      .scrollIntoView({ block: 'nearest' });
  }

  public isMarked(index: number) {
    return index === this.markedItem;
  }

  public get isFoldDirectionUp(): boolean {
    if (
      !!this.popupTemplate &&
      this._isFoldsUp !== this.popupTemplate.isFoldsUp
    ) {
      setTimeout(() => {
        this._isFoldsUp = this.popupTemplate.isFoldsUp;
        this.detectorRef.detectChanges();
      });
    }
    return this._isFoldsUp;
  }

  public get selectedDisplayedValue(): string {
    return this.translationService.translate(
      this.selectedItem != null
        ? this.selectedItem.displayValue
        : this.placeholder
    );
  }

  public setTitle(event, fieldValue: string) {
    const isEllipsisActive: boolean =
      event.target.offsetWidth < event.target.scrollWidth;

    if (isEllipsisActive && !this.isOpen && this.isHasTitle) {
      event.target.setAttribute('title', fieldValue);
    } else {
      event.target.removeAttribute('title');
    }
  }

  private innerSelectItem(item: DropdownListItem | null | undefined): void {
    this.selectedItem = item ?? null;
  }
}
