/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Notification } from '../models/notification';
import { NotificationSeenDto } from '../models/notification-seen-dto';
import { NotificationTitle } from '../models/notification-title';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getMyNotifications
   */
  static readonly GetMyNotificationsPath = '/api/notification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMyNotifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyNotifications$Response(params?: {
    customerId?: number;
    maxRows?: number;
  }): Observable<StrictHttpResponse<Array<Notification>>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.GetMyNotificationsPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
      rb.query('maxRows', params.maxRows, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Notification>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMyNotifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyNotifications(params?: {
    customerId?: number;
    maxRows?: number;
  }): Observable<Array<Notification>> {

    return this.getMyNotifications$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Notification>>) => r.body as Array<Notification>)
    );
  }

  /**
   * Path part for operation dismissNotification
   */
  static readonly DismissNotificationPath = '/api/notification/dismiss/{facilityGroupId}/{type}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dismissNotification()` instead.
   *
   * This method doesn't expect any request body.
   */
  dismissNotification$Response(params: {
    facilityGroupId: number;
    type: NotificationTitle;
    days?: number;
    key?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.DismissNotificationPath, 'put');
    if (params) {
      rb.path('facilityGroupId', params.facilityGroupId, {});
      rb.path('type', params.type, {});
      rb.query('days', params.days, {});
      rb.query('key', params.key, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dismissNotification$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dismissNotification(params: {
    facilityGroupId: number;
    type: NotificationTitle;
    days?: number;
    key?: string;
  }): Observable<void> {

    return this.dismissNotification$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation isActive
   */
  static readonly IsActivePath = '/api/notification/isactive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isActive()` instead.
   *
   * This method doesn't expect any request body.
   */
  isActive$Response(params?: {
    key?: string;
  }): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.IsActivePath, 'get');
    if (params) {
      rb.query('key', params.key, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `isActive$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isActive(params?: {
    key?: string;
  }): Observable<boolean> {

    return this.isActive$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation markAsSeen
   */
  static readonly MarkAsSeenPath = '/api/notification/markAsSeen';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `markAsSeen()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markAsSeen$Response(params?: {
    body?: NotificationSeenDto
  }): Observable<StrictHttpResponse<Array<Notification>>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.MarkAsSeenPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Notification>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `markAsSeen$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  markAsSeen(params?: {
    body?: NotificationSeenDto
  }): Observable<Array<Notification>> {

    return this.markAsSeen$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Notification>>) => r.body as Array<Notification>)
    );
  }

  /**
   * Path part for operation sendNotification
   */
  static readonly SendNotificationPath = '/api/notification/send';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendNotification()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendNotification$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.SendNotificationPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendNotification$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendNotification(params?: {
  }): Observable<void> {

    return this.sendNotification$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation sendNotificationByTitle
   */
  static readonly SendNotificationByTitlePath = '/api/notification/send/{title}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendNotificationByTitle()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendNotificationByTitle$Response(params: {
    title: NotificationTitle;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.SendNotificationByTitlePath, 'get');
    if (params) {
      rb.path('title', params.title, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendNotificationByTitle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendNotificationByTitle(params: {
    title: NotificationTitle;
  }): Observable<void> {

    return this.sendNotificationByTitle$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateNotification
   */
  static readonly UpdateNotificationPath = '/api/notification/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateNotification()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateNotification$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Notification>>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.UpdateNotificationPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Notification>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateNotification$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateNotification(params?: {
  }): Observable<Array<Notification>> {

    return this.updateNotification$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Notification>>) => r.body as Array<Notification>)
    );
  }

  /**
   * Path part for operation updateNotificationByTitle
   */
  static readonly UpdateNotificationByTitlePath = '/api/notification/update/{title}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateNotificationByTitle()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateNotificationByTitle$Response(params: {
    title: NotificationTitle;
  }): Observable<StrictHttpResponse<Array<Notification>>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.UpdateNotificationByTitlePath, 'get');
    if (params) {
      rb.path('title', params.title, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Notification>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateNotificationByTitle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateNotificationByTitle(params: {
    title: NotificationTitle;
  }): Observable<Array<Notification>> {

    return this.updateNotificationByTitle$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Notification>>) => r.body as Array<Notification>)
    );
  }

  /**
   * Path part for operation enableNotification
   */
  static readonly EnableNotificationPath = '/api/notification/{facilityId}/enableNotification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enableNotification()` instead.
   *
   * This method doesn't expect any request body.
   */
  enableNotification$Response(params: {
    facilityId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.EnableNotificationPath, 'put');
    if (params) {
      rb.path('facilityId', params.facilityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `enableNotification$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enableNotification(params: {
    facilityId: number;
  }): Observable<void> {

    return this.enableNotification$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setMyNotificationAsRead
   */
  static readonly SetMyNotificationAsReadPath = '/api/notification/{notificationId}/markAsRead';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setMyNotificationAsRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  setMyNotificationAsRead$Response(params: {
    notificationId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.SetMyNotificationAsReadPath, 'put');
    if (params) {
      rb.path('notificationId', params.notificationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setMyNotificationAsRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setMyNotificationAsRead(params: {
    notificationId: number;
  }): Observable<void> {

    return this.setMyNotificationAsRead$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setMyFacilityNotificationAsRead
   */
  static readonly SetMyFacilityNotificationAsReadPath = '/api/notification/{notificationId}/{facilityId}/markAsRead';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setMyFacilityNotificationAsRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  setMyFacilityNotificationAsRead$Response(params: {
    facilityId: number;
    notificationId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NotificationService.SetMyFacilityNotificationAsReadPath, 'put');
    if (params) {
      rb.path('facilityId', params.facilityId, {});
      rb.path('notificationId', params.notificationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setMyFacilityNotificationAsRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setMyFacilityNotificationAsRead(params: {
    facilityId: number;
    notificationId: number;
  }): Observable<void> {

    return this.setMyFacilityNotificationAsRead$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
