import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { PipesModule } from "src/app/common/pipes/pipes.module";
import { DetouchDeviceLocationModal } from "./detouch-device-location.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PipesModule,
    ],
    exports: [
        DetouchDeviceLocationModal,
    ],
    declarations: [
        DetouchDeviceLocationModal,
    ]
})
export class DetouchDeviceLocationModule {}