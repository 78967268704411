import { NgModule } from '@angular/core';
import { SelectableZonesComponent } from './selectable-zones.component';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { ZoneCardModule } from '../zone-card/zone-card.module';

@NgModule({
  declarations: [SelectableZonesComponent],
  imports: [CommonModule, PipesModule, FormsModule, ZoneCardModule],
  exports: [SelectableZonesComponent],
})
export class SelectableZonesModule {}
