import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'valueToColor',
  pure: false
})

export class ValueToColorPipe implements PipeTransform {
  public transform(value: number, limits: number[], palette: string[]): string {
    let intervalIndex = 0;
    while (value >= limits[intervalIndex] && intervalIndex < limits.length) {
      intervalIndex++;
    }
    return palette[intervalIndex];
  }
}
