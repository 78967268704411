<div class="datatable-header" [ngStyle]="{'width': tableWidth + 'px',
                                          'height': headerHeight + 'px'}">
  <div class="datatable-column" #dataTableColumn *ngFor="let column of columns" [ngStyle]="{'flex': column.flexGrow,
                                                                                            'height': headerHeight,
                                                                                            'minWidth': column.minWidth + 'px'}">
    {{column.updateHtmlElement(dataTableColumn)}}
    <ng-container *ngTemplateOutlet="column.columnTemplate;context:{column:column}"></ng-container>
  </div>
</div>
<div #staticContent class="static-content" [ngClass]="{'dark-scroll': isDarkScroll}" (scroll)="updateVisiblePart()" (resized)="updateVisiblePart()">
  <div #scrollableContent class="scrollable-content" [ngStyle]="{'height': scrollableElementHeight + 'px'}">
  </div>
  <sh-datatable-row *ngFor="let row of visibleTableRows"
                    class="visible-row"
                    [ngStyle]="{'top': row.top + 'px',
                                  'height': row.height + 'px'}"
                    [rowColorMode]="rowColorMode"
                    [row]="row.row"
                    [rowIndex]="getRowIndex(row.row)">
  </sh-datatable-row>
</div>
