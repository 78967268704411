<div class="display-flex position-relative">
  <div class="menus__menu">
    <div class="menus__menu-selector height66" *ngIf="isDateChanged">
      <div class="menus__menu-selector-label">{{ 'COMMON.Date' | translate }}</div>
      <date-picker
        [min]="startDate"
        [max]="maxDate"
        [value]="selectedDate"
        (valueChange)="changeSelectedDate($event)"
      ></date-picker>
    </div>
    <div class="menus__menu-selector height66" *ngIf="!isDateChanged && functionalMode === EventFunctionalModes.addEvent">
      <div class="menus__menu-selector-label">{{ 'COMMON.Date' | translate }}</div>
      <input type="text" value="NA" (click)="isDateChanged = true; changeSelectedDate(selectedDate)"/>
    </div>
  </div>
  <div class="menus__menu" *ngIf="functionalMode === EventFunctionalModes.addEvent">
    <div class="menus__menu-selector height66" *ngIf="!isDateChanged && functionalMode === EventFunctionalModes.addEvent">
      <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_Age' | translate }}</div>
      <input
        [options]="{
              digitGroupSeparator: translationService.digitGroupSeparator,
              decimalCharacter: translationService.decimalCharacter,
              decimalPlaces: 0,
              minimumValue: '0',
              maximumValue: '80'
            }"
        autonumeric
        [value]="birdAge"
        (change)="changeBirdAge($event.target.value)"
      >
    </div>
    <div class="menus__menu-selector height66" *ngIf="isDateChanged && functionalMode === EventFunctionalModes.addEvent">
      <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_Age' | translate }}</div>
      <input type="text" value="NA" (click)="isDateChanged = false; changeBirdAge(birdAge)"/>
  </div>
  </div>
  <div class="menus__dash" *ngIf="functionalMode !== EventFunctionalModes.addEvent">&mdash;</div>
  <div class="menus__menu" *ngIf="functionalMode !== EventFunctionalModes.addEvent">
    <div class="menus__menu-selector height66 custom-select custom-select__disabled">
      <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_age' | translate }}</div>
      <input disabled [value]="birdAge">
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector height66">
      <div class="menus__menu-selector-label">{{"EVENT.nutraceutical" | translate}}</div>
      <simple-dropdown
        [items]="nutraceuticals"
        [allowClear]="false"
        [selectedValue]="nutraceuticalsSelectedItem"
        (onItemSelected)="nutraceuticalsChanged($event)">
      </simple-dropdown>
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector height66">
      <div class="menus__menu-selector-label">{{"EVENT.reason" | translate}}</div>

      <simple-dropdown
        [items]="reasons"
        [allowClear]="false"
        [selectedValue]="reasonsSelectedItem"
        (onItemSelected)="reasonsChanged($event)">
      </simple-dropdown>
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector height66">
      <div class="menus__menu-selector-label">{{"EVENT.duration" | translate}}</div>

      <simple-dropdown
        [items]="duration"
        [allowClear]="false"
        [selectedValue]="durationSelectedItem"
        (onItemSelected)="durationChanged($event)">
      </simple-dropdown>
    </div>
  </div>

  <div class="remove-event" (click)="onRemoveEvent()">
    <span class="svg delete-blue"></span>
  </div>
</div>
