import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/common/pipes/pipes.module';
import { DropDownListsModule } from '../../dropdown-list/dropdown-list.module';
import { GroupDropdownComponent } from './group-dropdown.component';
import { GroupLocationModule } from './group-location/group-location.module';
import {CustomMultiselectModule} from '../../dropdowns/custom-multiselect/custom-multiselect.module';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    DropDownListsModule,
    GroupLocationModule,
    CustomMultiselectModule,
  ],
  exports: [GroupDropdownComponent],
  declarations: [GroupDropdownComponent],
  providers: [DatePipe],
})
export class GroupDropdownModule {}
