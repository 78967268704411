import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SensorGraphComponent } from './sensor-graph.component';
import { RouterModule } from '@angular/router';
import { NoDataAvailableModule } from '../../no-data-available/no-data-available.module';
import { ChartModule } from '@syncfusion/ej2-angular-charts';
import { FormsModule } from '@angular/forms';
import { NaParameterPipe } from '../../../pipes/na-parameter.pipe';
import { NumberFormatterPipe } from '../../../pipes/number-formatter.pipe';
import { UnitsConvertPipe } from '../../../pipes/units-convert.pipe';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
  declarations: [SensorGraphComponent],
  imports: [
    RouterModule,
    CommonModule,
    NoDataAvailableModule,
    ChartModule,
    FormsModule,
    PipesModule,
  ],
  exports: [SensorGraphComponent],
  providers: [DatePipe, NaParameterPipe, NumberFormatterPipe, UnitsConvertPipe],
})
export class SensorGraphModule {}
