import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges, ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { HarvestFullResponse } from '../../../../../services/api/models/harvest-full-response';
import { CustomerSelectorsService } from '../../../../../services/customer-selectors/customer-selectors.service';
import { PermissionService } from '../../../../../services/user/permission-service';
import { IExtendedHarvestEventDto } from '../add-event-modal.component';
import { HarvestEvent } from '../../../../../services/api/models/harvest-event';
import { EventFunctionalModes } from '../../events.component';
import { TranslationService } from '../../../../../services/translations/translation.service';
import { UtilsService } from '../../../../../services/utils/utils.service';
import {
  EVENT_DATE_FORMAT,
  MIDNIGHT_DATE_FORMAT,
} from '../../../../../utils/string-utils';

@Component({
  selector: 'planned-hand-weight',
  templateUrl: 'planned-hand-weight.component.html',
  styleUrls: ['planned-hand-weight.component.scss'],
})
export class PlannedHandWeightComponent implements OnChanges {
  @Input() public currentHarvest: HarvestFullResponse;
  @Input() public event: IExtendedHarvestEventDto | HarvestEvent;
  @Input() public rowIndex: number;
  @Input() public day0Date: Date;
  @Input() public possibleDeleteFirst: boolean = false;
  @Input() public functionalMode: EventFunctionalModes =
    EventFunctionalModes.addEvent;

  @Output() public removeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public dateChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public onCommentChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public onAvWeightChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public birdAgeChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Input() public maxDate: Date;
  @Input() public startDate: Date;
  public readonly systemNowDate = new Date();

  public birdAge: number = 0;
  public selectedDate: Date;
  public isTouched: boolean = false;
  public EventFunctionalModes = EventFunctionalModes;

  public isDateChanged: boolean = true;

  @ViewChild('inputContainer')
  public inputContainer: ElementRef;

  constructor(
    public customerSelectorsService: CustomerSelectorsService,
    public readonly permissionService: PermissionService,
    public readonly translationService: TranslationService,
    private utilsService: UtilsService
  ) {}

  public async ngOnChanges(changes: SimpleChanges) {
    if (changes.event || changes.day0Date || changes.maxDate || changes.startDate) {
      switch (this.functionalMode) {
        case EventFunctionalModes.editEvent: {
          this.setEditEventdate();
          break;
        }
        case EventFunctionalModes.addEvent: {
          console.log('EVENT TO ADD: ', this.event);
          this.setAddEventDate();
          break;
        }
        case EventFunctionalModes.wizard: {
          this.setWizardDate();
          break;
        }
      }
    }
  }

  public setEditEventdate() {
    console.log('EVENT TO EDIT: ', this.event);
    this.birdAge = this.event.birdAge;
    this.selectedDate = moment(this.event.date).toDate();
  }

  public setAddEventDate() {
    this.selectedDate = this.maxDate
      ? moment(this.maxDate)
        .set({
          hour: +moment(this.startDate).format('HH'),
          minute: +moment(this.startDate).format('mm'),
          seconds: +moment(this.startDate).format('ss'),
        })
        .toDate()
      : moment(this.systemNowDate)
        .set({
          hour: +moment(this.startDate).format('HH'),
          minute: +moment(this.startDate).format('mm'),
          seconds: +moment(this.startDate).format('ss'),
        })
        .toDate();

    this.birdAge = !this.event.birdAge ? moment(this.selectedDate).diff(this.startDate, 'days') : this.event.birdAge;
    this.event.birdAge = !this.event.birdAge ? this.birdAge : this.event.birdAge;
  }

  public changeBirdAge(value) {
    const maxBirdAge = this.maxDate ? moment(this.maxDate).diff(this.startDate, 'days') : 80;

    this.birdAge = Number(value) > maxBirdAge ? maxBirdAge : Number(value);
    this.isTouched = false;

    this.birdAgeChanged.emit(this.birdAge);
  }

  public setWizardDate() {
    this.startDate = this.day0Date;
    this.selectedDate = moment(this.day0Date)
      .add(this.event.birdAge, 'days')
      .toDate();
    this.birdAge = moment(this.selectedDate).diff(this.startDate, 'days');
    this.changeSelectedDate(this.selectedDate, true);
  }

  public changeSelectedDate(date: Date, isWizard?: boolean) {
    this.isTouched = true;
    this.dateChanged.emit(moment(date).format(EVENT_DATE_FORMAT));
    if (this.functionalMode !== EventFunctionalModes.wizard) {
      // this.birdAge = moment(date).diff(this.currentHarvest.startDate, 'days');
      this.birdAge = moment(date)
        .set({
          hour: +moment(this.startDate).format('HH'),
          minute: +moment(this.startDate).format('mm'),
          seconds: +moment(this.startDate).format('ss'),
        })
        .diff(this.startDate, 'days');
    } else {
      this.birdAge = moment(date)
        .set({
          hour: +moment(this.day0Date).format('HH'),
          minute: +moment(this.day0Date).format('mm'),
          seconds: +moment(this.startDate).format('ss'),
        })
        .diff(moment(this.day0Date).format(EVENT_DATE_FORMAT), 'days');
    }
  }

  public commentChanged(value: string) {
    this.onCommentChanged.emit(value);
  }

  public weightChanged(value: string) {
    this.onAvWeightChanged.emit(this.utilsService.toNormalValue(value));
  }

  public onRemoveEvent() {
    this.removeEvent.emit();
  }
}
