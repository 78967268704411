import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopupTemplateComponent } from './popup-template.component';
import { PipesModule } from '../../../../pipes/pipes.module';
import { OutsideClickHandlerDirective } from '../../../../directives/outside-click-handler.directive';

@NgModule({
  declarations: [PopupTemplateComponent, OutsideClickHandlerDirective],
  imports: [PipesModule, CommonModule, FormsModule, ReactiveFormsModule],
  exports: [PopupTemplateComponent, OutsideClickHandlerDirective],
})
export class PopupTemplateModule {}
