<div class="popup" *ngIf="customerSelectorsService.farmId">
  <div class="popup__body" #popupBody>
    <div class="popup__body-header">
      {{ "EVENT.add_event" | translate }}
    </div>
    <div class="popup__body-close">
      <span class="svg close-svg-blue" (click)="closeModal()"></span>
    </div>
    <div class="popup__body-content">
      <div class="menus__menu width-100 display-flex">
        <div class="menus__menu width-50">
          <div class="menus__menu-label">{{ "EVENT.select_event_type" | translate }}</div>
          <div class="menus__menu-selector">
            <div class="menus__menu-selector-label">{{ "EVENT.Type" | translate }}</div>
            <regular-select (change)="eventTypeChange($event.target.value)">
              <option #option
                      *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.AddFeed) > 0"
                      [value]="eventTypesEnum.AddFeed">{{ "EVENT.add_feed" | translate }}
              </option>
              <option #option *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.AddMedication) > 0"
                      [value]="eventTypesEnum.AddMedication">{{ "EVENT.add_medication" | translate }}
              </option>
              <option #option *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.AddNutraceutical) > 0"
                      [value]="eventTypesEnum.AddNutraceutical">{{ "EVENT.add_nutraceutical" | translate }}
              </option>
              <option #option *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.AddVaccination) > 0"
                      [value]="eventTypesEnum.AddVaccination">{{ "EVENT.add_vaccination" | translate }}
              </option>
              <option #option *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.FlushWaterLine) > 0"
                      [value]="eventTypesEnum.FlushWaterLine">{{ "EVENT.FlushWaterLine" | translate }}
              </option>
              <option #option *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.WaterSanitation) > 0"
                      [value]="eventTypesEnum.WaterSanitation">{{ "EVENT.WaterSanitation" | translate }}
              </option>
              <!--            <option #option-->
              <!--                    *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.PlannedThinningAges) > 0"-->
              <!--                    [value]="eventTypesEnum.PlannedThinningAges">{{"EVENT.PlannedThinningAges" | translate}}</option>-->
              <option #option
                      *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.PlannedClearingAge) > 0"
                      [value]="eventTypesEnum.PlannedClearingAge">{{ "EVENT.PlannedClearingAge" | translate }}
              </option>
              <option #option
                      *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.PlannedHandWeighingOfBirds) > 0"
                      [value]="eventTypesEnum.PlannedHandWeighingOfBirds">{{ "EVENT.PlannedHandWeighingOfBirds" | translate }}
              </option>
              <option #option
                      *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.PlannedFootpadScoringOfBirds) > 0"
                      [value]="eventTypesEnum.PlannedFootpadScoringOfBirds">{{ "EVENT.PlannedFootpadScoringOfBirds" | translate }}
              </option>
              <option #option
                      *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.Brooding) > 0
                      && permissionService.hasPermission(myFarmCropCardAddBrooding)
                      && +broodingService.getMaxBroodingDuration(0, 'addEvent') > 0
                      && currentHarvest.partBrooding === 'MANUAL'"
                      [value]="eventTypesEnum.Brooding">{{ "EVENT.Brooding" | translate }}
              </option>
              <option #option
                      *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.AddThinning) > 0"
                      [value]="eventTypesEnum.AddThinning">{{ "EVENT.Add_Thinning" | translate }}
              </option>
              <option #option *ngIf="cropBehaviourService.possibleEventsLeft(eventTypesEnum.OtherEvents) > 0"
                      [value]="eventTypesEnum.OtherEvents">{{ "EVENT.OtherEvents" | translate }}
              </option>
            </regular-select>
          </div>
        </div>
        <div class="menus__menu width-50 margin-top-15"
             *ngIf="getCurrentHouse && eventType !== eventTypesEnum.Brooding">
          <div class="house-selector menus__menu-selector ">
            <custom-houses-multiselect
              [maxHeight]="getMaxHeight"
              [isDisableAllowed]="false"
              [currentHouse]="getCurrentHouse"
              [houses]="houses"
              (selectedHouses)="setSelectedHouses($event)"
            ></custom-houses-multiselect>
          </div>
        </div>
      </div>
      <div class="menus__menu-selector width-50" *ngIf="eventType === eventTypesEnum.AddVaccination">
        <div class="menus__menu-selector-label">{{ "EVENT.Country" | translate }}</div>
        <regular-select [value]="selectedCountry" (change)="countryChanged($event.target.value)">
          <option #option *ngFor="let item of countries"
                  [selected]="item === selectedCountry"
                  [value]="item">{{ item }}
          </option>
        </regular-select>
      </div>

      <!-- COMMON PART OF ALL EVENTS TYPE -->
      <div class="menus__menu width-100">
        <div class="menus__menu-label">{{ "EVENT.Event_Details" | translate }}</div>
        <div class="events-container" [style.max-height]="calculateHeight">
          <!--ADD FEED-->
          <ng-container *ngIf="eventType === eventTypesEnum.AddFeed">
            <div class="width-100" *ngFor="let event of harvestEvents; let i = index">
              <feed-event
                [event]="event"
                [currentHarvest]="currentHarvest"
                [functionalMode]="eventFunctionalMode"
                [rowIndex]="i"
                [feed]="feed"
                [startDate]="startDate"
                [maxDate]="maxDate"
                [possibleDeleteFirst]="false"
                (dateChanged)="changeSelectedDate($event, i)"
                (birdAgeChanged)="changeSelectedBirdAge($event, i)"
                (onCoccidiostatChanged)="harvestEvents[i].values[1].value = $event"
                (onAdditivesChanged)="harvestEvents[i].values[0].value = $event"
                (onRationChanged)="harvestEvents[i].values[3].value = $event"
                (onKgChanged)="harvestEvents[i].values[4].value = $event"
                (onBatchNumberChanged)="harvestEvents[i].values[5].value = $event"
                (onMillChanged)="setMillValue($event, i)"
                (removeEvent)="removeEvent(i)"
              >
              </feed-event>
            </div>
          </ng-container>


          <!--ADD MEDICATION-->
          <ng-container *ngIf="eventType === eventTypesEnum.AddMedication">
            <div class="width-100" *ngFor="let event of harvestEvents; let i = index">
              <medication-event
                [event]="event"
                [currentHarvest]="currentHarvest"
                [functionalMode]="eventFunctionalMode"
                [rowIndex]="i"
                [medication]="medication"
                [startDate]="startDate"
                [maxDate]="maxDate"
                [possibleDeleteFirst]="false"
                (dateChanged)="changeSelectedDate($event, i)"
                (birdAgeChanged)="changeSelectedBirdAge($event, i)"
                (onMedicationDurationChanged)="harvestEvents[i].values[1].value = $event"
                (onmMedicationDiseaseChanged)="harvestEvents[i].values[0].value = $event"
                (onAntibioticsChanged)="harvestEvents[i].values[2].value = $event"
                (removeEvent)="removeEvent(i)">
              </medication-event>
            </div>
          </ng-container>

          <!--ADD THINNING-->
          <ng-container *ngIf="eventType === eventTypesEnum.AddThinning">
            <div class="width-100" *ngFor="let event of harvestEvents; let i = index">
              <thinning-event
                [event]="event"
                [currentHarvest]="currentHarvest"
                [functionalMode]="eventFunctionalMode"
                [rowIndex]="i"
                [startDate]="startDate"
                [maxDate]="maxDate"
                [possibleDeleteFirst]="false"
                [cropNumber]="cropNumber"
                (dateChanged)="changeSelectedDateTime($event, i)"
                (birdAgeChanged)="changeSelectedBirdAge($event, i)"
                (numberOfBirdsChanged)="changeThinningValues(harvestEvents[i], $event, 'thinnedValue')"
                (totalKgChanged)="changeThinningValues(harvestEvents[i], $event, 'totalKg')"
                (removeEvent)="removeEvent(i)">
              </thinning-event>
            </div>
          </ng-container>

          <!--ADD Nutraceutical-->
          <ng-container *ngIf="eventType === eventTypesEnum.AddNutraceutical">
            <div class="width-100" *ngFor="let event of harvestEvents; let i = index">
              <nutraceutical-event
                [event]="event"
                [currentHarvest]="currentHarvest"
                [nutraceutical]="nutraceuticals"
                [functionalMode]="eventFunctionalMode"
                [startDate]="startDate"
                [maxDate]="maxDate"
                [possibleDeleteFirst]="false"
                (dateChanged)="changeSelectedDate($event, i)"
                (birdAgeChanged)="changeSelectedBirdAge($event, i)"
                (onNutraceuticalChanged)="harvestEvents[i].values[1].value = $event"
                (onReasonChanged)="harvestEvents[i].values[2].value = $event"
                (onDurationChanged)="harvestEvents[i].values[0].value = $event"
                (removeEvent)="removeEvent(i)">
              </nutraceutical-event>
            </div>
          </ng-container>

          <!--Add Vaccination-->
          <ng-container *ngIf="eventType === eventTypesEnum.AddVaccination">
            <div class="width-100" *ngFor="let event of harvestEvents; let i = index">
              <vaccination-event
                [event]="event"
                [functionalMode]="eventFunctionalMode"
                [currentHarvest]="currentHarvest"
                [rowIndex]="i"
                [startDate]="startDate"
                [maxDate]="maxDate"
                [possibleDeleteFirst]="false"
                [vaccination]="vaccination[selectedCountry]"
                (dateChanged)="changeSelectedDate($event, i)"
                (birdAgeChanged)="changeSelectedBirdAge($event, i)"
                (onTypeChanged)="harvestEvents[i].values[1].value = $event"
                (onDiseaseChanged)="harvestEvents[i].values[0].value = $event"
                (onVaccineChanged)="harvestEvents[i].values[2].value = $event"
                (removeEvent)="removeEvent(i)">
              </vaccination-event>
            </div>
          </ng-container>

          <!--   Flush water line       -->
          <ng-container *ngIf="eventType === eventTypesEnum.FlushWaterLine">
            <div class="width-100" *ngFor="let event of harvestEvents; let i = index">
              <flush-water-line
                [event]="event"
                [functionalMode]="eventFunctionalMode"
                [currentHarvest]="currentHarvest"
                [rowIndex]="i"
                [allEvents]="allEvents"
                [harvestEvents]="harvestEvents"
                [startDate]="startDate"
                [maxDate]="maxDate"
                [possibleDeleteFirst]="false"
                (dateOutOfRange)="isFlushingRecurrentEndDateOutOfRange = $event"
                (onCommentChanged)="harvestEvents[i].values[0].value = $event"
                (dateChanged)="changeSelectedDate($event, i)"
                (endDateChanged)="changeRecurrentEndDate($event,i)"
                (recurrentEndBirdAgeChanged)="changeRecurrentEndBirdAge($event,i)"
                (birdAgeChanged)="changeSelectedBirdAge($event, i); "
                (recurrentIntevalChanged)="harvestEvents[i].values[5].value = $event"
                (removeEvent)="removeEvent(i)"
                (toggleRecurrent)="toggleRecurrentMode(i)"
              >
              </flush-water-line>
            </div>
          </ng-container>
          <!--  Water Sanitation      -->
          <ng-container *ngIf="eventType === eventTypesEnum.WaterSanitation">
            <div class="width-100" *ngFor="let event of harvestEvents; let i = index">
              <water-sanitation
                [event]="event"
                [functionalMode]="eventFunctionalMode"
                [currentHarvest]="currentHarvest"
                [rowIndex]="i"
                [startDate]="startDate"
                [maxDate]="maxDate"
                [possibleDeleteFirst]="false"
                (onCommentChanged)="harvestEvents[i].values[0].value = $event"
                (dateChanged)="changeSelectedDate($event, i)"
                (birdAgeChanged)="changeSelectedBirdAge($event, i)"
                (removeEvent)="removeEvent(i)"
              >
              </water-sanitation>
            </div>
          </ng-container>
          <!-- Planned thinning ages      -->
          <ng-container *ngIf="eventType === eventTypesEnum.PlannedThinningAges">
            <div class="width-100" *ngFor="let event of harvestEvents; let i = index">
              <planned-thinning-ages
                [event]="event"
                [functionalMode]="eventFunctionalMode"
                [currentHarvest]="currentHarvest"
                [rowIndex]="i"
                [startDate]="startDate"
                [maxDate]="maxDate"
                [possibleDeleteFirst]="false"
                (dateChanged)="changeSelectedDate($event, i)"
                (birdAgeChanged)="changeSelectedBirdAge($event, i)"
                (removeEvent)="removeEvent(i)"
              >
              </planned-thinning-ages>
            </div>
          </ng-container>
          <!-- Planned clearing age      -->
          <ng-container *ngIf="eventType === eventTypesEnum.PlannedClearingAge">
            <div class="width-100" *ngFor="let event of harvestEvents; let i = index">
              <planned-clearing-age
                [event]="event"
                [functionalMode]="eventFunctionalMode"
                [currentHarvest]="currentHarvest"
                [rowIndex]="i"
                [startDate]="startDate"
                [maxDate]="maxDate"
                [possibleDeleteFirst]="false"
                (dateChanged)="changeSelectedDate($event, i)"
                (birdAgeChanged)="changeSelectedBirdAge($event, i)"
                (removeEvent)="removeEvent(i)"
              >
              </planned-clearing-age>
            </div>
          </ng-container>
          <!-- Planned hand weighing of birds      -->
          <ng-container *ngIf="eventType === eventTypesEnum.PlannedHandWeighingOfBirds">
            <div class="width-100" *ngFor="let event of harvestEvents; let i = index">
              <planned-hand-weight
                [event]="event"
                [functionalMode]="eventFunctionalMode"
                [currentHarvest]="currentHarvest"
                [rowIndex]="i"
                [startDate]="startDate"
                [maxDate]="maxDate"
                [possibleDeleteFirst]="false"
                (onCommentChanged)="harvestEvents[i].values[0].value = $event"
                (onAvWeightChanged)="harvestEvents[i].values[1].value = $event"
                (dateChanged)="changeSelectedDate($event, i)"
                (birdAgeChanged)="changeSelectedBirdAge($event, i)"
                (removeEvent)="removeEvent(i)"
              >
              </planned-hand-weight>
            </div>
          </ng-container>
          <!-- Planned footpad scoring      -->
          <ng-container *ngIf="eventType === eventTypesEnum.PlannedFootpadScoringOfBirds">
            <div class="width-100" *ngFor="let event of harvestEvents; let i = index">
              <planned-footpad-scoring
                [event]="event"
                [functionalMode]="eventFunctionalMode"
                [currentHarvest]="currentHarvest"
                [rowIndex]="i"
                [startDate]="startDate"
                [maxDate]="maxDate"
                [possibleDeleteFirst]="false"
                (score0)="harvestEvents[i].values[0].value = $event"
                (score1)="harvestEvents[i].values[1].value = $event"
                (score2)="harvestEvents[i].values[2].value = $event"
                (numberOfZone)="setSelectedZones($event, i)"
                (dateChanged)="changeSelectedDate($event, i)"
                (birdAgeChanged)="changeSelectedBirdAge($event, i)"
                (removeEvent)="removeEvent(i)"
              >
              </planned-footpad-scoring>
            </div>
          </ng-container>
          <!-- Brooding      -->
          <ng-container *ngIf="eventType === eventTypesEnum.Brooding">
            <div class="width-100" *ngFor="let event of harvestEvents; let i = index">
              <brooding-event
                [currentHarvest]="currentHarvest"
                [event]="event"
                [initialDuration]="1"
                [currentEvents]="allHarvestEvents"
                [addedEvents]="harvestEvents"
                [rowIndex]="i"
                [startDate]="startDate"
                [maxDate]="maxDate"
                [functionalMode]="eventFunctionalMode"
                [possibleDeleteFirst]="true"
                (selectedZonesChanged)="harvestEvents[i].values[0].value = $event"
                (durationChanged)="setSelectedDurationAndUpdateInBroodingService($event, i)"
                (dateChanged)="changeSelectedDate($event, i)"
                (birdAgeChanged)="changeSelectedBirdAge($event, i)"
                (removeEvent)="removeEvent(i)"
              >
              </brooding-event>
            </div>
          </ng-container>
          <!--   Other Events       -->
          <ng-container *ngIf="eventType === eventTypesEnum.OtherEvents">
            <div class="width-100" *ngFor="let event of harvestEvents; let i = index">
              <other-event
                [event]="event"
                [functionalMode]="eventFunctionalMode"
                [currentHarvest]="currentHarvest"
                [rowIndex]="i"
                [harvestEvents]="harvestEvents"
                [startDate]="startDate"
                [maxDate]="maxDate"
                [possibleDeleteFirst]="false"
                (dateOutOfRange)="isFlushingRecurrentEndDateOutOfRange = $event"
                (onCommentChanged)="harvestEvents[i].values[0].value = $event"
                (dateChanged)="changeSelectedDate($event, i)"
                (endDateChanged)="changeRecurrentEndDate($event,i)"
                (recurrentEndBirdAgeChanged)="changeRecurrentEndBirdAge($event,i)"
                (birdAgeChanged)="changeSelectedBirdAge($event, i); "
                (recurrentIntevalChanged)="harvestEvents[i].values[5].value = $event"
                (removeEvent)="removeEvent(i)"
                (toggleRecurrent)="toggleRecurrentMode(i)"
              >
              </other-event>
            </div>
          </ng-container>
          <div class="display-flex">
            <div class="add-flock" *ngIf="isShowAddEventButton()" (click)="addEvent()">
              <div class="svg add-full-filled mr-10"></div>
              {{ 'CROP_CARD.add_event' | translate }}
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="center width-100">
      <button class="blue-rounded-button new-farm-button"
              (click)="confirmBeforeClose()">{{ 'COMMON.BUTTONS.CANCEL' | translate }}
      </button>
      <button class="blue-rounded-button new-farm-button"
              [disabled]="
              eventType === eventTypesEnum.Brooding && harvestEvents[0].values[1].value === '0'
              || selectedHouseIds.length === 0
              || isFlushingRecurrentEndDateOutOfRange
              || disabledAfterClick"
              (click)="createEvent()">{{ 'COMMON.BUTTONS.Create' | translate }}
      </button>
    </div>
  </div>
</div>

<confirmation-modal
  [title]="'CROP_CARD.SAVE_CONFIRMATION.Title' | translate"
  [text]="'CROP_CARD.SAVE_CONFIRMATION.Body' | translate"
  [mode]="'confirmation'"
  [isShowCross]="true"
  *ngIf="isShowBeforeCloseModal"
  (onCancel)="closeRemoveModal()"
  (onConfirm)="createEvent()"
></confirmation-modal>
