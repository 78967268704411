import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomerSelectorsService } from '../customer-selectors/customer-selectors.service';
import { HarvestBasicResponse } from '../api/models/harvest-basic-response';
import moment from 'moment';
import { HarvestEvent } from '../api/models/harvest-event';
import { EventType } from '../../common/components/events/add-event-modal/add-event-modal.component';
import { PermissionName } from '../api/models/permission-name';
import { PermissionService } from '../user/permission-service';
import {UserDetailsService} from '../user/user-details.service';
import {RoleName} from '../model/role-name';

@Injectable({ providedIn: 'root' })
export class CurrentCropBehaviourService implements OnDestroy {
  private destroyed$ = new Subject();

  public isBroodingActive = false;

  private currentCrop$: BehaviorSubject<HarvestBasicResponse> =
    new BehaviorSubject<HarvestBasicResponse>(undefined);

  private eventsTypeAlreadyInUse = [];

  public myFarmCropCardEditCropInformation: PermissionName =
    PermissionName.MyFarmCropCardEditCropInformation;

  constructor(
    private customerSelectorsService: CustomerSelectorsService,
    private permissionService: PermissionService,
    private userDataService: UserDetailsService,
  ) {
    this.customerSelectorsService.currentCrop$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((currentCrop) => {
        this.currentCrop$.next(currentCrop);
      });
  }

  public get crop(): HarvestBasicResponse {
    return this.currentCrop$.value;
  }

  public get isSuperSupportAdmin() {
    return this.userDataService.userRes.role.find(role => role.roleName === RoleName.SuperAdmin || role.roleName === RoleName.SupportAdmin);
  }

  public get isSuperAdmin() {
    return this.userDataService.userRes.role.find(role => role.roleName === RoleName.SuperAdmin);
  }

  public get cropCloseDate(): string {
    return this.crop?.closeDate;
  }

  public get cropNumber(): number {
    if (this.crop?.idx > 0) {
      return this.crop?.idx;
    } else {
      return 0;
    }
  }

  public get id(): number {
    return this.crop?.id;
  }

  public get isEditable() {
    return (
      ((!this.crop?.closeDate ||
        moment(this.crop?.closeDate).add(7, 'days').isAfter(moment())) &&
      this.permissionService.hasPermission(
        this.myFarmCropCardEditCropInformation
      )) || !!this.isSuperSupportAdmin);
  }

  public get isOpened() {
    return !this.crop?.closeDate;
  }

  public get startDate(): string {
    return this.crop?.startDate;
  }

  public get closeDate(): string {
    return this.crop?.closeDate;
  }

  public get cropAge(): number {
    const startMoment = moment.utc(this.startDate).startOf('day');
    let daysDifference;
    if (this.closeDate) {
      daysDifference =
        moment.utc(this.closeDate).startOf('day').diff(startMoment, 'days') + 1;
    } else {
      daysDifference = moment().startOf('day').diff(startMoment, 'days') + 2;
    }
    return daysDifference;
  }

  public setPossibleNumberOfEvents(events: HarvestEvent[]) {
    this.eventsTypeAlreadyInUse = [];
    const uniqueEventTypesList = [...new Set(events.map((ev) => ev.type))];
    uniqueEventTypesList.forEach(
      (eventType) =>
        (this.eventsTypeAlreadyInUse[eventType] = events.filter(
          (ev) => ev.type === eventType
        ).length)
    );
    console.log('eventsTypeAlreadyInUse', this.eventsTypeAlreadyInUse);
  }

  public possibleEventsLeft(type: EventType): number {
    if (!isNaN(this.eventsTypeAlreadyInUse[type])) {
      switch (type) {
        case EventType.AddFeed:
          return 10 - this.eventsTypeAlreadyInUse[type];
        case EventType.AddThinning:
          return 20 - this.eventsTypeAlreadyInUse[type];
        case EventType.AddVaccination:
          return 5 - this.eventsTypeAlreadyInUse[type];
        case EventType.AddMedication:
          return 5 - this.eventsTypeAlreadyInUse[type];
        case EventType.AddNutraceutical:
          return 10 - this.eventsTypeAlreadyInUse[type];
        case EventType.FlushWaterLine:
          return 10 - this.eventsTypeAlreadyInUse[type];
        case EventType.WaterSanitation:
          return 7 - this.eventsTypeAlreadyInUse[type];
        case EventType.PlannedThinningAges:
          return 3 - this.eventsTypeAlreadyInUse[type];
        case EventType.PlannedClearingAge:
          return 1 - this.eventsTypeAlreadyInUse[type];
        case EventType.PlannedHandWeighingOfBirds:
          return 10 - this.eventsTypeAlreadyInUse[type];
        case EventType.PlannedFootpadScoringOfBirds:
          return 5 - this.eventsTypeAlreadyInUse[type];
        case EventType.Brooding:
          return 10 - this.eventsTypeAlreadyInUse[type];
        case EventType.OtherEvents:
          return 10 - this.eventsTypeAlreadyInUse[type];
      }
    } else {
      switch (type) {
        case EventType.AddFeed:
          return 10;
        case EventType.AddVaccination:
          return 5;
        case EventType.AddMedication:
          return 5;
        case EventType.AddNutraceutical:
          return 10;
        case EventType.FlushWaterLine:
          return 10;
        case EventType.WaterSanitation:
          return 7;
        case EventType.PlannedThinningAges:
          return 3;
        case EventType.PlannedClearingAge:
          return 1;
        case EventType.PlannedHandWeighingOfBirds:
          return 10;
        case EventType.PlannedFootpadScoringOfBirds:
          return 5;
        case EventType.Brooding:
          return 10;
        case EventType.AddThinning:
          return 20;
        case EventType.OtherEvents:
          return 10;
      }
    }
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
