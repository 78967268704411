import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { PermissionName } from '../api/models/permission-name';
import { UserRolePermissionDto } from '../api/models/user-role-permission-dto';
import { UserService } from '../api/services/user.service';
import { SESSION_KEY } from '../customer-selectors/customer-selectors.service';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  private permissionsKey = 'permissions';
  public currentRole = '';

  private get facilityGroupId() {
    return JSON.parse(localStorage.getItem(SESSION_KEY)).farmId;
  }

  constructor(private userService: UserService) {}

  public hasRole(role: string) {
    const permissions = JSON.parse(
      localStorage.getItem(this.permissionsKey)
    ) as unknown as UserRolePermissionDto[];
    return !!permissions && permissions[0]?.roleName === role;
  }

  public hasPermission(permission: PermissionName) {
    //     const permissions = localStorage.getItem(
    //       this.permissionsKey
    //     ) as unknown as PermissionName[];
    // //  return permissions?.includes(permission);
    //     return true;
    const permissions = JSON.parse(
      localStorage.getItem(this.permissionsKey)
    ) as unknown as UserRolePermissionDto[];

    if (
      !!permissions &&
      permissions[0]?.customerId === null &&
      permission !== PermissionName.UserNotificationSettingUpdate &&
      permissions[0]?.roleName === 'SUPER_ADMIN'
    ) {
      this.currentRole = permissions[0].roleName;
      return true;
    } else if (
      !!permissions &&
      permission === PermissionName.CustomerDropdown
    ) {
      const uniqueCustomerIds = [
        ...new Set(permissions.map((item) => item.customerId)),
      ];
      return uniqueCustomerIds.length > 0;
    } else {
      const currentFarmPermissions = permissions?.find(
        (item) =>
          item.facilityGroupId === this.facilityGroupId ||
          item.facilityGroupId === null
      )?.permissions;
      this.currentRole = permissions?.find(
        (item) =>
          item.facilityGroupId === this.facilityGroupId ||
          item.facilityGroupId === null
      )?.roleName;
      if (!!permissions && !currentFarmPermissions) {
        return !!permissions[0]?.permissions.includes(permission);
      } else {
        return currentFarmPermissions?.includes(permission);
      }
    }
  }

  public getPermissions() {
    this.userService
      .getRolesPermissions()
      .pipe(take(1))
      .subscribe((res) => {
        this.setPermissions(res);
      });
  }

  public setPermissions(permissions: UserRolePermissionDto[]) {
    localStorage.setItem(this.permissionsKey, JSON.stringify(permissions));
  }

  public clearPermissions() {
    localStorage.removeItem(this.permissionsKey);
  }
}
