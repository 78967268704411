import {NgModule} from '@angular/core';
import {CustMgmtComponent} from './cust-mgmt.component';
import {PipesModule} from '../../../pipes/pipes.module';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';

@NgModule({
    declarations: [CustMgmtComponent],
    imports: [
        PipesModule,
        RouterModule,
        CommonModule
    ],
    exports: [CustMgmtComponent]
})
export class CustMgmtModule {

}
