import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslationService } from '../../../services/translations/translation.service';
import { Subscription } from 'rxjs';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import { UtilsService } from '../../../services/utils/utils.service';
import moment from 'moment';
import { DATE_FORMAT } from '../../../utils/string-utils';
import { CustomerSelectorsService } from '../../../services/customer-selectors/customer-selectors.service';

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public value: Date;

  @Input()
  public disabled: boolean;

  @Input()
  public min: Date;

  @Input()
  public max: Date;

  @Output()
  public valueChange: EventEmitter<Date> = new EventEmitter<Date>();

  public minConverted: Date;

  private languageChangeSubscription: Subscription;

  constructor(
    private readonly translationService: TranslationService,
    private readonly dateTimeAdapter: DateTimeAdapter<Date>,
    private utilsService: UtilsService,
    public customerSelectorsService: CustomerSelectorsService
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    // if (changes.value) {
    //   this.value = moment(this.value)
    //     .add(this.customerSelectorsService.currentFarmTimeZoneOffset, 'hours')
    //     .add(this.usersSystemTimeZoneOffset, 'hours')
    //     .toDate();
    //   console.log('value', this.value);
    // }
    // if (changes.min) {
    //   this.min = moment(this.min)
    //     .add(this.customerSelectorsService.currentFarmTimeZoneOffset, 'hours')
    //     .add(this.usersSystemTimeZoneOffset, 'hours')
    //     .toDate();
    //   console.log('min', this.min);
    // }
    // if (changes.max) {
    //   this.max = moment(this.max)
    //     .add(this.customerSelectorsService.currentFarmTimeZoneOffset, 'hours')
    //     .add(this.usersSystemTimeZoneOffset, 'hours')
    //     .toDate();
    //   console.log('max', this.max);
    // }
    if (!!this.min) {
      this.minConverted = this.utilsService.convertDateForDatePickerMinDate(
        this.min
      );
    }
  }

  public ngOnInit(): void {
    this.languageChangeSubscription =
      this.translationService.languageChangeSubject.subscribe((value) => {
        this.dateTimeAdapter.setLocale(
          this.translationService.transformLanguageEnum(
            this.translationService.selectedLanguage
          )
        );
      });
    this.dateTimeAdapter.setLocale(
      this.translationService.transformLanguageEnum(
        this.translationService.selectedLanguage
      )
    );
  }

  public ngOnDestroy(): void {
    this.languageChangeSubscription?.unsubscribe();
  }

  public preventTyping() {
    return null;
  }

  public onValueChanged(newValue: Date) {
    // check new date for the range
    const dt = newValue?.getTime();
    if (this.minConverted) {
      const mintime = this.minConverted.getTime();
      if (dt < mintime) {
        newValue = this.minConverted;
      }
    }
    if (this.max) {
      const maxtime = this.max.getTime();
      if (dt > maxtime) {
        newValue = this.max;
      }
    }
    this.value = newValue;
    this.valueChange.emit(newValue);
  }
}
