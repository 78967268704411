/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Hatchery } from '../models/hatchery';
import { HatcheryDto } from '../models/hatchery-dto';

@Injectable({
  providedIn: 'root',
})
export class HatcheryService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findAll
   */
  static readonly FindAllPath = '/api/hatchery';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Hatchery>>> {

    const rb = new RequestBuilder(this.rootUrl, HatcheryService.FindAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Hatchery>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll(params?: {
  }): Observable<Array<Hatchery>> {

    return this.findAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Hatchery>>) => r.body as Array<Hatchery>)
    );
  }

  /**
   * Path part for operation addHatchery
   */
  static readonly AddHatcheryPath = '/api/hatchery';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addHatchery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addHatchery$Response(params?: {
    body?: Hatchery
  }): Observable<StrictHttpResponse<Hatchery>> {

    const rb = new RequestBuilder(this.rootUrl, HatcheryService.AddHatcheryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Hatchery>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addHatchery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addHatchery(params?: {
    body?: Hatchery
  }): Observable<Hatchery> {

    return this.addHatchery$Response(params).pipe(
      map((r: StrictHttpResponse<Hatchery>) => r.body as Hatchery)
    );
  }

  /**
   * Path part for operation deleteHatchery
   */
  static readonly DeleteHatcheryPath = '/api/hatchery/hatchery/{hatcheryId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteHatchery()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHatchery$Response(params: {
    hatcheryId: number;
  }): Observable<StrictHttpResponse<Hatchery>> {

    const rb = new RequestBuilder(this.rootUrl, HatcheryService.DeleteHatcheryPath, 'delete');
    if (params) {
      rb.path('hatcheryId', params.hatcheryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Hatchery>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteHatchery$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteHatchery(params: {
    hatcheryId: number;
  }): Observable<Hatchery> {

    return this.deleteHatchery$Response(params).pipe(
      map((r: StrictHttpResponse<Hatchery>) => r.body as Hatchery)
    );
  }

  /**
   * Path part for operation findByRoleName
   */
  static readonly FindByRoleNamePath = '/api/hatchery/{hatcheryId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByRoleName()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByRoleName$Response(params: {
    hatcheryId: number;
  }): Observable<StrictHttpResponse<Hatchery>> {

    const rb = new RequestBuilder(this.rootUrl, HatcheryService.FindByRoleNamePath, 'get');
    if (params) {
      rb.path('hatcheryId', params.hatcheryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Hatchery>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByRoleName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByRoleName(params: {
    hatcheryId: number;
  }): Observable<Hatchery> {

    return this.findByRoleName$Response(params).pipe(
      map((r: StrictHttpResponse<Hatchery>) => r.body as Hatchery)
    );
  }

  /**
   * Path part for operation updateHatchery
   */
  static readonly UpdateHatcheryPath = '/api/hatchery/{hatcheryId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateHatchery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateHatchery$Response(params: {
    hatcheryId: number;
    body?: HatcheryDto
  }): Observable<StrictHttpResponse<Hatchery>> {

    const rb = new RequestBuilder(this.rootUrl, HatcheryService.UpdateHatcheryPath, 'put');
    if (params) {
      rb.path('hatcheryId', params.hatcheryId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Hatchery>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateHatchery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateHatchery(params: {
    hatcheryId: number;
    body?: HatcheryDto
  }): Observable<Hatchery> {

    return this.updateHatchery$Response(params).pipe(
      map((r: StrictHttpResponse<Hatchery>) => r.body as Hatchery)
    );
  }

  /**
   * Path part for operation setActiveHatchery
   */
  static readonly SetActiveHatcheryPath = '/api/hatchery/{hatcheryId}/setActive={isActive}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setActiveHatchery()` instead.
   *
   * This method doesn't expect any request body.
   */
  setActiveHatchery$Response(params: {
    hatcheryId: number;
    isActive: boolean;
  }): Observable<StrictHttpResponse<Hatchery>> {

    const rb = new RequestBuilder(this.rootUrl, HatcheryService.SetActiveHatcheryPath, 'put');
    if (params) {
      rb.path('hatcheryId', params.hatcheryId, {});
      rb.path('isActive', params.isActive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Hatchery>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setActiveHatchery$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setActiveHatchery(params: {
    hatcheryId: number;
    isActive: boolean;
  }): Observable<Hatchery> {

    return this.setActiveHatchery$Response(params).pipe(
      map((r: StrictHttpResponse<Hatchery>) => r.body as Hatchery)
    );
  }

}
