import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BootService } from './services/boot/boot.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MockHttpManagementComponent } from './testing/testing-layout/mock-http-management/mock-http-management.component';
import { SharedModule } from './common/shared.module';
import { FormsModule } from '@angular/forms';
import { TestingLayoutComponent } from './testing/testing-layout/testing-layout.component';
import { ApiModule } from './services/api/api.module';
import { MockHttpInterceptorProvider } from './testing/mock-http-interceptor';
import { AuthInterceptor } from './services/auth/interceptors/auth.interceptor';
import { CommonModule } from '@angular/common';
import { PipesModule } from './common/pipes/pipes.module';
import { WINDOW_PROVIDERS } from './utils/window-utils';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AutonumericModule } from '@angularfy/autonumeric';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { ErrorModalModule } from './common/components/modals/error-modal/error-modal.module';
import { NotificationModule } from './common/components/modals/notification/notification.module';
import { CreatePasswordModule } from './layouts/create-password/create-password.module';
import { InviteUserComponent } from './layouts/invite-user/invite-user.component';
import { EssentialLogoModule } from './common/components/essential-logo/essential-logo.module';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    AppComponent,
    MockHttpManagementComponent,
    TestingLayoutComponent,
    InviteUserComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
    ApiModule,
    PipesModule,
    CreatePasswordModule,
    ErrorModalModule,
    NotificationModule,
    EssentialLogoModule,
    NgxScannerQrcodeModule,
    ScrollingModule,
    AutonumericModule.forRoot({
      // @ts-ignore
      negativePositiveSignBehavior: false,
      modifyValueOnUpDownArrow: false,
      upDownStep: 1,
    }),
  ],
  providers: [
    BootService,
    WINDOW_PROVIDERS,
    {
      provide: APP_INITIALIZER,
      useFactory: (boot: BootService) => () => boot.init(),
      deps: [BootService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    MockHttpInterceptorProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
