<div class="popup">
  <div class="popup__body">
    <div class="popup__body-header" *ngIf="!isThinningModalCompleteMode">
      {{ "CROP_CARD.Add_thinning" | translate }}
    </div>
    <div class="popup__body-header" *ngIf="isThinningModalCompleteMode">
      {{ "CROP_CARD.Complete_thinning" | translate }}
    </div>
    <div class="popup__body-close">
      <span class="svg close-svg-blue" (click)="closeModal()"></span>
    </div>
    <div class="popup__body-content">
      <div class="display-flex space-between mb-20">
        <div class="menus__menu width-48">
          <div class="menus__menu-label">
            {{ "CROP_CARD.Date_of_thinning" | translate }}
          </div>
          <div class="menus__menu-selector">
            <date-picker
              [value]="selectedDate"
              (valueChange)="setDateAndTime($event)"
              [max]="maxDate"
              [min]="startDate"
            ></date-picker>
          </div>
        </div>
        <div class="menus__menu width-48">
          <div class="selector-header">{{ "COMMON.time" | translate }}</div>
          <time-picker class="create-batch-event-content-control"
                       [shownRemovableIcon]="false"
                       (epochChanged)="onEpochChanged($event)"
                       [selectedEpoch]="epochSelectedDate"></time-picker>
        </div>
      </div>
      <div class="display-flex space-between mb-20">
        <div class="menus__menu width-48">
          <div class="menus__menu-label">
            {{ "CROP_CARD.Crop_thinning_number" | translate }}
          </div>
          <div class="menus__menu-selector">
            <input type="text" [ngModel]="cropThinningNumber" readonly/>
          </div>
        </div>
        <div class="menus__menu width-48">
          <div class="menus__menu-label">
            {{ "CROP_CARD.Number_of_birds" | translate }}
          </div>
          <div class="menus__menu-selector">
            <input
              [options]="{
                digitGroupSeparator: translationService.digitGroupSeparator,
                decimalCharacter: translationService.decimalCharacter,
                decimalPlaces: 0,
                minimumValue: '0'
              }"
              autonumeric
              [(ngModel)]="birdsNumber"
              (change)="birdsChanged($event.target.value)"
            />
          </div>
          <div class="menus__menu-error" *ngIf="errors['birdsNumber']">
            {{ errors["birdsNumber"] }}
          </div>
          <div class="menus__menu-error" *ngIf="errors['less0']">
            {{ errors["less0"] }}
          </div>
        </div>
      </div>
      <div class="display-flex space-between mb-20">
        <div class="menus__menu width-48">
          <div class="menus__menu-label">
            {{ "CROP_CARD.Sex_of_birds" | translate }}
          </div>
          <div class="menus__menu-selector">
            <input type="text" [ngModel]="sexOfBirds" readonly/>
          </div>
        </div>
        <div class="menus__menu width-48">
          <div class="menus__menu-label">{{'CROP_CARD.Total_Kg' | translate}}</div>
          <div class="menus__menu-selector">
            <input name="weight"
                   [options]="{
                digitGroupSeparator: translationService.digitGroupSeparator,
                decimalCharacter: translationService.decimalCharacter,
                decimalPlaces: 3,
                minimumValue: '0'
              }"
                   autonumeric
                   [(ngModel)]="totalKg"
                   (change)="totalKg = utilsService.toNormalValue($event.target.value)">
          </div>
        </div>
      </div>
    </div>
    <div class="center width-100">
      <button
        class="blue-rounded-button new-farm-button"
        (click)="closeModal()"
      >
        {{ "COMMON.BUTTONS.CANCEL" | translate }}
      </button>
      <button
        type="submit"
        [disabled]="!isThinningValue || disabledAfterClick"
        class="blue-rounded-button new-farm-button"
        (click)="applyChanges()"
      >
        <span *ngIf="!isThinningModalCompleteMode">{{ "COMMON.BUTTONS.Add" | translate }}</span>
        <span *ngIf="isThinningModalCompleteMode">{{ "COMMON.BUTTONS.Complete" | translate }}</span>
      </button>
    </div>
  </div>
</div>
