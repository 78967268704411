/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CustomerQuickInsightsThresholdSetting } from '../models/customer-quick-insights-threshold-setting';
import { CustomerQuickInsightsThresholdSettingDto } from '../models/customer-quick-insights-threshold-setting-dto';

@Injectable({
  providedIn: 'root',
})
export class CustomerQuickInsightsThresholdSettingService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getList
   */
  static readonly GetListPath = '/api/customer/quickInsight/threshold/setting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getList$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CustomerQuickInsightsThresholdSetting>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerQuickInsightsThresholdSettingService.GetListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CustomerQuickInsightsThresholdSetting>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getList(params?: {
  }): Observable<Array<CustomerQuickInsightsThresholdSetting>> {

    return this.getList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CustomerQuickInsightsThresholdSetting>>) => r.body as Array<CustomerQuickInsightsThresholdSetting>)
    );
  }

  /**
   * Path part for operation addSetting
   */
  static readonly AddSettingPath = '/api/customer/quickInsight/threshold/setting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addSetting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSetting$Response(params?: {
    body?: CustomerQuickInsightsThresholdSettingDto
  }): Observable<StrictHttpResponse<CustomerQuickInsightsThresholdSetting>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerQuickInsightsThresholdSettingService.AddSettingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerQuickInsightsThresholdSetting>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addSetting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSetting(params?: {
    body?: CustomerQuickInsightsThresholdSettingDto
  }): Observable<CustomerQuickInsightsThresholdSetting> {

    return this.addSetting$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerQuickInsightsThresholdSetting>) => r.body as CustomerQuickInsightsThresholdSetting)
    );
  }

  /**
   * Path part for operation getSettingsByTitleRoleId
   */
  static readonly GetSettingsByTitleRoleIdPath = '/api/customer/quickInsight/threshold/setting/customerId/{customerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettingsByTitleRoleId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingsByTitleRoleId$Response(params: {
    customerId: number;
  }): Observable<StrictHttpResponse<CustomerQuickInsightsThresholdSetting>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerQuickInsightsThresholdSettingService.GetSettingsByTitleRoleIdPath, 'get');
    if (params) {
      rb.path('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerQuickInsightsThresholdSetting>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSettingsByTitleRoleId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingsByTitleRoleId(params: {
    customerId: number;
  }): Observable<CustomerQuickInsightsThresholdSetting> {

    return this.getSettingsByTitleRoleId$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerQuickInsightsThresholdSetting>) => r.body as CustomerQuickInsightsThresholdSetting)
    );
  }

  /**
   * Path part for operation getSetting
   */
  static readonly GetSettingPath = '/api/customer/quickInsight/threshold/setting/{settingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSetting()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSetting$Response(params: {
    settingId: number;
  }): Observable<StrictHttpResponse<CustomerQuickInsightsThresholdSetting>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerQuickInsightsThresholdSettingService.GetSettingPath, 'get');
    if (params) {
      rb.path('settingId', params.settingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerQuickInsightsThresholdSetting>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSetting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSetting(params: {
    settingId: number;
  }): Observable<CustomerQuickInsightsThresholdSetting> {

    return this.getSetting$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerQuickInsightsThresholdSetting>) => r.body as CustomerQuickInsightsThresholdSetting)
    );
  }

  /**
   * Path part for operation updateSetting_2
   */
  static readonly UpdateSetting_2Path = '/api/customer/quickInsight/threshold/setting/{settingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSetting_2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSetting_2$Response(params: {
    settingId: number;
    body?: CustomerQuickInsightsThresholdSettingDto
  }): Observable<StrictHttpResponse<CustomerQuickInsightsThresholdSetting>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerQuickInsightsThresholdSettingService.UpdateSetting_2Path, 'put');
    if (params) {
      rb.path('settingId', params.settingId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerQuickInsightsThresholdSetting>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateSetting_2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSetting_2(params: {
    settingId: number;
    body?: CustomerQuickInsightsThresholdSettingDto
  }): Observable<CustomerQuickInsightsThresholdSetting> {

    return this.updateSetting_2$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerQuickInsightsThresholdSetting>) => r.body as CustomerQuickInsightsThresholdSetting)
    );
  }

  /**
   * Path part for operation deleteSetting
   */
  static readonly DeleteSettingPath = '/api/customer/quickInsight/threshold/setting/{settingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSetting()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSetting$Response(params: {
    settingId: number;
  }): Observable<StrictHttpResponse<CustomerQuickInsightsThresholdSetting>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerQuickInsightsThresholdSettingService.DeleteSettingPath, 'delete');
    if (params) {
      rb.path('settingId', params.settingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerQuickInsightsThresholdSetting>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSetting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSetting(params: {
    settingId: number;
  }): Observable<CustomerQuickInsightsThresholdSetting> {

    return this.deleteSetting$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerQuickInsightsThresholdSetting>) => r.body as CustomerQuickInsightsThresholdSetting)
    );
  }

}
