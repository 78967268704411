import {NgModule} from '@angular/core';
import {EssentialLogoComponent} from './essential-logo.component';

@NgModule({
  declarations: [EssentialLogoComponent],
  exports: [EssentialLogoComponent],
  imports: [],
})
export class EssentialLogoModule {
}
