import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalibrationManualModalComponent } from './calibration-manual-modal.component';
import {PipesModule} from '../../../pipes/pipes.module';



@NgModule({
  declarations: [
    CalibrationManualModalComponent
  ],
    imports: [
        CommonModule,
        PipesModule
    ],
  exports: [
    CalibrationManualModalComponent
  ]
})
export class CalibrationManualModalModule { }
