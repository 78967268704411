import {NgModule} from '@angular/core';
import {ShDatatableRowComponent} from './sh-datatable-row.component';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [ShDatatableRowComponent],
  imports: [
    CommonModule
  ],
  exports: [ShDatatableRowComponent]
})
export class ShDatatableRowModule {

}
