import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum EConfirmationModalModes {
  Regular = 'regular',
  Alert = 'alert',
  Confirmation = 'confirmation',
  GotIt = 'gotIt',
}

@Component({
  selector: 'confirmation-modal',
  templateUrl: './confirmation.component.html',
  styleUrls: ['confirmation.component.scss'],
})
export class ConfirmationModalComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() mode: EConfirmationModalModes = EConfirmationModalModes.Regular;
  @Input() isShowCross: boolean = true;
  @Output() onCancel: EventEmitter<void> = new EventEmitter();
  @Output() onConfirm: EventEmitter<void> = new EventEmitter();
  @Output() onClose: EventEmitter<void> = new EventEmitter();
  public disabledAfterClick = false;

  public cancel() {
    this.closeModal();
    this.onClose.emit();
    this.onCancel.emit();
  }

  public closeModal() {
    this.onClose.emit();
  }

  public confirm() {
    this.disabledAfterClick = true;
    this.onConfirm.emit();
  }
}
