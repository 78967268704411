import {NgModule} from '@angular/core';
import {NoDataAvailableComponent} from './no-data-available.component';
import {PipesModule} from '../../pipes/pipes.module';

@NgModule({
    declarations: [NoDataAvailableComponent],
    imports: [
        PipesModule
    ],
    exports: [NoDataAvailableComponent]
})
export class NoDataAvailableModule {

}
