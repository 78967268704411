<div class="display-flex position-relative">
  <div class="menus__menu">
    <div class="menus__menu-selector height66" *ngIf="isDateChanged">
      <div class="menus__menu-selector-label">{{ 'COMMON.Date' | translate }}</div>
      <date-picker
        [value]="selectedDate"
        (valueChange)="setDateAndTime($event)"
        [max]="maxDate"
        [min]="startDate"
      ></date-picker>
    </div>
    <div class="menus__menu-selector height66" *ngIf="!isDateChanged && functionalMode === EventFunctionalModes.addEvent">
      <div class="menus__menu-selector-label">{{ 'COMMON.Date' | translate }}</div>
      <input type="text" value="NA" (click)="isDateChanged = true; changeSelectedDate(selectedDate)"/>
    </div>
  </div>
  <div class="menus__menu" *ngIf="isDateChanged && functionalMode === EventFunctionalModes.addEvent">
    <div class="menus__menu-selector height66">
      <div class="menus__menu-selector-label">{{ 'COMMON.Time' | translate }}</div>
      <time-picker class="create-batch-event-content-control"
                   [shownRemovableIcon]="false"
                   (epochChanged)="onEpochChanged($event)"
                   [selectedEpoch]="epochSelectedDate"></time-picker>
    </div>
  </div>
  <div class="menus__menu" *ngIf="functionalMode === EventFunctionalModes.addEvent">
    <div class="menus__menu-selector height66" *ngIf="!isDateChanged && functionalMode === EventFunctionalModes.addEvent">
      <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_Age' | translate }}</div>
      <input
        [options]="{
              digitGroupSeparator: translationService.digitGroupSeparator,
              decimalCharacter: translationService.decimalCharacter,
              decimalPlaces: 0,
              minimumValue: '0',
              maximumValue: '80'
            }"
        autonumeric
        [value]="birdAge"
        (change)="changeBirdAge($event.target.value)"
      >
    </div>
    <div class="menus__menu-selector height66" *ngIf="isDateChanged && functionalMode === EventFunctionalModes.addEvent">
      <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_Age' | translate }}</div>
      <input type="text" value="NA" (click)="isDateChanged = false; changeBirdAge(birdAge)"/>
    </div>
  </div>
  <div class="menus__dash" *ngIf="functionalMode !== EventFunctionalModes.wizard">&mdash;</div>
  <div class="menus__menu" *ngIf="functionalMode !== EventFunctionalModes.wizard">
    <div class="menus__menu-selector height66">
      <div class="menus__menu-selector-label">{{ 'CROP_CARD.Crop_thinning_number' | translate }}</div>
      <input type="text" [ngModel]="cropThinningNumber" readonly/>
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector height66">
      <div class="menus__menu-selector-label">{{ 'CROP_CARD.Number_of_birds' | translate }}</div>
      <input
        [options]="{
              digitGroupSeparator: translationService.digitGroupSeparator,
              decimalCharacter: translationService.decimalCharacter,
              decimalPlaces: 0,
              minimumValue: '0'
            }"
        autonumeric
        [(ngModel)]="birdsNumber"
        (change)="birdsChanged($event.target.value)"
      />
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector height66">
      <div class="menus__menu-selector-label">{{ 'EVENT.kg' | translate }}</div>
      <input name="weight"
             [options]="{
              digitGroupSeparator: translationService.digitGroupSeparator,
              decimalCharacter: translationService.decimalCharacter,
              decimalPlaces: 3,
              minimumValue: '0'
            }"
             autonumeric
             [(ngModel)]="totalKg"
             (change)="kgChanged($event.target.value)">
    </div>
  </div>
  <div class="menus__menu-selector height66">
    <div class="menus__menu-selector-label">{{ 'CROP_CARD.Sex_of_birds' | translate }}</div>
    <input type="text" [ngModel]="sexOfBirds" readonly/>
  </div>

  <div class="remove-event" (click)="onRemoveEvent()">
    <span class="svg delete-blue"></span>
  </div>
</div>
