import {Inject, Injectable} from '@angular/core';
import {WINDOW} from '../../../utils/window-utils';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpResponseBase} from '@angular/common/http';
import {MockHttpService} from '../mock-http.service';
import {v4 as uuidv4} from 'uuid';
import * as moment from 'moment';
import {ClientSessionRecorderStorageService} from './client-session-recorder-storage.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ClientSessionRecorderMessagingService} from './client-session-recorder-messaging.service';
import {ClientSessionRecorderDomService} from './client-session-recorder-dom.service';
import {
  ClientSessionRecorderDataEvent,
  DomElementClientSessionRecorderEvent, FocusClientSessionRecorderEvent,
  InputClientSessionRecorderEvent,
  KeyboardClientSessionRecorderEvent, KeyboardEventType,
  MouseClickClientSessionRecorderEvent, ScrollClientSessionRecorderEvent,
  WebApiClientSessionRecorderEvent, WindowSizeSessionRecorderEvent
} from "../../common-model/csr-message-event.model";
import {ClientSessionRecorderEvent} from "../../common-model/csr-message.model";

@Injectable({
  providedIn: 'root'
})
export class MockSessionRecordingService {

  constructor(@Inject(WINDOW) private _window: Window,
              private readonly mockHttpService: MockHttpService,
              private readonly clientSessionRecorderStorageService: ClientSessionRecorderStorageService,
              private readonly clientSessionRecorderMessagingService: ClientSessionRecorderMessagingService,
              private readonly clientSessionRecorderDomService: ClientSessionRecorderDomService) {

  }

  public init() {
    this.clientSessionRecorderMessagingService.init();
    this.onWindowResize(true);
  }

  public handleHttpEvent(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap(
      event => {
        if (event instanceof HttpResponseBase) {
          this.recordWebApiMessage(request, event);
        }
      },
      error => {
        if (error instanceof HttpResponseBase) {
          this.recordWebApiMessage(request, error);
        }
      }
    ));
  }

  public recordWebApiMessage(request: HttpRequest<any>,
                             response: HttpResponseBase) {
    const webApiClientSessionRecorderEvent = new WebApiClientSessionRecorderEvent();
    webApiClientSessionRecorderEvent.endpoint = this.mockHttpService.resolveEndpointName(request);
    webApiClientSessionRecorderEvent.method = request.method;
    webApiClientSessionRecorderEvent.requestHeaders = this.mockHttpService.serializeHttpHeaders(request.headers);
    webApiClientSessionRecorderEvent.requestBody = request.body;
    webApiClientSessionRecorderEvent.responseHeaders = this.mockHttpService.serializeHttpHeaders(response.headers);
    if(response instanceof HttpResponse){
      webApiClientSessionRecorderEvent.responseBody = response.body;
      webApiClientSessionRecorderEvent.isErrorResponse = false;
    } else if(response instanceof  HttpErrorResponse){
      webApiClientSessionRecorderEvent.responseBody = response.error;
      webApiClientSessionRecorderEvent.isErrorResponse = true;
    }
    webApiClientSessionRecorderEvent.responseStatus = response.status;
    this.addEventToSend(webApiClientSessionRecorderEvent);
  }

  public onDocumentFocus(evt : FocusEvent) {
    let targetElement = <Element>evt.target;
    if(targetElement != null) {
      let focusClientSessionRecorderEvent = new FocusClientSessionRecorderEvent();
      if(!this.setDomElementEvent(focusClientSessionRecorderEvent, targetElement)) {
        return;
      }
      this.addEventToSend(focusClientSessionRecorderEvent);
    }
  }

  public onDocumentClick(evt : MouseEvent) {
    let targetElement = <Element>evt.target;
    if(targetElement != null) {
      let mouseClickClientSessionRecorderEvent = new MouseClickClientSessionRecorderEvent();
      if(!this.setDomElementEvent(mouseClickClientSessionRecorderEvent, targetElement)) {
        return;
      }
      mouseClickClientSessionRecorderEvent.clientX = evt.clientX;
      mouseClickClientSessionRecorderEvent.clientY = evt.clientY;
      mouseClickClientSessionRecorderEvent.button = evt.button;
      this.addEventToSend(mouseClickClientSessionRecorderEvent);
    }
  }

  public onDocumentKeyboardEvent(evt : KeyboardEvent) {
    let targetElement = <Element>evt.target;
    if(targetElement != null) {
      let keyboardClientSessionRecorderEvent = new KeyboardClientSessionRecorderEvent();
      if(!this.setDomElementEvent(keyboardClientSessionRecorderEvent, targetElement)) {
        return;
      }
      keyboardClientSessionRecorderEvent.keyboardEventType = <KeyboardEventType>evt.type;
      keyboardClientSessionRecorderEvent.code = evt.code;
      keyboardClientSessionRecorderEvent.key = evt.key;
      keyboardClientSessionRecorderEvent.ctrlKey = evt.ctrlKey;
      keyboardClientSessionRecorderEvent.altKey = evt.altKey;
      keyboardClientSessionRecorderEvent.shiftKey = evt.shiftKey;
      keyboardClientSessionRecorderEvent.location = evt.location;
      keyboardClientSessionRecorderEvent.metaKey = evt.metaKey;
      keyboardClientSessionRecorderEvent.repeat = evt.repeat;
      this.addEventToSend(keyboardClientSessionRecorderEvent);
    }
  }

  public onDocumentInput(evt : Event) {
    let targetElement = <HTMLInputElement>evt.target;
    if(targetElement != null) {
      let keyDownClientSessionRecorderEvent = new InputClientSessionRecorderEvent();
      if(!this.setDomElementEvent(keyDownClientSessionRecorderEvent, targetElement)) {
        return;
      }
      keyDownClientSessionRecorderEvent.value = targetElement.value;
      this.addEventToSend(keyDownClientSessionRecorderEvent);
    }
  }

  public onScroll(evt: Event) {
    let targetElement = <Element>evt.target;
    if(targetElement != null) {
      let scrollClientSessionRecorderEvent = new ScrollClientSessionRecorderEvent();
      if(!this.setDomElementEvent(scrollClientSessionRecorderEvent, targetElement)) {
        return;
      }
      scrollClientSessionRecorderEvent.scrollTop = targetElement.scrollTop;
      scrollClientSessionRecorderEvent.scrollLeft = targetElement.scrollLeft;
      this.addEventToSend(scrollClientSessionRecorderEvent);
    }
  }

  public onWindowResize(includeStorageState: boolean) {
    let windowSizeSessionRecorderEvent = new WindowSizeSessionRecorderEvent();
    windowSizeSessionRecorderEvent.width = this._window.innerWidth;
    windowSizeSessionRecorderEvent.height = this._window.innerHeight;
    if(includeStorageState) {
      windowSizeSessionRecorderEvent.sessionStorage = JSON.parse(JSON.stringify(this._window.sessionStorage));
      windowSizeSessionRecorderEvent.localStorage = JSON.parse(JSON.stringify(this._window.localStorage));
    }
    this.addEventToSend(windowSizeSessionRecorderEvent);
  }

  private addEventToSend(event:ClientSessionRecorderDataEvent) {
    event.eventTimestamp = moment().unix();
    event.eventId = uuidv4();
    event.clientUrl = window.location.href.split('#')[1];
    this.clientSessionRecorderMessagingService.addMessageToSend(event, true);
  }

  private setDomElementEvent(event:DomElementClientSessionRecorderEvent, element:Element) : boolean {
    event.elementXpath = this.clientSessionRecorderDomService.getElementXpath(element);
    event.elementShId = this.clientSessionRecorderDomService.findElementShId(element);
    event.elementType = element.tagName;
    if(event.elementXpath == null &&
       event.elementShId == null &&
       element.parentElement != null) {
      return this.setDomElementEvent(event, element.parentElement);
    }
    return event.elementXpath != null || event.elementShId != null;
  }
}
