import {Inject, Injectable} from '@angular/core';
import {ClientSessionRecorderStorageService} from './client-session-recorder-storage.service';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpResponseBase
} from '@angular/common/http';
import {from, Observable, of} from 'rxjs';
import {dematerialize, materialize, switchMap} from 'rxjs/operators';
import {MockHttpService} from '../mock-http.service';
import * as _ from 'lodash';
import {ClientSessionRecorderMessagingService} from './client-session-recorder-messaging.service';
import {ClientSessionRecorderDomService} from './client-session-recorder-dom.service';
import {WINDOW} from '../../../utils/window-utils';
import {
  EndOfAutoPlaybackActionType,
  PlaybackChangeModeClientSessionRecorderStatus,
  PlaybackConnectivityClientSessionRecorderStatus
} from "../../common-model/csr-message-status.model";
import {
  DomElementClientSessionRecorderEvent,
  DomElementEventType,
  FocusClientSessionRecorderEvent,
  InputClientSessionRecorderEvent,
  KeyboardClientSessionRecorderEvent,
  MouseClickClientSessionRecorderEvent,
  ScrollClientSessionRecorderEvent,
  WebApiClientSessionRecorderEvent,
  WindowSizeSessionRecorderEvent
} from "../../common-model/csr-message-event.model";
import {
  ClientSessionRecorderEvent,
  ClientSessionRecorderEventType,
  ClientSessionRecorderModeType
} from "../../common-model/csr-message.model";
import {ClientSessionTestingService} from "./client-session-testing.service";
import {ApplicationEventsService} from "../../../services/application-events/application-events.service";
import {
  ClientSessionRecorderTestRelatedEvent,
  DomElementExpectationClientSessionRecorderEvent,
  DomElementExpectationType,
  DomElementPropertyExpectationClientSessionRecorderEvent,
  DomElementStateExpectationClientSessionRecorderEvent,
  DomElementStateType,
  TestCaseDefinitionClientSessionRecorderEvent,
  TestExpectationDefinitionClientSessionRecorderEvent,
  TestExpectationType,
  TestRelatedEventType, WebApiExpectationClientSessionRecorderEvent
} from "../../common-model/csr-message-events-test-related.model";
import {ConfigService} from "../../../services/config/config.service";

@Injectable({
  providedIn: 'root'
})
export class MockSessionPlaybackService {

  private static readonly CONNECTIVITY_CHECK_INTERVAL:number = 2000;

  private playbackDetails :PlaybackChangeModeClientSessionRecorderStatus;

  private expectedNextDomElementEvent : DomElementClientSessionRecorderEvent = null;

  private allUserActionEventsCompleted : boolean;

  private mockHttpGetMessagesMap : Map<string, WebApiClientSessionRecorderEvent> = new Map<string, WebApiClientSessionRecorderEvent>();

  private mockHttpPostMessagesMap : Map<string, WebApiClientSessionRecorderEvent> = new Map<string, WebApiClientSessionRecorderEvent>();

  private mockHttpPutMessagesMap : Map<string, WebApiClientSessionRecorderEvent> = new Map<string, WebApiClientSessionRecorderEvent>();

  private mockHttpDeleteMessagesMap : Map<string, WebApiClientSessionRecorderEvent> = new Map<string, WebApiClientSessionRecorderEvent>();

  private autoPlaybackCurrentEvent : ClientSessionRecorderEvent = null;

  constructor(@Inject(WINDOW) private _window: Window,
              private readonly clientSessionRecorderStorageService: ClientSessionRecorderStorageService,
              private readonly mockHttpService: MockHttpService,
              private readonly clientSessionRecorderMessagingService: ClientSessionRecorderMessagingService,
              private readonly clientSessionRecorderDomService: ClientSessionRecorderDomService,
              private readonly clientSessionTestingService: ClientSessionTestingService,
              private readonly applicationEventsService: ApplicationEventsService,
              private readonly configService: ConfigService) {
    applicationEventsService.afterAppInitSubject.subscribe(async () => {
      if(this.playbackDetails != null) {
        await this.updateAutoPlaybackEvent();
      }
    });
  }

  public async init() {
    if (this.clientSessionRecorderStorageService.recorderStatus.changeMode == ClientSessionRecorderModeType.Playback) {
      this.playbackDetails = <PlaybackChangeModeClientSessionRecorderStatus>this.clientSessionRecorderStorageService.recorderStatus;
      this.configService.configuration.cloud = this.detectIsPlaybackIsReverseProxy();
      this.initStorage();
      this.initWebApiMessages();
      this.updateNextExpectedUserActionEvent();
      if (this.playbackDetails.validateConnectivity) {
        this.clientSessionRecorderMessagingService.init();
        setInterval(() => {
          let connectivityMessage = new PlaybackConnectivityClientSessionRecorderStatus();
          connectivityMessage.playbackSessionId = this.playbackDetails.playbackSession.sessionId;
          this.clientSessionRecorderMessagingService.addMessageToSend(connectivityMessage, true)
        }, MockSessionPlaybackService.CONNECTIVITY_CHECK_INTERVAL)
      }
      if (this.playbackDetails.autoPlayback != null) {
        this.clientSessionRecorderStorageService.storeNewRecorderStatus(null);
      }
    }
  }

  public handleHttpEvent(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleRequest(request)).pipe(switchMap(response => {
      if (response instanceof HttpErrorResponse) {
        throw <HttpErrorResponse>response;
      } else {
        return of(<HttpResponse<any>>response);
      }
    })).pipe(materialize()).pipe(dematerialize());
  }

  public async handleRequest(request: HttpRequest<any>): Promise<HttpResponseBase> {
    let webApiEvent = this.getWebApiEventByRequest(request);
    if(webApiEvent != null) {
      if(webApiEvent.isErrorResponse) {
        return new HttpErrorResponse({
          headers: this.mockHttpService.deserializeHttpHeaders(webApiEvent.responseHeaders),
          status: webApiEvent.responseStatus,
          error: _.cloneDeep(webApiEvent.responseBody)
        });
      }
      else {
        return new HttpResponse({
          headers: this.mockHttpService.deserializeHttpHeaders(webApiEvent.responseHeaders),
          status: webApiEvent.responseStatus,
          body: _.cloneDeep(webApiEvent.responseBody)
        });
      }
    } else {
      return null;
    }
  }

  public onDocumentFocus(evt : FocusEvent) {
    let targetElement = <Element>evt.target;
    if(targetElement == null ||
      this.expectedNextDomElementEvent == null ||
      this.expectedNextDomElementEvent.domElementEventType != DomElementEventType.Focus) {
      return;
    }
    let expectedNextMouseClickEvent = <FocusClientSessionRecorderEvent>this.expectedNextDomElementEvent;
    if(expectedNextMouseClickEvent.elementShId == this.clientSessionRecorderDomService.findElementShId(targetElement) &&
       expectedNextMouseClickEvent.elementXpath == this.clientSessionRecorderDomService.getElementXpath(targetElement)) {
       this.updateNextExpectedUserActionEvent();
    }
  }

  public onDocumentClick(evt : MouseEvent) {
    let targetElement = <Element>evt.target;
    if(targetElement == null ||
       this.expectedNextDomElementEvent == null ||
       this.expectedNextDomElementEvent.domElementEventType != DomElementEventType.MouseClick) {
      return;
    }
    let expectedNextMouseClickEvent = <MouseClickClientSessionRecorderEvent>this.expectedNextDomElementEvent;
    if(expectedNextMouseClickEvent.button == evt.button &&
       expectedNextMouseClickEvent.elementShId == this.clientSessionRecorderDomService.findElementShId(targetElement) &&
       expectedNextMouseClickEvent.elementXpath == this.clientSessionRecorderDomService.getElementXpath(targetElement)) {
      this.updateNextExpectedUserActionEvent();
    }
  }

  public onDocumentKeyboardEvent(evt : KeyboardEvent) {
    let targetElement = <Element>evt.target;
    if(targetElement == null ||
      this.expectedNextDomElementEvent == null ||
      this.expectedNextDomElementEvent.domElementEventType != DomElementEventType.Keyboard) {
      return;
    }
    let keyboardClientSessionRecorderEvent = <KeyboardClientSessionRecorderEvent>this.expectedNextDomElementEvent;
    if(keyboardClientSessionRecorderEvent.keyboardEventType == evt.type &&
       keyboardClientSessionRecorderEvent.altKey == evt.altKey &&
       keyboardClientSessionRecorderEvent.ctrlKey == evt.altKey &&
       keyboardClientSessionRecorderEvent.shiftKey == evt.shiftKey &&
       keyboardClientSessionRecorderEvent.code == evt.code &&
       keyboardClientSessionRecorderEvent.key == evt.key &&
       keyboardClientSessionRecorderEvent.elementShId == this.clientSessionRecorderDomService.findElementShId(targetElement) &&
       keyboardClientSessionRecorderEvent.elementXpath == this.clientSessionRecorderDomService.getElementXpath(targetElement)) {
      this.updateNextExpectedUserActionEvent();
    }
  }

  public onDocumentInput(evt : Event) {
    let targetElement = <HTMLInputElement>evt.target;
    if(targetElement == null ||
      this.expectedNextDomElementEvent == null ||
      this.expectedNextDomElementEvent.domElementEventType != DomElementEventType.Input) {
      return;
    }
    let keyDownClientSessionRecorderEvent = <InputClientSessionRecorderEvent>this.expectedNextDomElementEvent;
    if(keyDownClientSessionRecorderEvent.value == targetElement.value &&
      keyDownClientSessionRecorderEvent.elementShId == this.clientSessionRecorderDomService.findElementShId(targetElement) &&
      keyDownClientSessionRecorderEvent.elementXpath == this.clientSessionRecorderDomService.getElementXpath(targetElement)) {
      this.updateNextExpectedUserActionEvent();
    }
  }

  public onScroll(evt : Event) {
    let targetElement = <Element>evt.target;
    if(targetElement == null ||
      this.expectedNextDomElementEvent == null ||
      this.expectedNextDomElementEvent.domElementEventType != DomElementEventType.Scroll) {
      return;
    }
    let scrollClientSessionRecorderEvent = <ScrollClientSessionRecorderEvent>this.expectedNextDomElementEvent;
    if(scrollClientSessionRecorderEvent.elementShId == this.clientSessionRecorderDomService.findElementShId(targetElement) &&
       scrollClientSessionRecorderEvent.elementXpath == this.clientSessionRecorderDomService.getElementXpath(targetElement)) {
      this.updateNextExpectedUserActionEvent();
    }
  }

  public onWindowResize() {

  }

  public static initClientUrl(playbackChangeModeClientSessionRecorderStatus : PlaybackChangeModeClientSessionRecorderStatus) :boolean {
    if(playbackChangeModeClientSessionRecorderStatus.playbackSession.recordedEvents.length == 0) {
      return false;
    }
    let locationHost = window.location.href.split('#')[0];
    let windowResizeEvent = <WindowSizeSessionRecorderEvent>playbackChangeModeClientSessionRecorderStatus.playbackSession.recordedEvents[0];
    window.open(locationHost + '#' + windowResizeEvent.clientUrl,
                'SenseHubPlaybackWindow',
                'menubar=0,width=' + windowResizeEvent.width + ',height=' + windowResizeEvent.height);
    return true;
  }

  private updateNextExpectedUserActionEvent() {
    if(this.allUserActionEventsCompleted) {
      return;
    }
    let searchStartIndex = this.expectedNextDomElementEvent == null ? 0 : (this.playbackDetails.playbackSession.recordedEvents.indexOf(this.expectedNextDomElementEvent) + 1);
    this.expectedNextDomElementEvent = null;
    for (let eventIndex = searchStartIndex; eventIndex < this.playbackDetails.playbackSession.recordedEvents.length; eventIndex++) {
      if(this.playbackDetails.playbackSession.recordedEvents[eventIndex].eventType == ClientSessionRecorderEventType.DomElement) {
        this.expectedNextDomElementEvent = <DomElementClientSessionRecorderEvent>this.playbackDetails.playbackSession.recordedEvents[eventIndex];
        break;
      }
    }
    if(this.expectedNextDomElementEvent == null) {
      this.allUserActionEventsCompleted = true;
    }
    this.updateWebApiMessagesToNextExpectedUserAction();
  }

  private handleTestRelatedEvent(testRelatedEvent:ClientSessionRecorderTestRelatedEvent) : boolean {
    switch (testRelatedEvent.testEventType) {
      case TestRelatedEventType.TestCaseDefinition:
      {
        let testCaseDefinition = <TestCaseDefinitionClientSessionRecorderEvent>testRelatedEvent;
        if(this.clientSessionTestingService.currentTestCase != null &&
           this.clientSessionTestingService.currentTestCase.failureReason == null) {
          this.clientSessionTestingService.currentTestCase.success = true;
        }
        this.clientSessionTestingService.addTestCase({name: testCaseDefinition.testCaseName, success: false, failureReason: null});
      }
      case TestRelatedEventType.TestExpectationDefinition:
      {
        let testExpectationDefinition = <TestExpectationDefinitionClientSessionRecorderEvent>testRelatedEvent;
        if(testExpectationDefinition.expectationType == TestExpectationType.DomElement) {
          let domElementTestExpectation = <DomElementExpectationClientSessionRecorderEvent>testExpectationDefinition;
          if(domElementTestExpectation.domElementExpectationType == DomElementExpectationType.DomElementState) {
            this.scheduleTestExpectationDomElementState(<DomElementStateExpectationClientSessionRecorderEvent> domElementTestExpectation, 10);
            return true;
          } else if(domElementTestExpectation.domElementExpectationType == DomElementExpectationType.DomElementProperty) {
            this.scheduleTestExpectationDomElementProperty(<DomElementPropertyExpectationClientSessionRecorderEvent> domElementTestExpectation, 10);
            return true;
          }
        } else if(testExpectationDefinition.expectationType == TestExpectationType.WebApi) {
          this.scheduleTestExpectationWebApi(<WebApiExpectationClientSessionRecorderEvent>testExpectationDefinition);
          return true;
        }
      }
    }
    return false;
  }

  private updateWebApiMessagesToNextExpectedUserAction() {
    let updateMaxEventIndex = this.allUserActionEventsCompleted ?
                              this.playbackDetails.playbackSession.recordedEvents.length :
                              this.playbackDetails.playbackSession.recordedEvents.indexOf(this.expectedNextDomElementEvent);
    for (let eventIndex = 0; eventIndex < updateMaxEventIndex; eventIndex++) {
      if(this.playbackDetails.playbackSession.recordedEvents[eventIndex].eventType == ClientSessionRecorderEventType.WebApi) {
        this.setWebApiEventToMap(<WebApiClientSessionRecorderEvent>this.playbackDetails.playbackSession.recordedEvents[eventIndex], true);
      }
    }
  }

  private initStorage() {
    if(this.playbackDetails.playbackSession.recordedEvents.length == 0) {
      return;
    }
    if(this.playbackDetails.playbackSession.recordedEvents[0].eventType != ClientSessionRecorderEventType.WindowSize) {
      return;
    }
    let windowSizeFirstEvent = <WindowSizeSessionRecorderEvent>this.playbackDetails.playbackSession.recordedEvents[0];

    if(windowSizeFirstEvent.localStorage != null) {
      this._window.localStorage.clear();
      let newLocalStorageKeys = Object.keys(windowSizeFirstEvent.localStorage);
      for (let newLocalStorageKey of newLocalStorageKeys) {
        this._window.localStorage.setItem(newLocalStorageKey, windowSizeFirstEvent.localStorage[newLocalStorageKey]);
      }
    }

    if(windowSizeFirstEvent.sessionStorage != null) {
      this._window.sessionStorage.clear();
      let newSessionStorageKeys = Object.keys(windowSizeFirstEvent.sessionStorage);
      for (let newSessionStorageKey of newSessionStorageKeys) {
        this._window.sessionStorage.setItem(newSessionStorageKey, windowSizeFirstEvent.sessionStorage[newSessionStorageKey]);
      }
    }
  }

  private initWebApiMessages() {
    for (let webApiEvent of this.playbackDetails.playbackSession
                                                .recordedEvents
                                                .filter(event => event.eventType == ClientSessionRecorderEventType.WebApi)
                                                .map(event => <WebApiClientSessionRecorderEvent>event)) {
      this.setWebApiEventToMap(webApiEvent, false);
    }
  }

  private detectIsPlaybackIsReverseProxy() : boolean {
    for (let webApiEvent of this.playbackDetails.playbackSession
                                                .recordedEvents
                                                .filter(event => event.eventType == ClientSessionRecorderEventType.WebApi)
                                                .map(event => <WebApiClientSessionRecorderEvent>event)) {
      if(webApiEvent.endpoint == '/rest/api/server/setup') {
        return false;
      }
    }
    return true;
  }

  private getWebApiEventByRequest(request: HttpRequest<any>) : WebApiClientSessionRecorderEvent {
    let endpoint = this.mockHttpService.resolveEndpointName(request);
    if(request.method == 'GET') {
      if(this.mockHttpGetMessagesMap.has(endpoint)) {
        return this.mockHttpGetMessagesMap.get(endpoint)
      } else {
        console.error('Endpoint not found in record: ' + endpoint);
        return null;
      }
    }
    else if(request.method == 'POST') {
      if(this.mockHttpPostMessagesMap.has(endpoint)) {
        return this.mockHttpPostMessagesMap.get(endpoint)
      } else {
        console.error('Endpoint not found in record: ' + endpoint);
        return null;
      }
    }
    else if(request.method == 'PUT') {
      if(this.mockHttpPutMessagesMap.has(endpoint)) {
        return this.mockHttpPutMessagesMap.get(endpoint)
      } else {
        console.error('Endpoint not found in record: ' + endpoint);
        return null;
      }
    }
    else if(request.method == 'DELETE') {
      if(this.mockHttpDeleteMessagesMap.has(endpoint)) {
        return this.mockHttpDeleteMessagesMap.get(endpoint)
      } else {
        console.error('Endpoint not found in record: ' + endpoint);
        return null;
      }
    }
  }

  private setWebApiEventToMap(webApiEvent : WebApiClientSessionRecorderEvent, overwrite : boolean) {
    if(webApiEvent.method == 'GET') {
      if(!overwrite &&
         this.mockHttpGetMessagesMap.has(webApiEvent.endpoint)) {
        return;
      }
      this.mockHttpGetMessagesMap.set(webApiEvent.endpoint, webApiEvent);
    }
    else if(webApiEvent.method == 'POST') {
      if(!overwrite &&
        this.mockHttpPostMessagesMap.has(webApiEvent.endpoint)) {
        return;
      }
      this.mockHttpPostMessagesMap.set(webApiEvent.endpoint, webApiEvent);
    }
    else if(webApiEvent.method == 'PUT') {
      if(!overwrite &&
        this.mockHttpPutMessagesMap.has(webApiEvent.endpoint)) {
        return;
      }
      this.mockHttpPutMessagesMap.set(webApiEvent.endpoint, webApiEvent);
    }
    else if(webApiEvent.method == 'DELETE') {
      if(!overwrite &&
        this.mockHttpDeleteMessagesMap.has(webApiEvent.endpoint)) {
        return;
      }
      this.mockHttpDeleteMessagesMap.set(webApiEvent.endpoint, webApiEvent);
    }
  }

  private async updateAutoPlaybackEvent() {
    console.log('updateAutoPlaybackEvent');
    let searchStartIndex = this.autoPlaybackCurrentEvent == null ? 0 : (this.playbackDetails.playbackSession.recordedEvents.indexOf(this.autoPlaybackCurrentEvent) + 1);
    for (let eventIndex = searchStartIndex; eventIndex < this.playbackDetails.playbackSession.recordedEvents.length; eventIndex++) {
      if(this.playbackDetails.playbackSession.recordedEvents[eventIndex].eventType == ClientSessionRecorderEventType.DomElement) {
        let domEvent = <DomElementClientSessionRecorderEvent>this.playbackDetails.playbackSession.recordedEvents[eventIndex];
        if(domEvent.domElementEventType == DomElementEventType.Keyboard) {
          this.autoPlaybackCurrentEvent = this.playbackDetails.playbackSession.recordedEvents[eventIndex];
          this.scheduleAutoPlaybackKeyboardEvent(<KeyboardClientSessionRecorderEvent>this.autoPlaybackCurrentEvent, 50);
          return;
        }
        if(domEvent.domElementEventType == DomElementEventType.Focus) {
          this.autoPlaybackCurrentEvent = this.playbackDetails.playbackSession.recordedEvents[eventIndex];
          this.scheduleAutoPlaybackFocusEvent(<FocusClientSessionRecorderEvent>this.autoPlaybackCurrentEvent, 50);
          return;
        }
        if(domEvent.domElementEventType == DomElementEventType.MouseClick) {
          this.autoPlaybackCurrentEvent = this.playbackDetails.playbackSession.recordedEvents[eventIndex];
          this.scheduleAutoPlaybackMouseClickEvent(<MouseClickClientSessionRecorderEvent>this.autoPlaybackCurrentEvent, 50);
          return;
        }
        if(domEvent.domElementEventType == DomElementEventType.Input) {
          this.autoPlaybackCurrentEvent = this.playbackDetails.playbackSession.recordedEvents[eventIndex];
          this.scheduleAutoPlaybackInputEvent(<InputClientSessionRecorderEvent>this.autoPlaybackCurrentEvent, 50);
          return;
        }
        if(domEvent.domElementEventType == DomElementEventType.Scroll) {
          this.autoPlaybackCurrentEvent = this.playbackDetails.playbackSession.recordedEvents[eventIndex];
          this.scheduleAutoPlaybackScrollEvent(<ScrollClientSessionRecorderEvent>this.autoPlaybackCurrentEvent, 50);
          return;
        }
      }
      else if(this.playbackDetails.playbackSession.recordedEvents[eventIndex].eventType == ClientSessionRecorderEventType.WindowSize) {
        this.autoPlaybackCurrentEvent = this.playbackDetails.playbackSession.recordedEvents[eventIndex];
        this.scheduleAutoPlaybackWindowSizeEvent(<WindowSizeSessionRecorderEvent>this.autoPlaybackCurrentEvent);
        return;
      }
      else if(this.playbackDetails.playbackSession.recordedEvents[eventIndex].eventType == ClientSessionRecorderEventType.TestRelated) {
        if(this.handleTestRelatedEvent(<ClientSessionRecorderTestRelatedEvent>this.playbackDetails.playbackSession.recordedEvents[eventIndex])) {
          this.autoPlaybackCurrentEvent = this.playbackDetails.playbackSession.recordedEvents[eventIndex];
          return;
        }
      }
    }
    await this.clientSessionTestingService.notifyCompleted();
    if(this.playbackDetails.autoPlayback.endOfPlaybackAction == EndOfAutoPlaybackActionType.ExitPlaybackMode) {
      this._window.close();
    }
  }

  private scheduleAutoPlaybackKeyboardEvent(keyboardClientSessionRecorderEvent : KeyboardClientSessionRecorderEvent, numberOfAttempts: number) {
    setTimeout(async () => {
      let element = this.getElementByEvent(keyboardClientSessionRecorderEvent.elementType,
                                           keyboardClientSessionRecorderEvent.elementShId,
                                           keyboardClientSessionRecorderEvent.elementXpath);
      if(element != null) {
        let intersectionObserver = new IntersectionObserver(async entries => {
          let isVisible = entries[0].isIntersecting;
          intersectionObserver.disconnect();
          if(isVisible) {
            element.dispatchEvent(new KeyboardEvent(keyboardClientSessionRecorderEvent.keyboardEventType, {
              code: keyboardClientSessionRecorderEvent.code,
              key: keyboardClientSessionRecorderEvent.key,
              ctrlKey: keyboardClientSessionRecorderEvent.ctrlKey,
              altKey: keyboardClientSessionRecorderEvent.altKey,
              shiftKey: keyboardClientSessionRecorderEvent.shiftKey,
              location: keyboardClientSessionRecorderEvent.location,
              metaKey: keyboardClientSessionRecorderEvent.metaKey,
              repeat: keyboardClientSessionRecorderEvent.repeat,
              bubbles: true,
              cancelable: true
            }));
            await this.updateAutoPlaybackEvent();
          } else {
            if(numberOfAttempts == 0) {
              let errorMessage = this.createElementNotFoundText('Key Down Element Not Found',
                keyboardClientSessionRecorderEvent.elementXpath,
                keyboardClientSessionRecorderEvent.elementShId,
                keyboardClientSessionRecorderEvent);
              await this.handleEventError(errorMessage);
            } else {
              this.scheduleAutoPlaybackKeyboardEvent(keyboardClientSessionRecorderEvent, numberOfAttempts-1);
            }
          }
        }, { threshold: [0] });
        intersectionObserver.observe(element);
      } else {
        if(numberOfAttempts == 0) {
          let errorMessage = this.createElementNotFoundText('Key Down Element Not Found',
                                                     keyboardClientSessionRecorderEvent.elementXpath,
                                                     keyboardClientSessionRecorderEvent.elementShId,
                                                     keyboardClientSessionRecorderEvent);
          await this.handleEventError(errorMessage);
        } else {
          this.scheduleAutoPlaybackKeyboardEvent(keyboardClientSessionRecorderEvent, numberOfAttempts-1);
        }
      }
    }, this.playbackDetails.autoPlayback.keyboardInterval);
  }

  private scheduleAutoPlaybackInputEvent(inputClientSessionRecorderEvent : InputClientSessionRecorderEvent, numberOfAttempts: number) {
    setTimeout(async () => {
      let element = <HTMLInputElement>this.getElementByEvent(inputClientSessionRecorderEvent.elementType,
                                                             inputClientSessionRecorderEvent.elementShId,
                                                             inputClientSessionRecorderEvent.elementXpath);
      if(element != null) {
        let intersectionObserver = new IntersectionObserver(async entries => {
          let isVisible = entries[0].isIntersecting;
          intersectionObserver.disconnect();
          if(isVisible) {
            element.focus();
            element.value = inputClientSessionRecorderEvent.value;
            element.dispatchEvent(new Event('input', {
              bubbles: true,
              cancelable: true
            }));
            await this.updateAutoPlaybackEvent();
          } else {
            if(numberOfAttempts == 0) {
              let errorMessage = this.createElementNotFoundText('Input Element Not Found',
                inputClientSessionRecorderEvent.elementXpath,
                inputClientSessionRecorderEvent.elementShId,
                inputClientSessionRecorderEvent);
              await this.handleEventError(errorMessage);
            } else {
              this.scheduleAutoPlaybackInputEvent(inputClientSessionRecorderEvent, numberOfAttempts-1);
            }
          }
        }, { threshold: [0] });
        intersectionObserver.observe(element);
      } else {
        if(numberOfAttempts == 0) {
          let errorMessage = this.createElementNotFoundText('Input Element Not Found',
                                                            inputClientSessionRecorderEvent.elementXpath,
                                                            inputClientSessionRecorderEvent.elementShId,
                                                            inputClientSessionRecorderEvent);
          await this.handleEventError(errorMessage);
        } else {
          this.scheduleAutoPlaybackInputEvent(inputClientSessionRecorderEvent, numberOfAttempts-1);
        }
    }
    }, this.playbackDetails.autoPlayback.inputInterval);
  }

  private scheduleAutoPlaybackFocusEvent(focusClientSessionRecorderEvent : FocusClientSessionRecorderEvent, numberOfAttempts: number) {
    setTimeout(async () => {
      let element = this.getElementByEvent(focusClientSessionRecorderEvent.elementType,
                                           focusClientSessionRecorderEvent.elementShId,
                                           focusClientSessionRecorderEvent.elementXpath);
      if(element != null) {
        let intersectionObserver = new IntersectionObserver(async entries => {
          let isVisible = entries[0].isIntersecting;
          intersectionObserver.disconnect();
          if(isVisible) {
            let focusFunc = element['focus'];
            if(typeof focusFunc === 'function') {
              let anyElement: any = <any>element;
              anyElement.focus();
            }
            await this.updateAutoPlaybackEvent();
          } else {
            if(numberOfAttempts == 0) {
              let errorMessage = this.createElementNotFoundText('Mouse Click Element Not Found',
                focusClientSessionRecorderEvent.elementXpath,
                focusClientSessionRecorderEvent.elementShId,
                focusClientSessionRecorderEvent);
              await this.handleEventError(errorMessage);
            } else {
              this.scheduleAutoPlaybackFocusEvent(focusClientSessionRecorderEvent, numberOfAttempts-1);
            }
          }
        }, { threshold: [0] });
        intersectionObserver.observe(element);
      } else {
        if(numberOfAttempts == 0) {
          let errorMessage = this.createElementNotFoundText('Mouse Click Element Not Found',
            focusClientSessionRecorderEvent.elementXpath,
            focusClientSessionRecorderEvent.elementShId,
            focusClientSessionRecorderEvent);
          await this.handleEventError(errorMessage);
        } else {
          this.scheduleAutoPlaybackFocusEvent(focusClientSessionRecorderEvent, numberOfAttempts-1);
        }
      }
    }, this.playbackDetails.autoPlayback.clickInterval);
  }

  private scheduleAutoPlaybackMouseClickEvent(mouseClickClientSessionRecorderEvent : MouseClickClientSessionRecorderEvent, numberOfAttempts: number) {
    setTimeout(async () => {
      let element = this.getElementByEvent(mouseClickClientSessionRecorderEvent.elementType,
                                           mouseClickClientSessionRecorderEvent.elementShId,
                                           mouseClickClientSessionRecorderEvent.elementXpath);
      if(element != null) {
        let intersectionObserver = new IntersectionObserver(async entries => {
          let isVisible = entries[0].isIntersecting;
          intersectionObserver.disconnect();
          if(isVisible) {
            element.dispatchEvent(new MouseEvent('click', {
              button: mouseClickClientSessionRecorderEvent.button,
              clientX: mouseClickClientSessionRecorderEvent.clientX,
              clientY: mouseClickClientSessionRecorderEvent.clientY,
              bubbles: true,
              cancelable: true
            }));
            await this.updateAutoPlaybackEvent();
          } else {
            if(numberOfAttempts == 0) {
              let errorMessage = this.createElementNotFoundText('Mouse Click Element Not Found',
                mouseClickClientSessionRecorderEvent.elementXpath,
                mouseClickClientSessionRecorderEvent.elementShId,
                mouseClickClientSessionRecorderEvent);
              await this.handleEventError(errorMessage);
            } else {
              this.scheduleAutoPlaybackMouseClickEvent(mouseClickClientSessionRecorderEvent, numberOfAttempts-1);
            }
          }
        }, { threshold: [0] });
        intersectionObserver.observe(element);
      } else {
        if(numberOfAttempts == 0) {
          let errorMessage = this.createElementNotFoundText('Mouse Click Element Not Found',
            mouseClickClientSessionRecorderEvent.elementXpath,
            mouseClickClientSessionRecorderEvent.elementShId,
            mouseClickClientSessionRecorderEvent);
          await this.handleEventError(errorMessage);
        } else {
          this.scheduleAutoPlaybackMouseClickEvent(mouseClickClientSessionRecorderEvent, numberOfAttempts-1);
        }
      }
    },  this.playbackDetails.autoPlayback.clickInterval);
  }

  private scheduleAutoPlaybackScrollEvent(scrollClientSessionRecorderEvent : ScrollClientSessionRecorderEvent, numberOfAttempts: number) {
    setTimeout(async () => {
      let element = this.getElementByEvent(scrollClientSessionRecorderEvent.elementType,
                                           scrollClientSessionRecorderEvent.elementShId,
                                           scrollClientSessionRecorderEvent.elementXpath);
      if(element != null) {
        let intersectionObserver = new IntersectionObserver(async entries => {
          let isVisible = entries[0].isIntersecting;
          intersectionObserver.disconnect();
          if(isVisible) {
            element.scroll({left: scrollClientSessionRecorderEvent.scrollLeft, top: scrollClientSessionRecorderEvent.scrollTop});
            await this.updateAutoPlaybackEvent();
          } else {
            if(numberOfAttempts == 0) {
              let errorMessage = this.createElementNotFoundText('Scroll Element Not Found',
                scrollClientSessionRecorderEvent.elementXpath,
                scrollClientSessionRecorderEvent.elementShId,
                scrollClientSessionRecorderEvent);
              await this.handleEventError(errorMessage);
            } else {
              this.scheduleAutoPlaybackScrollEvent(scrollClientSessionRecorderEvent, numberOfAttempts-1);
            }
          }
        }, { threshold: [0] });
        intersectionObserver.observe(element);
      } else {
        if(numberOfAttempts == 0) {
          let errorMessage = this.createElementNotFoundText('Scroll Element Not Found',
                                                scrollClientSessionRecorderEvent.elementXpath,
                                                scrollClientSessionRecorderEvent.elementShId,
                                                scrollClientSessionRecorderEvent);
          await this.handleEventError(errorMessage);
        } else {
          this.scheduleAutoPlaybackScrollEvent(scrollClientSessionRecorderEvent, numberOfAttempts-1);
        }
      }
    },  this.playbackDetails.autoPlayback.scrollInterval);
  }

  private scheduleAutoPlaybackWindowSizeEvent(windowSizeSessionRecorderEvent : WindowSizeSessionRecorderEvent) {
    setTimeout(async () => {
      MockSessionPlaybackService.resizeWindowByInnerSize(this._window,
                                                         windowSizeSessionRecorderEvent.width,
                                                         windowSizeSessionRecorderEvent.height);
      await this.updateAutoPlaybackEvent();
    }, this.playbackDetails.autoPlayback.windowResizeInterval);
  }

  private scheduleTestExpectationDomElementProperty(domElementPropertyTestExpectation: DomElementPropertyExpectationClientSessionRecorderEvent, numberOfAttempts: number) {
    setTimeout(async () => {
      let element = this.getElementByEvent(domElementPropertyTestExpectation.elementType,
                                           domElementPropertyTestExpectation.elementShId,
                                           domElementPropertyTestExpectation.elementXpath);
      if(element != null) {
        let result = eval('element.' + domElementPropertyTestExpectation.domElementPropertyEvaluation);
        if (result == true) {
          await this.updateAutoPlaybackEvent();
        } else {
          if(numberOfAttempts == 0) {
            await this.handleEventError('Evaluation Failed: ' + JSON.stringify(domElementPropertyTestExpectation));
          } else {
            this.scheduleTestExpectationDomElementProperty(domElementPropertyTestExpectation, numberOfAttempts - 1);
          }
        }
      } else {
        if(numberOfAttempts == 0) {
          await this.handleEventError('Dom Element Not Found: ' + JSON.stringify(domElementPropertyTestExpectation));
        } else {
          this.scheduleTestExpectationDomElementProperty(domElementPropertyTestExpectation, numberOfAttempts - 1);
        }
      }
    }, domElementPropertyTestExpectation.waitTime)
  }

  private scheduleTestExpectationDomElementState(domElementStateTestExpectation: DomElementStateExpectationClientSessionRecorderEvent, numberOfAttempts: number) {
    setTimeout(async () => {
      let element = this.getElementByEvent(domElementStateTestExpectation.elementType,
                                           domElementStateTestExpectation.elementShId,
                                           domElementStateTestExpectation.elementXpath);
      if(element != null &&
         domElementStateTestExpectation.stateType == DomElementStateType.Visible) {
        let intersectionObserver = new IntersectionObserver(async entries => {
          let isVisible = entries[0].isIntersecting;
          await this.testExpectationDomElementState(domElementStateTestExpectation, element, isVisible);
          intersectionObserver.disconnect();
        }, { threshold: [0] });
        intersectionObserver.observe(element);
      } else {
        if(numberOfAttempts == 0) {
          await this.testExpectationDomElementState(domElementStateTestExpectation, element, false);
        } else {
          this.scheduleTestExpectationDomElementState(domElementStateTestExpectation, numberOfAttempts - 1);
        }
      }
    }, domElementStateTestExpectation.waitTime);
  }

  private scheduleTestExpectationWebApi(webApiExpectationClientSessionRecorderEvent: WebApiExpectationClientSessionRecorderEvent) {
    // setTimeout(async () => {
    //   let element = this.getElementByEvent(domElementPropertyTestExpectation.elementType,
    //     domElementPropertyTestExpectation.elementShId,
    //     domElementPropertyTestExpectation.elementXpath);
    //   if(element != null) {
    //     let result = eval('element.' + domElementPropertyTestExpectation.domElementPropertyEvaluation);
    //     if (result == true) {
    //       await this.updateAutoPlaybackEvent();
    //     } else {
    //       await this.handleEventError('Evaluation Failed: ' + JSON.stringify(domElementPropertyTestExpectation));
    //     }
    //   } else {
    //     await this.handleEventError('Dom Element Not Found: ' + JSON.stringify(domElementPropertyTestExpectation));
    //   }
    // }, webApiExpectationClientSessionRecorderEvent.waitTime);
  }

  private async testExpectationDomElementState(domElementStateTestExpectation: DomElementStateExpectationClientSessionRecorderEvent, element: Element, isVisible: boolean) {
    if (!domElementStateTestExpectation.falseExpectation) {
      if (element == null) {
        await this.handleEventError('Test Element Not Found: ' + JSON.stringify(domElementStateTestExpectation));
        return;
      } else if (domElementStateTestExpectation.stateType == DomElementStateType.Visible &&
        !isVisible) {
        await this.handleEventError('Test Element Not Visible: ' + JSON.stringify(domElementStateTestExpectation));
        return;
      }
    } else if (element != null) {
      if (domElementStateTestExpectation.stateType == DomElementStateType.Visible &&
        isVisible) {
        await this.handleEventError('Test Element Visible: ' + JSON.stringify(domElementStateTestExpectation));
        return;
      } else if (domElementStateTestExpectation.stateType == DomElementStateType.Exist) {
        await this.handleEventError('Test Element Exist: ' + JSON.stringify(domElementStateTestExpectation));
      }
    }
    await this.updateAutoPlaybackEvent();
  }

  private showElementNotFoundConfirmWindow(errorText : string) {
    if(this._window.confirm(errorText)) {
      this._window.close();
    }
  }

  private createElementNotFoundText(title:string, xpath:string, shid:string, event:ClientSessionRecorderEvent) : string {
    return title + '\n' +
      'XPath: ' + xpath + '\n' +
      'ShId: ' + shid + '\n' +
      JSON.stringify(event);
  }

  private getElementByEvent(elementType:string, elementShId:string, elementXpath:string) : Element {
    let element : Element
    if(elementShId != null) {
      element = this.clientSessionRecorderDomService.findElementByShId(elementType, elementShId);
    }
    if(element == null) {
      element = this.clientSessionRecorderDomService.findElementByXpath(elementXpath);
    }
    return element;
  }

  private async handleEventError(errorMessage: string) {
    if (this.clientSessionTestingService.playAsTest) {
      let currentTestCase = this.clientSessionTestingService.currentTestCase;
      if(currentTestCase == null) {
        this.clientSessionTestingService.addDefaultTestCase();
        currentTestCase = this.clientSessionTestingService.currentTestCase;
      }
      currentTestCase.success = false;
      currentTestCase.failureReason = errorMessage;
      await this.updateAutoPlaybackEvent();
    } else {
      this.showElementNotFoundConfirmWindow(errorMessage);
    }
  }

  private static resizeWindowByInnerSize(windowToResize:Window, width:number, height:number) {
    let widthDifference = windowToResize.outerWidth - windowToResize.innerWidth;
    let heightDifference = windowToResize.outerHeight - windowToResize.innerHeight;
    windowToResize.resizeTo(width + widthDifference,
                            height + heightDifference);
  }
}
