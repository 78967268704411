import { NgModule } from '@angular/core';
import { FooterModule } from './footer/footer.module';
import { HeaderModule } from './header/header.module';
import { QuickInsightWidgetModule } from './widgets/quick-insight/quick-insight-widget.module';
import { WeightWidgetModule } from './widgets/weight/weight-widget.module';
import { DiaryWidgetModule } from './widgets/diary/diary-widget.module';
import { Last5ReportsWidgetModule } from './widgets/last-5-reports/last5-reports-widget.module';
import { WaterConsumptionWidgetModule } from './widgets/water-consumption/water-consumption-widget.module';
import { MortalityWidgetModule } from './widgets/mortality/mortality-widget.module';
import { NoDataAvailableModule } from './no-data-available/no-data-available.module';
import { DropdownsModule } from './dropdowns/dropdowns.module';
import { ZoneCardModule } from './zone-card/zone-card.module';
import { MortalityTableModule } from './mortality-table/mortality-table.module';
import { EnvironmentalReadingsModule } from './environmental-readings/environmental-readings.module';
import { RowActionsPopupModule } from './row-actions-popup/row-actions-popup.module';
import { ShDatatableModule } from './sh-datatable/sh-datatable.module';
import { DeliveryNotesModalModule } from './modals/delivery-notes-modal/delivery-notes-modal.module';
import { StartNewCropModule } from './start-new-crop/start-new-crop.module';
import { AddNewFarmModule } from './add-new-farm/add-new-farm.module';
import { DataGridModule } from './data-grid/data-grid.module';
import { CloseCropModalModule } from './modals/close-crop-modal/close-crop-modal.module';
import { EventsModule } from './events/events.module';
import { EditFarmModalModule } from './modals/edit-farm-modal/edit-farm-modal.module';
import { ShowHousesModule } from './show-houses/show-houses.module';
import { AddEditHouseModule } from './modals/add-edit-house/add-edit-house.module';
import { AddDeviceModalModule } from './modals/add-device-modal/add-device-modal.module';
import { AddBaseStationModalModule } from './modals/add-base-station-modal/add-base-station-modal.module';
import { SelectDeviceLocationModalModule } from './modals/select-device-location-modal/select-device-location-modal.module';
import { AllZonesModule } from './all-zones/all-zones.module';
import { DeleteDeviceModalModule } from './modals/delete-device-modal/delete-device-modal.module';
import { CalibrationManualModalModule } from './modals/calibration-manual-modal/calibration-manual-modal.module';
import { PaginationModule } from './pagination/pagination.module';
import { BreadcrumbsModule } from './breadcrumbs/breadcrumbs.module';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { DetouchDeviceLocationModule } from './modals/detouch-device-location/detouch-device-location.module';
import { SelectorsModule } from './selectors/selectors.module';
import { AddThinningModalModule } from './modals/add-thinning-modal/add-thinning-modal.module';
import { ConfirmationModalModule } from './modals/confirmation-modal/confirmation.module';
import { DropDownListsModule } from './dropdown-list/dropdown-list.module';
import { CopyrightModule } from './copyright/copyright.module';
import { SensorGraphModule } from './graphs/sensor-graph/sensor-graph.module';
import { NotificationsModule } from './notifications/notifications.module';
import { SelectableZonesModule } from './selectable-zones/selectable-zones.module';
import { PrivacyModule } from './privacy/privacy.module';

import { TimePickerModule } from './time-picker/time-picker.module'
@NgModule({
  imports: [OwlDateTimeModule, OwlNativeDateTimeModule],
  exports: [
    CopyrightModule,
    FooterModule,
    HeaderModule,
    QuickInsightWidgetModule,
    DropDownListsModule,
    WeightWidgetModule,
    DiaryWidgetModule,
    Last5ReportsWidgetModule,
    WaterConsumptionWidgetModule,
    MortalityWidgetModule,
    NoDataAvailableModule,
    DropdownsModule,
    ZoneCardModule,
    BreadcrumbsModule,
    MortalityTableModule,
    EnvironmentalReadingsModule,
    RowActionsPopupModule,
    ConfirmationModalModule,
    ShDatatableModule,
    DeliveryNotesModalModule,
    StartNewCropModule,
    AddNewFarmModule,
    DataGridModule,
    CloseCropModalModule,
    EventsModule,
    EditFarmModalModule,
    ShowHousesModule,
    AddEditHouseModule,
    AddDeviceModalModule,
    AddThinningModalModule,
    AddBaseStationModalModule,
    SelectDeviceLocationModalModule,
    AllZonesModule,
    DeleteDeviceModalModule,
    CalibrationManualModalModule,
    PaginationModule,
    DetouchDeviceLocationModule,
    SelectorsModule,
    SensorGraphModule,
    NotificationsModule,
    SelectableZonesModule,
    PrivacyModule,
    TimePickerModule
  ],
})
export class ComponentsModule {}
