import { NgModule } from '@angular/core';
import { ShowHousesComponent } from './show-houses.component';
import { PipesModule } from '../../pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddEditHouseModule } from '../modals/add-edit-house/add-edit-house.module';
import { ConfirmationModalModule } from '../modals/confirmation-modal/confirmation.module';
import { ErrorModalModule } from '../modals/error-modal/error-modal.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DimensionsModule } from '../../../pages/farms-overview/dimensions/dimensions.module';
import { HouseIllustrationModule } from '../../../pages/farms-overview/house-illustration/house-illustration.module';
import { SensorsModule } from '../../../pages/farms-overview/sensors/sensors.module';

@NgModule({
  declarations: [ShowHousesComponent],
  imports: [
    PipesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AddEditHouseModule,
    ConfirmationModalModule,
    ErrorModalModule,
    SlickCarouselModule,
    DimensionsModule,
    HouseIllustrationModule,
    SensorsModule,
  ],
  exports: [ShowHousesComponent],
})
export class ShowHousesModule {}
