import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../../../pipes/pipes.module';
import { ResetPasswordComponent } from './reset-password.component';

@NgModule({
  declarations: [ResetPasswordComponent],
  imports: [CommonModule, ReactiveFormsModule, PipesModule, FormsModule],
  exports: [ResetPasswordComponent],
})
export class ResetPasswordModule {}
