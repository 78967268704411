/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CustomerFeedMill } from '../models/customer-feed-mill';
import { CustomerFeedMillCreateDto } from '../models/customer-feed-mill-create-dto';
import { CustomerFeedMillDto } from '../models/customer-feed-mill-dto';

@Injectable({
  providedIn: 'root',
})
export class CustomerFeedMillService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation addCustomerFeedMill
   */
  static readonly AddCustomerFeedMillPath = '/api/customer/feedMill';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCustomerFeedMill()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addCustomerFeedMill$Response(params?: {
    body?: CustomerFeedMillCreateDto
  }): Observable<StrictHttpResponse<CustomerFeedMill>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerFeedMillService.AddCustomerFeedMillPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerFeedMill>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addCustomerFeedMill$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addCustomerFeedMill(params?: {
    body?: CustomerFeedMillCreateDto
  }): Observable<CustomerFeedMill> {

    return this.addCustomerFeedMill$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerFeedMill>) => r.body as CustomerFeedMill)
    );
  }

  /**
   * Path part for operation findByBusinessNumber
   */
  static readonly FindByBusinessNumberPath = '/api/customer/feedMill/businessNumber/{businessNumber}/zipCode/{zipCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByBusinessNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByBusinessNumber$Response(params: {
    businessNumber: string;
    zipCode: string;
  }): Observable<StrictHttpResponse<Array<CustomerFeedMill>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerFeedMillService.FindByBusinessNumberPath, 'get');
    if (params) {
      rb.path('businessNumber', params.businessNumber, {});
      rb.path('zipCode', params.zipCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CustomerFeedMill>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByBusinessNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByBusinessNumber(params: {
    businessNumber: string;
    zipCode: string;
  }): Observable<Array<CustomerFeedMill>> {

    return this.findByBusinessNumber$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CustomerFeedMill>>) => r.body as Array<CustomerFeedMill>)
    );
  }

  /**
   * Path part for operation findById
   */
  static readonly FindByIdPath = '/api/customer/feedMill/customerFeedMill/{customerFeedMillId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    customerFeedMillId: number;
  }): Observable<StrictHttpResponse<CustomerFeedMill>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerFeedMillService.FindByIdPath, 'get');
    if (params) {
      rb.path('customerFeedMillId', params.customerFeedMillId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerFeedMill>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    customerFeedMillId: number;
  }): Observable<CustomerFeedMill> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerFeedMill>) => r.body as CustomerFeedMill)
    );
  }

  /**
   * Path part for operation findByName
   */
  static readonly FindByNamePath = '/api/customer/feedMill/name/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByName()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByName$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<Array<CustomerFeedMill>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerFeedMillService.FindByNamePath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CustomerFeedMill>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByName(params: {
    name: string;
  }): Observable<Array<CustomerFeedMill>> {

    return this.findByName$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CustomerFeedMill>>) => r.body as Array<CustomerFeedMill>)
    );
  }

  /**
   * Path part for operation updateCustomerFeedMill
   */
  static readonly UpdateCustomerFeedMillPath = '/api/customer/feedMill/{customerFeedMillId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomerFeedMill()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerFeedMill$Response(params: {
    customerFeedMillId: number;
    body?: CustomerFeedMillDto
  }): Observable<StrictHttpResponse<CustomerFeedMill>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerFeedMillService.UpdateCustomerFeedMillPath, 'put');
    if (params) {
      rb.path('customerFeedMillId', params.customerFeedMillId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerFeedMill>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCustomerFeedMill$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerFeedMill(params: {
    customerFeedMillId: number;
    body?: CustomerFeedMillDto
  }): Observable<CustomerFeedMill> {

    return this.updateCustomerFeedMill$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerFeedMill>) => r.body as CustomerFeedMill)
    );
  }

  /**
   * Path part for operation deleteCustomerFeedMill
   */
  static readonly DeleteCustomerFeedMillPath = '/api/customer/feedMill/{customerFeedMillId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerFeedMill()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerFeedMill$Response(params: {
    customerFeedMillId: number;
  }): Observable<StrictHttpResponse<CustomerFeedMill>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerFeedMillService.DeleteCustomerFeedMillPath, 'delete');
    if (params) {
      rb.path('customerFeedMillId', params.customerFeedMillId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerFeedMill>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerFeedMill$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerFeedMill(params: {
    customerFeedMillId: number;
  }): Observable<CustomerFeedMill> {

    return this.deleteCustomerFeedMill$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerFeedMill>) => r.body as CustomerFeedMill)
    );
  }

  /**
   * Path part for operation setActiveCustomer
   */
  static readonly SetActiveCustomerPath = '/api/customer/feedMill/{customerFeedMillId}/setActive={isActive}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setActiveCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  setActiveCustomer$Response(params: {
    customerFeedMillId: number;
    isActive: boolean;
  }): Observable<StrictHttpResponse<CustomerFeedMill>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerFeedMillService.SetActiveCustomerPath, 'put');
    if (params) {
      rb.path('customerFeedMillId', params.customerFeedMillId, {});
      rb.path('isActive', params.isActive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerFeedMill>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setActiveCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setActiveCustomer(params: {
    customerFeedMillId: number;
    isActive: boolean;
  }): Observable<CustomerFeedMill> {

    return this.setActiveCustomer$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerFeedMill>) => r.body as CustomerFeedMill)
    );
  }

  /**
   * Path part for operation findByCustomerFeedMillId
   */
  static readonly FindByCustomerFeedMillIdPath = '/api/customer/feedMill/{customerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByCustomerFeedMillId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByCustomerFeedMillId$Response(params: {
    customerId: number;
  }): Observable<StrictHttpResponse<Array<CustomerFeedMill>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerFeedMillService.FindByCustomerFeedMillIdPath, 'get');
    if (params) {
      rb.path('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CustomerFeedMill>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByCustomerFeedMillId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByCustomerFeedMillId(params: {
    customerId: number;
  }): Observable<Array<CustomerFeedMill>> {

    return this.findByCustomerFeedMillId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CustomerFeedMill>>) => r.body as Array<CustomerFeedMill>)
    );
  }

  /**
   * Path part for operation findByCustomerFeedMillId_1
   */
  static readonly FindByCustomerFeedMillId_1Path = '/api/customer/feedMill/{customerId}/feedMill/{feedMillId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByCustomerFeedMillId_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByCustomerFeedMillId_1$Response(params: {
    customerId: number;
    feedMillId: number;
  }): Observable<StrictHttpResponse<CustomerFeedMill>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerFeedMillService.FindByCustomerFeedMillId_1Path, 'get');
    if (params) {
      rb.path('customerId', params.customerId, {});
      rb.path('feedMillId', params.feedMillId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerFeedMill>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByCustomerFeedMillId_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByCustomerFeedMillId_1(params: {
    customerId: number;
    feedMillId: number;
  }): Observable<CustomerFeedMill> {

    return this.findByCustomerFeedMillId_1$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerFeedMill>) => r.body as CustomerFeedMill)
    );
  }

}
