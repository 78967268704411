import { Injectable } from '@angular/core';
import { HarvestEvent } from '../api/models/harvest-event';
import { CustomerResponse } from '../api/models/customer-response';

@Injectable({ providedIn: 'root' })
export class CompanyDetailsService {
  private customerData: CustomerResponse;

  constructor() {}

  public setCustomerData(ev: CustomerResponse) {
    this.customerData = ev;
  }

  public get customer(): CustomerResponse {
    return this.customerData;
  }
}
