<div class="time-selection">
  <div class="time-part">
    <div [attr.sh-id]="'time-selection-hours-button-plus'" class="button-plus" (click)="addHour()"></div>
    <div [attr.sh-id]="'time-selection-hours-value'" class="value" [ngStyle]="{'background':valueBackgroundColor}">{{hours}}</div>
    <div [attr.sh-id]="'time-selection-hours-button-minus'" class="button-minus" (click)="subtractHour()"></div>
  </div>
  <div class="time-part-separator">:</div>
  <div class="time-part">
    <div [attr.sh-id]="'time-selection-minutes-button-plus'" class="button-plus" (click)="addMinute()"></div>
    <div [attr.sh-id]="'time-selection-minutes-value'" class="value" [ngStyle]="{'background':valueBackgroundColor}">{{minutes}}</div>
    <div [attr.sh-id]="'time-selection-minutes-button-minus'" class="button-minus" (click)="subtractMinute()"></div>
  </div>
</div>
