/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { UserDetailsResponse } from '../models/user-details-response';
import { UserUpdateProfileRequest } from '../models/user-update-profile-request';
import { ZonedDateTime } from '../models/zoned-date-time';

@Injectable({
  providedIn: 'root',
})
export class MyService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getMyUserDetails
   */
  static readonly GetMyUserDetailsPath = '/api/my/user-details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMyUserDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyUserDetails$Response(params?: {
  }): Observable<StrictHttpResponse<UserDetailsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MyService.GetMyUserDetailsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDetailsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMyUserDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMyUserDetails(params?: {
  }): Observable<UserDetailsResponse> {

    return this.getMyUserDetails$Response(params).pipe(
      map((r: StrictHttpResponse<UserDetailsResponse>) => r.body as UserDetailsResponse)
    );
  }

  /**
   * Path part for operation updateMyUserDetails
   */
  static readonly UpdateMyUserDetailsPath = '/api/my/user-details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMyUserDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMyUserDetails$Response(params?: {
    body?: UserUpdateProfileRequest
  }): Observable<StrictHttpResponse<UserDetailsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MyService.UpdateMyUserDetailsPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDetailsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateMyUserDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMyUserDetails(params?: {
    body?: UserUpdateProfileRequest
  }): Observable<UserDetailsResponse> {

    return this.updateMyUserDetails$Response(params).pipe(
      map((r: StrictHttpResponse<UserDetailsResponse>) => r.body as UserDetailsResponse)
    );
  }

  /**
   * Path part for operation updateLastLoginUser
   */
  static readonly UpdateLastLoginUserPath = '/api/my/user/lastLogin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLastLoginUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateLastLoginUser$Response(params?: {
  }): Observable<StrictHttpResponse<ZonedDateTime>> {

    const rb = new RequestBuilder(this.rootUrl, MyService.UpdateLastLoginUserPath, 'put');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ZonedDateTime>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateLastLoginUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateLastLoginUser(params?: {
  }): Observable<ZonedDateTime> {

    return this.updateLastLoginUser$Response(params).pipe(
      map((r: StrictHttpResponse<ZonedDateTime>) => r.body as ZonedDateTime)
    );
  }

}
