<div class="display-flex">
  <div class="service-zone" *ngIf="numberOfZonesInHouse > 0">
    <span>{{'CROP_CARD.Service_Area' | translate}}</span>
  </div>
  <div class="zones-wrapper">

    <div class="display-flex width-100">
      <ng-container *ngFor="let zone of zonesFirstLine; let i = index">
        <div class="zone" [class.selected]="isSelected(zone)" (click)="toggleSelection(zone)">
          <div class="checkmark" [class.checkmark-checked]="isSelected(zone)">
            <div [class.checked]="isSelected(zone)"></div>
          </div>
          {{'CROP_CARD.Zone' | translate}} {{zone}}
        </div>
      </ng-container>
    </div>

    <div class="horizontal-line"></div>

    <div class="display-flex width-100">
      <ng-container *ngFor="let zone of zonesSecondLine">
        <div class="zone" [class.selected]="isSelected(zone)" (click)="toggleSelection(zone)">
          <div class="checkmark" [class.checkmark-checked]="isSelected(zone)">
            <div [class.checked]="isSelected(zone)"></div>
          </div>
          {{'CROP_CARD.Zone' | translate}} {{zone}}
        </div>
      </ng-container>
    </div>

    <div class="vertical-line"
         *ngFor="let _ of [].constructor(numberOfVerticalLines); let i = index"
         [ngStyle]="{'left.%': ((i+1) * (100/numberOfVerticalLines))}"
    >
    </div>
  </div>
</div>
