<div class="popup">
  <div class="popup__body">
    <div class="popup__body-content">
      <div class="display-flex languages width-100">
        <div class="menus__menu width-100" [class.opened]="SettingType.Language === openedSettingType">
          <div class="menus__menu-selector">
            <div class="type-header" (click)="selectSettingType(SettingType.Language)">
              {{ "PROFILE.Defaults.Default_Language" | translate }}
            </div>
            <div class="type-body display-flex languages" *ngIf="SettingType.Language === openedSettingType">
              <ng-container  *ngFor="let language of LanguageEnum | keyvalue">
                <div (click)="selectedLanguage = language.value; isChangedLanguage = true" class="menus__menu language" [ngClass]="{'selected': language.value === this.selectedLanguage}">
                  {{"PROFILE.Language." + language.value | translate}}
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="menus__menu width-100" *ngIf="permissionService.hasPermission(updateUiDefaults) || permissionService.hasPermission(readUiDefaults)" [class.opened]="SettingType.Paging === openedSettingType">
          <div class="menus__menu-selector">
            <div class="type-header" (click)="selectSettingType(SettingType.Paging)">
              {{ "PROFILE.Defaults.Default_Paging" | translate }}
            </div>
            <div class="type-body" *ngIf="SettingType.Paging === openedSettingType">
              <table class="reports-table">
                <thead>
                <tr>
                  <th>{{ "PROFILE.Defaults.Page" | translate }} </th>
                  <th class="page-size">{{ "PROFILE.Defaults.Page_Size" | translate }}</th>
                </tr>
                </thead>
                <tbody>
                <tr class="role-row" *ngFor="let config of paginationConfig; let i = index">
                  <td>{{ "PROFILE.Defaults." + config.name | translate }}</td>
                  <td class="position-relative">
                    <regular-dropdown-list
                      [disabled]="!permissionService.hasPermission(updateUiDefaults)"
                      [items]="countersElementsPerPageDropdown"
                      [allowClear]="false"
                      [selectedValue]="{ value: config.value }"
                      (onItemSelected)="config.value = $event.value; isChangedPaginationConfig = true">
                    </regular-dropdown-list>
                  </td>
                </tr>
                <tr class="role-row">
                  <td>{{ "PROFILE.Defaults.All_Pages" | translate }}</td>
                  <td class="position-relative">
                    <regular-dropdown-list
                      [items]="countersElementsPerPageDropdown"
                      [disabled]="!permissionService.hasPermission(updateUiDefaults)"
                      [allowClear]="false"
                      [selectedValue]="{ value: allPagesValue.value }"
                      (onItemSelected)="setAllPagesPaginationConfig($event.value); isChangedPaginationConfig = true">
                    </regular-dropdown-list>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="display-flex end width-100 farms-button-wrapper">
        <button *ngIf="SettingType.Language === openedSettingType"
                [disabled]="this.selectedLanguage === this.profileData.language || !isChangedLanguage"
                class="blue-rounded-button" (click)="updateMyUserDetails()">
          {{ "COMMON.BUTTONS.SAVE_CHANGES" | translate }}
        </button>
        <button *ngIf="SettingType.Paging === openedSettingType"
                [disabled]="!isChangedPaginationConfig"
                class="blue-rounded-button" (click)="updatePaginationConfig()">
          {{ "COMMON.BUTTONS.SAVE_CHANGES" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
