import { Pipe } from '@angular/core';

@Pipe({
  name: 'filterBy',
  pure: false,
})
export class FilterByPipe {
  public transform(value: any[], filterKey: string, valueKey: string): any {
    if (!valueKey) {
      return value;
    }

    if (!value) {
      return [];
    }

    return value.filter((item: any) => item[filterKey] === valueKey);
  }
}
