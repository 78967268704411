import {
  AfterContentChecked,
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownListItem } from '../dropdown-list-model';
import { CdkPortalOutlet, DomPortal } from '@angular/cdk/portal';

type TValue = string;

@Component({
  selector: 'regular-select',
  templateUrl: './regular-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RegularSelectComponent),
      multi: true,
    },
  ],
})
export class RegularSelectComponent
  implements ControlValueAccessor, AfterContentInit, AfterContentChecked
{
  private onChangeHandler = (value: TValue) => {};
  private onTouchHandler = () => {};
  @Input() disabled: boolean = false;
  @Input() isTranslated: boolean = true;
  @Input() value: TValue = '';
  @Input() allowClear: boolean = false;
  @Input() class: string = '';

  @Output() change: EventEmitter<{ target: { value: TValue } }> =
    new EventEmitter<{ target: { value: TValue } }>();

  @ContentChildren('option') options: QueryList<ElementRef>;

  @ViewChildren('template') templates: QueryList<TemplateRef<any>>;

  tHost: CdkPortalOutlet;

  public items: DropdownListItem<TValue>[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewRef: ViewContainerRef,
    private el: ElementRef
  ) {}

  writeValue(obj: TValue): void {
    this.value = obj?.toString() ?? '';
    this.changeDetectorRef.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChangeHandler = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchHandler = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange(value: TValue) {
    this.value = value ?? '';
    this.onChangeHandler(this.value);
    this.onTouchHandler();
    this.change.emit({ target: { value: this.value } });
    this.changeDetectorRef.detectChanges();
  }

  ngAfterContentInit() {
    this.initItemsFromOptions();
  }

  ngAfterContentChecked() {
    this.initItemsFromOptions();
  }

  private getValuesKeys(values: DropdownListItem[]) {
    return JSON.stringify(
      values.map((i) => ({
        value: i.value,
        disabled: i.disabled,
        icon: i.icon,
        displayValue: (i.template as DomPortal).element.innerHTML,
      }))
    );
  }

  private getValuesFromOptions(): DropdownListItem[] {
    if (!this.templates) {
      return [];
    }
    return (
      this.options?.map((option, index) => {
        let portal = new DomPortal(option);
        return {
          value: option.nativeElement.value.toString(),
          template: portal,
          displayValue: option.nativeElement.innerText,
          disabled: option.nativeElement.disabled === 'true',
          icon: option.nativeElement.icon ?? null,
        };
      }) ?? []
    );
  }

  private initItemsFromOptions() {
    const selectedByAttribute = this.options?.find(
      (option) => option.nativeElement.selected
    );
    const selectedOption = selectedByAttribute ?? this.options?.first ?? null;

    let selectedValue = selectedOption?.nativeElement.value ?? '';
    if (!selectedValue && this.options?.length > 0) {
      selectedValue = this.options.first.nativeElement.value?.toString() ?? '';
    }

    const values = this.getValuesFromOptions();
    const vkey = this.getValuesKeys(values);
    const pkey = this.getValuesKeys(this.items);
    if (vkey !== pkey) {
      this.writeValue(selectedValue);
      this.items = values;
    }
  }
}
