<div class="body" [style.backgroundColor]="'#ffbbbb'" [style.borderColor]="'#ad120d'" [style.color]="'#ad120d'">
  <div class="notification-content">
    <div class="svg icon-error" [style.backgroundColor]="'#ad120d'"></div>
    <div class="text" *ngFor="let errorContent of errorContentList">
      <span
        *ngIf="errorContent?.errorName !== 'ConstraintViolationException'"
      >{{
          'COMMON.ERRORS.' + errorContent.errorName | translate : null :
            errorContent.params[0] :
            errorContent.params[1] :
            errorContent.params[2] :
            errorContent.params[3] :
            errorContent.params[4] :
            errorContent.params[5] :
            errorContent.params[6] :
            errorContent.params[7] :
            errorContent.params[8] :
            errorContent.params[9]
        }}</span>
      <span
        *ngIf="errorContent?.errorName === 'ConstraintViolationException'"
      >{{ errorContent.formattedMessage }}</span>
    </div>
  </div>
</div>

