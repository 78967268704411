import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth/guards/auth.guard';
import { CreatePasswordComponent } from './layouts/create-password/create-password.component';
import { InviteUserComponent } from './layouts/invite-user/invite-user.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./layouts/layout-auth/layout-auth.module').then(
        (m) => m.LayoutAuthModule
      ),
  },
  { path: 'create-password', component: CreatePasswordComponent },
  { path: 'invite', component: InviteUserComponent },
  {
    path: '',
    loadChildren: () =>
      import('./layouts/layout-navigation/layout-navigation.module').then(
        (m) => m.LayoutNavigationModule
      ),
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '/auth', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
