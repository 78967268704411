/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FacilityStatusReportDto } from '../models/facility-status-report-dto';
import { FacilityStatusReportUpdateDto } from '../models/facility-status-report-update-dto';
import { MedicinesBookDto } from '../models/medicines-book-dto';
import { PerformancePageDto } from '../models/performance-page-dto';

@Injectable({
  providedIn: 'root',
})
export class FacilityStatusReportService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation addFacilityStatusReport
   */
  static readonly AddFacilityStatusReportPath = '/api/facilitystatusreport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addFacilityStatusReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addFacilityStatusReport$Response(params?: {
    body?: FacilityStatusReportDto
  }): Observable<StrictHttpResponse<FacilityStatusReportDto>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityStatusReportService.AddFacilityStatusReportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FacilityStatusReportDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addFacilityStatusReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addFacilityStatusReport(params?: {
    body?: FacilityStatusReportDto
  }): Observable<FacilityStatusReportDto> {

    return this.addFacilityStatusReport$Response(params).pipe(
      map((r: StrictHttpResponse<FacilityStatusReportDto>) => r.body as FacilityStatusReportDto)
    );
  }

  /**
   * Path part for operation getFacilityStatusReports
   */
  static readonly GetFacilityStatusReportsPath = '/api/facilitystatusreport/medbook/{customerId}/{facilityGroupId}/{facilityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFacilityStatusReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFacilityStatusReports$Response(params: {
    customerId: number;
    facilityGroupId: number;
    facilityId: number;
  }): Observable<StrictHttpResponse<MedicinesBookDto>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityStatusReportService.GetFacilityStatusReportsPath, 'get');
    if (params) {
      rb.path('customerId', params.customerId, {});
      rb.path('facilityGroupId', params.facilityGroupId, {});
      rb.path('facilityId', params.facilityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MedicinesBookDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFacilityStatusReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFacilityStatusReports(params: {
    customerId: number;
    facilityGroupId: number;
    facilityId: number;
  }): Observable<MedicinesBookDto> {

    return this.getFacilityStatusReports$Response(params).pipe(
      map((r: StrictHttpResponse<MedicinesBookDto>) => r.body as MedicinesBookDto)
    );
  }

  /**
   * Path part for operation getPerformanceReports
   */
  static readonly GetPerformanceReportsPath = '/api/facilitystatusreport/performance';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPerformanceReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPerformanceReports$Response(params?: {
    authorId?: number;
    customerId?: number;
    facilityGroupId?: number;
    facilityId?: number;
    publishedOnly?: boolean;
  }): Observable<StrictHttpResponse<PerformancePageDto>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityStatusReportService.GetPerformanceReportsPath, 'get');
    if (params) {
      rb.query('authorId', params.authorId, {});
      rb.query('customerId', params.customerId, {});
      rb.query('facilityGroupId', params.facilityGroupId, {});
      rb.query('facilityId', params.facilityId, {});
      rb.query('publishedOnly', params.publishedOnly, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PerformancePageDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPerformanceReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPerformanceReports(params?: {
    authorId?: number;
    customerId?: number;
    facilityGroupId?: number;
    facilityId?: number;
    publishedOnly?: boolean;
  }): Observable<PerformancePageDto> {

    return this.getPerformanceReports$Response(params).pipe(
      map((r: StrictHttpResponse<PerformancePageDto>) => r.body as PerformancePageDto)
    );
  }

  /**
   * Path part for operation updateFacilityStatusReport
   */
  static readonly UpdateFacilityStatusReportPath = '/api/facilitystatusreport/{facilityStatusReportId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFacilityStatusReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFacilityStatusReport$Response(params: {
    facilityStatusReportId: number;
    body?: FacilityStatusReportUpdateDto
  }): Observable<StrictHttpResponse<FacilityStatusReportDto>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityStatusReportService.UpdateFacilityStatusReportPath, 'put');
    if (params) {
      rb.path('facilityStatusReportId', params.facilityStatusReportId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FacilityStatusReportDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFacilityStatusReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFacilityStatusReport(params: {
    facilityStatusReportId: number;
    body?: FacilityStatusReportUpdateDto
  }): Observable<FacilityStatusReportDto> {

    return this.updateFacilityStatusReport$Response(params).pipe(
      map((r: StrictHttpResponse<FacilityStatusReportDto>) => r.body as FacilityStatusReportDto)
    );
  }

  /**
   * Path part for operation deleteFacilityStatusReport
   */
  static readonly DeleteFacilityStatusReportPath = '/api/facilitystatusreport/{facilityStatusReportId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFacilityStatusReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFacilityStatusReport$Response(params: {
    facilityStatusReportId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FacilityStatusReportService.DeleteFacilityStatusReportPath, 'delete');
    if (params) {
      rb.path('facilityStatusReportId', params.facilityStatusReportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFacilityStatusReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFacilityStatusReport(params: {
    facilityStatusReportId: number;
  }): Observable<void> {

    return this.deleteFacilityStatusReport$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
