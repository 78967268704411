<div class="display-flex position-relative">
  <div class="menus__menu">
    <div class="menus__menu-selector" *ngIf="isDateChanged">
      <div class="menus__menu-selector-label">{{ 'COMMON.Date' | translate }}</div>
      <date-picker
        [min]="startDate"
        [max]="maxDate"
        [value]="selectedDate"
        (valueChange)="changeSelectedDate($event)"
      ></date-picker>
    </div>
    <div class="menus__menu-selector height66" *ngIf="!isDateChanged && functionalMode === EventFunctionalModes.addEvent">
      <div class="menus__menu-selector-label">{{ 'COMMON.Date' | translate }}</div>
      <input type="text" value="NA" (click)="isDateChanged = true; changeSelectedDate(selectedDate)"/>
    </div>
  </div>
  <div class="menus__menu" *ngIf="functionalMode === EventFunctionalModes.addEvent">
    <div class="menus__menu-selector height66" *ngIf="!isDateChanged && functionalMode === EventFunctionalModes.addEvent">
      <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_Age' | translate }}</div>
      <input
        [options]="{
              digitGroupSeparator: translationService.digitGroupSeparator,
              decimalCharacter: translationService.decimalCharacter,
              decimalPlaces: 0,
              minimumValue: '0',
              maximumValue: '80'
            }"
        autonumeric
        [value]="birdAge"
        (change)="changeBirdAge($event.target.value)"
      >
    </div>
    <div class="menus__menu-selector height66" *ngIf="isDateChanged && functionalMode === EventFunctionalModes.addEvent">
      <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_Age' | translate }}</div>
      <input type="text" value="NA" (click)="isDateChanged = false; changeBirdAge(birdAge)"/>
  </div>
  </div>
  <div class="menus__dash" *ngIf="functionalMode !== EventFunctionalModes.addEvent">&mdash;</div>
  <div class="menus__menu" *ngIf="functionalMode !== EventFunctionalModes.addEvent">
    <div class="menus__menu-selector custom-select custom-select__disabled">
      <div class="menus__menu-selector-label">{{ 'CROP_CARD.Bird_age' | translate }}</div>
      <input disabled [value]="birdAge">
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector">
      <div class="menus__menu-selector-label">{{ 'EVENT.Score 0' | translate }} ({{score0Percent}}%)</div>
      <input [value]="event.values[0].value" type="number" (change)="score0Changed($event.target.value)">
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector">
      <div class="menus__menu-selector-label">{{ 'EVENT.Score 1' | translate }} ({{score1Percent}}%)</div>
      <input [value]="event.values[1].value" type="number" (change)="score1Changed($event.target.value)">
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector">
      <div class="menus__menu-selector-label">{{ 'EVENT.Score 2' | translate }} ({{score2Percent}}%)</div>
      <input [value]="event.values[2].value" type="number" (change)="score2Changed($event.target.value)">
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector">
      <div class="menus__menu-selector-label">{{ 'EVENT.Number Of Zone' | translate }}</div>
      <default-multiselect [values]="dropdownValues" [defaultValues]="selectedZones" (selectedValuesChanged)="numberOfZoneChanged($event)"></default-multiselect>
    </div>
  </div>
  <div class="remove-event" (click)="onRemoveEvent()">
    <span class="svg delete-blue"></span>
  </div>
</div>
