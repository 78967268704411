import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../../../pipes/pipes.module';
import { UiDefaultsComponent } from './ui-defaults.component';
import {DropDownListsModule} from '../../dropdown-list/dropdown-list.module';

@NgModule({
  declarations: [UiDefaultsComponent],
  imports: [CommonModule, ReactiveFormsModule, PipesModule, FormsModule, DropDownListsModule],
  exports: [UiDefaultsComponent],
})
export class UiDefaultsModule {}
