<div class="popup">
  <div class="popup__body">
    <div class="popup__body-header">
      <div>
        {{ "SETTINGS.MY_PROFILE" | translate }}
      </div>
      <div class="popup__body-close">
        <span class="svg close-svg-blue" (click)="closeModal()"></span>
      </div>
    </div>
    <div class="body-content">
      <div class="popup__body-content-right information">
        <div class="column photo-container">
          <div class="row__right">
            <div class="row__right-input-wrapper">
              <div class="photo">
                <img class="photo-image" [src]="avatarUrl">
              </div>
              <label for="fileDropRef" class="photo-logo">
              </label>
              <input class="file" type="file" #fileDropRef id="fileDropRef"
                     (change)="fileBrowseHandler($event.target.files[0])"/>
            </div>
          </div>
        </div>
        <div class="column info-container">
          <div class="row__right">
            <div class="row__right-input-wrapper name">
              <span>{{profileData?.firstName}}</span>
              <span> {{profileData?.lastName}}</span>
            </div>
            <div class="row__right-disabled name">{{profileData.role}}</div>
            <div class="row__right-disabled">{{profileData.customer}}</div>
          </div>
        </div>
        <div class="column phone-container">
          <div class="row__right">
            <div class="row__right-input-wrapper name">
              {{profileData?.phone}}
            </div>
          </div>
          <div class="row__right">
            <div class="row__right-input-wrapper email">
              <span>{{profileData?.email}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="popup__body-content-right settings-body">
        <div class="settings-tabs">
          <div
            class="settings-tab"
            (click)="selectTab(ProfileTabs.PersonalDetails)"
            [ngClass]="{ selected: selectedTab === ProfileTabs.PersonalDetails }"
          >
            {{ "PROFILE.TABS." + ProfileTabs.PersonalDetails | translate }}
          </div>
          <div
            class="settings-tab"
            (click)="selectTab(ProfileTabs.ResetPassword)"
            [ngClass]="{ selected: selectedTab === ProfileTabs.ResetPassword }"
          >
            {{ "PROFILE.TABS." + ProfileTabs.ResetPassword | translate }}
          </div>
          <div
            class="settings-tab"
            (click)="selectTab(ProfileTabs.Ui_Defaults)"
            [ngClass]="{ selected: selectedTab === ProfileTabs.Ui_Defaults }"
          >
            {{ "PROFILE.TABS." + ProfileTabs.Ui_Defaults | translate }}
          </div>
          <div [class.none]="!permissionService.hasPermission(notificationSettingUpdate)"
               class="settings-tab"
               (click)="selectTab(ProfileTabs.Notifications)"
               [ngClass]="{ selected: selectedTab === ProfileTabs.Notifications }"
          >
            {{ "PROFILE.TABS." + ProfileTabs.Notifications | translate }}
          </div>
          <div
            class="settings-tab"
            (click)="selectTab(ProfileTabs.Timezone)"
            [ngClass]="{ selected: selectedTab === ProfileTabs.Timezone }"
          >
            {{ "PROFILE.TABS." + ProfileTabs.Timezone | translate }}
          </div>
        </div>
        <div class="settings-content">
          <edit-notification-settings-modal
            *ngIf="selectedTab === ProfileTabs.Notifications && isEditUser"
            [settings]="settings"
            [profileData]="profileData"
            [isProfileSettings]="true"
            [settingsUpdated]="settingsUpdated$"
            (updateSetting)="updateSetting($event)"
            (onEditRole)="onEditRole($event)"
            (onCloseModal)="getUserNotificationSettings()"
            (onShowPopup)="isShowWhatsappConfirmation = true"
          ></edit-notification-settings-modal>
          <profile-details
            *ngIf="selectedTab === ProfileTabs.PersonalDetails && isEditUser"
            [profileData]="profileData"
          ></profile-details>
          <reset-password
            *ngIf="selectedTab === ProfileTabs.ResetPassword && isEditUser"
            [profileData]="profileData"
          ></reset-password>
          <ui-defaults
            *ngIf="selectedTab === ProfileTabs.Ui_Defaults && isEditUser"
            [profileData]="profileData"
          ></ui-defaults>
        </div>
      </div>
    </div>
  </div>
</div>
<confirmation-modal
  [title]="'COMMON.CONFIRMATION.ATTENTION' | translate"
  [text]="'COMMON.PROFILE.whatsapp_notification' | translate"
  [mode]="'gotIt'"
  [isShowCross]="false"
  *ngIf="isShowWhatsappConfirmation"
  (onClose)="isShowWhatsappConfirmation = false"
  (onConfirm)="isShowWhatsappConfirmation = false"
></confirmation-modal>

