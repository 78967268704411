import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultMultiselectComponent } from './default-multiselect.component';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
  declarations: [DefaultMultiselectComponent],
  imports: [CommonModule, PipesModule],
  exports: [DefaultMultiselectComponent],
})
export class DefaultMultiselectModule {}
