import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ConfigService } from './services/config/config.service';
import { ApplicationEventsService } from './services/application-events/application-events.service';
import { ErrorsService } from './services/errors-service/errors.service';
import packageJson from 'package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit {
  public title = 'poultry-sense-client';

  public isShowErrorModal: boolean = false;

  constructor(
    public configService: ConfigService,
    public errorsService: ErrorsService,
    private readonly applicationEventsService: ApplicationEventsService
  ) {}

  public ngOnInit() {
    this.errorsService.isShowError$.subscribe((value) => {
      this.isShowErrorModal = value;
    });
    console.log('App Version:', packageJson.version);
  }

  public closeErrorModal() {
    this.errorsService.isShowError$.next(false);
    this.isShowErrorModal = false;
  }

  public ngAfterViewInit(): void {
    this.applicationEventsService.afterAppInitSubject.next();
  }
}
