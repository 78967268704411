import { NgModule } from '@angular/core';
import { AddNewFarmComponent } from './add-new-farm.component';
import { PipesModule } from '../../pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorModalModule } from '../modals/error-modal/error-modal.module';
import { DropDownListsModule } from '../dropdown-list/dropdown-list.module';
import {
  AutoCompleteModule,
  DropDownListModule,
} from '@syncfusion/ej2-angular-dropdowns';
import { SimpleDropdownModule } from '../dropdowns/simple-dropdown/simple-dropdown.module';

@NgModule({
  declarations: [AddNewFarmComponent],
  imports: [
    PipesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ErrorModalModule,
    DropDownListsModule,
    AutoCompleteModule,
    DropDownListModule,
    SimpleDropdownModule,
  ],
  exports: [AddNewFarmComponent],
})
export class AddNewFarmModule {}
