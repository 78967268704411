/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DailyMetrics } from '../models/daily-metrics';
import { LiveSensorDataDto } from '../models/live-sensor-data-dto';

@Injectable({
  providedIn: 'root',
})
export class BaseService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getLiveSensorDataByHouse
   */
  static readonly GetLiveSensorDataByHousePath = '/api/liveSensorData/facilityGroupId/{facilityGroupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLiveSensorDataByHouse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveSensorDataByHouse$Response(params: {
    facilityGroupId: number;
    facilityId?: number;
  }): Observable<StrictHttpResponse<Array<LiveSensorDataDto>>> {

    const rb = new RequestBuilder(this.rootUrl, BaseService.GetLiveSensorDataByHousePath, 'get');
    if (params) {
      rb.path('facilityGroupId', params.facilityGroupId, {});
      rb.query('facilityId', params.facilityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LiveSensorDataDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLiveSensorDataByHouse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLiveSensorDataByHouse(params: {
    facilityGroupId: number;
    facilityId?: number;
  }): Observable<Array<LiveSensorDataDto>> {

    return this.getLiveSensorDataByHouse$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LiveSensorDataDto>>) => r.body as Array<LiveSensorDataDto>)
    );
  }

  /**
   * Path part for operation getHarvestTarget
   */
  static readonly GetHarvestTargetPath = '/api/liveTargetDataByHouse/facilityGroupId/{facilityGroupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHarvestTarget()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHarvestTarget$Response(params: {
    facilityGroupId: number;
    fromDate?: string;
  }): Observable<StrictHttpResponse<Array<DailyMetrics>>> {

    const rb = new RequestBuilder(this.rootUrl, BaseService.GetHarvestTargetPath, 'get');
    if (params) {
      rb.path('facilityGroupId', params.facilityGroupId, {});
      rb.query('fromDate', params.fromDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DailyMetrics>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHarvestTarget$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHarvestTarget(params: {
    facilityGroupId: number;
    fromDate?: string;
  }): Observable<Array<DailyMetrics>> {

    return this.getHarvestTarget$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DailyMetrics>>) => r.body as Array<DailyMetrics>)
    );
  }

}
