import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddBaseStationModalComponent} from './add-base-station-modal.component';
import {ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '../../../pipes/pipes.module';
import { AddDeviceModalModule } from "../add-device-modal/add-device-modal.module";



@NgModule({
  declarations: [
    AddBaseStationModalComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PipesModule,
        AddDeviceModalModule,
    ],
  exports: [
    AddBaseStationModalComponent
  ]
})
export class AddBaseStationModalModule { }
