import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { PipesModule } from "src/app/common/pipes/pipes.module";
import { DropDownListsModule } from "../../dropdown-list/dropdown-list.module";
import { HouseDropdownComponent } from "./house-dropdown.component";

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
        FormsModule,
        DropDownListsModule,
    ],
    exports: [HouseDropdownComponent],
    declarations: [HouseDropdownComponent],
})
export class HouseDropdownModule {}