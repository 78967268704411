import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ApplicationEventsService {

  public afterAppInitSubject : Subject<void> = new Subject<void>();

  constructor() { }

}
