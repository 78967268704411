<div class="login-wrapper">
  <div class="login-tab">
    <div class="login-tab__img">
      <img src="./assets/img/login/chicken.46b8937b.png">
    </div>
    <div class="login-tab__form">
      <div class="language-wrapper">
        <change-language-dropdown></change-language-dropdown>
      </div>
      <form class="login-tab__form-wrapper">
        <div class="login-tab__forgot-logo center">
          <img src="assets/img/login/forgot-logo.svg">
        </div>
        <div class="login-tab__form-title">{{'CREATE.TITLE.Create_new_password' | translate}}</div>
        <div class="login-tab__form-subtitle">{{'CREATE.TITLE.Your_new_password_must_be_different_from_previously_used_password' | translate}}</div>
        <div class="login-tab__form-error"
             *ngIf="showAuthFail$ | async">{{'COMMON.ERRORS.' + errorMessage | translate}}
        </div>
        <div class="login-tab__form-input">
          <input placeholder="{{'CREATE.FORM.New_password' | translate}}" [type]="passwordType" class="login-input"
                 (input)="passwordNew = $event.target.value">
          <img src="assets/img/login/eye_icon.009fd067.svg" (click)="showHidePass()">
        </div>
        <div class="login-tab__form-input">
          <input placeholder="{{'CREATE.FORM.Confirm_password' | translate}}" [type]="passwordType" class="login-input"
                 (input)="passwordConfirm = $event.target.value">
          <img src="assets/img/login/eye_icon.009fd067.svg" (click)="showHidePass()">
        </div>
        <small class="warn">{{'COMMON.PROFILE.password_hint' | translate}}</small>
        <div class="login-tab__form-button">
          <button class="blue-rounded-button width-100" type="submit"
                  [disabled]="!isValidPassword || passwordNew !== passwordConfirm || passwordNew.length < 8 || passwordConfirm.length < 8"
                  (click)="updateUserPasswordByToken()">
            {{'CREATE.FORM.continue' | translate}}
          </button>
        </div>
      </form>
      <div class="login-tab__form-logo2">
        <img [src]="getLogo">
        <essential-logo></essential-logo>
      </div>
    </div>
  </div>
</div>
<copyright></copyright>
