import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges, ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { HarvestFullResponse } from '../../../../../services/api/models/harvest-full-response';
import { CustomerSelectorsService } from '../../../../../services/customer-selectors/customer-selectors.service';
import { PermissionService } from '../../../../../services/user/permission-service';
import { IExtendedHarvestEventDto } from '../add-event-modal.component';
import { HarvestEvent } from '../../../../../services/api/models/harvest-event';
import { DropdownListItem } from '../../../dropdown-list/dropdown-list-model';
import { EventFunctionalModes } from '../../events.component';
import {
  EVENT_DATE_FORMAT,
  MIDNIGHT_DATE_FORMAT,
} from '../../../../../utils/string-utils';
import {TranslationService} from '../../../../../services/translations/translation.service';

@Component({
  selector: 'vaccination-event',
  templateUrl: 'vaccination-event.component.html',
  styleUrls: ['vaccination-event.component.scss'],
})
export class VaccinationEventComponent implements OnChanges {
  @Input() public currentHarvest: HarvestFullResponse;
  @Input() public day0Date: Date;
  @Input() public event: IExtendedHarvestEventDto | HarvestEvent;
  @Input() public vaccination;
  @Input() public functionalMode: EventFunctionalModes =
    EventFunctionalModes.addEvent;
  @Input() public rowIndex: number;
  @Input() public possibleDeleteFirst: boolean = false;

  @Output() public removeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public dateChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public onTypeChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public onDiseaseChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public onVaccineChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public birdAgeChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Input() public maxDate: Date;
  @Input() public startDate: Date;

  public EventFunctionalModes = EventFunctionalModes;
  public selectedDate: Date;
  public readonly systemNowDate = new Date();
  public birdAge: number = 0;
  public isTouched: boolean = false;
  public types: DropdownListItem[] = [];
  public diseases: DropdownListItem[] = [];
  public vaccines: DropdownListItem[] = [];

  public typesSelectedItem: DropdownListItem<string>;
  public diseaseSelectedItem: DropdownListItem<string>;
  public vaccineSelectedItem: DropdownListItem<string>;

  public isDateChanged: boolean = true;

  @ViewChild('inputContainer')
  public inputContainer: ElementRef;

  constructor(
    public customerSelectorsService: CustomerSelectorsService,
    public readonly permissionService: PermissionService,
    public readonly translationService: TranslationService,
  ) {}

  public async ngOnChanges(changes: SimpleChanges) {
    this.setDefaultTypes();
    if (changes.event || changes.day0Date || changes.maxDate || changes.startDate) {
      switch (this.functionalMode) {
        case EventFunctionalModes.editEvent: {
          this.setEditEventdate();
          break;
        }
        case EventFunctionalModes.addEvent: {
          console.log('EVENT TO ADD: ', this.event);
          this.setAddEventDate();
          break;
        }
        case EventFunctionalModes.wizard: {
          this.setWizardDate();
          break;
        }
      }
    }
  }

  public setEditEventdate() {
    console.log('EVENT TO EDIT: ', this.event);
    this.birdAge = this.event.birdAge;
    this.selectedDate = moment(this.event.date).toDate();
  }

  public setAddEventDate() {
    this.selectedDate = this.maxDate
      ? moment(this.maxDate)
        .set({
          hour: +moment(this.startDate).format('HH'),
          minute: +moment(this.startDate).format('mm'),
          seconds: +moment(this.startDate).format('ss'),
        })
        .toDate()
      : moment(this.systemNowDate)
        .set({
          hour: +moment(this.startDate).format('HH'),
          minute: +moment(this.startDate).format('mm'),
          seconds: +moment(this.startDate).format('ss'),
        })
        .toDate();

    this.birdAge = !this.event.birdAge ? moment(this.selectedDate).diff(this.startDate, 'days') : this.event.birdAge;
    this.event.birdAge = !this.event.birdAge ? this.birdAge : this.event.birdAge;
  }

  public changeBirdAge(value) {
    const maxBirdAge = this.maxDate ? moment(this.maxDate).diff(this.startDate, 'days') : 80;

    this.birdAge = Number(value) > maxBirdAge ? maxBirdAge : Number(value);
    this.isTouched = false;

    this.birdAgeChanged.emit(this.birdAge);
  }

  public setWizardDate() {
    this.startDate = this.day0Date;
    this.selectedDate = moment(this.day0Date)
      .add(this.event.birdAge, 'days')
      .toDate();
    this.birdAge = moment(this.selectedDate).diff(this.startDate, 'days');
    this.changeSelectedDate(this.selectedDate, true);
  }

  public changeSelectedDate(date: Date, isWizard?: boolean) {
    this.isTouched = true;
    this.dateChanged.emit(moment(date).format(EVENT_DATE_FORMAT));
    if (this.functionalMode !== EventFunctionalModes.wizard) {
      // this.birdAge = moment(date).diff(this.currentHarvest.startDate, 'days');
      this.birdAge = moment(date)
        .set({
          hour: +moment(this.startDate).format('HH'),
          minute: +moment(this.startDate).format('mm'),
          seconds: +moment(this.startDate).format('ss'),
        })
        .diff(this.startDate, 'days');
    } else {
      this.birdAge = moment(date)
        .set({
          hour: +moment(this.day0Date).format('HH'),
          minute: +moment(this.day0Date).format('mm'),
          seconds: +moment(this.startDate).format('ss'),
        })
        .diff(moment(this.day0Date).format(EVENT_DATE_FORMAT), 'days');
    }
  }

  public setDefaultTypes() {
    this.types = Object.keys(this.vaccination).map((item) => ({
      value: item,
      disabled: false,
      icon: null,
      displayValue: item,
    }));
    if (!!this.event) {
      this.typesSelectedItem =
        this.types[
          this.types.findIndex(
            (item) => item.value === this.event.values[1].value
          )
        ];
    } else {
      this.typesSelectedItem = this.types[0];
    }
    this.setDefaultDiseases();
  }

  public setDefaultDiseases() {
    if (this.types.find((item) => item.value === this.event.values[1].value)) {
      this.diseases = Object.keys(
        this.vaccination[this.typesSelectedItem.value]
      ).map((item) => ({
        value: item,
        disabled: false,
        icon: null,
        displayValue: item,
      }));
      this.diseases.push({
        value: 'Other',
        disabled: false,
        icon: null,
        displayValue: 'Other',
      });
      this.diseaseSelectedItem =
        this.diseases[
          this.diseases.findIndex(
            (item) => item.value === this.event.values[0].value
          )
        ];
    } else {
      this.diseases = Object.keys(this.vaccination[this.types[0].value]).map(
        (item) => ({
          value: item,
          disabled: false,
          icon: null,
          displayValue: item,
        })
      );
      this.diseaseSelectedItem =
        this.diseases[
          this.diseases.findIndex(
            (item) => item.value === this.event.values[0].value
          )
        ];
    }
    if (!this.diseases.find((item) => item.value === 'Other')) {
      this.diseases.push({
        value: 'Other',
        disabled: false,
        icon: null,
        displayValue: 'Other',
      });
    }
    this.setDefaultVaccines();
  }

  public setDefaultVaccines() {
    if (this.diseaseSelectedItem.value === 'Other') {
      this.vaccines = [
        {
          value: 'Other',
          disabled: false,
          icon: null,
          displayValue: 'Other',
        },
      ];
      this.vaccineSelectedItem = {
        value: 'Other',
        disabled: false,
        icon: null,
        displayValue: 'Other',
      };
    } else {
      if (
        this.types.find((item) => item.value === this.event.values[1].value)
      ) {
        this.vaccines = this.vaccination[this.typesSelectedItem.value][
          this.diseaseSelectedItem.value
        ].map((item) => ({
          value: item,
          disabled: false,
          icon: null,
          displayValue: item,
        }));
        if (!this.vaccines.find((item) => item.value === 'Other')) {
          this.vaccines.push({
            value: 'Other',
            disabled: false,
            icon: null,
            displayValue: 'Other',
          });
        }
        !!this.event.values[2].value
          ? (this.vaccineSelectedItem = {
              value: this.event.values[2].value,
              disabled: false,
              icon: null,
              displayValue: this.event.values[2].value,
            })
          : (this.vaccineSelectedItem = this.vaccines[0]);
      } else {
        this.vaccines =
          this.vaccination[this.types[0].value][this.diseaseSelectedItem.value];
        if (
          !!this.vaccines &&
          !this.vaccines.find((item) => item.value === 'Other')
        ) {
          this.vaccines.push({
            value: 'Other',
            disabled: false,
            icon: null,
            displayValue: 'Other',
          });
        }
      }
    }
  }

  public typeChanged(selectedType: DropdownListItem) {
    this.typesSelectedItem = selectedType;
    this.onTypeChanged.emit(this.typesSelectedItem.value);
    this.diseases = Object.keys(this.vaccination[selectedType.value]).map(
      (item) => ({
        value: item,
        disabled: false,
        icon: null,
        displayValue: item,
      })
    );
    if (!this.diseases.find((item) => item.value === 'Other')) {
      this.diseases.push({
        value: 'Other',
        disabled: false,
        icon: null,
        displayValue: 'Other',
      });
    }
    this.diseaseSelectedItem = this.diseases[0];
    this.diseaseChanged(this.diseaseSelectedItem);
  }

  public diseaseChanged(diseaseSelectedItem: DropdownListItem) {
    this.diseaseSelectedItem = diseaseSelectedItem;
    this.onDiseaseChanged.emit(diseaseSelectedItem.value);
    if (diseaseSelectedItem.value !== 'Other') {
      this.vaccines = this.vaccination[this.typesSelectedItem.value][
        this.diseaseSelectedItem.value
      ].map((item) => ({
        value: item,
        disabled: false,
        icon: null,
        displayValue: item,
      }));
    } else {
      this.vaccines = [
        { value: 'Other', disabled: false, icon: null, displayValue: 'Other' },
      ];
    }
    if (!this.vaccines.find((item) => item.value === 'Other')) {
      this.vaccines.push({
        value: 'Other',
        disabled: false,
        icon: null,
        displayValue: 'Other',
      });
    }
    this.vaccineSelectedItem = this.vaccines[0];
    this.vaccineChanged(this.vaccineSelectedItem);
  }

  public vaccineChanged(vaccineSelectedItem: DropdownListItem) {
    this.onVaccineChanged.emit(vaccineSelectedItem.value);
    this.vaccineSelectedItem = vaccineSelectedItem;
  }

  public onRemoveEvent() {
    this.removeEvent.emit();
  }
}
