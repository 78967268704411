import { NgModule } from '@angular/core';
import { DeliveryNotesModalComponent } from './delivery-notes-modal.component';
import { PipesModule } from '../../../pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { DeliveryNotesUpdateModule } from './delivery-notes-update/delivery-notes-update.module';
import {ErrorModalModule} from '../error-modal/error-modal.module';

@NgModule({
  declarations: [DeliveryNotesModalComponent],
    imports: [PipesModule, CommonModule, DeliveryNotesUpdateModule, ErrorModalModule],
  exports: [DeliveryNotesModalComponent],
})
export class DeliveryNotesModalModule {}
