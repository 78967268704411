import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../auth.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate{
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let token = await this.authService.getAccessToken();
    if (token) {
      return true;
    }
    await this.router.navigate(['/auth']);
    return false;
  }
}
