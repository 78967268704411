import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleDropdownComponent } from './simple-dropdown.component';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
  declarations: [SimpleDropdownComponent],
  imports: [CommonModule, PipesModule],
  exports: [SimpleDropdownComponent],
})
export class SimpleDropdownModule {}
