import { DomPortal, TemplatePortal } from '@angular/cdk/portal';

export enum DropdownListAutoSelectionMode {
  None = 'none',
  First = 'first',
  Single = 'single',
}

// tslint:disable-next-line:no-any
export class DropdownListItem<T = any> {
  public value: T;
  public disabled: boolean;
  public icon: DropdownListLeftIcon;
  public displayValue?: string;
  public template?: DomPortal | TemplatePortal;
}

export enum DropdownListLeftIcon {
  Milking = 'Milking',
  LockUpTime = 'LockUpTime',
  Cooling = 'Cooling',
  FeedPushUp = 'FeedPushUp',
  DeliverFreshFood = 'DeliverFreshFood',
  OutToPasture = 'OutToPasture',
  BackFromPasture = 'BackFromPasture',
  EnUsFlag = 'EnUsFlag',
  EnGbFlag = 'EnGbFlag',
  PtBrFlag = 'PtBrFlag',
  FrFrFlag = 'FrFrFlag',
}
