import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { TranslationService } from '../../services/translations/translation.service';

@Pipe({
  name: 'numberFormatter',
  pure: false,
})
export class NumberFormatterPipe implements PipeTransform {
  constructor(private translationService: TranslationService) {}
  public transform(
    value: any,
    format: number,
    isFixed?: boolean,
    isIgnoreLocale?: boolean,
    isIgnoreLocalization?: boolean
  ): string {
    if (value?.toFixed) {
      if (!isIgnoreLocalization) {
        const formattedValue = isFixed
          ? value?.toLocaleString(
              isIgnoreLocale
                ? 'en-US'
                : this.translationService.transformLanguageEnum(
                    this.translationService.selectedLanguage
                  ),
              {
                minimumFractionDigits: format,
                maximumFractionDigits: format,
              }
            )
          : _.round(value, format)?.toLocaleString(
              isIgnoreLocale
                ? 'en-US'
                : this.translationService.transformLanguageEnum(
                    this.translationService.selectedLanguage
                  ),
              {
                minimumFractionDigits: format,
                maximumFractionDigits: format,
              }
            );
        return value !== null &&
          value !== undefined &&
          !isNaN(value) &&
          value !== 'NaN'
          ? formattedValue
          : formattedValue?.toLocaleString(value);
      } else {
        const formattedValue = isFixed
          ? value?.toFixed(format)
          : _.round(value, format);
        return value !== null &&
          value !== undefined &&
          !isNaN(value) &&
          value !== 'NaN'
          ? formattedValue
          : value;
      }
    } else {
      return value;
    }
  }
}
