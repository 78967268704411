/* tslint:disable */
/* eslint-disable */
export enum HarvestMetricType {
  Weight = 'WEIGHT',
  Humidity = 'HUMIDITY',
  Co2 = 'CO2',
  Temperature = 'TEMPERATURE',
  Lux = 'LUX',
  Thi = 'THI',
  At = 'AT',
  AirPressure = 'AIR_PRESSURE',
  Watering = 'WATERING',
  WaterTemperature = 'WATER_TEMPERATURE',
  Feed = 'FEED',
  FoodFeed = 'FOOD_FEED',
  Mortality = 'MORTALITY',
  HoursDarkLux = 'HOURS_DARK_LUX',
  WeightGain = 'WEIGHT_GAIN',
  WaterSelection = 'WATER_SELECTION',
  MortalityAnalysis = 'MORTALITY_ANALYSIS',
  WeightCv = 'WEIGHT_CV'
}
