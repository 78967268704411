/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HarvestBrandActivateDto } from '../models/harvest-brand-activate-dto';
import { HarvestMetricTargetBrandEntry } from '../models/harvest-metric-target-brand-entry';
import { HarvestMetricTargetClone } from '../models/harvest-metric-target-clone';
import { HarvestMetricTargetDto } from '../models/harvest-metric-target-dto';
import { HarvestMetricTargetUpdate } from '../models/harvest-metric-target-update';
import { HarvestMetricTargetUpdateResponse } from '../models/harvest-metric-target-update-response';
import { HarvestSex } from '../models/harvest-sex';
import { HarvestTargetResponse } from '../models/harvest-target-response';

@Injectable({
  providedIn: 'root',
})
export class HarvestMetricTargetService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTargets
   */
  static readonly GetTargetsPath = '/api/metric-target';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTargets()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTargets$Response(params: {
    harvestId: number;
  }): Observable<StrictHttpResponse<HarvestTargetResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestMetricTargetService.GetTargetsPath, 'get');
    if (params) {
      rb.query('harvestId', params.harvestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestTargetResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTargets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTargets(params: {
    harvestId: number;
  }): Observable<HarvestTargetResponse> {

    return this.getTargets$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestTargetResponse>) => r.body as HarvestTargetResponse)
    );
  }

  /**
   * Path part for operation activateBrand
   */
  static readonly ActivateBrandPath = '/api/metric-target/activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateBrand()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateBrand$Response(params?: {
    body?: Array<HarvestBrandActivateDto>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestMetricTargetService.ActivateBrandPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activateBrand$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateBrand(params?: {
    body?: Array<HarvestBrandActivateDto>
  }): Observable<void> {

    return this.activateBrand$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation cloneTargets
   */
  static readonly CloneTargetsPath = '/api/metric-target/clone';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cloneTargets()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cloneTargets$Response(params?: {
    body?: HarvestMetricTargetClone
  }): Observable<StrictHttpResponse<HarvestMetricTargetDto>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestMetricTargetService.CloneTargetsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestMetricTargetDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cloneTargets$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cloneTargets(params?: {
    body?: HarvestMetricTargetClone
  }): Observable<HarvestMetricTargetDto> {

    return this.cloneTargets$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestMetricTargetDto>) => r.body as HarvestMetricTargetDto)
    );
  }

  /**
   * Path part for operation cloneTargetsAndUpdate
   */
  static readonly CloneTargetsAndUpdatePath = '/api/metric-target/clone-and-update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cloneTargetsAndUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cloneTargetsAndUpdate$Response(params?: {
    body?: HarvestMetricTargetClone
  }): Observable<StrictHttpResponse<HarvestMetricTargetUpdateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestMetricTargetService.CloneTargetsAndUpdatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestMetricTargetUpdateResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cloneTargetsAndUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cloneTargetsAndUpdate(params?: {
    body?: HarvestMetricTargetClone
  }): Observable<HarvestMetricTargetUpdateResponse> {

    return this.cloneTargetsAndUpdate$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestMetricTargetUpdateResponse>) => r.body as HarvestMetricTargetUpdateResponse)
    );
  }

  /**
   * Path part for operation deleteTargets
   */
  static readonly DeleteTargetsPath = '/api/metric-target/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTargets()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTargets$Response(params: {
    brandName: string;
    sex: HarvestSex;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestMetricTargetService.DeleteTargetsPath, 'delete');
    if (params) {
      rb.query('brandName', params.brandName, {});
      rb.query('sex', params.sex, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTargets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTargets(params: {
    brandName: string;
    sex: HarvestSex;
  }): Observable<void> {

    return this.deleteTargets$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getTargetValues
   */
  static readonly GetTargetValuesPath = '/api/metric-target/get';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTargetValues()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTargetValues$Response(params: {
    brand: string;
    sex: HarvestSex;
  }): Observable<StrictHttpResponse<HarvestMetricTargetDto>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestMetricTargetService.GetTargetValuesPath, 'get');
    if (params) {
      rb.query('brand', params.brand, {});
      rb.query('sex', params.sex, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestMetricTargetDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTargetValues$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTargetValues(params: {
    brand: string;
    sex: HarvestSex;
  }): Observable<HarvestMetricTargetDto> {

    return this.getTargetValues$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestMetricTargetDto>) => r.body as HarvestMetricTargetDto)
    );
  }

  /**
   * Path part for operation listBrandsTargets
   */
  static readonly ListBrandsTargetsPath = '/api/metric-target/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listBrandsTargets()` instead.
   *
   * This method doesn't expect any request body.
   */
  listBrandsTargets$Response(params?: {
    customerId?: number;
  }): Observable<StrictHttpResponse<Array<HarvestMetricTargetBrandEntry>>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestMetricTargetService.ListBrandsTargetsPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HarvestMetricTargetBrandEntry>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `listBrandsTargets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listBrandsTargets(params?: {
    customerId?: number;
  }): Observable<Array<HarvestMetricTargetBrandEntry>> {

    return this.listBrandsTargets$Response(params).pipe(
      map((r: StrictHttpResponse<Array<HarvestMetricTargetBrandEntry>>) => r.body as Array<HarvestMetricTargetBrandEntry>)
    );
  }

  /**
   * Path part for operation renameBrand
   */
  static readonly RenameBrandPath = '/api/metric-target/rename';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renameBrand()` instead.
   *
   * This method doesn't expect any request body.
   */
  renameBrand$Response(params?: {
    nameFrom?: string;
    nameTo?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestMetricTargetService.RenameBrandPath, 'put');
    if (params) {
      rb.query('nameFrom', params.nameFrom, {});
      rb.query('nameTo', params.nameTo, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `renameBrand$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  renameBrand(params?: {
    nameFrom?: string;
    nameTo?: string;
  }): Observable<void> {

    return this.renameBrand$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateTargets
   */
  static readonly UpdateTargetsPath = '/api/metric-target/update/{brandName}/{harvestSex}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTargets()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTargets$Response(params: {
    brandName: string;
    harvestSex: HarvestSex;
    body?: HarvestMetricTargetUpdate
  }): Observable<StrictHttpResponse<HarvestMetricTargetUpdateResponse>> {

    const rb = new RequestBuilder(this.rootUrl, HarvestMetricTargetService.UpdateTargetsPath, 'put');
    if (params) {
      rb.path('brandName', params.brandName, {});
      rb.path('harvestSex', params.harvestSex, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestMetricTargetUpdateResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateTargets$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTargets(params: {
    brandName: string;
    harvestSex: HarvestSex;
    body?: HarvestMetricTargetUpdate
  }): Observable<HarvestMetricTargetUpdateResponse> {

    return this.updateTargets$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestMetricTargetUpdateResponse>) => r.body as HarvestMetricTargetUpdateResponse)
    );
  }

}
