import {NgModule} from '@angular/core';
import {ErrorModalComponent} from './error-modal.component';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '../../../pipes/pipes.module';

@NgModule({
  declarations: [ErrorModalComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PipesModule
    ],
  exports: [ErrorModalComponent]
})
export class ErrorModalModule {

}
