import { Component } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'copyright',
  templateUrl: 'copyright.component.html',
  styleUrls: ['copyright.component.scss'],
})
export class CopyrightComponent {
  public currentYear: string = moment().format('YYYY');
}
