import { NgModule } from '@angular/core';
import { ReportsComponent } from './reports.component';
import { PipesModule } from '../../../pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ReportsComponent],
  imports: [PipesModule, RouterModule, CommonModule],
  exports: [ReportsComponent],
})
export class ReportsModule {}
