import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BreadcrumbsComponent} from './breadcrumbs.component';
import {PipesModule} from '../../pipes/pipes.module';

@NgModule({
  declarations: [BreadcrumbsComponent],
  imports: [
    CommonModule,
    PipesModule
  ],
  exports: [BreadcrumbsComponent]
})
export class BreadcrumbsModule {

}
