import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { HarvestFullResponse } from '../../../services/api/models/harvest-full-response';
import { CustomerSelectorsService } from '../../../services/customer-selectors/customer-selectors.service';
import { PermissionService } from '../../../services/user/permission-service';
import { HarvestHatcheryVaccine } from '../../../services/api/models/harvest-hatchery-vaccine';
import { DropdownListItem } from '../dropdown-list/dropdown-list-model';

@Component({
  selector: 'hatchery-vaccines',
  templateUrl: 'hatchery-vaccines.component.html',
  styleUrls: ['hatchery-vaccines.component.scss'],
})
export class HatcheryVaccinesComponent implements OnInit, OnChanges {
  @Input() public currentHarvest: HarvestFullResponse;
  @Input() public day0Date: Date;
  @Input() public event: HarvestHatcheryVaccine;
  @Input() public vaccination;
  @Input() public rowIndex: number;
  @Input() public possibleDeleteFirst: boolean = false;
  @Input() public isDisabled: boolean = false;

  @Output() public removeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public dateChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public onTypeChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public onDiseaseChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public onVaccineChanged: EventEmitter<string> =
    new EventEmitter<string>();

  public nowDate: Date;
  public selectDate: string;
  public startDate: Date;
  public maxDate: Date;
  public selectedDate: Date;
  public birdAge: number = 0;
  public isTouched: boolean = false;
  public types: DropdownListItem[] = [];
  public diseases: DropdownListItem[] = [];
  public vaccines: DropdownListItem[] = [];

  public typesSelectedItem: DropdownListItem<string>;
  public diseaseSelectedItem: DropdownListItem<string>;
  public vaccineSelectedItem: DropdownListItem<string>;

  constructor(
    public customerSelectorsService: CustomerSelectorsService,
    public readonly permissionService: PermissionService
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.vaccination && !!this.vaccination) {
      this.setDefaultTypes();
    }
  }

  public ngOnInit() {}

  public setDefaultTypes() {
    this.types = Object.keys(this.vaccination).map((item) => ({
      value: item,
      disabled: false,
      icon: null,
      displayValue: item,
    }));
    if (!!this.event) {
      this.typesSelectedItem =
        this.types[
          this.types.findIndex((item) => item.value === this.event.type)
        ];
    } else {
      this.typesSelectedItem = this.types[0];
    }
    this.setDefaultDiseases();
  }

  public setDefaultDiseases() {
    if (this.types.find((item) => item.value === this.event.type)) {
      this.diseases = Object.keys(
        this.vaccination[this.typesSelectedItem.value]
      ).map((item) => ({
        value: item,
        disabled: false,
        icon: null,
        displayValue: item,
      }));
      this.diseases.push({
        value: 'Other',
        disabled: false,
        icon: null,
        displayValue: 'Other',
      });
      this.diseaseSelectedItem =
        this.diseases[
          this.diseases.findIndex((item) => item.value === this.event.disease)
        ];
    } else {
      this.diseases = Object.keys(this.vaccination[this.types[0].value]).map(
        (item) => ({
          value: item,
          disabled: false,
          icon: null,
          displayValue: item,
        })
      );
      this.diseaseSelectedItem =
        this.diseases[
          this.diseases.findIndex((item) => item.value === this.event.disease)
        ];
    }
    if (!this.diseases.find((item) => item.value === 'Other')) {
      this.diseases.push({
        value: 'Other',
        disabled: false,
        icon: null,
        displayValue: 'Other',
      });
    }
    this.setDefaultVaccines();
  }

  public setDefaultVaccines() {
    if (this.diseaseSelectedItem.value === 'Other') {
      this.vaccines = [
        {
          value: 'Other',
          disabled: false,
          icon: null,
          displayValue: 'Other',
        },
      ];
      this.vaccineSelectedItem = {
        value: 'Other',
        disabled: false,
        icon: null,
        displayValue: 'Other',
      };
    } else {
      if (this.diseases.find((item) => item.value === this.event.disease)) {
        this.vaccines = this.vaccination[this.typesSelectedItem.value][
          this.diseaseSelectedItem.value
        ].map((item) => ({
          value: item,
          disabled: false,
          icon: null,
          displayValue: item,
        }));
        if (!this.vaccines.find((item) => item.value === 'Other')) {
          this.vaccines.push({
            value: 'Other',
            disabled: false,
            icon: null,
            displayValue: 'Other',
          });
        }
        !!this.event.vaccine
          ? (this.vaccineSelectedItem = {
              value: this.event.vaccine,
              disabled: false,
              icon: null,
              displayValue: this.event.vaccine,
            })
          : (this.vaccineSelectedItem = this.vaccines[0]);
      } else {
        this.vaccines =
          this.vaccination[this.types[0].value][this.diseaseSelectedItem.value];
        if (
          !!this.vaccines &&
          !this.vaccines.find((item) => item.value === 'Other')
        ) {
          this.vaccines.push({
            value: 'Other',
            disabled: false,
            icon: null,
            displayValue: 'Other',
          });
        }
      }
    }
  }

  public typeChanged(selectedType: DropdownListItem) {
    this.typesSelectedItem = selectedType;
    this.onTypeChanged.emit(this.typesSelectedItem.value);
    this.diseases = Object.keys(this.vaccination[selectedType.value]).map(
      (item) => ({
        value: item,
        disabled: false,
        icon: null,
        displayValue: item,
      })
    );
    if (!this.diseases.find((item) => item.value === 'Other')) {
      this.diseases.push({
        value: 'Other',
        disabled: false,
        icon: null,
        displayValue: 'Other',
      });
    }
    this.diseaseSelectedItem = this.diseases[0];
    this.diseaseChanged(this.diseaseSelectedItem);
  }

  public diseaseChanged(diseaseSelectedItem: DropdownListItem) {
    this.diseaseSelectedItem = diseaseSelectedItem;
    this.onDiseaseChanged.emit(diseaseSelectedItem.value);
    if (diseaseSelectedItem.value !== 'Other') {
      this.vaccines = this.vaccination[this.typesSelectedItem.value][
        this.diseaseSelectedItem.value
      ].map((item) => ({
        value: item,
        disabled: false,
        icon: null,
        displayValue: item,
      }));
    } else {
      this.vaccines = [
        { value: 'Other', disabled: false, icon: null, displayValue: 'Other' },
      ];
    }
    if (!this.vaccines.find((item) => item.value === 'Other')) {
      this.vaccines.push({
        value: 'Other',
        disabled: false,
        icon: null,
        displayValue: 'Other',
      });
    }
    this.vaccineSelectedItem = this.vaccines[0];
    this.vaccineChanged(this.vaccineSelectedItem);
  }

  public vaccineChanged(vaccineSelectedItem: DropdownListItem) {
    this.onVaccineChanged.emit(vaccineSelectedItem.value);
    this.vaccineSelectedItem = vaccineSelectedItem;
  }

  public onRemoveEvent() {
    this.removeEvent.emit();
  }
}
