/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { StrictHttpResponse } from '../api/strict-http-response';
import { RequestBuilder } from '../api/request-builder';
import { BaseServiceUrl } from '../api/base-service-url';
import { ApiConfiguration } from '../api/api-configuration';

@Injectable({
  providedIn: 'root',
})
export class ReservedShortLinkService extends BaseServiceUrl {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation getFullLink
   */
  static readonly GetFullLinkPath = '/api/link/{linkUuid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFullLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFullLink$Response(params: {
    linkUuid: string;
  }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      ReservedShortLinkService.GetFullLinkPath,
      'get'
    );
    if (params) {
      rb.path('linkUuid', params.linkUuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFullLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFullLink(params: { linkUuid: string }): Observable<string> {
    return this.getFullLink$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }
}
