import {
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import * as moment from 'moment';
import { EpochStateModel } from '../epoch-state-model';

export enum TimeSelectionHoursMode {
  Mode24Hours = 'Mode24Hours',
  Mode12Hours = 'Mode12Hours',
}

export enum TimeSelectionChangeType {
  AddHour = 'AddHour',
  SubtractHour = 'SubtractHour',
  AddMinute = 'AddMinute',
  SubtractMinute = 'SubtractMinute',
}

export class EpochChangedDetails {
  public epoch: EpochStateModel;
  public changeType: TimeSelectionChangeType;
}

@Component({
  selector: 'time-selection',
  templateUrl: './time-selection.component.html',
  styleUrls: ['./time-selection.component.scss'],
})
export class TimeSelectionComponent {
  @Input()
  public valueBackgroundColor: string = '#fff';

  @Input()
  public hoursMode: TimeSelectionHoursMode;

  @Input()
  public selectedEpoch: EpochStateModel;

  @Input()
  public subtractHoursToZero: boolean;

  @Output()
  public epochChanged = new EventEmitter<EpochChangedDetails>();

  constructor() {}

  public addHour() {
    this.selectedEpoch.addHour();
    this.epochChanged.emit({
      epoch: this.selectedEpoch,
      changeType: TimeSelectionChangeType.AddHour,
    });
  }

  public subtractHour() {
    this.selectedEpoch.subtractHour(this.subtractHoursToZero);
    this.epochChanged.emit({
      epoch: this.selectedEpoch,
      changeType: TimeSelectionChangeType.SubtractHour,
    });
  }

  public addMinute() {
    this.selectedEpoch.addMinute();
    this.epochChanged.emit({
      epoch: this.selectedEpoch,
      changeType: TimeSelectionChangeType.AddMinute,
    });
  }

  public subtractMinute() {
    this.selectedEpoch.subtractMinute();
    this.epochChanged.emit({
      epoch: this.selectedEpoch,
      changeType: TimeSelectionChangeType.SubtractMinute,
    });
  }

  public get hours(): string {
    if (this.selectedEpoch.epochTime == null) {
      return moment.unix(EpochStateModel.MinValue).utc().format('HH');
    } else {
      if (this.hoursMode == TimeSelectionHoursMode.Mode24Hours) {
        return moment.unix(this.selectedEpoch.epochTime).utc().format('HH');
      } else {
        return moment.unix(this.selectedEpoch.epochTime).utc().format('hh');
      }
    }
  }

  public get minutes(): string {
    if (this.selectedEpoch.epochTime == null) {
      return moment.unix(EpochStateModel.MinValue).utc().format('mm');
    } else {
      return moment.unix(this.selectedEpoch.epochTime).utc().format('mm');
    }
  }
}
