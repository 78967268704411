/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GeneralLookup } from '../models/general-lookup';
import { GeneralLookupDto } from '../models/general-lookup-dto';

@Injectable({
  providedIn: 'root',
})
export class LookupTableService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getGeneralLookup
   */
  static readonly GetGeneralLookupPath = '/api/lookuptable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGeneralLookup()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGeneralLookup$Response(params?: {
  }): Observable<StrictHttpResponse<Array<GeneralLookup>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupTableService.GetGeneralLookupPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GeneralLookup>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGeneralLookup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGeneralLookup(params?: {
  }): Observable<Array<GeneralLookup>> {

    return this.getGeneralLookup$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GeneralLookup>>) => r.body as Array<GeneralLookup>)
    );
  }

  /**
   * Path part for operation addGeneralLookup
   */
  static readonly AddGeneralLookupPath = '/api/lookuptable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addGeneralLookup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addGeneralLookup$Response(params?: {
    body?: GeneralLookupDto
  }): Observable<StrictHttpResponse<GeneralLookup>> {

    const rb = new RequestBuilder(this.rootUrl, LookupTableService.AddGeneralLookupPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralLookup>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addGeneralLookup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addGeneralLookup(params?: {
    body?: GeneralLookupDto
  }): Observable<GeneralLookup> {

    return this.addGeneralLookup$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralLookup>) => r.body as GeneralLookup)
    );
  }

  /**
   * Path part for operation getHarvestEventsMap_1
   */
  static readonly GetHarvestEventsMap_1Path = '/api/lookuptable/harvestevents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHarvestEventsMap_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHarvestEventsMap_1$Response(params?: {
  }): Observable<StrictHttpResponse<{
[key: string]: any;
}>> {

    const rb = new RequestBuilder(this.rootUrl, LookupTableService.GetHarvestEventsMap_1Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: any;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHarvestEventsMap_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHarvestEventsMap_1(params?: {
  }): Observable<{
[key: string]: any;
}> {

    return this.getHarvestEventsMap_1$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: any;
}>) => r.body as {
[key: string]: any;
})
    );
  }

  /**
   * Path part for operation getTimeZones
   */
  static readonly GetTimeZonesPath = '/api/lookuptable/timezones';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimeZones()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeZones$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, LookupTableService.GetTimeZonesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTimeZones$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimeZones(params?: {
  }): Observable<Array<string>> {

    return this.getTimeZones$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation getGeneralLookup_1
   */
  static readonly GetGeneralLookup_1Path = '/api/lookuptable/{generalLookupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGeneralLookup_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGeneralLookup_1$Response(params: {
    generalLookupId: number;
  }): Observable<StrictHttpResponse<GeneralLookup>> {

    const rb = new RequestBuilder(this.rootUrl, LookupTableService.GetGeneralLookup_1Path, 'get');
    if (params) {
      rb.path('generalLookupId', params.generalLookupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralLookup>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGeneralLookup_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGeneralLookup_1(params: {
    generalLookupId: number;
  }): Observable<GeneralLookup> {

    return this.getGeneralLookup_1$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralLookup>) => r.body as GeneralLookup)
    );
  }

  /**
   * Path part for operation updateGeneralLookup
   */
  static readonly UpdateGeneralLookupPath = '/api/lookuptable/{generalLookupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateGeneralLookup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGeneralLookup$Response(params: {
    generalLookupId: number;
    body?: GeneralLookupDto
  }): Observable<StrictHttpResponse<GeneralLookup>> {

    const rb = new RequestBuilder(this.rootUrl, LookupTableService.UpdateGeneralLookupPath, 'put');
    if (params) {
      rb.path('generalLookupId', params.generalLookupId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralLookup>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateGeneralLookup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateGeneralLookup(params: {
    generalLookupId: number;
    body?: GeneralLookupDto
  }): Observable<GeneralLookup> {

    return this.updateGeneralLookup$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralLookup>) => r.body as GeneralLookup)
    );
  }

  /**
   * Path part for operation deleteGeneralLookup
   */
  static readonly DeleteGeneralLookupPath = '/api/lookuptable/{generalLookupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteGeneralLookup()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteGeneralLookup$Response(params: {
    generalLookupId: number;
  }): Observable<StrictHttpResponse<GeneralLookup>> {

    const rb = new RequestBuilder(this.rootUrl, LookupTableService.DeleteGeneralLookupPath, 'delete');
    if (params) {
      rb.path('generalLookupId', params.generalLookupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GeneralLookup>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteGeneralLookup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteGeneralLookup(params: {
    generalLookupId: number;
  }): Observable<GeneralLookup> {

    return this.deleteGeneralLookup$Response(params).pipe(
      map((r: StrictHttpResponse<GeneralLookup>) => r.body as GeneralLookup)
    );
  }

}
