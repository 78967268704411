import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EllipsisableTextComponent } from "./ellipsisable-text.component";

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [EllipsisableTextComponent, ],
    declarations: [EllipsisableTextComponent, ],
})
export class EllipsisableTextModule {}