import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
  HttpHeaders,
  HttpErrorResponse, HttpResponseBase
} from '@angular/common/http';
import {from, Observable, of, throwError} from 'rxjs';
import {delay, mergeMap, materialize, dematerialize, tap, switchMap} from 'rxjs/operators';

import {ConfigService} from '../services/config/config.service';
import {MockHttpService} from './services/mock-http.service';
import {ClientSessionRecorderService} from './services/client-session-recorder/client-session-recorder.service';

// import {ClientSessionRecorderService} from './services/client-session-recorder/client-session-recorder.service';

@Injectable()
export class MockHttpInterceptor implements HttpInterceptor {

  constructor(public readonly configService: ConfigService,
              private readonly mockHttpService: MockHttpService,
              private readonly clientSessionRecorder: ClientSessionRecorderService) {

  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.configService.configuration && request.url.startsWith(this.configService.configuration.apiUrl) ) {
      if (this.configService.configuration.useMockHttp) {
        return from(this.mockHttpService.handleRequest(request)).pipe(switchMap(response => {
          if (response == null) {
            return this.traceWebApiMessage(request, next);
          } else {
            this.mockHttpService.traceWebApiMessage(request, response, false);
            if (response instanceof HttpErrorResponse) {
              throw <HttpErrorResponse> response;
            } else {
              return of(<HttpResponse<any>> response);
            }
          }
        })).pipe(materialize()).pipe(delay(this.mockHttpService.handleMessageDelayMs)).pipe(dematerialize());
      } else if (this.clientSessionRecorder.needToHandleHttpEvent) {
        return this.clientSessionRecorder.handleHttpEvent(request, next);
      }
    }
    return next.handle(request);
  }

  private traceWebApiMessage(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap(
      event => {
        if (event instanceof HttpResponseBase) {
          this.mockHttpService.traceWebApiMessage(request, event, true);
        }
      },
      error => {
        if (error instanceof HttpResponseBase) {
          this.mockHttpService.traceWebApiMessage(request, error, true);
        }
      }
    ));
  }
}

export let MockHttpInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: MockHttpInterceptor,
  multi: true
};
