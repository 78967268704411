import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/common/pipes/pipes.module';
import { DatePickerModule } from '../../date-picker/date-picker.module';
import { AddThinningModalComponent } from './add-thinning-modal.component';
import { AutonumericModule } from '@angularfy/autonumeric';
import {TimePickerModule} from '../../time-picker/time-picker.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    DatePickerModule,
    AutonumericModule,
    TimePickerModule,
  ],
  exports: [AddThinningModalComponent],
  declarations: [AddThinningModalComponent],
})
export class AddThinningModalModule {}
