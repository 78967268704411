/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateReportRequest } from '../models/create-report-request';
import { CropReport } from '../models/crop-report';
import { CropReportUpdateRequest } from '../models/crop-report-update-request';

@Injectable({
  providedIn: 'root',
})
export class CropReportService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation triggerCropReportCreation
   */
  static readonly TriggerCropReportCreationPath = '/api/cropreport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `triggerCropReportCreation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  triggerCropReportCreation$Response(params?: {
    body?: CreateReportRequest
  }): Observable<StrictHttpResponse<CropReport>> {

    const rb = new RequestBuilder(this.rootUrl, CropReportService.TriggerCropReportCreationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CropReport>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `triggerCropReportCreation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  triggerCropReportCreation(params?: {
    body?: CreateReportRequest
  }): Observable<CropReport> {

    return this.triggerCropReportCreation$Response(params).pipe(
      map((r: StrictHttpResponse<CropReport>) => r.body as CropReport)
    );
  }

  /**
   * Path part for operation getCropReportByFacilityId
   */
  static readonly GetCropReportByFacilityIdPath = '/api/cropreport/facility/{facilityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCropReportByFacilityId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCropReportByFacilityId$Response(params: {
    facilityId: number;
  }): Observable<StrictHttpResponse<Array<CropReport>>> {

    const rb = new RequestBuilder(this.rootUrl, CropReportService.GetCropReportByFacilityIdPath, 'get');
    if (params) {
      rb.path('facilityId', params.facilityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CropReport>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCropReportByFacilityId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCropReportByFacilityId(params: {
    facilityId: number;
  }): Observable<Array<CropReport>> {

    return this.getCropReportByFacilityId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CropReport>>) => r.body as Array<CropReport>)
    );
  }

  /**
   * Path part for operation getCropReportByCropIdx
   */
  static readonly GetCropReportByCropIdxPath = '/api/cropreport/facility/{facilityId}/idx/{cropIdx}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCropReportByCropIdx()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCropReportByCropIdx$Response(params: {
    cropIdx: number;
    facilityId: number;
  }): Observable<StrictHttpResponse<Array<CropReport>>> {

    const rb = new RequestBuilder(this.rootUrl, CropReportService.GetCropReportByCropIdxPath, 'get');
    if (params) {
      rb.path('cropIdx', params.cropIdx, {});
      rb.path('facilityId', params.facilityId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CropReport>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCropReportByCropIdx$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCropReportByCropIdx(params: {
    cropIdx: number;
    facilityId: number;
  }): Observable<Array<CropReport>> {

    return this.getCropReportByCropIdx$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CropReport>>) => r.body as Array<CropReport>)
    );
  }

  /**
   * Path part for operation getCropReportByFacilityGroupId
   */
  static readonly GetCropReportByFacilityGroupIdPath = '/api/cropreport/facilityGroup/{facilityGroupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCropReportByFacilityGroupId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCropReportByFacilityGroupId$Response(params: {
    facilityGroupId: number;
  }): Observable<StrictHttpResponse<Array<CropReport>>> {

    const rb = new RequestBuilder(this.rootUrl, CropReportService.GetCropReportByFacilityGroupIdPath, 'get');
    if (params) {
      rb.path('facilityGroupId', params.facilityGroupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CropReport>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCropReportByFacilityGroupId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCropReportByFacilityGroupId(params: {
    facilityGroupId: number;
  }): Observable<Array<CropReport>> {

    return this.getCropReportByFacilityGroupId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CropReport>>) => r.body as Array<CropReport>)
    );
  }

  /**
   * Path part for operation getCropReportByReportId
   */
  static readonly GetCropReportByReportIdPath = '/api/cropreport/{cropReportId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCropReportByReportId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCropReportByReportId$Response(params: {
    cropReportId: string;
  }): Observable<StrictHttpResponse<CropReport>> {

    const rb = new RequestBuilder(this.rootUrl, CropReportService.GetCropReportByReportIdPath, 'get');
    if (params) {
      rb.path('cropReportId', params.cropReportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CropReport>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCropReportByReportId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCropReportByReportId(params: {
    cropReportId: string;
  }): Observable<CropReport> {

    return this.getCropReportByReportId$Response(params).pipe(
      map((r: StrictHttpResponse<CropReport>) => r.body as CropReport)
    );
  }

  /**
   * Path part for operation updateCropReport
   */
  static readonly UpdateCropReportPath = '/api/cropreport/{cropReportId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCropReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCropReport$Response(params: {
    cropReportId: string;
    sendEmail?: boolean;
    body?: CropReportUpdateRequest
  }): Observable<StrictHttpResponse<CropReport>> {

    const rb = new RequestBuilder(this.rootUrl, CropReportService.UpdateCropReportPath, 'put');
    if (params) {
      rb.path('cropReportId', params.cropReportId, {});
      rb.query('sendEmail', params.sendEmail, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CropReport>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCropReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCropReport(params: {
    cropReportId: string;
    sendEmail?: boolean;
    body?: CropReportUpdateRequest
  }): Observable<CropReport> {

    return this.updateCropReport$Response(params).pipe(
      map((r: StrictHttpResponse<CropReport>) => r.body as CropReport)
    );
  }

  /**
   * Path part for operation deleteCropReport
   */
  static readonly DeleteCropReportPath = '/api/cropreport/{cropReportId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCropReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCropReport$Response(params: {
    cropReportId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CropReportService.DeleteCropReportPath, 'delete');
    if (params) {
      rb.path('cropReportId', params.cropReportId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCropReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCropReport(params: {
    cropReportId: string;
  }): Observable<void> {

    return this.deleteCropReport$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
