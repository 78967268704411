import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IProfile } from '../profile-modal.component';
import { takeUntil } from 'rxjs/operators';
import { MyService } from '../../../../services/api/services/my.service';
import { UserDetailsService } from '../../../../services/user/user-details.service';

@Component({
  selector: 'profile-details',
  templateUrl: 'profile-details.component.html',
  styleUrls: ['profile-details.component.scss'],
})
export class ProfileDetailsComponent implements OnDestroy {
  @Input() public profileData: IProfile;

  private destroyed$ = new Subject();

  public showSaveFail$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  public file: any = null;

  constructor(
    public myService: MyService,
    public userDetailsService: UserDetailsService
  ) {}

  public updateMyUserDetails() {
    this.myService
      .updateMyUserDetails({
        body: {
          firstName: this.profileData.firstName,
          lastName: this.profileData.lastName,
          phone: this.profileData.phone,
          language: this.profileData.language,
        },
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.userDetailsService.setUserDetails(res);
      });
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
