import { Component, OnInit } from '@angular/core';
import {MockHttpService} from '../services/mock-http.service';

enum TestingLayoutSection {
  HttpManagement,
  DashboardReports,
  PrintToExport,
  Translations,
  SystemState,
  NotificationDialogs
}

@Component({
  selector: 'testing-layout',
  templateUrl: './testing-layout.component.html',
  styleUrls: ['./testing-layout.component.scss']
})
export class TestingLayoutComponent implements OnInit {

  public isVisible: boolean;

  public selectedSection: TestingLayoutSection = TestingLayoutSection.HttpManagement;

  TestingLayoutSection = TestingLayoutSection;

  constructor(private mockHttpService: MockHttpService) { }

  ngOnInit() {
  }

  public selectSection(section: TestingLayoutSection): void {
    this.selectedSection = section;
  }
}
