import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

export interface INotificationContent {
  color: string;
  fontColor: string;
  message: string;
  IconName: string;
  name?: string;
}

@Component({
  selector: 'notification-modal',
  templateUrl: 'notification.component.html',
  styleUrls: ['notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input() public content: INotificationContent;
  @Output() public onClose: EventEmitter<void> = new EventEmitter<void>();

  @Input() public name: string = '';

  public ngOnInit() {
    setTimeout(() =>  this.onClose.emit(), 3000);
  }
}
