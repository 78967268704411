import { Injectable } from '@angular/core';
import { countries, COUNTRY_NAME, timezones } from './geolocation.timezones';

const US = 'United States of America';

const CA = 'Canada';

@Injectable({ providedIn: 'root' })
export class GeolocationService {


  public getCountry() {
    return localStorage.getItem(COUNTRY_NAME);
  }

  public setCountry() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone === '' || !timezone) {
      localStorage.setItem(COUNTRY_NAME, '');
    }
    const _country = timezones[timezone].c[0];
    localStorage.setItem(COUNTRY_NAME, countries[_country]);
  }

  public getLogoPath() {
    const country = this.getCountry();
    if (country === US || country === CA) {
      return 'assets/img/logo/merck_logo.png';
    } else {
      return 'assets/img/logo/msd_logo.png';
    }
  }
}
