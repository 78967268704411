import { NgModule } from '@angular/core';
import { MortalityWidgetComponent } from './mortality-widget.component';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../../pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { NoDataAvailableModule } from '../../no-data-available/no-data-available.module';
import {MortalityModalModule} from './mortality-edit-modal/mortality-modal.module';

@NgModule({
  declarations: [MortalityWidgetComponent],
    imports: [RouterModule, PipesModule, CommonModule, NoDataAvailableModule, MortalityModalModule],
  exports: [MortalityWidgetComponent],
})
export class MortalityWidgetModule {}
