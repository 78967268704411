import { NgModule } from '@angular/core';
import { ColorToValuePipe } from './colorToValue.pipe';
import { FilterByPipe } from './filter-by.pipe';
import { NaParameterPipe } from './na-parameter.pipe';
import { NumberFormatterPipe } from './number-formatter.pipe';
import { PositionToTextPipe } from './position-to-text.pipe';
import { StatusToText } from './status-to-text.pipe';
import { TranslatePipe } from './translate.pipe';
import { TruncateNamePipe } from './truncate-name.pipe';
import { UnitsConvertPipe } from './units-convert.pipe';
import { ValueToColorPipe } from './valueToColor.pipe';

const pipes = [
  TranslatePipe,
  PositionToTextPipe,
  StatusToText,
  NaParameterPipe,
  NumberFormatterPipe,
  UnitsConvertPipe,
  ValueToColorPipe,
  TruncateNamePipe,
  ColorToValuePipe,
  FilterByPipe,
];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
})
export class PipesModule {}
