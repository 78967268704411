/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DeviceNmsResponse } from '../models/device-nms-response';
import { DeviceSupplierDto } from '../models/device-supplier-dto';
import { DeviceTypeDto } from '../models/device-type-dto';
import { FarmDto } from '../models/farm-dto';
import { HouseDto } from '../models/house-dto';
import { ZonedDateTime } from '../models/zoned-date-time';

@Injectable({
  providedIn: 'root',
})
export class NmsService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDeviceTypes
   */
  static readonly GetDeviceTypesPath = '/api/nms/device-type';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeviceTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceTypes$Response(params?: {
  }): Observable<StrictHttpResponse<DeviceTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, NmsService.GetDeviceTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeviceTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDeviceTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceTypes(params?: {
  }): Observable<DeviceTypeDto> {

    return this.getDeviceTypes$Response(params).pipe(
      map((r: StrictHttpResponse<DeviceTypeDto>) => r.body as DeviceTypeDto)
    );
  }

  /**
   * Path part for operation getDevices
   */
  static readonly GetDevicesPath = '/api/nms/devices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDevices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDevices$Response(params: {

    /**
     * Page number
     */
    page: number;

    /**
     * Page size
     */
    size: number;

    /**
     * Filter to return records updated after specified time
     */
    updated_after?: ZonedDateTime;
  }): Observable<StrictHttpResponse<DeviceNmsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, NmsService.GetDevicesPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('updated_after', params.updated_after, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeviceNmsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDevices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDevices(params: {

    /**
     * Page number
     */
    page: number;

    /**
     * Page size
     */
    size: number;

    /**
     * Filter to return records updated after specified time
     */
    updated_after?: ZonedDateTime;
  }): Observable<DeviceNmsResponse> {

    return this.getDevices$Response(params).pipe(
      map((r: StrictHttpResponse<DeviceNmsResponse>) => r.body as DeviceNmsResponse)
    );
  }

  /**
   * Path part for operation getFarms
   */
  static readonly GetFarmsPath = '/api/nms/farms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFarms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFarms$Response(params: {

    /**
     * Page number
     */
    page: number;

    /**
     * Page size
     */
    size: number;

    /**
     * Filter to return records updated after specified time
     */
    updated_after?: ZonedDateTime;
  }): Observable<StrictHttpResponse<FarmDto>> {

    const rb = new RequestBuilder(this.rootUrl, NmsService.GetFarmsPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('updated_after', params.updated_after, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FarmDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFarms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFarms(params: {

    /**
     * Page number
     */
    page: number;

    /**
     * Page size
     */
    size: number;

    /**
     * Filter to return records updated after specified time
     */
    updated_after?: ZonedDateTime;
  }): Observable<FarmDto> {

    return this.getFarms$Response(params).pipe(
      map((r: StrictHttpResponse<FarmDto>) => r.body as FarmDto)
    );
  }

  /**
   * Path part for operation getHouses
   */
  static readonly GetHousesPath = '/api/nms/houses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHouses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHouses$Response(params: {

    /**
     * Page number
     */
    page: number;

    /**
     * Page size
     */
    size: number;

    /**
     * Filter to return records updated after specified time
     */
    updated_after?: ZonedDateTime;
  }): Observable<StrictHttpResponse<HouseDto>> {

    const rb = new RequestBuilder(this.rootUrl, NmsService.GetHousesPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
      rb.query('updated_after', params.updated_after, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HouseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHouses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHouses(params: {

    /**
     * Page number
     */
    page: number;

    /**
     * Page size
     */
    size: number;

    /**
     * Filter to return records updated after specified time
     */
    updated_after?: ZonedDateTime;
  }): Observable<HouseDto> {

    return this.getHouses$Response(params).pipe(
      map((r: StrictHttpResponse<HouseDto>) => r.body as HouseDto)
    );
  }

  /**
   * Path part for operation getHouseWithCrops
   */
  static readonly GetHouseWithCropsPath = '/api/nms/housesWithCrops';

  /**
   * Returns list of house IDs with open flock
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHouseWithCrops()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHouseWithCrops$Response(params: {

    /**
     * Page number
     */
    page: number;

    /**
     * Page size
     */
    size: number;
  }): Observable<StrictHttpResponse<Array<number>>> {

    const rb = new RequestBuilder(this.rootUrl, NmsService.GetHouseWithCropsPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('size', params.size, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<number>>;
      })
    );
  }

  /**
   * Returns list of house IDs with open flock
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHouseWithCrops$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHouseWithCrops(params: {

    /**
     * Page number
     */
    page: number;

    /**
     * Page size
     */
    size: number;
  }): Observable<Array<number>> {

    return this.getHouseWithCrops$Response(params).pipe(
      map((r: StrictHttpResponse<Array<number>>) => r.body as Array<number>)
    );
  }

  /**
   * Path part for operation getDeviceSuppliers
   */
  static readonly GetDeviceSuppliersPath = '/api/nms/suppliers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeviceSuppliers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceSuppliers$Response(params?: {
  }): Observable<StrictHttpResponse<DeviceSupplierDto>> {

    const rb = new RequestBuilder(this.rootUrl, NmsService.GetDeviceSuppliersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeviceSupplierDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDeviceSuppliers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceSuppliers(params?: {
  }): Observable<DeviceSupplierDto> {

    return this.getDeviceSuppliers$Response(params).pipe(
      map((r: StrictHttpResponse<DeviceSupplierDto>) => r.body as DeviceSupplierDto)
    );
  }

}
