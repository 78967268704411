/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { HarvestPredictionValuesDto } from '../models/harvest-prediction-values-dto';
import { HarvestSex } from '../models/harvest-sex';
import { KpiFarmSetting } from '../models/kpi-farm-setting';

@Injectable({
  providedIn: 'root',
})
export class KpiFarmSettingsService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getHarvestPredictionWeightValues
   */
  static readonly GetHarvestPredictionWeightValuesPath = '/api/harvestPrediction/weight';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHarvestPredictionWeightValues()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHarvestPredictionWeightValues$Response(params?: {
    brandId?: number;
    harvestSex?: HarvestSex;
  }): Observable<StrictHttpResponse<HarvestPredictionValuesDto>> {

    const rb = new RequestBuilder(this.rootUrl, KpiFarmSettingsService.GetHarvestPredictionWeightValuesPath, 'get');
    if (params) {
      rb.query('brandId', params.brandId, {});
      rb.query('harvestSex', params.harvestSex, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HarvestPredictionValuesDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHarvestPredictionWeightValues$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getHarvestPredictionWeightValues(params?: {
    brandId?: number;
    harvestSex?: HarvestSex;
  }): Observable<HarvestPredictionValuesDto> {

    return this.getHarvestPredictionWeightValues$Response(params).pipe(
      map((r: StrictHttpResponse<HarvestPredictionValuesDto>) => r.body as HarvestPredictionValuesDto)
    );
  }

  /**
   * Path part for operation getAllKpiFarmSetting
   */
  static readonly GetAllKpiFarmSettingPath = '/api/kpisettings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllKpiFarmSetting()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllKpiFarmSetting$Response(params?: {
  }): Observable<StrictHttpResponse<Array<KpiFarmSetting>>> {

    const rb = new RequestBuilder(this.rootUrl, KpiFarmSettingsService.GetAllKpiFarmSettingPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<KpiFarmSetting>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllKpiFarmSetting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllKpiFarmSetting(params?: {
  }): Observable<Array<KpiFarmSetting>> {

    return this.getAllKpiFarmSetting$Response(params).pipe(
      map((r: StrictHttpResponse<Array<KpiFarmSetting>>) => r.body as Array<KpiFarmSetting>)
    );
  }

  /**
   * Path part for operation createKpiFarmSetting
   */
  static readonly CreateKpiFarmSettingPath = '/api/kpisettings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createKpiFarmSetting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createKpiFarmSetting$Response(params?: {
    body?: KpiFarmSetting
  }): Observable<StrictHttpResponse<KpiFarmSetting>> {

    const rb = new RequestBuilder(this.rootUrl, KpiFarmSettingsService.CreateKpiFarmSettingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KpiFarmSetting>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createKpiFarmSetting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createKpiFarmSetting(params?: {
    body?: KpiFarmSetting
  }): Observable<KpiFarmSetting> {

    return this.createKpiFarmSetting$Response(params).pipe(
      map((r: StrictHttpResponse<KpiFarmSetting>) => r.body as KpiFarmSetting)
    );
  }

  /**
   * Path part for operation getKpiFarmSettingById
   */
  static readonly GetKpiFarmSettingByIdPath = '/api/kpisettings/{kpiFarmSettingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getKpiFarmSettingById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKpiFarmSettingById$Response(params: {
    kpiFarmSettingId: number;
  }): Observable<StrictHttpResponse<KpiFarmSetting>> {

    const rb = new RequestBuilder(this.rootUrl, KpiFarmSettingsService.GetKpiFarmSettingByIdPath, 'get');
    if (params) {
      rb.path('kpiFarmSettingId', params.kpiFarmSettingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KpiFarmSetting>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getKpiFarmSettingById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getKpiFarmSettingById(params: {
    kpiFarmSettingId: number;
  }): Observable<KpiFarmSetting> {

    return this.getKpiFarmSettingById$Response(params).pipe(
      map((r: StrictHttpResponse<KpiFarmSetting>) => r.body as KpiFarmSetting)
    );
  }

  /**
   * Path part for operation updateKpiFarmSetting
   */
  static readonly UpdateKpiFarmSettingPath = '/api/kpisettings/{kpiFarmSettingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateKpiFarmSetting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateKpiFarmSetting$Response(params: {
    kpiFarmSettingId: number;
    body?: KpiFarmSetting
  }): Observable<StrictHttpResponse<KpiFarmSetting>> {

    const rb = new RequestBuilder(this.rootUrl, KpiFarmSettingsService.UpdateKpiFarmSettingPath, 'put');
    if (params) {
      rb.path('kpiFarmSettingId', params.kpiFarmSettingId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<KpiFarmSetting>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateKpiFarmSetting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateKpiFarmSetting(params: {
    kpiFarmSettingId: number;
    body?: KpiFarmSetting
  }): Observable<KpiFarmSetting> {

    return this.updateKpiFarmSetting$Response(params).pipe(
      map((r: StrictHttpResponse<KpiFarmSetting>) => r.body as KpiFarmSetting)
    );
  }

  /**
   * Path part for operation deleteKpiFarmSetting
   */
  static readonly DeleteKpiFarmSettingPath = '/api/kpisettings/{kpiFarmSettingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteKpiFarmSetting()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteKpiFarmSetting$Response(params: {
    kpiFarmSettingId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, KpiFarmSettingsService.DeleteKpiFarmSettingPath, 'delete');
    if (params) {
      rb.path('kpiFarmSettingId', params.kpiFarmSettingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteKpiFarmSetting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteKpiFarmSetting(params: {
    kpiFarmSettingId: number;
  }): Observable<void> {

    return this.deleteKpiFarmSetting$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
