import {NgModule} from '@angular/core';
import {EnvironmentalReadingsComponent} from './environmental-readings.component';
import {NoDataAvailableModule} from '../no-data-available/no-data-available.module';
import {CommonModule} from '@angular/common';
import {DataGridModule} from '../data-grid/data-grid.module';
import {PipesModule} from '../../pipes/pipes.module';

@NgModule({
    declarations: [EnvironmentalReadingsComponent],
    imports: [
        NoDataAvailableModule,
        CommonModule,
        DataGridModule,
        PipesModule
    ],
    exports: [EnvironmentalReadingsComponent]
})
export class EnvironmentalReadingsModule {

}
