import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownWithHeaderComponent } from './dropdown-with-header.component';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
  declarations: [DropdownWithHeaderComponent],
  imports: [CommonModule, PipesModule],
  exports: [DropdownWithHeaderComponent],
})
export class DropdownWithHeaderModule {}
