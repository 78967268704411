import {
  ClientSessionRecorderEvent, ClientSessionRecorderEventType,
  ClientSessionRecorderMessageData,
  ClientSessionRecorderMessageType
} from "./csr-message.model";

export abstract class ClientSessionRecorderDataEvent extends ClientSessionRecorderEvent{
  public eventTimestamp: number;
  public clientUrl: string;
  public type: ClientSessionRecorderMessageType;
  // tslint:disable-next-line:no-any
  public localStorage: any;
  // tslint:disable-next-line:no-any
  public sessionStorage: any;

  protected constructor() {
    super();
    this.type = ClientSessionRecorderMessageType.Event;
  }
}

export class WebApiClientSessionRecorderEvent extends ClientSessionRecorderDataEvent {
  public eventType: ClientSessionRecorderEventType = ClientSessionRecorderEventType.WebApi;
  public endpoint: string;
  public method: string;
  // tslint:disable-next-line:no-any
  public requestHeaders: any;
  // tslint:disable-next-line:no-any
  public requestBody: any;
  // tslint:disable-next-line:no-any
  public responseHeaders: any;
  // tslint:disable-next-line:no-any
  public responseBody: any;
  // tslint:disable-next-line:no-any
  public responseStatus: any;
  public isErrorResponse: boolean;

  constructor() {
    super();
    this.eventType = ClientSessionRecorderEventType.WebApi;
  }
}

export class WindowSizeSessionRecorderEvent extends ClientSessionRecorderDataEvent {
  public eventType: ClientSessionRecorderEventType = ClientSessionRecorderEventType.WindowSize;
  public width: number;
  public height: number;

  constructor() {
    super();
    this.eventType = ClientSessionRecorderEventType.WindowSize;
  }
}

export enum DomElementEventType {
  Focus = 'Focus',
  MouseClick = 'MouseClick',
  Keyboard = 'Keyboard',
  Scroll = 'Scroll',
  Input = 'Input'
}

export abstract class DomElementClientSessionRecorderEvent extends ClientSessionRecorderDataEvent{
  public eventType: ClientSessionRecorderEventType = ClientSessionRecorderEventType.DomElement;
  public abstract domElementEventType: DomElementEventType;
  public elementXpath : string;
  public elementShId : string;
  public elementType : string;
  public waitTime: number;
}

export class FocusClientSessionRecorderEvent extends  DomElementClientSessionRecorderEvent {
  public domElementEventType: DomElementEventType = DomElementEventType.Focus;

  constructor() {
    super();
    this.domElementEventType = DomElementEventType.Focus;
  }
}

export class MouseClickClientSessionRecorderEvent extends DomElementClientSessionRecorderEvent{
  public domElementEventType: DomElementEventType = DomElementEventType.MouseClick;
  public clientX : number;
  public clientY : number;
  public button : number;

  constructor() {
    super();
    this.domElementEventType = DomElementEventType.MouseClick;
  }
}

export enum KeyboardEventType {
  keydown = 'keydown',
  keyup = 'keyup'
}

export class KeyboardClientSessionRecorderEvent extends DomElementClientSessionRecorderEvent{
  public domElementEventType: DomElementEventType = DomElementEventType.Keyboard;
  public keyboardEventType: KeyboardEventType;
  public code: string;
  public key: string;
  public ctrlKey: boolean;
  public altKey: boolean;
  public shiftKey: boolean;
  public location: number;
  public metaKey: boolean;
  public repeat: boolean;

  constructor() {
    super();
    this.domElementEventType = DomElementEventType.Keyboard;
  }
}

export class InputClientSessionRecorderEvent extends DomElementClientSessionRecorderEvent{
  public domElementEventType: DomElementEventType = DomElementEventType.Input;
  public value: string;

  constructor() {
    super();
    this.domElementEventType = DomElementEventType.Input;
  }
}

export class ScrollClientSessionRecorderEvent extends DomElementClientSessionRecorderEvent{
  public domElementEventType: DomElementEventType = DomElementEventType.Scroll;
  public scrollTop: number;
  public scrollLeft: number;

  constructor() {
    super();
    this.domElementEventType = DomElementEventType.Scroll;
  }
}
