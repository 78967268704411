import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CustomerSelectorsService } from '../../../../services/customer-selectors/customer-selectors.service';
import { EMPTY, Subject } from 'rxjs';
import * as moment from 'moment';
import { HarvestFullResponse } from '../../../../services/api/models/harvest-full-response';
import { HarvestService } from '../../../../services/api/services/harvest.service';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { HarvestEventDto } from '../../../../services/api/models/harvest-event-dto';
import { LookupTableService } from '../../../../services/api/services/lookup-table.service';
import { HarvestEvent } from '../../../../services/api/models/harvest-event';
import { PermissionService } from '../../../../services/user/permission-service';
import { PermissionName } from '../../../../services/api/models/permission-name';
import { EventFunctionalModes } from '../events.component';
import { EventType } from '../add-event-modal/add-event-modal.component';
import { HarvestDeliveryNoteRequest } from '../../../../services/api/models/harvest-delivery-note-request';
import { BroodingService } from '../../../../services/brooding/brooding.service';
import { HarvestEventsLookupService } from '../../../../services/api/services/harvest-events-lookup.service';
import { VaccinationTitle } from '../../../../services/api/models/vaccination-title';
import { HarvestCustomerDefaultsDto } from '../../../../services/api/models/harvest-customer-defaults-dto';
import { VaccinationDto } from '../../../../services/api/models/vaccination-dto';
import { EVENT_DATE_FORMAT, TIME_ONLY } from '../../../../utils/string-utils';
import { CurrentCropBehaviourService } from '../../../../services/harvest/current-crop-behaviour.service';
import { IErrorContent } from '../../modals/error-modal/error-modal.component';

export interface IExtendedHarvestEventDto extends HarvestEventDto {
  birdAge: number;
}

@Component({
  selector: 'edit-event-modal',
  templateUrl: 'edit-event-modal.component.html',
  styleUrls: ['edit-event-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditEventModalComponent implements OnChanges {
  @Output() public onCloseModal: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() public onEventCreated: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() public currentHarvest: HarvestFullResponse;
  @Input() public currentEventIndex: number;
  @Input() public currentEvent: HarvestEvent = null;
  @Input() public isComplete: boolean = false;
  @Input() public allEvents: HarvestEvent[];

  public isEventMarkedAsComplite: boolean = false;
  public nowDate: Date;
  public selectDate: string;
  public coccidiostat = [];
  public additives = [];
  public mill = [];
  public ration = [];
  public medicationDisease = [];
  public vaccinationDisease = [];
  public medicationDuration = [];
  public antibiotics = [];
  public nutraceuticalDuration = [];
  public nutraceutical = [];
  public reason = [];
  public types = [];
  public vaccine = [];
  public eventType: EventType = EventType.AddFeed;
  public eventTypesEnum: typeof EventType = EventType;
  public harvestEvents: IExtendedHarvestEventDto[] = [];
  public selectedAdditive: string;
  public selectedCoccidiostat: string;
  public selectedMill: string;
  public selectedRation: string;
  public selectedNutraceuticalDuration: string;
  public selectedNutraceutical: string;
  public selectedReason: string;
  public selectedMedicationDisease: string;
  public selectedMedicationDuration: string;
  public selectedMedication: string;
  public selectedVaccinationDisease: string;
  public selectedType: string;
  public selectedVaccine: string;
  public startDate: Date;
  public maxDate: Date;
  public birdAge: number = null;
  public defaults: HarvestCustomerDefaultsDto;
  public selectedCountry: string;
  public countries = [];
  public vaccination;
  public isShowBeforeCloseModal: boolean = false;
  public isTouched: boolean = false;
  public isDefaultCountry: boolean = false;
  public allHarvestEvents: HarvestEvent[] = [];
  public feed;
  public medication;
  public nutraceuticals;
  public hatcheryVaccination;
  public eventFunctionalMode = EventFunctionalModes.editEvent;

  public myFarmCropCardViewEvents: PermissionName =
    PermissionName.MyFarmCropCardViewEvents;

  private destroyed$ = new Subject();

  constructor(
    public customerSelectorsService: CustomerSelectorsService,
    public broodingService: BroodingService,
    private harvestService: HarvestService,
    private lookupTableService: LookupTableService,
    private harvestEventsLookupService: HarvestEventsLookupService,
    public readonly permissionService: PermissionService,
    public cropBehaviourService: CurrentCropBehaviourService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.currentEvent) {
      console.log('currentEvent', this.currentEvent);
      // @ts-ignore
      this.eventType = this.currentEvent.type;
      this.isEventMarkedAsComplite = this.currentEvent.isDone;

      this.nowDate = moment().toDate();

      this.harvestService
        .getDefaults({
          customerId: this.customerSelectorsService.customerId,
        })
        .pipe(takeUntil(this.destroyed$))
        .subscribe((res) => {
          this.defaults = res;
          this.lookupTableService
            .getHarvestEventsMap_1()
            .pipe(take(1))
            .subscribe((lookupTableres) => {
              this.leaveOnlyDefaultVaccines(lookupTableres);
              this.changeDetectorRef.detectChanges();
            });
        });

      this.startDate = moment(this.currentHarvest?.startDate).toDate();
      this.maxDate = this.currentHarvest?.closeDate
        ? moment(this.currentHarvest?.closeDate).toDate()
        : undefined;
      this.nowDate = moment().toDate();
      this.birdAge = moment(
        this.currentHarvest.closeDate ? this.maxDate : this.nowDate
      ).diff(this.startDate, 'days');

      this.selectDate = this.currentHarvest?.closeDate
        ? moment(this.maxDate)
            .set({
              hour: +moment(this.currentHarvest.startDate).format('HH'),
              minute: +moment(this.currentHarvest.startDate).format('mm'),
            })
            .format(EVENT_DATE_FORMAT)
        : moment(this.nowDate)
            .set({
              hour: +moment(this.currentHarvest.startDate).format('HH'),
              minute: +moment(this.currentHarvest.startDate).format('mm'),
            })
            .format(EVENT_DATE_FORMAT);
    }
  }

  public leaveOnlyDefaultVaccines(lookupTableres) {
    const defaultVaccines = this.defaults.vaccines;
    console.log('DEFAULT VACCINES', defaultVaccines);
    const hatcheryVaccines = defaultVaccines.filter(
      (item) => item.title === VaccinationTitle.HatcheryVaccine
    );
    const vaccines = defaultVaccines.filter(
      (item) => item.title === VaccinationTitle.VaccineG
    );
    lookupTableres.VACCINE_G = this.convertMapToObject(vaccines);
    lookupTableres.HATCHERY_VACCINE = this.convertMapToObject(hatcheryVaccines);

    this.setDropdownValues(lookupTableres);
    this.changeDetectorRef.detectChanges();
  }

  public get cropNumber() {
    return this.cropBehaviourService.cropNumber;
  }

  public convertMapToObject(vaccines: VaccinationDto[]) {
    let allTypesList: string[] = [
      ...new Set(vaccines.map((item) => item.type)),
    ];

    let vaccinesObj = {};

    vaccines.forEach((vaccine) => {
      vaccinesObj[vaccine.country] = {};

      const typesArrayOfArrays = [];
      allTypesList.forEach((type) => typesArrayOfArrays.push([type, null]));
      vaccinesObj[vaccine.country] = Object.fromEntries(typesArrayOfArrays);

      Object.keys(vaccinesObj[vaccine.country]).forEach((type) => {
        if (vaccinesObj[vaccine.country][type] === null) {
          let diseasesArrayOfArrays = [];
          let diseasePerType = [
            ...new Set(
              vaccines.map((vaccine1) => {
                if (vaccine1.type === type) {
                  return vaccine1.disease;
                }
              })
            ),
          ];

          diseasePerType = diseasePerType.filter((el) => {
            return el !== undefined;
          });

          diseasePerType.forEach((disease) =>
            diseasesArrayOfArrays.push([disease, []])
          );
          vaccinesObj[vaccine.country][type] = Object.fromEntries(
            diseasesArrayOfArrays
          );
        }
      });

      Object.keys(vaccinesObj[vaccine.country]).forEach((type) => {
        Object.keys(vaccinesObj[vaccine.country][type]).forEach((disease) => {
          let filteredVaccines = vaccines.map((item) => {
            if (item.type === type && item.disease === disease) {
              return item.vaccine;
            }
          });
          filteredVaccines = filteredVaccines.filter((el) => {
            return el !== undefined;
          });
          vaccinesObj[vaccine.country][type][disease] = filteredVaccines;
        });
      });
    });

    console.log('vaccinesObj', vaccinesObj);
    return vaccinesObj;
  }

  public setDropdownValues(res) {
    const feed = res?.FEED;
    const medication = res?.MEDICATION;
    const nutraceutical = res?.NUTRACEUTICAL;
    this.vaccination = res?.VACCINE_G;
    this.feed = res?.FEED;
    this.medication = res?.MEDICATION;

    this.nutraceuticals = res?.NUTRACEUTICAL;
    this.hatcheryVaccination = res?.HATCHERY_VACCINE;
    // @ts-ignore
    this.coccidiostat = feed?.Coccidiostat;
    this.coccidiostat.push('Other');
    this.coccidiostat.unshift('None');
    this.additives = feed?.Additives;
    this.additives.push('Other');
    this.additives.unshift('None');
    this.ration = feed?.Ration;
    this.mill = feed?.Mill;
    this.medicationDisease = medication?.Disease;
    this.medicationDisease.push('Other');
    this.medicationDuration = medication?.Duration;
    this.medicationDuration.push('Other');
    this.antibiotics = medication?.Antibiotics;
    this.antibiotics.push('Other');
    this.nutraceuticalDuration = nutraceutical?.Duration;
    this.nutraceutical = nutraceutical?.Nutraceutical;
    this.nutraceutical.push('Other');
    this.reason = nutraceutical?.Reason;

    this.selectedAdditive = this.additives[0];
    this.selectedCoccidiostat = this.coccidiostat[0];
    this.selectedMill = this.mill[0];
    this.selectedRation = this.ration[0];
    this.selectedMedicationDisease = this.medicationDisease[0];
    this.selectedMedicationDuration = this.medicationDuration[0];
    this.selectedMedication = this.medication[0];
    this.selectedNutraceuticalDuration = this.nutraceuticalDuration[0];
    this.selectedNutraceutical = this.nutraceutical[0];
    this.selectedReason = this.reason[0];

    this.countries = Object.keys(this.vaccination);
    this.getAllHarvestEvents();
    this.findDefaultCountry();
    this.addEvent();
    this.changeDetectorRef.detectChanges();
  }

  public getAllHarvestEvents() {
    if (this.permissionService.hasPermission(this.myFarmCropCardViewEvents)) {
      this.harvestService
        .getEvents({
          harvestId: this.customerSelectorsService.cropsList[0].id,
        })
        .pipe(take(1))
        .subscribe((res) => {
          this.allHarvestEvents = res;
          this.broodingService.setBroodingDurationRemaining(
            this.allHarvestEvents.filter(
              (item) => item.type === EventType.Brooding
            )
          );
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  public setSelectedDurationAndUpdateInBroodingService(value, idx?) {
    this.currentEvent.values[1].value = value;
    this.changeDetectorRef.detectChanges();
  }

  public findDefaultCountry() {
    const currentFarmCountry =
      this.customerSelectorsService.currentFarm.country;
    const currentFarmCountryIndex = this.countries.indexOf(currentFarmCountry);
    const currentCustomerCountry =
      this.customerSelectorsService.currentCustomer.country;
    const currentCustomerCountryIndex = this.countries.indexOf(
      currentCustomerCountry
    );
    if (currentFarmCountryIndex >= 0) {
      this.isDefaultCountry = false;
      this.selectedCountry = this.countries[currentFarmCountryIndex];
    } else if (currentCustomerCountryIndex >= 0) {
      this.isDefaultCountry = false;
      this.selectedCountry = this.countries[currentCustomerCountryIndex];
    } else {
      this.isDefaultCountry = true;
      const unitedKingdomIdx = this.countries.indexOf('United Kingdom');
      this.selectedCountry = this.countries[unitedKingdomIdx];
    }
    console.log('currentFarmCountry', currentFarmCountry);
    console.log('currentCustomerCountry', currentCustomerCountry);
    console.log('selectedCountry', this.selectedCountry);
    this.changeDetectorRef.detectChanges();
  }

  public closeModal() {
    this.onCloseModal.emit();
  }

  public changeSelectedDate(date: Date) {
    this.isEventMarkedAsComplite = false;
    this.isTouched = true;

    const startDateTime = moment(this.currentHarvest.startDate).format(
      TIME_ONLY
    );
    let stringDate = moment(date).format(EVENT_DATE_FORMAT);
    let dateStringWithSelectedTime =
      stringDate.split('').slice(0, 11).join('') + startDateTime;
    this.currentEvent.date = dateStringWithSelectedTime;
    this.currentEvent.birdAge = null;

    if (
      this.currentEvent.type === EventType.FlushWaterLine &&
      this.currentEvent.values[this.idByParameterName('startDate')]?.value
    ) {
      this.currentEvent.values[this.idByParameterName('startDate')].value =
        dateStringWithSelectedTime;
      this.currentEvent.values[this.idByParameterName('startBirdAge')].value =
        null;
    }

    if (
      this.currentEvent.type === EventType.OtherEvents &&
      this.currentEvent.values[this.idByParameterName('startDate')]?.value
    ) {
      this.currentEvent.values[this.idByParameterName('startDate')].value =
        dateStringWithSelectedTime;
      this.currentEvent.values[this.idByParameterName('startBirdAge')].value =
        null;
    }
    console.log('birdAge', this.currentEvent.birdAge);
    this.changeDetectorRef.detectChanges();
  }

  public changeRecurrentEndDate(date: Date, i: number) {
    this.isTouched = true;
    let startDateTime = moment(date).format(TIME_ONLY);
    if (
      moment(date).format(EVENT_DATE_FORMAT) !==
      moment(new Date()).format(EVENT_DATE_FORMAT)
    ) {
      startDateTime = moment(this.currentHarvest.startDate).format(TIME_ONLY);
    }

    let stringDate = moment(date).format(EVENT_DATE_FORMAT);
    let dateStringWithSelectedTime =
      stringDate.split('').slice(0, 11).join('') + startDateTime;
    console.log('dateStringWithSelectedTime', dateStringWithSelectedTime);

    if (this.currentEvent.type === EventType.FlushWaterLine) {
      this.currentEvent.values[this.idByParameterName('endDate')].value =
        dateStringWithSelectedTime;
      this.currentEvent.values[this.idByParameterName('endBirdAge')].value =
        null;
    } else if (this.currentEvent.type === EventType.OtherEvents) {
      this.currentEvent.values[this.idByParameterName('endDate')].value =
        dateStringWithSelectedTime;
      this.currentEvent.values[this.idByParameterName('endBirdAge')].value =
        null;
    }

    console.log(
      'changeRecurrentEndDate',
      this.currentEvent.values[this.idByParameterName('endDate')].value
    );
    this.changeDetectorRef.detectChanges();
  }

  public changeSelectedBirdAge(birdAge: number, i: number) {
    this.isTouched = true;

    this.currentEvent.date = null;
    this.currentEvent.birdAge = birdAge;
    // =================FLUSH=====================
    if (
      this.currentEvent.type === EventType.FlushWaterLine &&
      this.currentEvent.values[this.idByParameterName('isRecurring')]?.value ===
        'true'
    ) {
      this.currentEvent.values[this.idByParameterName('endBirdAge')].value =
        birdAge.toString();
    }
    // =====================OTHER===========================
    if (
      this.currentEvent.type === EventType.OtherEvents &&
      this.currentEvent.values[this.idByParameterName('isRecurring')]?.value ===
        'true'
    ) {
      this.currentEvent.values[this.idByParameterName('endBirdAge')].value =
        birdAge.toString();
    }
    this.changeDetectorRef.detectChanges();
  }

  public idByParameterName(name: string) {
    return this.currentEvent.values.findIndex((value) => {
      return value.name === name;
    });
  }

  public changeRecurrentEndBirdAge(birdAge: number, i: number) {
    this.isTouched = true;

    if (this.currentEvent.type === EventType.FlushWaterLine) {
      this.currentEvent.values[this.idByParameterName('endBirdAge')].value =
        birdAge.toString();
    } else if (this.harvestEvents[0].type === EventType.OtherEvents) {
      this.currentEvent.values[this.idByParameterName('endBirdAge')].value =
        birdAge.toString();
    }
    this.changeDetectorRef.detectChanges();
  }

  public changeSelectedDateTime(value, i) {
    this.isEventMarkedAsComplite = false;
    this.selectDate = value;
    this.currentEvent.date = this.selectDate;
    this.changeDetectorRef.detectChanges();
  }

  public setMillValue(value: string, i: number) {
    this.selectedMill = value;
    this.currentEvent.values[2].value = value;
    this.changeDetectorRef.detectChanges();
  }

  public updateEvent() {
    if (!this.mill.includes(this.selectedMill)) {
      this.harvestEventsLookupService
        .addMill({ body: { name: this.selectedMill } })
        .pipe(take(1))
        .subscribe();
    }
    this.harvestService
      .updateEvent({
        eventId: this.currentEvent.id,
        harvestId: this.currentHarvest.harvestId,
        body: {
          date: this.currentEvent.date,
          isDone: this.isComplete
            ? this.isComplete
            : this.isEventMarkedAsComplite,
          isSystem: this.currentEvent.createdBy === 'system',
          type: this.currentEvent.type,
          values: this.currentEvent.values,
        },
      })
      .pipe(take(1))
      .subscribe(() => {
        if (this.currentEvent.type === EventType.PlannedClearingAge) {
          this.updateViewDeliveryNotesBirdAge();
        }
        this.isShowBeforeCloseModal = false;
        this.closeModal();
        this.onEventCreated.emit(true);
      });
    this.changeDetectorRef.detectChanges();
  }

  public changeThinningValues(
    event: IExtendedHarvestEventDto,
    value: string,
    prop: string
  ) {
    event.values.find((v) => v.name === prop).value = value;
  }

  public updateViewDeliveryNotesBirdAge() {
    const cropUpdatedBirdAge: number = moment(this.currentEvent.date).diff(
      this.currentHarvest.startDate,
      'days'
    );

    const harvestDeliveryNoteRequestList: HarvestDeliveryNoteRequest[] =
      this.currentHarvest.deliveryNotes.map((item) => ({
        chicksValue: item.chicksValue,
        harvestCode: item.harvestCode,
        hatcheryName: item.hatchery?.name,
        parentHarvestAge: item.parentHarvestAge,
      }));

    this.harvestService
      .updateHarvest({
        harvestId: this.currentHarvest?.harvestId,
        body: {
          closeDate: this.currentHarvest?.closeDate,
          openDate: this.currentHarvest?.openDate,
          comments: this.currentHarvest?.comments,
          harvestDeliveryNoteRequestList: harvestDeliveryNoteRequestList,
          hatcheryVaccines: this.currentHarvest.hatcheryVaccines,
          inactivatedVaccineId: this.currentHarvest.inactivatedVaccine?.id,
          integratorId: this.currentHarvest.integrator?.id,
          maxStockingDensity: this.currentHarvest.maxStockingDensity,
          vaccineId: this.currentHarvest.vaccine?.id,
          startDate: this.currentHarvest.startDate,
          targetWeightAtClearance: this.currentHarvest.targetWeightAtClearance,
          targetBirdAgeAtClearance: cropUpdatedBirdAge,
        },
      })
      .pipe(take(1))
      .subscribe();
  }

  public addEvent() {
    console.log('addEvent');
    if (this.eventType === EventType.AddFeed) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.AddFeed,
        birdAge: null,
        values: [
          { name: 'Additives', value: this.additives[0] },
          { name: 'Coccidiostat', value: this.coccidiostat[0] },
          { name: 'Mill', value: this.mill[0] },
          { name: 'Ration', value: this.ration[0] },
        ],
      });
    }
    if (this.eventType === EventType.AddMedication) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.AddMedication,
        birdAge: null,
        values: [
          { name: 'Disease', value: this.medicationDisease[0] },
          { name: 'Duration', value: this.medicationDuration[0] },
          { name: 'Medication', value: this.antibiotics[0] },
        ],
      });
    }
    if (this.eventType === EventType.AddNutraceutical) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.AddNutraceutical,
        birdAge: null,
        values: [
          { name: 'Duration', value: this.nutraceuticalDuration[0] },
          { name: 'Nutraceutical', value: this.nutraceutical[0] },
          { name: 'Reason', value: this.reason[0] },
        ],
      });
    }
    if (this.eventType === EventType.AddVaccination) {
      this.selectedVaccinationDisease = this.vaccinationDisease[0];
      this.selectedType = this.types[0];
      this.selectedVaccine = this.vaccine[0];
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.AddVaccination,
        birdAge: null,
        values: [
          { name: 'Disease', value: this.vaccinationDisease[0] },
          { name: 'Type', value: this.types[0] },
          { name: 'Vaccine', value: this.vaccine[0] },
        ],
      });
    }
    if (this.eventType === EventType.FlushWaterLine) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.FlushWaterLine,
        birdAge: null,
        values: [{ name: 'Comment', value: '' }],
      });
    }
    if (this.eventType === EventType.WaterSanitation) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.WaterSanitation,
        birdAge: null,
        values: [{ name: 'Comment', value: '' }],
      });
    }
    if (this.eventType === EventType.PlannedThinningAges) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.PlannedThinningAges,
        birdAge: null,
        values: [{ name: 'Number Of Birds', value: null }],
      });
    }
    if (this.eventType === EventType.PlannedClearingAge) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.PlannedClearingAge,
        birdAge: null,
        values: [],
      });
    }
    if (this.eventType === EventType.PlannedHandWeighingOfBirds) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.PlannedHandWeighingOfBirds,
        birdAge: null,
        values: [
          { name: 'Number Of Birds', value: null },
          { name: 'Average Weight', value: null },
        ],
      });
    }
    if (this.eventType === EventType.PlannedFootpadScoringOfBirds) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.PlannedFootpadScoringOfBirds,
        birdAge: null,
        values: [
          { name: 'Score 0', value: null },
          { name: 'Score 1', value: null },
          { name: 'Score 2', value: null },
          { name: 'Number Of Zone', value: null },
        ],
      });
    }
    if (this.eventType === EventType.OtherEvents) {
      this.harvestEvents.push({
        date: this.selectDate,
        type: EventType.OtherEvents,
        birdAge: null,
        values: [{ name: 'Comment', value: '' }],
      });
    }
    this.changeDetectorRef.detectChanges();
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public countryChanged(value: string) {
    this.harvestEvents = [];
    this.addEvent();
    this.selectedCountry = value;
    this.types = Object.keys(this.vaccination[this.selectedCountry]);
    this.selectedType = this.types[0];
    console.log('countryChanged');
    this.typeChanged(this.selectedType, 0);
    this.changeDetectorRef.detectChanges();
  }

  public typeChanged(selectedRoute: string, i?: number) {
    this.selectedType = selectedRoute;
    this.vaccinationDisease = Object.keys(
      this.vaccination[this.selectedCountry][this.selectedType]
    );
    if (!this.vaccinationDisease.includes('Other')) {
      this.vaccinationDisease.push('Other');
    }
    this.harvestEvents[i].values[1].value = this.selectedType;
    this.selectedVaccinationDisease = this.vaccinationDisease[0];
    this.diseaseChanged(this.selectedVaccinationDisease, i);
    this.changeDetectorRef.detectChanges();
  }

  public diseaseChanged(selectedVaccinationDisease: string, i?: number) {
    this.selectedVaccinationDisease = selectedVaccinationDisease;
    if (selectedVaccinationDisease !== 'Other') {
      this.vaccine =
        this.vaccination[this.selectedCountry][this.selectedType][
          this.selectedVaccinationDisease
        ];
    } else {
      this.vaccine = ['Other'];
    }
    if (!this.vaccine.includes('Other')) {
      this.vaccine.push('Other');
    }
    this.harvestEvents[i].values[0].value = this.selectedVaccinationDisease;
    this.selectedVaccine = this.vaccine[0];
    this.vaccineChanged(this.selectedVaccine, i);
    this.changeDetectorRef.detectChanges();
  }

  public vaccineChanged(selectedVaccine: string, i?: number) {
    this.selectedVaccine = selectedVaccine;
    this.harvestEvents[i].values[2].value = this.selectedVaccine;
    this.changeDetectorRef.detectChanges();
  }

  public setSelectedZones(event) {
    this.currentEvent.values[3].value = event.join();
    this.changeDetectorRef.detectChanges();
  }
}
