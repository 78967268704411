import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '../../../pipes/pipes.module';
import {NotificationComponent} from './notification.component';

@NgModule({
  declarations: [NotificationComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PipesModule
    ],
  exports: [NotificationComponent]
})
export class NotificationModule {

}
