import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import moment from 'moment';
import { FacilityDataDto } from '../../../../services/api/models/facility-data-dto';
import { HouseType } from '../../../../services/api/models/house-type';
import * as _ from 'lodash';
import {FacilityDto} from '../../../../services/api/models/facility-dto';
import {CurrentCropBehaviourService} from '../../../../services/harvest/current-crop-behaviour.service';

@Component({
  selector: 'custom-houses-multiselect',
  templateUrl: './custom-houses-multiselect.component.html',
  styleUrls: ['./custom-houses-multiselect.component.scss'],
})
export class CustomHousesMultiselectComponent implements OnChanges {
  @Input() public houses: FacilityDataDto[];
  @Input() public currentHouse: FacilityDto;
  @Input() public isDisableAllowed: boolean = true;
  @Input() public maxHeight: string = 'auto';
  @Output() public selectedHouses: EventEmitter<Array<number>> =
    new EventEmitter();
  public selectedHouseIds = [];
  public isOpen: boolean = false;
  public selectedValues: string[] = [];
  public possibleNumberOfSelectedHouses: number = 0;
  private isClickInside: boolean = false;
  public houseTypeHouse = HouseType.House;
  public houseTypeOutside = HouseType.Outside;

  @Input()
  public isEndOfCropMode?: boolean = false;

  constructor(
    public currentCropBehaviourService: CurrentCropBehaviourService) {}

  @HostListener('click')
  public clickInside() {
    this.isClickInside = true;
  }

  @HostListener('document:click')
  public clickOutside() {
    if (!this.isClickInside) {
      this.scrollToStart();
      this.isOpen = false;
    }
    this.isClickInside = false;
  }

  @HostListener('window:scroll')
  public scroll() {
    if (!this.isClickInside) {
      this.scrollToStart();
      this.isOpen = false;
    }
    this.isClickInside = false;
  }

  @ViewChild('list')
  public list: ElementRef;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.houses) {
      this.selectedValues = [];
    }
    if (this.houses?.length > 0) {
      this.possibleNumberOfSelectedHouses =
        this.houses.length -
        this.houses.filter(
          (house) =>
            house.houseType === HouseType.Outside ||
            ((!this.currentCropBehaviourService.isSuperSupportAdmin && !this.getHouseCLoseDate(house) ||
            house.reportInProgress ||
            this.isNotPartOfCurrentCycle(house)) && this.isEndOfCropMode) ||
            (!this.currentCropBehaviourService.isSuperSupportAdmin && this.getHouseCLoseDate(house) && !this.isEndOfCropMode)
        ).length;
    }
    this.selectedHouseIds = [];
    if (this.isEndOfCropMode) {
      this.toggle('all');
    } else {
      this.toggle(this.currentHouse);
    }
  }

  public selectAll() {
    if (this.selectedHouseIds.length === this.possibleNumberOfSelectedHouses) {
      this.selectedHouseIds = [];
    } else {
      const idsShouldBeRemoved = this.houses
        .filter(
          (house) =>
            house.houseType === HouseType.Outside ||
            ((!this.currentCropBehaviourService.isSuperSupportAdmin && !this.getHouseCLoseDate(house) ||
              house.reportInProgress ||
              this.isNotPartOfCurrentCycle(house)) && this.isEndOfCropMode) ||
            (!this.currentCropBehaviourService.isSuperSupportAdmin && this.getHouseCLoseDate(house) && !this.isEndOfCropMode)
        )
        .map((item) => item.facilityId);
      this.selectedHouseIds = this.houses.map((item) => item.facilityId);
      idsShouldBeRemoved.forEach((idToRemove) => {
        this.selectedHouseIds.splice(
          this.selectedHouseIds.indexOf(idToRemove),
          1
        );
      });
    }
  }

  public toggle(house) {
    if (house === 'all') {
      this.selectAll();
    } else if (this.selectedHouseIds.includes(house?.facilityId)) {
      this.selectedHouseIds.splice(
        this.selectedHouseIds.indexOf(house.facilityId),
        1
      );
    } else {
      this.selectedHouseIds.push(house.facilityId);
    }
    this.selectedValues = [];
    this.selectedHouseIds.forEach((id) => {
      this.selectedValues.push(
        this.houses.find((house) => house.facilityId === id).facilityName
      );
    });
    this.selectedHouses.emit(this.selectedHouseIds);
  }

  public isChecked(house) {
    if (house === 'all') {
      return (
        this.selectedHouseIds.length === this.possibleNumberOfSelectedHouses &&
        this.possibleNumberOfSelectedHouses > 0
      );
    } else {
      return this.selectedHouseIds.includes(house.facilityId);
    }
  }

  public isNotPartOfCurrentCycle(house: FacilityDataDto) {
    if (house.houseType === this.houseTypeHouse) {
      let nearestCloseDate;
      const closeDates = this.houses.map((house) => house.closeDate);
      closeDates.forEach((date) => {
        if (nearestCloseDate) {
          if (moment(date).diff(moment(nearestCloseDate), 'days') > 0) {
            nearestCloseDate = date;
          }
        } else {
          nearestCloseDate = date;
        }
      });
      return moment(nearestCloseDate).diff(this.getHouseCLoseDate(house), 'days') > 21;
    }
  }

  public getHouseCLoseDate(house: FacilityDataDto) {
    if (this.isDisableAllowed) {
      return house.closeDate;
    } else {
      const closeDate = moment(new Date()).diff(moment(house.closeDate).format('YYYY-MM-DDTHH:mm:ssZ'), 'days');
      return closeDate > 7 ? house.closeDate : undefined;
    }
  }

  public isFullWidth(house) {
    if (house.houseType === this.houseTypeOutside) {
      return true;
    } else if (house.reportInProgress === true) {
      return false;
    } else if (!this.currentCropBehaviourService.isSuperSupportAdmin && !this.getHouseCLoseDate(house)) {
      return false;
    } else if (!this.isNotPartOfCurrentCycle(house)) {
      return true;
    }
  }

  public openDropdown() {
    if (this.houses?.length > 0) {
      this.isOpen = true;
    }
  }

  public sortSelectedValues(values: string[]) {
    return _.sortBy(values);
  }

  public sortHouses(houses: FacilityDataDto[]): FacilityDataDto[] {
    return _.sortBy(houses, ['facilityName']).filter(house => house.houseType !== 'OUTSIDE');
  }

  public scrollToStart() {
    this.list.nativeElement.scrollTop = 0;
  }
}
