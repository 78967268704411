import {NgModule} from '@angular/core';
import {ShDatatableComponent} from './sh-datatable.component';
import {ShDatatableRowModule} from './sh-datatable-row/sh-datatable-row.module';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [ShDatatableComponent],
  imports: [ShDatatableRowModule, CommonModule],
  exports: [ShDatatableComponent]
})
export class ShDatatableModule {

}
