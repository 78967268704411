import {Inject, Injectable} from '@angular/core';
import {WINDOW} from '../../../utils/window-utils';
import {
  ChangeModeClientSessionRecorderStatus,
  IdleChangeModeClientSessionRecorderStatus
} from "../../common-model/csr-message-status.model";
import {ClientSessionRecorderModeType} from "../../common-model/csr-message.model";

@Injectable({
  providedIn: 'root'
})
export class ClientSessionRecorderStorageService {

  private static readonly CLIENT_SESSION_RECORDING_MODE_STORAGE_KEY = 'ClientSessionRecordingMode';

  private _currentMode : ChangeModeClientSessionRecorderStatus;

  constructor(@Inject(WINDOW) private _window: Window) {
    this.loadCurrentMode();
  }

  public get recorderStatus() : ChangeModeClientSessionRecorderStatus {
    return this._currentMode;
  }

  public loadCurrentMode() {
    this._currentMode = <ChangeModeClientSessionRecorderStatus>JSON.parse(this._window.localStorage.getItem(ClientSessionRecorderStorageService.CLIENT_SESSION_RECORDING_MODE_STORAGE_KEY));
    if(this._currentMode == null) {
      this._currentMode = new IdleChangeModeClientSessionRecorderStatus();
    } else if(this._currentMode.changeMode == ClientSessionRecorderModeType.Playback) {
      console.log('Client is in Playback Mode');
    } else if(this._currentMode.changeMode == ClientSessionRecorderModeType.Recording) {
      console.log('Client is in Recording Mode');
    }
  }

  public storeNewRecorderStatus(status: ChangeModeClientSessionRecorderStatus) {
    if(status != null) {
      this._window.localStorage.setItem(ClientSessionRecorderStorageService.CLIENT_SESSION_RECORDING_MODE_STORAGE_KEY, JSON.stringify(status));
    } else {
      this._window.localStorage.removeItem(ClientSessionRecorderStorageService.CLIENT_SESSION_RECORDING_MODE_STORAGE_KEY);
    }
  }
}
