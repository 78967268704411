import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'colorToValue',
  pure: false
})

export class ColorToValuePipe implements PipeTransform {
  public transform(colorString: string, limits: number[], palette: string[]): number {
    let paletteIndex = palette.findIndex(item => item === colorString);

    if (paletteIndex === limits.length) {
      paletteIndex--;
    }

    return limits[paletteIndex];
  }
}
