import { Component } from '@angular/core';
import { TranslationService } from '../../../../services/translations/translation.service';
import { LanguageEnum } from '../../../../services/api/models/language-enum';

@Component({
  selector: 'change-language-dropdown',
  styleUrls: ['change-language-dropdown.component.scss'],
  templateUrl: 'change-language-dropdown.component.html',
})
export class ChangeLanguageDropdownComponent {
  protected readonly LanguageEnum = LanguageEnum;

  constructor(public translationService: TranslationService) {}

  public isSelected(lang: string): boolean {
    return this.translationService.selectedLanguage == lang;
  }

  public get selectedLanguage(): LanguageEnum {
    return this.translationService.selectedLanguage;
  }

  public get languageName(): string {
    switch (this.translationService.selectedLanguage) {
      case LanguageEnum.EnUs:
        return 'EN|US';
        break;
      case LanguageEnum.EnGb:
        return 'EN|UK';
        break;
      case LanguageEnum.PtBr:
        return 'PT|BZ';
        break;
      case LanguageEnum.FrFr:
        return 'FR|FR';
        break;
    }
  }

  public closeDropdown() {
    document.getElementById('change-lang').classList.remove('show');
  }

  public openDropdown() {
    document.getElementById('change-lang').classList.add('show');
  }

  public async changeTranslation(lang: LanguageEnum) {
    // this.translationService.isLanguageDirectlyChanged = true;
    await this.translationService.selectLanguage(lang, true);
    this.closeDropdown();
  }
}
