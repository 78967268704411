import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileDetailsComponent } from './profile-details.component';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
  declarations: [ProfileDetailsComponent],
  imports: [CommonModule, ReactiveFormsModule, PipesModule, FormsModule],
  exports: [ProfileDetailsComponent],
})
export class ProfileDetailsModule {}
