import {Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'no-data-available',
  templateUrl: 'no-data-available.component.html',
  styleUrls: ['no-data-available.component.scss']
})
export class NoDataAvailableComponent {
  @Input() message: string = 'COMMON.ERRORS.No_data_available';
  @Input()
  @HostBinding('class.no-padding')
  noPadding: boolean = false;
}
