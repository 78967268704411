import {NgModule} from '@angular/core';
import {AllZonesComponent} from './all-zones.component';
import {CommonModule} from '@angular/common';
import {PipesModule} from '../../pipes/pipes.module';
import {FormsModule} from '@angular/forms';
import {ZoneCardModule} from '../zone-card/zone-card.module';

@NgModule({
  declarations: [AllZonesComponent],
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    ZoneCardModule
  ],
  exports: [AllZonesComponent]
})
export class AllZonesModule {

}
