import {NgModule} from '@angular/core';
import {RowActionsPopupComponent} from './row-actions-popup.component';

@NgModule({
  declarations: [RowActionsPopupComponent],
  imports: [],
  exports: [RowActionsPopupComponent]
})
export class RowActionsPopupModule {

}
