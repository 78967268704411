import { NgModule } from '@angular/core';
import { EditFarmModalComponent } from './edit-farm-modal.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../../../pipes/pipes.module';
import { DropDownListsModule } from '../../dropdown-list/dropdown-list.module';
import { AutoCompleteModule } from '@syncfusion/ej2-angular-dropdowns';
import { SimpleDropdownModule } from '../../dropdowns/simple-dropdown/simple-dropdown.module';

@NgModule({
  declarations: [EditFarmModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropDownListsModule,
    PipesModule,
    AutoCompleteModule,
    SimpleDropdownModule,
  ],
  exports: [EditFarmModalComponent],
})
export class EditFarmModalModule {}
