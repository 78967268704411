import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import moment from 'moment';
import { TranslationService } from 'src/app/services/translations/translation.service';
import { HarvestFullResponse } from '../../../../services/api/models/harvest-full-response';
import { MortalityTableRow } from '../../../../services/api/models/mortality-table-row';
import {
  DATE_FORMAT_WITH_Z,
  UtilsService,
} from '../../../../services/utils/utils.service';
import { HarvestEvent } from '../../../../services/api/models/harvest-event';
import { POUND_TO_KG } from '../../../pipes/units-convert.pipe';
import { Subscription } from 'rxjs';
import { CustomerSelectorsService } from '../../../../services/customer-selectors/customer-selectors.service';
import { take } from 'rxjs/operators';
import { LanguageEnum } from '../../../../services/api/models/language-enum';
import {
  EpochStateModel,
  EpochStateModelMinMaxMode,
} from '../../time-picker/epoch-state-model';
import { EVENT_DATE_FORMAT } from '../../../../utils/string-utils';

export const TIME_12_PM = '1970-01-01T12:00:00Z';

@Component({
  selector: 'add-thinning-modal',
  styleUrls: ['add-thinning-modal.component.scss'],
  templateUrl: './add-thinning-modal.component.html',
})
export class AddThinningModalComponent implements OnChanges, OnInit {
  @Output() onCloseModal: EventEmitter<void> = new EventEmitter();
  @Output() onAddThinning: EventEmitter<any> = new EventEmitter();
  @Input() public cropNumber: number;
  @Input() public thinningEvent: HarvestEvent;
  @Input() public addThinningData: {
    date: Date;
    birdsNumber: number;
    totalKg: number;
  };
  @Input() public isThinningModalCompleteMode: boolean = false;
  @Input() public currentHarvest: HarvestFullResponse;
  @Input() public mortalities: MortalityTableRow[];

  public selectedDate: Date = new Date();
  public epochSelectedDate = new EpochStateModel(
    EpochStateModelMinMaxMode.Time,
    Date.parse(TIME_12_PM) / 1000
  );
  public readonly systemNowDate = new Date();
  public birdsNumber: number = 1;
  public totalKg: number = null;
  public disabledAfterClick = false;

  public startDate: Date;
  public maxDate: Date;

  public errors: { [key: string]: string } = {};

  private translationServiceSubscription: Subscription;

  public get thinningNumber() {
    return this.currentHarvest?.thinningDataList?.length + 1;
  }

  public get birdsRemaining() {
    const firstThinning =
      this.currentHarvest?.thinningDataList[0]?.thinningValue ?? 0;
    const secondThinning =
      this.currentHarvest?.thinningDataList[1]?.thinningValue ?? 0;
    const mortalitySum = this.mortalities.reduce(
      (previousValue, currentValue) =>
        previousValue + this.utilsService.calculateMortalities(currentValue),
      0
    );
    const removedChicksSum = firstThinning + secondThinning + mortalitySum;
    return Number(this.currentHarvest?.totalChicksValue - removedChicksSum);
  }

  public birdsChanged(value) {
    if (value < 1) {
      this.birdsNumber = 1;
    }
  }

  public onEpochChanged(epoch) {
    if (this.selectedDate) {
      this.epochSelectedDate = epoch;
      const timeString = this.convertEpochToStringTime(epoch);
      let dateString = moment(this.selectedDate).format(EVENT_DATE_FORMAT);
      let dateStringWithSelectedTime =
        dateString.split('').slice(0, 11).join('') + timeString;
      this.selectedDate = moment(dateStringWithSelectedTime).toDate();
    } else {
      this.selectedDate = null;
    }
  }

  public convertEpochToStringTime(epoch) {
    return moment.unix(epoch.epochTime).utc().format('HH:mm');
  }

  public get cropThinningNumber() {
    return `${this.cropNumber}/${this.thinningNumber}`;
  }

  public get sexOfBirds() {
    return this.currentHarvest?.sex;
  }

  public get isThinningValue() {
    return !!this.birdsNumber;
  }

  public get minDate() {
    return moment(this.currentHarvest.startDate).toDate();
  }

  constructor(
    public translationService: TranslationService,
    public customerSelectorsService: CustomerSelectorsService,
    public utilsService: UtilsService
  ) {}

  public ngOnInit() {
    const defaultDateWithTimeString =
      moment(this.selectedDate)
        .format(EVENT_DATE_FORMAT)
        .split('')
        .slice(0, 11)
        .join('') + '12:00';
    this.translationServiceSubscription =
      this.translationService.languageChangeSubject.subscribe((value) => {
        if (this.translationService.selectedLanguage === LanguageEnum.EnUs) {
          this.totalKg = Number(this.totalKg / POUND_TO_KG);
        } else {
          this.totalKg = Number(this.totalKg * POUND_TO_KG);
        }
      });

    this.customerSelectorsService.currentFarm$
      .pipe(take(1))
      .subscribe((farm) => {
        this.selectedDate = moment(defaultDateWithTimeString).toDate();
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.startDate = moment(this.currentHarvest?.startDate)
      .set({
        hour: +moment(this.currentHarvest.startDate).format('HH'),
        minute: +moment(this.currentHarvest.startDate).format('mm'),
      })
      .toDate();
    this.maxDate = this.currentHarvest?.closeDate
      ? moment(this.currentHarvest?.closeDate)
          .set({
            hour: +moment(this.currentHarvest.startDate).format('HH'),
            minute: +moment(this.currentHarvest.startDate).format('mm'),
          })
          .toDate()
      : undefined;

    this.selectedDate = this.currentHarvest?.closeDate
      ? moment(this.maxDate)
          .set({
            hour: +moment(this.currentHarvest.startDate).format('HH'),
            minute: +moment(this.currentHarvest.startDate).format('mm'),
          })
          .toDate()
      : moment(this.systemNowDate)
          .set({
            hour: +moment(this.currentHarvest.startDate).format('HH'),
            minute: +moment(this.currentHarvest.startDate).format('mm'),
          })
          .toDate();
  }

  public closeModal() {
    this.onCloseModal.emit();
  }

  public validate() {
    this.errors = {};
    const birds = Number(this.birdsNumber);
    if (birds > this.birdsRemaining) {
      this.errors['birdsNumber'] = this.translationService.translate(
        'CROP_CARD.VALIDATION.max_birds_number',
        null,
        this.birdsRemaining.toString()
      );
    }
    if (birds < 0) {
      this.errors['less0'] = this.translationService.translate(
        'COMMON.ERRORS.invalid'
      );
    }
    if (Object.keys(this.errors).length > 0) {
      return this.errors;
    }
  }

  public applyChanges() {
    this.disabledAfterClick = true;

    if (this.translationService.selectedLanguage === LanguageEnum.EnUs) {
      this.totalKg = this.totalKg * POUND_TO_KG;
    }

    if (!this.validate()) {
      this.closeModal();
      this.onAddThinning.emit({
        date:
          !this.selectedDate || this.selectedDate?.toString() === 'Invalid date'
            ? null
            : this.selectedDate,
        birdsNumber: Number(this.birdsNumber),
        totalKg: this.totalKg,
      });
    }
  }

  public setDateAndTime(value) {
    this.selectedDate = value;
    this.onEpochChanged(this.epochSelectedDate);
  }
}
