import { NgModule } from '@angular/core';
import { PrivacyComponent } from './privacy.component';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [PrivacyComponent],
  imports: [CommonModule, PipesModule],
  exports: [PrivacyComponent],
})
export class PrivacyModule {}
