import {DataRow} from '../model/sh-datatable.model';

export class ShDatatableUtils {

  public static getRowHeight(row:DataRow): number{
    let rowExpandedState = row.gridViewState.getRowExpandedState(row);
    if(rowExpandedState == null) {
      return row.rowDefaultHeight;
    }
    else {
      return rowExpandedState.expandedHeight;
    }
  }
}
