import {NgModule} from '@angular/core';
import {ZoneCardComponent} from './zone-card.component';
import {PipesModule} from '../../pipes/pipes.module';
import {CommonModule} from '@angular/common';

@NgModule({
    declarations: [ZoneCardComponent],
    imports: [
        PipesModule,
        CommonModule
    ],
    exports: [ZoneCardComponent]
})
export class ZoneCardModule {

}
