import {
  ClientSessionRecorderMessageData,
  ClientSessionRecorderMessageType,
  ClientSessionRecorderModeType, ClientSessionRecorderSession
} from "./csr-message.model";

export enum ClientSessionRecorderStatusType {
  StartMode = 'StartMode',
  ChangeMode = 'ChangeMode',
  MessageReceived = 'MessageReceived',
  PlaybackConnectivity = 'PlaybackConnectivity'
}

export abstract class ClientSessionRecorderStatus extends ClientSessionRecorderMessageData {
  public abstract statusType: ClientSessionRecorderStatusType;
  public type: ClientSessionRecorderMessageType;

  protected constructor() {
    super();
    this.type = ClientSessionRecorderMessageType.Status;
  }
}

export class StartModeClientSessionRecorderStatus extends ClientSessionRecorderStatus{
  public statusType: ClientSessionRecorderStatusType = ClientSessionRecorderStatusType.StartMode;
  public startMode: ClientSessionRecorderModeType;

  constructor() {
    super();
    this.statusType = ClientSessionRecorderStatusType.StartMode;
  }
}

export class MessageReceivedClientSessionRecorderStatus extends ClientSessionRecorderStatus {
  public statusType: ClientSessionRecorderStatusType = ClientSessionRecorderStatusType.MessageReceived;
  public receivedMessageId: string;

  constructor() {
    super();
    this.statusType = ClientSessionRecorderStatusType.MessageReceived;
  }
}

export class PlaybackConnectivityClientSessionRecorderStatus extends ClientSessionRecorderStatus{
  public statusType: ClientSessionRecorderStatusType = ClientSessionRecorderStatusType.PlaybackConnectivity;
  public playbackSessionId: string;

  constructor() {
    super();
    this.statusType = ClientSessionRecorderStatusType.PlaybackConnectivity;
  }
}

export abstract class ChangeModeClientSessionRecorderStatus extends ClientSessionRecorderStatus{
  public statusType: ClientSessionRecorderStatusType = ClientSessionRecorderStatusType.ChangeMode;
  public abstract changeMode: ClientSessionRecorderModeType;

  protected constructor() {
    super();
    this.statusType = ClientSessionRecorderStatusType.ChangeMode;
  }
}

export class RecordingChangeModeClientSessionRecorderStatus extends ChangeModeClientSessionRecorderStatus{
  public changeMode: ClientSessionRecorderModeType = ClientSessionRecorderModeType.Recording;

  constructor() {
    super();
    this.changeMode = ClientSessionRecorderModeType.Recording;
  }
}

export class IdleChangeModeClientSessionRecorderStatus extends ChangeModeClientSessionRecorderStatus{
  public changeMode: ClientSessionRecorderModeType = ClientSessionRecorderModeType.Idle;

  constructor() {
    super();
    this.changeMode = ClientSessionRecorderModeType.Idle;
  }
}

//Playback mode
export class PlaybackChangeModeClientSessionRecorderStatus extends ChangeModeClientSessionRecorderStatus {
  public changeMode: ClientSessionRecorderModeType = ClientSessionRecorderModeType.Playback;
  public playbackSession : ClientSessionRecorderSession;
  public validateConnectivity : boolean;
  public autoPlayback : AutoPlaybackDetails;

  constructor() {
    super();
    this.changeMode = ClientSessionRecorderModeType.Playback;
  }
}

export enum EndOfAutoPlaybackActionType {
  None = 'None',
  ExitPlaybackMode = 'ExitPlaybackMode'
}

export class AutoPlaybackDetails {
  public clickInterval : number;
  public keyboardInterval : number;
  public inputInterval : number;
  public windowResizeInterval : number;
  public scrollInterval : number;
  public stopEventId : string;
  public endOfPlaybackAction : EndOfAutoPlaybackActionType;
}
