import {NgModule} from '@angular/core';
import {ProfileComponent} from './profile.component';
import {RouterModule} from '@angular/router';
import {PipesModule} from '../../../pipes/pipes.module';

@NgModule({
  declarations: [ProfileComponent],
    imports: [
        RouterModule,
        PipesModule
    ],
  exports: [ProfileComponent]
})
export class ProfileModule {

}
