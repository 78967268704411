export class StringUtils {
  public static isNumber(value: string) {
    return '0123456789'.indexOf(value) != -1;
  }
}

export const DATE_FORMAT: string = 'YYYY-MM-DD';
export const EVENT_DATE_FORMAT = 'YYYY-MM-DDTHH:mm';
export const TIME_ONLY = 'HH:mm';
export const MIDNIGHT_DATE_FORMAT: string = 'YYYY-MM-DDT00:00';
