import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PipesModule } from '../../../pipes/pipes.module';
import { InfoComponent } from './info.component';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [InfoComponent],
  imports: [RouterModule, PipesModule, CommonModule],
  exports: [InfoComponent],
})
export class InfoModule {}
