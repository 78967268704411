import { NgModule } from '@angular/core';
import { DiaryWidgetComponent } from './diary-widget.component';
import { NoDataAvailableModule } from '../../no-data-available/no-data-available.module';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
  declarations: [DiaryWidgetComponent],
  imports: [NoDataAvailableModule, PipesModule],
  exports: [DiaryWidgetComponent],
})
export class DiaryWidgetModule {}
