<div class="display-flex position-relative">
  <div class="menus__menu">
    <div class="menus__menu-selector">
      <div class="menus__menu-selector-label">{{"EVENT.Type" | translate}}</div>
      <simple-dropdown
        [disabled]="isDisabled"
        [items]="types"
        [allowClear]="false"
        [selectedValue]="typesSelectedItem"
        (onItemSelected)="typeChanged($event)">
      </simple-dropdown>
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector">
      <div class="menus__menu-selector-label">{{"EVENT.disease" | translate}}</div>
      <simple-dropdown
        [disabled]="isDisabled"
        [items]="diseases"
        [allowClear]="false"
        [selectedValue]="diseaseSelectedItem"
        (onItemSelected)="diseaseChanged($event)">
      </simple-dropdown>
    </div>
  </div>
  <div class="menus__menu">
    <div class="menus__menu-selector">
      <div class="menus__menu-selector-label">{{"EVENT.vaccine" | translate}}</div>
      <simple-dropdown
        [disabled]="isDisabled"
        [items]="vaccines"
        [allowClear]="false"
        [selectedValue]="vaccineSelectedItem"
        (onItemSelected)="vaccineChanged($event)">
      </simple-dropdown>
    </div>
  </div>
  <div class="remove-event" *ngIf="!isDisabled" (click)="onRemoveEvent()">
    <span class="svg delete-blue"></span>
  </div>
</div>
