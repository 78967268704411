import { Injectable } from '@angular/core';
import { TranslationService } from '../translations/translation.service';
import moment from 'moment';
import { SensorPageDto } from '../api/models/sensor-page-dto';
import { KPIS_INFO_I18NAMES } from '../model/kpis-model';
import { DATE_FORMAT } from '../../utils/string-utils';
import { MortalityTableRow } from '../api/models/mortality-table-row';
import { LanguageEnum } from '../api/models/language-enum';
import { HarvestMetricType } from '../api/models/harvest-metric-type';

export enum RectIntersectionResultType {
  NoIntersection = 'NoIntersection',
  PartialIntersection = 'PartialIntersection',
  ARectContainedInBRect = 'ARectContainedInBRect',
  BRectContainedInARect = 'BRectContainedInARect',
  Identical = 'Identical',
}

export const EMAIL_REGEXP =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

export const DATE_FORMAT_WITH_Z = 'YYYY-MM-DDTHH:mm:ss[Z]';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public readonly smallScreenSize: number = 1366;

  public KPIS_INFO_I18NAMES = KPIS_INFO_I18NAMES;

  private static readonly KGtoPounds: number = 2.20462;

  private static readonly FeetToMeter: number = 0.3048;

  private static readonly LiterToGallon = 0.26417205;

  private static readonly MinutesToHours = 60;

  constructor(private translationService: TranslationService) {}

  public static convertPoundsToKilograms(pounds: number): number {
    return Number((pounds / UtilsService.KGtoPounds).toFixed(3));
  }

  public static convertKilogramsToPounds(kg: number): number {
    if (kg === null || kg === undefined) {
      return null;
    }
    return Number((kg * UtilsService.KGtoPounds).toFixed(3));
  }

  public static convertFeetToMeters(feet: number): number {
    return Number(feet * UtilsService.FeetToMeter);
  }

  public static objectsEqual(o1, o2) {
    return typeof o1 === 'object' && o1 && Object.keys(o1).length > 0
      ? o2 &&
          Object.keys(o1).length === Object.keys(o2).length &&
          Object.keys(o1).every((p) => UtilsService.objectsEqual(o1[p], o2[p]))
      : o1 === o2;
  }

  public static convertMetersToFeet(meters: number): number {
    return Number(meters / UtilsService.FeetToMeter);
  }

  public static convertCelsiusToFahrenheit(celsius: number): number {
    if (celsius === null || celsius === undefined) {
      return null;
    }
    return Number((celsius * 9) / 5 + 32);
  }

  public static convertLitersToGallons(liters: number): number {
    if (liters === null || liters === undefined) {
      return null;
    }
    return Number(liters * UtilsService.LiterToGallon);
  }

  public arraysEqual(a1, a2) {
    return (
      a1?.length === a2?.length &&
      a1?.every((o, idx) => UtilsService.objectsEqual(o, a2[idx]))
    );
  }

  public showWeightValueAccordingToUnitType(row) {
    if (this.getUnitType() === WeightUnitType.LBS) {
      return row.weightValueLBS;
    } else {
      return row.weightValueKG;
    }
  }

  public convertKilogramsToPounds(kg: number): number {
    if (kg === null || kg === undefined) {
      return null;
    }
    return Number((kg * UtilsService.KGtoPounds).toFixed(3));
  }

  public convertCelsiusToFahrenheit(celsius: number): number {
    if (celsius === null || celsius === undefined) {
      return null;
    }
    return Number((celsius * 9) / 5 + 32);
  }

  public convertFahrenheitToCelsius(fahrenheit: number): number {
    if (fahrenheit === null || fahrenheit === undefined) {
      return null;
    }
    return Number((5 / 9) * (fahrenheit - 32));
  }

  public convertPoundsToKilograms(pounds: number): number {
    return Number((pounds / UtilsService.KGtoPounds).toFixed(3));
  }

  public getUnitType(): WeightUnitType {
    if (this.translationService.selectedLanguage === LanguageEnum.EnUs) {
      return WeightUnitType.LBS;
    } else {
      return WeightUnitType.KG;
    }
  }

  public get measurementSystem(): MeasurementSystem {
    if (this.translationService.selectedLanguage === LanguageEnum.EnUs) {
      return MeasurementSystem.Imperial;
    } else {
      return MeasurementSystem.Metric;
    }
  }

  public removeSpacesAndDashesFromInternationalPhoneNumber(
    phoneNumber: string
  ): string {
    return phoneNumber.replace(/[- ]/g, '');
  }

  public domRectIntersect(
    rectA: DOMRect | ClientRect,
    rectB: DOMRect | ClientRect
  ): RectIntersectionResultType {
    if (
      rectA.left == rectB.left &&
      rectA.top == rectB.top &&
      rectA.right == rectB.right &&
      rectA.bottom == rectB.bottom
    ) {
      return RectIntersectionResultType.Identical;
    }
    if (
      rectA.left <= rectB.left &&
      rectA.right >= rectB.right &&
      rectA.top <= rectB.top &&
      rectA.bottom >= rectB.bottom
    ) {
      return RectIntersectionResultType.BRectContainedInARect;
    }
    if (
      rectB.left <= rectA.left &&
      rectB.right >= rectA.right &&
      rectB.top <= rectA.top &&
      rectB.bottom >= rectA.bottom
    ) {
      return RectIntersectionResultType.ARectContainedInBRect;
    }
    let hasPartialIntersection = !(
      rectB.left >= rectA.right ||
      rectB.right <= rectA.left ||
      rectB.top >= rectA.bottom ||
      rectB.bottom <= rectA.top
    );
    return hasPartialIntersection
      ? RectIntersectionResultType.PartialIntersection
      : RectIntersectionResultType.NoIntersection;
  }

  public isVisibleDomElement(rect: DOMRect | ClientRect) {
    return (
      rect.left != 0 || rect.right != 0 || rect.top != 0 || rect.bottom != 0
    );
  }

  public getDate(startYear: number, startMonth: number, startDay: number) {
    return `${startYear}-${('0' + (startMonth + 1)).slice(-2)}-${(
      '0' + startDay
    ).slice(-2)}`;
  }

  public convertMinutesToHours(minutes: number): number {
    if (minutes === null || minutes === undefined) {
      return null;
    }
    return Number(minutes / UtilsService.MinutesToHours);
  }

  public filterByAllProperties(filterValue: string, array: any[]) {
    return array?.filter(function (object) {
      return Object.keys(object).some(function (key) {
        return object[key] !== null && object[key] !== undefined
          ? object[key]
              .toString()
              .toLowerCase()
              .indexOf(filterValue.toLowerCase()) !== -1
          : false;
      });
    });
  }

  public filterByConcreteProperties(
    filterValue: string,
    array: any[],
    properties: any[]
  ) {
    return array?.filter(function (object) {
      return Object.keys(object).some((key) => {
        if (properties?.includes(key)) {
          if (typeof object[key] === 'object' && object[key] !== null) {
            Object.keys(object[key]).some((k) => {
              if (properties?.includes(k)) {
                return object[key][k] !== null && object[key][k] !== undefined
                  ? object[key][k]
                      .toString()
                      .toLowerCase()
                      .indexOf(filterValue.toLowerCase()) !== -1
                  : false;
              }
            });
          } else {
            return object[key] !== null && object[key] !== undefined
              ? object[key]
                  .toString()
                  .toLowerCase()
                  .indexOf(filterValue.toString().toLowerCase()) !== -1
              : false;
          }
        }
      });
    });
  }

  public divideByThousand(value: number): number {
    if (value === null || value === undefined) {
      return null;
    }
    return value / 1000;
  }

  public convertDateForDatePickerMinDate(value: Date): Date {
    return new Date(value.getFullYear(), value.getMonth(), value.getDate());
  }

  public getNextDayTruncated(value: Date): Date {
    return new Date(moment(value).add(1, 'days').format(DATE_FORMAT));
  }

  public addOffset(value: Date): Date {
    return new Date(
      moment(value).add(value.getTimezoneOffset(), 'minutes').format()
    );
  }

  public sortAsKPIS(data: SensorPageDto) {
    data?.data.sort(
      (a, b) =>
        this.KPIS_INFO_I18NAMES.indexOf(a.parameter) -
        this.KPIS_INFO_I18NAMES.indexOf(b.parameter)
    );

    if (data?.data) {
      data.data = data.data.filter(
        (kpi) => kpi.parameter !== HarvestMetricType.Thi
      );
    }
    return data;
  }

  public calculateMortalities(mortalityTableRow: MortalityTableRow) {
    return (
      mortalityTableRow.legCullsValue +
      mortalityTableRow.deadValue +
      mortalityTableRow.otherCullsValue
    );
  }

  public toNormalValue(value: any) {
    if (!value || typeof value === 'number') {
      return value;
    }
    const { format } = new Intl.NumberFormat(
      this.translationService.transformLanguageEnum(
        this.translationService.selectedLanguage
      )
    );
    const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
    return +value
      .replace(new RegExp(`[^${decimalSign}\\d]`, 'g'), '')
      .replace(decimalSign, '.');
  }

  public transformTooltipValues(
    args: any,
    excludeTranslationPartsForFirstLine?: boolean
  ) {
    const translationParts = [];
    const parts = [];
    args.text.split(': ').forEach((part, index) =>
      part.split(' ').forEach((p) => {
        if (p !== '' && excludeTranslationPartsForFirstLine === true) {
          if (index === 0) {
            translationParts.push(p);
          } else {
            parts.push(p);
          }
        } else if (p !== '') {
          parts.push(p);
        }
      })
    );

    for (let i = 0; i < parts.length; i++) {
      const value = Number(parts[i]);
      if (!isNaN(value)) {
        const labelFormat = parts[i].split('.')[1]?.length;

        if (labelFormat) {
          parts[i] =
            ': ' +
            value?.toLocaleString(
              this.translationService.transformLanguageEnum(
                this.translationService.selectedLanguage
              ),
              {
                minimumFractionDigits: labelFormat,
                maximumFractionDigits: labelFormat,
              }
            );
        } else {
          parts[i] =
            ': ' +
            value?.toLocaleString(
              this.translationService.transformLanguageEnum(
                this.translationService.selectedLanguage
              )
            );
        }
      } else {
        if (!isNaN(Number(parts[i][0])) && parts[i] !== '1000<b>') {
          parts[i] = ': ' + parts[i];
        }
      }
    }

    args.text = translationParts.join(' ') + parts.join(' ');
  }
}

export enum WeightUnitType {
  LBS = ' Lbs',
  KG = ' Kg',
}

export enum MeasurementSystem {
  Imperial = 'Imperial',
  Metric = 'Metric',
}
