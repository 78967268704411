/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { NotificationTitle } from '../models/notification-title';
import { UserAlertNotificationSetting } from '../models/user-alert-notification-setting';
import { UserAlertNotificationSettingDto } from '../models/user-alert-notification-setting-dto';

@Injectable({
  providedIn: 'root',
})
export class UserAlertNotificationSettingService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getList_4
   */
  static readonly GetList_4Path = '/api/user/notification/alert/setting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getList_4()` instead.
   *
   * This method doesn't expect any request body.
   */
  getList_4$Response(params?: {
  }): Observable<StrictHttpResponse<Array<UserAlertNotificationSetting>>> {

    const rb = new RequestBuilder(this.rootUrl, UserAlertNotificationSettingService.GetList_4Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserAlertNotificationSetting>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getList_4$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getList_4(params?: {
  }): Observable<Array<UserAlertNotificationSetting>> {

    return this.getList_4$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserAlertNotificationSetting>>) => r.body as Array<UserAlertNotificationSetting>)
    );
  }

  /**
   * Path part for operation addSetting_3
   */
  static readonly AddSetting_3Path = '/api/user/notification/alert/setting';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addSetting_3()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSetting_3$Response(params?: {
    body?: UserAlertNotificationSettingDto
  }): Observable<StrictHttpResponse<UserAlertNotificationSetting>> {

    const rb = new RequestBuilder(this.rootUrl, UserAlertNotificationSettingService.AddSetting_3Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserAlertNotificationSetting>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addSetting_3$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSetting_3(params?: {
    body?: UserAlertNotificationSettingDto
  }): Observable<UserAlertNotificationSetting> {

    return this.addSetting_3$Response(params).pipe(
      map((r: StrictHttpResponse<UserAlertNotificationSetting>) => r.body as UserAlertNotificationSetting)
    );
  }

  /**
   * Path part for operation getSetting_4
   */
  static readonly GetSetting_4Path = '/api/user/notification/alert/setting/id/{settingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSetting_4()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSetting_4$Response(params: {
    settingId: number;
  }): Observable<StrictHttpResponse<UserAlertNotificationSetting>> {

    const rb = new RequestBuilder(this.rootUrl, UserAlertNotificationSettingService.GetSetting_4Path, 'get');
    if (params) {
      rb.path('settingId', params.settingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserAlertNotificationSetting>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSetting_4$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSetting_4(params: {
    settingId: number;
  }): Observable<UserAlertNotificationSetting> {

    return this.getSetting_4$Response(params).pipe(
      map((r: StrictHttpResponse<UserAlertNotificationSetting>) => r.body as UserAlertNotificationSetting)
    );
  }

  /**
   * Path part for operation findByTitle_1
   */
  static readonly FindByTitle_1Path = '/api/user/notification/alert/setting/title/{title}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByTitle_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByTitle_1$Response(params: {
    title: NotificationTitle;
  }): Observable<StrictHttpResponse<Array<UserAlertNotificationSetting>>> {

    const rb = new RequestBuilder(this.rootUrl, UserAlertNotificationSettingService.FindByTitle_1Path, 'get');
    if (params) {
      rb.path('title', params.title, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserAlertNotificationSetting>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByTitle_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByTitle_1(params: {
    title: NotificationTitle;
  }): Observable<Array<UserAlertNotificationSetting>> {

    return this.findByTitle_1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserAlertNotificationSetting>>) => r.body as Array<UserAlertNotificationSetting>)
    );
  }

  /**
   * Path part for operation getSettingsByTitleRoleId_2
   */
  static readonly GetSettingsByTitleRoleId_2Path = '/api/user/notification/alert/setting/title/{title}/userId/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSettingsByTitleRoleId_2()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingsByTitleRoleId_2$Response(params: {
    title: NotificationTitle;
    userId: number;
  }): Observable<StrictHttpResponse<UserAlertNotificationSetting>> {

    const rb = new RequestBuilder(this.rootUrl, UserAlertNotificationSettingService.GetSettingsByTitleRoleId_2Path, 'get');
    if (params) {
      rb.path('title', params.title, {});
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserAlertNotificationSetting>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSettingsByTitleRoleId_2$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSettingsByTitleRoleId_2(params: {
    title: NotificationTitle;
    userId: number;
  }): Observable<UserAlertNotificationSetting> {

    return this.getSettingsByTitleRoleId_2$Response(params).pipe(
      map((r: StrictHttpResponse<UserAlertNotificationSetting>) => r.body as UserAlertNotificationSetting)
    );
  }

  /**
   * Path part for operation findByRoleId_1
   */
  static readonly FindByRoleId_1Path = '/api/user/notification/alert/setting/userId/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByRoleId_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByRoleId_1$Response(params: {
    userId: number;
  }): Observable<StrictHttpResponse<Array<UserAlertNotificationSetting>>> {

    const rb = new RequestBuilder(this.rootUrl, UserAlertNotificationSettingService.FindByRoleId_1Path, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserAlertNotificationSetting>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByRoleId_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByRoleId_1(params: {
    userId: number;
  }): Observable<Array<UserAlertNotificationSetting>> {

    return this.findByRoleId_1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserAlertNotificationSetting>>) => r.body as Array<UserAlertNotificationSetting>)
    );
  }

  /**
   * Path part for operation updateSetting_5
   */
  static readonly UpdateSetting_5Path = '/api/user/notification/alert/setting/{settingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSetting_5()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSetting_5$Response(params: {
    settingId: number;
    body?: UserAlertNotificationSettingDto
  }): Observable<StrictHttpResponse<UserAlertNotificationSetting>> {

    const rb = new RequestBuilder(this.rootUrl, UserAlertNotificationSettingService.UpdateSetting_5Path, 'put');
    if (params) {
      rb.path('settingId', params.settingId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserAlertNotificationSetting>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateSetting_5$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSetting_5(params: {
    settingId: number;
    body?: UserAlertNotificationSettingDto
  }): Observable<UserAlertNotificationSetting> {

    return this.updateSetting_5$Response(params).pipe(
      map((r: StrictHttpResponse<UserAlertNotificationSetting>) => r.body as UserAlertNotificationSetting)
    );
  }

  /**
   * Path part for operation deleteSetting_3
   */
  static readonly DeleteSetting_3Path = '/api/user/notification/alert/setting/{settingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSetting_3()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSetting_3$Response(params: {
    settingId: number;
  }): Observable<StrictHttpResponse<UserAlertNotificationSetting>> {

    const rb = new RequestBuilder(this.rootUrl, UserAlertNotificationSettingService.DeleteSetting_3Path, 'delete');
    if (params) {
      rb.path('settingId', params.settingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserAlertNotificationSetting>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSetting_3$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteSetting_3(params: {
    settingId: number;
  }): Observable<UserAlertNotificationSetting> {

    return this.deleteSetting_3$Response(params).pipe(
      map((r: StrictHttpResponse<UserAlertNotificationSetting>) => r.body as UserAlertNotificationSetting)
    );
  }

}
