import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../../common/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { DropDownListsModule } from '../../../common/components/dropdown-list/dropdown-list.module';
import { HouseIllustrationComponent } from './house-illustration.component';
import { DatePickerModule } from '../../../common/components/date-picker/date-picker.module';
import { AutonumericModule } from '@angularfy/autonumeric';
import { ErrorModalModule } from '../../../common/components/modals/error-modal/error-modal.module';

@NgModule({
  declarations: [HouseIllustrationComponent],
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    MultiSelectModule,
    DropDownListsModule,
    ReactiveFormsModule,
    DatePickerModule,
    AutonumericModule,
    ErrorModalModule,
  ],
  exports: [HouseIllustrationComponent],
})
export class HouseIllustrationModule {}
