import { NgModule } from '@angular/core';
import { PipesModule } from '../../pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimePickerComponent } from './time-picker.component';
import { TimeSelectionModule } from './time-selection/time-selection.module';
import { AmPmSelectionModule } from './am-pm-selection/am-pm-selection.module';
import {PopupTemplateModule} from '../dropdown-list/popups/popup-template/popup-template.module';

@NgModule({
  declarations: [TimePickerComponent],
  imports: [
    PipesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TimeSelectionModule,
    AmPmSelectionModule,
    PopupTemplateModule
  ],
  exports: [TimePickerComponent],
})
export class TimePickerModule {}
