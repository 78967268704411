import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { Subject } from 'rxjs';
import { OktaAuth } from '@okta/okta-auth-js';
import { Router } from '@angular/router';
import { LINK_PARAMS_KEY } from '../../layouts/layout-auth/layout-auth.component';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private authClient: OktaAuth;

  constructor(public configService: ConfigService, private router: Router) {}

  public async init() {
    let storedConfiguration = this.configService.storedConfiguration;
    this.authClient = new OktaAuth({
      clientId: storedConfiguration.oktaClientId,
      issuer: storedConfiguration.oktaIssuerUrl,
      pkce: true,
      scopes: ['openid', 'offline_access', 'application_roles', 'app_lbl'],
    });
    await this.authClient.start();
  }

  public async login(username: string, password: string) {
    let response = await this.authClient.signInWithCredentials({
      username: username,
      password: password,
    });
    let response2 = await this.authClient.token.getWithoutPrompt({
      sessionToken: response.sessionToken,
      redirectUri: window.location.origin + '/login/callback',
      scopes: ['openid', 'offline_access', 'application_roles', 'app_lbl'],
    });
    this.authClient.tokenManager.setTokens(response2.tokens);
  }

  public async getAccessToken(): Promise<string> {
    return this.authClient?.getAccessToken();
  }

  public async logout() {
    await this.router.navigate(['/auth']);
    localStorage.clear();
    // do not remove appLanguage from session storage
    // sessionStorage.clear();
  }

  public removeLinkParams() {
    sessionStorage.removeItem(LINK_PARAMS_KEY);
  }
}
