import { Component, OnDestroy, OnInit } from '@angular/core';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { BaseService } from '../../services/api/services/base.service';
import { AuthService } from '../../services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerSelectorsService } from '../../services/customer-selectors/customer-selectors.service';
import { UserService } from '../../services/api/services/user.service';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { ConfigService } from '../../services/config/config.service';
import { GeolocationService } from '../../services/geolocation/geolocation.service';
import { AgreementsDto } from '../../services/api/models/agreements-dto';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { privacyModes } from '../../common/components/privacy/privacy.component';
import { SignedDocumentsDto } from '../../services/api/models/signed-documents-dto';
import { TranslationService } from '../../services/translations/translation.service';
import { LanguageEnum } from '../../services/api/models/language-enum';

declare let branch: any;

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

class BranchIoData {
  public activationCode: string;
  public method: string;
  public email: string;
  public language: string;
}

export enum inviteViews {
  invite = 'invite',
  privacy = 'privacy',
  eula = 'eula',
}

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss'],
})
export class InviteUserComponent implements OnInit, OnDestroy {
  public passwordType: string = 'password';
  public passwordNew: string = '';
  public passwordConfirm: string = '';
  public email: string;
  public activationCode: string;
  public inviteView: inviteViews = inviteViews.invite;
  public inviteViewEnum: typeof inviteViews = inviteViews;
  public isAllAgreementsAccepted: boolean = false;
  public EULAAgreementsAccepted: boolean = false;
  public privacyAgreementsAccepted: boolean = false;
  public agreements: AgreementsDto;
  public privacyAgreementText: string;
  public privacyAgreementTextReserved: string;
  public EULAAgreementText: string;
  public EULAAgreementTextReserved: string;
  public privacyModesEnum: typeof privacyModes = privacyModes;
  public signedDocuments: SignedDocumentsDto[] = [];
  public errorMessage: string = '';
  public showAuthFail$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private destroyed$ = new Subject();

  constructor(
    private baseService: BaseService,
    private authService: AuthService,
    private router: Router,
    private configService: ConfigService,
    private customerSelectorService: CustomerSelectorsService,
    private activatedRoute: ActivatedRoute,
    public userService: UserService,
    private geolocationService: GeolocationService,
    private httpClient: HttpClient,
    private translationService: TranslationService
  ) {}

  async ngOnInit() {
    branch.init(
      this.configService.configuration.branchIoKey,
      async (err, data) => {
        let externalLinkUserData: BranchIoData = <BranchIoData>(
          data.data_parsed.$custom_meta_tags
        );
        console.log(externalLinkUserData);
        this.email = externalLinkUserData.email;
        this.activationCode = externalLinkUserData.activationCode;
        this.getAgreements();
        this.selectLanguageAccordingToUserData(externalLinkUserData.language);
      }
    );
    this.translationService.languageChangeSubject
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.getAgreements();
      });
  }

  public selectLanguageAccordingToUserData(language: string) {
    if (language === 'pt-bz' || language === 'pt-br') {
      this.translationService.selectLanguage(LanguageEnum.PtBr, true);
    } else if (language === 'en-uk') {
      this.translationService.selectLanguage(LanguageEnum.EnGb, true);
    } else {
      this.translationService.selectLanguage(
        language.toLocaleUpperCase().replace('-', '_') as LanguageEnum,
        true
      );
    }
  }

  public getAgreements() {
    this.userService
      .getAgreements({
        language: this.translationService.selectedLanguage,
      })
      .pipe(take(1))
      .subscribe((res) => {
        console.log(res);
        this.agreements = res;
        const privacyAgreementLink = res.Agreements.find(
          (item) => item.DocumentName === 'Privacy'
        )?.Link;
        const EULAAgreementLink = res.Agreements.find(
          (item) => item.DocumentName === 'EULA'
        )?.Link;
        if (privacyAgreementLink) {
          this.httpClient
            .get(privacyAgreementLink, { responseType: 'text' })
            .pipe(take(1))
            .subscribe((res) => {
              this.privacyAgreementText = res;
              this.privacyAgreementTextReserved = res;
            });
        }
        if (EULAAgreementLink) {
          this.httpClient
            .get(EULAAgreementLink, { responseType: 'text' })
            .pipe(take(1))
            .subscribe((res) => {
              this.EULAAgreementText = res;
              this.EULAAgreementTextReserved = res;
            });
        }
      });
  }

  public get getLogo() {
    return this.geolocationService.getLogoPath();
  }

  public eulaAccepted(isFromCheckbox: boolean) {
    const agreementId = this.agreements.Agreements.find(
      (item) => item.DocumentName === 'EULA'
    ).DocumentId;
    if (!this.signedDocuments.find((item) => item.DocumentId === agreementId)) {
      this.signedDocuments.push({
        DocumentId: agreementId,
        ResourceId: this.email,
      });
    }
    if (isFromCheckbox) {
      this.EULAAgreementsAccepted = !this.EULAAgreementsAccepted;
    } else {
      this.EULAAgreementsAccepted = true;
    }
  }

  public privacyAccepted(isFromCheckbox: boolean) {
    const agreementId = this.agreements.Agreements.find(
      (item) => item.DocumentName === 'Privacy'
    ).DocumentId;
    if (!this.signedDocuments.find((item) => item.DocumentId === agreementId)) {
      this.signedDocuments.push({
        DocumentId: agreementId,
        ResourceId: this.email,
      });
    }
    if (isFromCheckbox) {
      this.privacyAgreementsAccepted = !this.privacyAgreementsAccepted;
    } else {
      this.privacyAgreementsAccepted = true;
    }
  }

  public showPrivacy(agreement: inviteViews) {
    this.inviteView = agreement;
  }

  public completeInviteUser() {
    this.userService
      .activateInvitedUser({
        body: {
          signedDocuments: this.signedDocuments,
          activationCode: this.activationCode,
          password: this.passwordConfirm,
          email: this.email,
        },
      })
      .pipe(
        takeUntil(this.destroyed$),
        catchError(this.showErrorMessage.bind(this))
      )
      .subscribe(() => {
        this.router.navigate(['/auth']);
      });
  }

  public showErrorMessage(error: HttpErrorResponse) {
    const parsedError =
      typeof error?.error === 'string'
        ? JSON.parse(error?.error)
        : error?.error;
    if (JSON.parse(parsedError[0].formattedMessage).Message === 'User Exists') {
      this.errorMessage =
        'Invitation token not valid or expired, please contact your support.';
    } else {
      this.errorMessage = JSON.parse(parsedError[0].formattedMessage).Message;
    }
    this.showAuthFail$.next(true);
    return throwError(error);
  }

  public showHidePass(): void {
    if (this.passwordType === 'password') {
      this.passwordType = 'text';
    } else {
      this.passwordType = 'password';
    }
  }

  public get isValidPassword() {
    return this.passwordConfirm.match(PASSWORD_REGEX);
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
