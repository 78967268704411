import { NgModule } from '@angular/core';
import { DevicesLocationComponent } from './location.component';
import { ErrorModalModule } from '../../../common/components/modals/error-modal/error-modal.module';
import { NoDataAvailableModule } from '../../../common/components/no-data-available/no-data-available.module';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../../common/pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { AddDeviceByQrModalComponent } from '../../../common/components/modals/add-device-by-qr-modal/add-device-by-qr-modal.component';
import { AddBulkModule } from '../../../common/components/modals/add-bulk/add-bulk.module';
import { AddDeviceByQrModalModule } from '../../../common/components/modals/add-device-by-qr-modal/add-device-by-qr-modal.module';
import { DetouchDeviceLocationModule } from '../../../common/components/modals/detouch-device-location/detouch-device-location.module';
import { AddDeviceModalModule } from '../../../common/components/modals/add-device-modal/add-device-modal.module';

@NgModule({
  declarations: [DevicesLocationComponent],
  imports: [
    NoDataAvailableModule,
    ErrorModalModule,
    CommonModule,
    PipesModule,
    FormsModule,
    AddBulkModule,
    AddDeviceByQrModalModule,
    AddDeviceModalModule,
    DetouchDeviceLocationModule,
  ],
  exports: [DevicesLocationComponent],
})
export class DeviceLocationModule {}
