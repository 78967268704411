import { NgModule } from '@angular/core';
import { ErrorModalModule } from '../../../common/components/modals/error-modal/error-modal.module';
import { DimensionsComponent } from './dimensions.component';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../../common/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { DropDownListsModule } from '../../../common/components/dropdown-list/dropdown-list.module';
import { DatePickerModule } from '../../../common/components/date-picker/date-picker.module';
import { AutonumericModule } from '@angularfy/autonumeric';
import {SimpleDropdownModule} from '../../../common/components/dropdowns/simple-dropdown/simple-dropdown.module';

@NgModule({
  declarations: [DimensionsComponent],
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    MultiSelectModule,
    DropDownListsModule,
    ReactiveFormsModule,
    DatePickerModule,
    AutonumericModule,
    ErrorModalModule,
    SimpleDropdownModule
  ],
  exports: [DimensionsComponent],
})
export class DimensionsModule {}
