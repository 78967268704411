import { NgModule } from '@angular/core';
import { WaterConsumptionWidgetComponent } from './water-consumption-widget.component';
import { NoDataAvailableModule } from '../../no-data-available/no-data-available.module';
import { ChartModule } from '@syncfusion/ej2-angular-charts';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
  declarations: [WaterConsumptionWidgetComponent],
  imports: [NoDataAvailableModule, ChartModule, CommonModule, PipesModule],
  exports: [WaterConsumptionWidgetComponent],
})
export class WaterConsumptionWidgetModule {}
