import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {DataRow, ShTableColumn, ShTableVisibleRowState} from './model/sh-datatable.model';
import {ShDatatableUtils} from './utils/sh-datatable.utils';

export enum ShDatatableScrollStyle {
  None = 'None',
  Dark = 'Dark'
}

export class ShDatatableScrollEvent {
  public firstRowIndex : number;
  public lastRowIndex: number;
}

export enum ShDatatableRowColorMode {
  None = 'None',
  EvenOdd = 'EvenOdd'
}

@Component({
  selector: 'sh-datatable',
  templateUrl: './sh-datatable.component.html',
  styleUrls: ['./sh-datatable.component.scss']
})
export class ShDatatableComponent implements OnInit, OnChanges {

  @Input()
  public columns: ShTableColumn[] = [];

  @Input()
  public rows: DataRow[] = [];

  @Input()
  public headerHeight: number;

  @Input()
  public rowColorMode: ShDatatableRowColorMode;

  @Input()
  public scrollStyle: ShDatatableScrollStyle;

  @Output()
  public onGridScroll : EventEmitter<ShDatatableScrollEvent> = new EventEmitter<ShDatatableScrollEvent>();

  public visibleTableRows: ShTableVisibleRowState[] = [];

  @ViewChild('scrollableContent')
  public scrollableContent: ElementRef<HTMLElement>;

  @ViewChild('staticContent')
  public staticContent: ElementRef<HTMLElement>;

  public scrollableElementHeight: number = 0;

  constructor() { }

  public ngOnInit(): void {

  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.updateScrollableContentHeight();
  }

  public get tableWidth() : number {
    if(this.scrollableContent == null) {
      return 0;
    } else {
      return this.scrollableContent.nativeElement.getBoundingClientRect().width;
    }
  }

  public getRowIndex(row: DataRow) {
    return this.rows.indexOf(row);
  }

  public getRowBackground(row: DataRow) : string {
    if(row.rowColor != null) {
      return row.rowColor;
    }
    if(this.rowColorMode == ShDatatableRowColorMode.None) {
      return 'unset';
    }
    if(this.isEvenColorRow(row)) {
      return '#FFFFFF';
    } else if(this.isOddColorRow(row)){
      return '#f4f4f4';
    }
  }

  public isEvenColorRow(row: DataRow) : boolean {
    return this.getRowIndex(row) % 2 == 0;
  }

  public isOddColorRow(row: DataRow) : boolean{
    return this.getRowIndex(row) % 2 == 1;
  }

  public get isCellBordered() : boolean {
    return this.rowColorMode == ShDatatableRowColorMode.EvenOdd;
  }

  public refreshRowsHeight(scrollToBottom: boolean) {
    this.updateVisiblePart();
    if(scrollToBottom) {
      setTimeout(() => {
        this.scrollToBottom();
      });
    }
  }

  // tslint:disable-next-line:no-any
  public scrollEvent(evt:any) {
    console.info(evt);
  }

  public updateVisiblePart() {
    if(this.staticContent?.nativeElement == null) {
      return;
    }
    let scrollTop = this.staticContent.nativeElement.scrollTop;
    let scrollableAreaHeight = this.staticContent.nativeElement.getBoundingClientRect().height;
    let scrollBottom = scrollTop + scrollableAreaHeight;
    this.visibleTableRows = [];
    let currentRowTop : number = 0;
    let startIndex: number = 0;
    for (let row of this.rows) {
      let rowHeight = ShDatatableUtils.getRowHeight(row);
      let currentRowBottom = currentRowTop + rowHeight;
      if(this.isInRange(scrollTop, scrollBottom, currentRowTop) ||
         this.isInRange(scrollTop, scrollBottom, currentRowBottom) ||
         this.isInRange(currentRowTop, currentRowBottom, scrollTop) ||
         this.isInRange(currentRowTop, currentRowBottom, scrollBottom)) {
        this.visibleTableRows.push({row: row, top: currentRowTop, height: rowHeight});
        if(this.visibleTableRows.length == 1) {
          startIndex = this.rows.indexOf(row);
        }
      }
      currentRowTop += rowHeight;
    }
    let endIndex = startIndex + this.visibleTableRows.length - 1;
    this.onGridScroll.emit({firstRowIndex: startIndex, lastRowIndex: endIndex});
  }

  public scrollToBottom() {
    this.staticContent.nativeElement.scroll(0,  this.staticContent.nativeElement.scrollHeight);
  }

  public get isDarkScroll() : boolean {
    return this.scrollStyle == ShDatatableScrollStyle.Dark;
  }

  private updateScrollableContentHeight() {
    this.scrollableElementHeight = 0;
    for (let row of this.rows) {
      this.scrollableElementHeight +=  ShDatatableUtils.getRowHeight(row);
    }
    this.updateVisiblePart();
  }

  private isInRange(from: number, to: number, value: number) : boolean {
    return value >= from && value <= to;
  }
}
