import { NgModule } from '@angular/core';
import { AddBulkComponent } from './add-bulk.component';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../../pipes/pipes.module';
import {DndDirective} from '../../../directives/dnd.directive';
@NgModule({
  declarations: [AddBulkComponent, DndDirective],
  imports: [CommonModule, PipesModule],
  exports: [AddBulkComponent, DndDirective],
})
export class AddBulkModule {}
