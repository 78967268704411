import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../services/translations/translation.service';

@Pipe({
  name: 'naParameter',
  pure: false,
})
export class NaParameterPipe implements PipeTransform {
  constructor(private readonly translationService: TranslationService) {}

  public transform(
    value: any,
    naFormat?: string,
    format?: string,
    isNotLocaleString?: boolean
  ): string {
    value = isNotLocaleString
      ? value
      : value?.toLocaleString(
          this.translationService.transformLanguageEnum(
            this.translationService.selectedLanguage
          )
        );

    if (naFormat === ' chicks' && value == 1) {
      return value + ' chick';
    }

    if (naFormat === ' PA' && value == 0) {
      return 'NA';
    }

    if (value === 0 || value === '0') {
      return value;
    }

    return value !== null &&
      value !== undefined &&
      !Number.isNaN(value) &&
      value !== 'NaN'
      ? value + (naFormat ? naFormat : '')
      : format;
  }
}
