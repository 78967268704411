import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '../../pipes/pipes.module';
import {PaginationComponent} from './pagination.component';
import {DropDownListsModule} from '../dropdown-list/dropdown-list.module';

@NgModule({
    declarations: [PaginationComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PipesModule,
    DropDownListsModule
  ],
    exports: [PaginationComponent]
})
export class PaginationModule {

}
