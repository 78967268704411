import { NgModule } from '@angular/core';
import { Last5ReportsWidgetComponent } from './last5-reports-widget.component';
import { NoDataAvailableModule } from '../../no-data-available/no-data-available.module';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../../pipes/pipes.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [Last5ReportsWidgetComponent],
  imports: [NoDataAvailableModule, CommonModule, PipesModule, RouterModule],
  exports: [Last5ReportsWidgetComponent],
})
export class Last5ReportsWidgetModule {}
