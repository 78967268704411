import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild,} from '@angular/core';
import * as moment from 'moment';
import {HarvestFullResponse} from '../../../../../services/api/models/harvest-full-response';
import {CustomerSelectorsService} from '../../../../../services/customer-selectors/customer-selectors.service';
import {PermissionService} from '../../../../../services/user/permission-service';
import {IExtendedHarvestEventDto} from '../add-event-modal.component';
import {HarvestEvent} from '../../../../../services/api/models/harvest-event';
import {EventFunctionalModes} from '../../events.component';
import {TranslationService} from '../../../../../services/translations/translation.service';
import {EVENT_DATE_FORMAT} from '../../../../../utils/string-utils';
import {EpochStateModel, EpochStateModelMinMaxMode} from '../../../time-picker/epoch-state-model';
import {UtilsService} from '../../../../../services/utils/utils.service';
import {LanguageEnum} from '../../../../../services/api/models/language-enum';
import {POUND_TO_KG} from '../../../../pipes/units-convert.pipe';
import {take} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {TIME_10_AM} from '../../../start-new-crop/start-new-crop.component';
import {HarvestSex} from '../../../../../services/api/models/harvest-sex';
import {FacilityGroupNotificationSetting} from '../../../../../services/api/models/facility-group-notification-setting';

@Component({
  selector: 'thinning-event',
  templateUrl: 'thinning-event.component.html',
  styleUrls: ['thinning-event.component.scss'],
})
export class ThinningEventComponent implements OnChanges {
  @Input() public currentHarvest: HarvestFullResponse;
  @Input() public day0Date: Date;
  @Input() public event: IExtendedHarvestEventDto | HarvestEvent;
  @Input() public rowIndex: number;
  @Input() public possibleDeleteFirst: boolean = false;
  @Input() public cropType: HarvestSex = null;
  @Input() public functionalMode: EventFunctionalModes =
    EventFunctionalModes.addEvent;
  @Output() public removeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public dateChanged: EventEmitter<string> =
    new EventEmitter<string>();

  @Output() public numberOfBirdsChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() public birdAgeChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() public totalKgChanged: EventEmitter<number> =
    new EventEmitter<number>();

  @Input() public cropNumber: number;

  public selectedDate: Date = new Date();
  public readonly systemNowDate = new Date();
  public isTouched: boolean = false;

  @Input() public maxDate: Date;
  @Input() public startDate: Date;

  public birdAge: number = 0;

  public isDateChanged: boolean = true;

  @ViewChild('inputContainer')
  public inputContainer: ElementRef;

  public birdsNumber: number = 1;
  public totalKg: number = null;
  public backUpTotalKg: number = null;

  public EventFunctionalModes = EventFunctionalModes;

  private translationServiceSubscription: Subscription;

  public epochSelectedDate = new EpochStateModel(
    EpochStateModelMinMaxMode.Time,
    Date.parse(TIME_10_AM) / 1000
  );

  constructor(
    public customerSelectorsService: CustomerSelectorsService,
    public readonly permissionService: PermissionService,
    public readonly translationService: TranslationService,
    public utilsService: UtilsService
  ) {}

  public ngOnInit() {
    const defaultDateWithTimeString =
      moment(this.selectedDate)
        .format(EVENT_DATE_FORMAT)
        .split('')
        .slice(0, 11)
        .join('') + '10:00';
    this.translationServiceSubscription =
      this.translationService.languageChangeSubject.subscribe((value) => {
        if (this.translationService.selectedLanguage === LanguageEnum.EnUs) {
          this.totalKg = Number(this.totalKg / POUND_TO_KG);
        } else {
          this.totalKg = Number(this.totalKg * POUND_TO_KG);
        }
      });
    this.customerSelectorsService.currentFarm$
      .pipe(take(1))
      .subscribe((farm) => {
        this.selectedDate = moment(defaultDateWithTimeString).toDate();
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.setDefaultValues();
    if (changes.event || changes.day0Date || changes.maxDate || changes.startDate) {
      switch (this.functionalMode) {
        case EventFunctionalModes.editEvent: {
          this.setEditEventdate();
          break;
        }
        case EventFunctionalModes.addEvent: {
          console.log('EVENT TO ADD: ', this.event);
          this.setAddEventDate();
          break;
        }
        case EventFunctionalModes.wizard: {
          this.setWizardDate();
          break;
        }
      }
    }
  }

  public setDefaultValues() {
    if (!!this.event) {
      const totalKg = this.event.values.find(v => v.name === 'totalKg')?.value;
      this.birdsNumber = Number(this.event.values.find(v => v.name === 'thinnedValue')?.value) || 1;
      this.totalKg = totalKg ? Number(this.utilsService.toNormalValue(totalKg)) : null;

      if (this.translationService.selectedLanguage === LanguageEnum.EnUs && this.totalKg) {
        this.totalKg = Number(this.totalKg / POUND_TO_KG);
      }
    }
  }

  public setEditEventdate() {
    console.log('EVENT TO EDIT: ', this.event);
    this.selectedDate = moment(this.event.date).toDate();
    this.birdAge = this.event.birdAge;
    this.epochSelectedDate = new EpochStateModel(EpochStateModelMinMaxMode.Time,
      Date.parse(this.event.date) / 1000);
  }

  public setAddEventDate() {
    this.selectedDate = this.maxDate
      ? moment(this.maxDate)
        .set({
          hour: +moment(this.startDate).format('HH'),
          minute: +moment(this.startDate).format('mm'),
          seconds: +moment(this.startDate).format('ss'),
        })
        .toDate()
      : moment(this.systemNowDate)
        .set({
          hour: +moment(this.startDate).format('HH'),
          minute: +moment(this.startDate).format('mm'),
          seconds: +moment(this.startDate).format('ss'),
        })
        .toDate();

    this.birdAge = !this.event.birdAge ? moment(this.selectedDate).diff(this.startDate, 'days') : this.event.birdAge;
  }

  public changeBirdAge(value) {
    const maxBirdAge = this.maxDate ? moment(this.maxDate).diff(this.startDate, 'days') : 80;

    this.birdAge = Number(value) > maxBirdAge ? maxBirdAge : Number(value);
    this.isTouched = false;

    this.birdAgeChanged.emit(this.birdAge);
  }

  public setWizardDate() {
    this.startDate = this.day0Date;
    this.selectedDate = moment(this.day0Date)
      .add(this.event.birdAge, 'days')
      .toDate();
    this.birdAge = moment(this.selectedDate).diff(this.startDate, 'days');
    this.changeSelectedDate(this.selectedDate, true);
  }

  public changeSelectedDate(date: Date, isWizard?: boolean) {
    this.isTouched = true;
    this.dateChanged.emit(moment(date).format(EVENT_DATE_FORMAT));
    if (this.functionalMode !== EventFunctionalModes.wizard) {
      // this.birdAge = moment(date).diff(this.currentHarvest.startDate, 'days');
      this.birdAge = moment(date)
        .set({
          hour: +moment(this.startDate).format('HH'),
          minute: +moment(this.startDate).format('mm'),
          seconds: +moment(this.startDate).format('ss'),
        })
        .diff(this.startDate, 'days');
    } else {
      this.birdAge = moment(date)
        .set({
          hour: +moment(this.day0Date).format('HH'),
          minute: +moment(this.day0Date).format('mm'),
          seconds: +moment(this.startDate).format('ss'),
        })
        .diff(moment(this.day0Date).format(EVENT_DATE_FORMAT), 'days');
    }
  }

  public convertKilogramsToPounds() {
    if (this.totalKg === this.backUpTotalKg) {
      return this.totalKg;
    } else if (this.translationService.selectedLanguage === LanguageEnum.EnUs) {
      return Number(this.backUpTotalKg * POUND_TO_KG);
    } else {
      return this.backUpTotalKg;
    }
  }

  public onEpochChanged(epoch) {
    if (this.selectedDate) {
      this.epochSelectedDate = epoch;
      const timeString = this.convertEpochToStringTime(epoch);
      let dateString = moment(this.selectedDate).format(EVENT_DATE_FORMAT);
      let dateStringWithSelectedTime =
        dateString.split('').slice(0, 11).join('') + timeString;
      this.selectedDate = moment(dateStringWithSelectedTime).toDate();
      this.dateChanged.emit(moment(this.selectedDate).format(EVENT_DATE_FORMAT));
    } else {
      this.dateChanged.emit(null);
    }
  }

  public birdsChanged(value) {
    if (value < 1) {
      this.birdsNumber = 1;
    }
    this.numberOfBirdsChanged.emit(this.birdsNumber);
  }

  public kgChanged(value) {
    this.totalKg = this.utilsService.toNormalValue(value);

    if (this.translationService.selectedLanguage === LanguageEnum.EnUs) {
      this.totalKgChanged.emit(Number(this.totalKg * POUND_TO_KG))
    } else {
      this.totalKgChanged.emit(this.totalKg)
    }
  }

  public convertEpochToStringTime(epoch) {
    return moment.unix(epoch.epochTime).utc().format('HH:mm');
  }

  public get cropThinningNumber() {
    return `${this.cropNumber}/${this.thinningNumber}`;
  }

  public get thinningNumber() {
    return this.functionalMode === EventFunctionalModes.editEvent ? this.currentHarvest?.thinningDataList?.length : this.currentHarvest?.thinningDataList?.length + 1;
  }

  public get sexOfBirds() {
    return this.cropType || this.currentHarvest?.sex;
  }

  public setDateAndTime(value) {
    this.selectedDate = value;
    this.onEpochChanged(this.epochSelectedDate);
  }

  public onRemoveEvent() {
    this.removeEvent.emit();
  }

  public ngOnDestroy() {
    this.translationServiceSubscription?.unsubscribe();
  }
}
