<div class="multiselect" (click)="openDropdown()">
  <div class="selected-values">{{selectedValue?.displayValue}}</div>
  <div class="drop-icon" [ngClass]="isOpen ? 'fold-up': ''"></div>
  <div class="values-list" #list *ngIf="isOpen">
    <div class="item-full-text"
         [ngStyle]="{'top': cursorOnItemIndex * 40 - (cursorOnItemIndex>0 ? 40 : 0) + 'px'}"
         *ngIf="isShowFullText && textToShow.length >= numberOfSymbolsToShowTooltip">
      <span>{{textToShow}}</span>
    </div>
    <div *ngFor="let value of items; let i = index">
      <div class="item"
           *ngIf="showTooltips === true"
           [class.selected]="isChecked(value)"
           (mouseenter)="showFullText(value.displayValue, i)"
           (mouseleave)="hideFullText()"
           (click)="selectItem(value, $event)">{{value.displayValue}}
      </div>
      <div *ngIf="showTooltips === false"
        class="item"
        [class.selected]="isChecked(value)"
        (click)="selectItem(value, $event)"
      >
        {{ value.displayValue }}
      </div>
    </div>
  </div>
</div>

