/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CustomerHatchery } from '../models/customer-hatchery';
import { CustomerHatcheryCreateDto } from '../models/customer-hatchery-create-dto';
import { CustomerHatcheryDto } from '../models/customer-hatchery-dto';

@Injectable({
  providedIn: 'root',
})
export class CustomerHatcheryService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation addCustomerHatchery
   */
  static readonly AddCustomerHatcheryPath = '/api/customer/hatchery';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCustomerHatchery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addCustomerHatchery$Response(params?: {
    body?: CustomerHatcheryCreateDto
  }): Observable<StrictHttpResponse<CustomerHatchery>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerHatcheryService.AddCustomerHatcheryPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerHatchery>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addCustomerHatchery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addCustomerHatchery(params?: {
    body?: CustomerHatcheryCreateDto
  }): Observable<CustomerHatchery> {

    return this.addCustomerHatchery$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerHatchery>) => r.body as CustomerHatchery)
    );
  }

  /**
   * Path part for operation findByBusinessNumber_1
   */
  static readonly FindByBusinessNumber_1Path = '/api/customer/hatchery/businessNumber/{businessNumber}/zipCode/{zipCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByBusinessNumber_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByBusinessNumber_1$Response(params: {
    businessNumber: string;
    zipCode: string;
  }): Observable<StrictHttpResponse<Array<CustomerHatchery>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerHatcheryService.FindByBusinessNumber_1Path, 'get');
    if (params) {
      rb.path('businessNumber', params.businessNumber, {});
      rb.path('zipCode', params.zipCode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CustomerHatchery>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByBusinessNumber_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByBusinessNumber_1(params: {
    businessNumber: string;
    zipCode: string;
  }): Observable<Array<CustomerHatchery>> {

    return this.findByBusinessNumber_1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CustomerHatchery>>) => r.body as Array<CustomerHatchery>)
    );
  }

  /**
   * Path part for operation findById_1
   */
  static readonly FindById_1Path = '/api/customer/hatchery/customerHatchery/{customerHatcheryId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById_1$Response(params: {
    customerHatcheryId: number;
  }): Observable<StrictHttpResponse<CustomerHatchery>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerHatcheryService.FindById_1Path, 'get');
    if (params) {
      rb.path('customerHatcheryId', params.customerHatcheryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerHatchery>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById_1(params: {
    customerHatcheryId: number;
  }): Observable<CustomerHatchery> {

    return this.findById_1$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerHatchery>) => r.body as CustomerHatchery)
    );
  }

  /**
   * Path part for operation findByName_1
   */
  static readonly FindByName_1Path = '/api/customer/hatchery/name/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByName_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByName_1$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<Array<CustomerHatchery>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerHatcheryService.FindByName_1Path, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CustomerHatchery>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByName_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByName_1(params: {
    name: string;
  }): Observable<Array<CustomerHatchery>> {

    return this.findByName_1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CustomerHatchery>>) => r.body as Array<CustomerHatchery>)
    );
  }

  /**
   * Path part for operation updateCustomerHatchery
   */
  static readonly UpdateCustomerHatcheryPath = '/api/customer/hatchery/{customerHatcheryId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomerHatchery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerHatchery$Response(params: {
    customerHatcheryId: number;
    body?: CustomerHatcheryDto
  }): Observable<StrictHttpResponse<CustomerHatchery>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerHatcheryService.UpdateCustomerHatcheryPath, 'put');
    if (params) {
      rb.path('customerHatcheryId', params.customerHatcheryId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerHatchery>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCustomerHatchery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerHatchery(params: {
    customerHatcheryId: number;
    body?: CustomerHatcheryDto
  }): Observable<CustomerHatchery> {

    return this.updateCustomerHatchery$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerHatchery>) => r.body as CustomerHatchery)
    );
  }

  /**
   * Path part for operation deleteCustomerHatchery
   */
  static readonly DeleteCustomerHatcheryPath = '/api/customer/hatchery/{customerHatcheryId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerHatchery()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerHatchery$Response(params: {
    customerHatcheryId: number;
  }): Observable<StrictHttpResponse<CustomerHatchery>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerHatcheryService.DeleteCustomerHatcheryPath, 'delete');
    if (params) {
      rb.path('customerHatcheryId', params.customerHatcheryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerHatchery>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerHatchery$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerHatchery(params: {
    customerHatcheryId: number;
  }): Observable<CustomerHatchery> {

    return this.deleteCustomerHatchery$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerHatchery>) => r.body as CustomerHatchery)
    );
  }

  /**
   * Path part for operation setActiveCustomer_1
   */
  static readonly SetActiveCustomer_1Path = '/api/customer/hatchery/{customerHatcheryId}/setActive={isActive}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setActiveCustomer_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  setActiveCustomer_1$Response(params: {
    customerHatcheryId: number;
    isActive: boolean;
  }): Observable<StrictHttpResponse<CustomerHatchery>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerHatcheryService.SetActiveCustomer_1Path, 'put');
    if (params) {
      rb.path('customerHatcheryId', params.customerHatcheryId, {});
      rb.path('isActive', params.isActive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerHatchery>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setActiveCustomer_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setActiveCustomer_1(params: {
    customerHatcheryId: number;
    isActive: boolean;
  }): Observable<CustomerHatchery> {

    return this.setActiveCustomer_1$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerHatchery>) => r.body as CustomerHatchery)
    );
  }

  /**
   * Path part for operation findByCustomerHatcheryId
   */
  static readonly FindByCustomerHatcheryIdPath = '/api/customer/hatchery/{customerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByCustomerHatcheryId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByCustomerHatcheryId$Response(params: {
    customerId: number;
  }): Observable<StrictHttpResponse<Array<CustomerHatchery>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerHatcheryService.FindByCustomerHatcheryIdPath, 'get');
    if (params) {
      rb.path('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CustomerHatchery>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByCustomerHatcheryId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByCustomerHatcheryId(params: {
    customerId: number;
  }): Observable<Array<CustomerHatchery>> {

    return this.findByCustomerHatcheryId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CustomerHatchery>>) => r.body as Array<CustomerHatchery>)
    );
  }

  /**
   * Path part for operation findByCustomerHatcheryId_1
   */
  static readonly FindByCustomerHatcheryId_1Path = '/api/customer/hatchery/{customerId}/hatchery/{hatcheryId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByCustomerHatcheryId_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByCustomerHatcheryId_1$Response(params: {
    customerId: number;
    hatcheryId: number;
  }): Observable<StrictHttpResponse<CustomerHatchery>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerHatcheryService.FindByCustomerHatcheryId_1Path, 'get');
    if (params) {
      rb.path('customerId', params.customerId, {});
      rb.path('hatcheryId', params.hatcheryId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerHatchery>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByCustomerHatcheryId_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByCustomerHatcheryId_1(params: {
    customerId: number;
    hatcheryId: number;
  }): Observable<CustomerHatchery> {

    return this.findByCustomerHatcheryId_1$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerHatchery>) => r.body as CustomerHatchery)
    );
  }

}
