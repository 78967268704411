import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AlertService {
  public isAlertModeSubject = new BehaviorSubject(this.isAlertMode);

  set isAlertMode(value: boolean) {
    if (value === null) {
      localStorage.removeItem('isAlertMode');
    } else {
      localStorage.setItem('isAlertMode', String(value));
    }
    this.isAlertModeSubject.next(value);
  }

  get isAlertMode() {
    return JSON.parse(localStorage.getItem('isAlertMode'));
  }

  set notificationTitle(value: string) {
    if (value === null) {
      localStorage.removeItem('notificationTitle');
    } else {
      localStorage.setItem('notificationTitle', value);
    }
  }

  get notificationTitle() {
    return localStorage.getItem('notificationTitle');
  }

  set houseId(value: number) {
    if (value === null) {
      localStorage.removeItem('houseId');
    } else {
      localStorage.setItem('houseId', String(value));
    }
  }

  get houseId() {
    return JSON.parse(localStorage.getItem('houseId'));
  }
}
