import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../services/translations/translation.service';

@Pipe({
  name: 'translate',
  pure: false,
})
export class TranslatePipe implements PipeTransform {
  constructor(private readonly translationService: TranslationService) {}

  public transform(
    value: string,
    defaultValue?: string,
    ...params: string[]
  ): string {
    return this.translationService.translate(value, defaultValue, ...params);
  }
}
