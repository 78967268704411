import { NgModule } from '@angular/core';
import { StartNewCropComponent } from './start-new-crop.component';
import { PipesModule } from '../../pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePickerModule } from '../date-picker/date-picker.module';
import { EventsModule } from '../events/events.module';
import { AutoCompleteAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { DropDownListsModule } from '../dropdown-list/dropdown-list.module';
import { HatcheryVaccinesModule } from '../hatchery-vaccines/hatchery-vaccines.module';
import { AutonumericModule } from '@angularfy/autonumeric';
import { ConfirmationModalModule } from '../modals/confirmation-modal/confirmation.module';
import { SelectableZonesModule } from '../selectable-zones/selectable-zones.module';
import {SimpleDropdownModule} from '../dropdowns/simple-dropdown/simple-dropdown.module';
import {TimePickerModule} from '../time-picker/time-picker.module';

@NgModule({
  declarations: [StartNewCropComponent],
  imports: [
    PipesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DatePickerModule,
    EventsModule,
    AutoCompleteAllModule,
    DropDownListsModule,
    HatcheryVaccinesModule,
    AutonumericModule,
    ConfirmationModalModule,
    SelectableZonesModule,
    SimpleDropdownModule,
    TimePickerModule,
  ],
  exports: [StartNewCropComponent],
})
export class StartNewCropModule {}
