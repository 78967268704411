<div class="popup">
  <div class="popup__body">
    <div class="popup__body-content">
      <div class="display-flex details width-100">
        <div class="menus__menu width-50 right">
          <div class="menus__menu-label">
            {{ "PROFILE.DETAILS.First_Name" | translate }}
          </div>
          <div class="menus__menu-selector">
            <input
              type="text"
              maxlength="35"
              (change)="profileData.firstName = $event.target.value"
              [value]="profileData.firstName"
            />
          </div>
        </div>
        <div class="menus__menu width-50">
          <div class="menus__menu-label">
            {{ "PROFILE.DETAILS.Last_Name" | translate }}
          </div>
          <div class="menus__menu-selector">
            <input
              type="text"
              maxlength="35"
              (change)="profileData.lastName = $event.target.value"
              [value]="profileData.lastName"
            />
          </div>
        </div>
      </div>
      <div class="display-flex role-name width-100">
        <div class="menus__menu width-50 right">
          <div class="menus__menu-label">
            {{ "PROFILE.DETAILS.Role" | translate }}
          </div>
          <div class="menus__menu-selector role">
            <input
              type="text"
              maxlength="35"
              disabled="disabled"
              [value]="profileData.role"
            />
          </div>
        </div>
        <div class="menus__menu width-50">
          <div class="menus__menu-label">
            {{ "PROFILE.DETAILS.Phone" | translate }}
          </div>
          <div class="menus__menu-selector">
            <input
              type="text"
              maxlength="35"
              (change)="profileData.phone = $event.target.value"
              [value]="profileData.phone"
            />
          </div>
        </div>
      </div>
<!--      <div class="row border-bottom cursor-pointer" (click)="isChangePassword = !isChangePassword">-->
<!--        <div class="row__left">-->
<!--          <span class="row__left-text">{{'COMMON.PROFILE.Change_Password'| translate}}</span>-->
<!--        </div>-->
<!--        <div class="row__right">-->
<!--          <span class="svg expand-more-small" *ngIf="!isChangePassword"></span>-->
<!--          <span class="svg expand-less-small" *ngIf="isChangePassword"></span>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row" *ngIf="isChangePassword">-->
<!--        <div class="row__left">{{'COMMON.PROFILE.New_Password'| translate}}</div>-->
<!--        <div class="row__right">-->
<!--          <div class="row__right-input-wrapper">-->
<!--            <input type="password" (input)="passwordNew = $event.target.value">-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row" *ngIf="isChangePassword">-->
<!--        <div class="row__left">{{'COMMON.PROFILE.Confirm_Password'| translate}}</div>-->
<!--        <div class="row__right">-->
<!--          <div class="row__right-input-wrapper">-->
<!--            <input type="password" (input)="passwordConfirm = $event.target.value">-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row err" *ngIf="showSaveFail$ | async">-->
<!--        {{'COMMON.PROFILE.Not_saved'| translate}}-->
<!--      </div>-->
    </div>

    <div class="display-flex end width-100 farms-button-wrapper">
      <button
        class="blue-rounded-button"
        [disabled]="
              profileData.firstName?.length === 0 ||
              profileData.lastName?.length === 0 || profileData.phone?.length === 0 "
        (click)="updateMyUserDetails()">
        {{ "COMMON.BUTTONS.SAVE_CHANGES" | translate }}
      </button>
    </div>
  </div>
</div>
