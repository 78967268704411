<div class="dropdown">
  <div class="dropdown-icon" (click)="openDropdown()">
    <div class="lang">
      <img
        [src]="'assets/flags/' + translationService.transformLanguageEnum(selectedLanguage)  + '_flag.png'"
        width="30"
        height="20"
      />
      <span class="header-lang">{{ languageName }}</span>
    </div>
  </div>
  <div id="change-lang" class="dropdown-content" (mouseleave)="closeDropdown()">
    <div
      (click)="changeTranslation(LanguageEnum.EnUs)"
      class="lang"
      [ngClass]="{ 'lang-selected': isSelected('en-us') }"
    >
      <img src="assets/flags/en-us_flag.png" width="30" height="20"/>
      <span>EN|US</span>
    </div>
    <div
      (click)="changeTranslation(LanguageEnum.EnGb)"
      class="lang"
      [ngClass]="{ 'lang-selected': isSelected('en-gb') }"
    >
      <img src="assets/flags/en-gb_flag.png" width="30" height="20"/>
      <span>EN|UK</span>
    </div>
    <div
      (click)="changeTranslation(LanguageEnum.PtBr)"
      class="lang"
      [ngClass]="{ 'lang-selected': isSelected('pt-br') }"
    >
      <img src="assets/flags/pt-br_flag.png" width="30" height="20"/>
      <span>PT|BZ</span>
    </div>
    <div
      (click)="changeTranslation(LanguageEnum.FrFr)"
      class="lang"
      [ngClass]="{ 'lang-selected': isSelected('fr-fr') }"
    >
      <img src="assets/flags/fr-fr_flag.png" width="30" height="20"/>
      <span>FR|FR</span>
    </div>
  </div>
</div>
