import {NgModule} from '@angular/core';
import {FarmDropdownComponent} from './farm-dropdown.component';
import {CommonModule} from '@angular/common';
import {PipesModule} from '../../../pipes/pipes.module';
import {FormsModule} from '@angular/forms';
import { DropDownListsModule } from "../../dropdown-list/dropdown-list.module";

@NgModule({
  declarations: [FarmDropdownComponent],
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    DropDownListsModule,
  ],
  exports: [FarmDropdownComponent]
})
export class FarmDropdownModule {

}
