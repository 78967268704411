import {NgModule} from '@angular/core';
import {CopyrightComponent} from './copyright.component';
import {PipesModule} from '../../pipes/pipes.module';

@NgModule({
  declarations: [CopyrightComponent],
  imports: [PipesModule],
  exports: [CopyrightComponent],
})
export class CopyrightModule {}
