<div class="datatable-row"
     [ngStyle]="{'height':getRowHeight(row) + 'px',
                 'background':rowBackground}">
  <div class="datatable-cell"
       [ngClass]="{'datatable-cell-bordered': isCellBordered}"
       *ngFor="let column of row.rowColumns" [ngStyle]="{'flex':column.flexGrow,
                                                         'height':getRowHeight(row) +  'px',
                                                         'minWidth':column.minWidth + 'px'}">
    <ng-container *ngTemplateOutlet="column.cellTemplate;context:{column:column, row:row}"></ng-container>
  </div>
</div>
