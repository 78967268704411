<regular-dropdown-list
  class="{{class}}"
  [isTranslated]="isTranslated"
  [allowClear]="allowClear"
  [selectedValue]="{
    value: value,
    disabled: disabled,
    icon: null,
    displayValue: value
   }"
  (onItemSelected)="onChange($event?.value)"
  [disabled]="disabled"
  [items]="items"
>
  <ng-content></ng-content>
</regular-dropdown-list>

