import { NgModule } from '@angular/core';
import { MortalityTableComponent } from './mortality-table.component';
import { NoDataAvailableModule } from '../no-data-available/no-data-available.module';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { ConfirmationModalModule } from '../modals/confirmation-modal/confirmation.module';
import { AutonumericModule } from '@angularfy/autonumeric';
import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualForOf,
  CdkVirtualScrollViewport,
} from '@angular/cdk/scrolling';
import {DefaultMultiselectModule} from '../dropdowns/default-multiselect/default-multiselect.module';
@NgModule({
  declarations: [MortalityTableComponent],
  imports: [
    NoDataAvailableModule,
    CommonModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectModule,
    ConfirmationModalModule,
    AutonumericModule,
    DefaultMultiselectModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
  ],
  exports: [MortalityTableComponent],
})
export class MortalityTableModule {}
