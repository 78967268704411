import { Pipe } from '@angular/core';

@Pipe({
  name: 'truncateName',
  pure: false,
})
export class TruncateNamePipe {
  public transform(name: string) {
    if (!!+name === true) {
      return name;
    } else if (!!+name.slice(name.length - 2)) {
      return +name.slice(name.length - 2);
    } else if (!!+name.slice(2)) {
      return +name.slice(2);
    } else {
      return name.slice(0, 2) + '...';
    }
  }
}
