<popup-template [isOpen]="isOpen && !disabled && canDisplayPopupAccordingNumberOfItems" (close)="closeDropdown()">
  <ng-container header>
    <div class="input-area"
         [class.disabled]="disabled"
         [class.error]="isFieldError"
         [ngClass]="'color-scheme-' + colorScheme">
      <div *ngIf="selectedItem != null && selectedItem.icon != null" [ngClass]="'item-icon-' + selectedItem.icon"></div>
      <input class="search"
             [ngClass]="!selectedItem ? 'placeholder': ''"
             [attr.sh-id]="'dropdown_list_search' + (shIdPostfix ? '_' + shIdPostfix : '')"
             type="button"
             [disabled]="disabled"
             (keyup.enter)="onEnter($event)"
             (keydown.arrowdown)="onArrowDown()"
             (keydown.arrowup)="onArrowUp()"
             (click)="toggleOpen($event)"
             (mouseenter)="setTitle($event, selectedDisplayedValue)"
             [value]="selectedDisplayedValue"/>
      <span class='value-tooltip'>{{selectedDisplayedValue}}</span>
      <ng-container *ngIf="customIcon == DropdownCustomIcon.none">
        <div class="icon icon-delete" *ngIf="!disabled && selectedItem != null && allowClear" (click)="selectItem(null, null)"></div>
        <div *ngIf="presentDropdownFoldUpIcon"
             class="icon"
             [ngClass]="{'icon-drop-down': !isOpen, 'icon-fold-up': isOpen, 'disabled': disabled}"
             (click)="toggleOpen($event)">
        </div>
      </ng-container>
      <ng-container *ngIf="customIcon == DropdownCustomIcon.blueCalendar">
        <div class="icon icon-blue-calendar" [ngClass]="{disabled: disabled}" (click)="toggleOpen()"></div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container popup-content>
    <div class="dropdown-popup" [ngClass]="{'fold-up': isFoldDirectionUp}">
      <ul class="items" #itemsListElement [style.maxHeight.px]="itemsMaxHeight">
        <li (click)="selectItem(null, $event)" class="placeholder" *ngIf="showPlaceholder()">
          <span>{{placeholder}}</span>
        </li>
        <li *ngFor="let dropdownItem of items; index as i"
            [ngClass]="{ marked: isMarked(i), selected: selectedItem?.value === dropdownItem.value, customers: shIdPostfix === 'customers' }"
            [id]="'regular-item-' + i"
            (mouseenter)="markedItem = i"
            (mouseleave)="markedItem = null"
            (click)="selectItem(dropdownItem, $event)"
            [attr.disabled]="dropdownItem.disabled"
            [attr.sh-id]="'dropdown_list_item_' + i + (shIdPostfix ? '_' + shIdPostfix : '')"
            [attr.alt-sh-id]='"dropdown_list_item_" + dropdownItem.displayValue'>
          <div *ngIf="dropdownItem.icon != null" [ngClass]="'item-icon-' + dropdownItem.icon"></div>
          <ellipsisable-text
            class="item"
            [maxLines]="maxLinesForRow"
          >
            <!--            Disabled template functionality since it's buggy and never used-->
<!--             *ngIf="!dropdownItem.template"-->
            <ng-container *ngIf="isTranslated">{{dropdownItem.displayValue}}</ng-container>
            <ng-container *ngIf="!isTranslated">{{dropdownItem.displayValue | translate}}</ng-container>

<!--            <ng-template *ngIf="dropdownItem.template" [cdkPortalOutlet]="dropdownItem.template"></ng-template>-->
          </ellipsisable-text>
        </li>
      </ul>
    </div>
  </ng-container>
</popup-template>
<div style="display: none">
  <ng-content></ng-content>
</div>
