import {Injectable} from '@angular/core';
import {RectIntersectionResultType, UtilsService} from '../../../services/utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class ClientSessionRecorderDomService {

  constructor(private readonly utilsService: UtilsService) { }

  public getElementXpath(element : Element) : string {
    let originalElement = element;
    var xpath = '';
    while (element != null)
    {
      let id = '';
      if(element.parentElement != null) {
        let sameNameElements : Element[] = [];
        for (let childIndex = 0; childIndex < element.parentElement.children.length; childIndex++) {
          let otherChildElement = element.parentElement.children.item(childIndex);
          if(otherChildElement.tagName == element.tagName) {
            sameNameElements.push(otherChildElement)
          }
        }
        if(sameNameElements.length > 1) {
          id = '[' + (sameNameElements.indexOf(element) + 1) + ']';
        }
      }
      if(element.tagName.toLowerCase() == 'svg') {
        return this.getElementXpath(element.parentElement);
      } else {
        xpath = '/' + element.tagName.toLowerCase() + id + xpath;
      }
      element = element.parentElement;
    }
    let evaluatedDocument = document.evaluate(xpath, document, null, XPathResult.ANY_TYPE, null);
    let singleNodeValue = evaluatedDocument.iterateNext();
    if(singleNodeValue == originalElement) {
      return xpath;
    }
    return null;
  }

  public findElementShId(element : Element) : string {
    let shId = element.getAttribute('sh-id');
    if(shId != null) {
      return shId;
    }
    let originalElement = element;
    let originalElementRect = originalElement.getBoundingClientRect();
    while (element != null) {
      element = element.parentElement;
      if(element == null) {
        continue;
      }
      let elementBoundingRect = element.getBoundingClientRect();
      if(this.utilsService.domRectIntersect(elementBoundingRect, originalElementRect) != RectIntersectionResultType.NoIntersection) {
        shId = element.getAttribute('sh-id');
        if(shId != null) {
          return shId;
        }
      }
    }
    return null;
  }

  public findElementByXpath(xpath : string) : Element {
    let evaluatedDocument = document.evaluate(xpath, document, null, XPathResult.ANY_TYPE, null);
    let element = <Element>evaluatedDocument.iterateNext();
    if(element == null) {
      if(xpath.includes('/svg/')){
        let beforeSvgXpath = xpath.substring(0, xpath.indexOf('/svg/'));
        let afterSvgXpath = xpath.substring(beforeSvgXpath.length, xpath.length);
        element = <Element>document.evaluate(beforeSvgXpath, document, null, XPathResult.ANY_TYPE, null).iterateNext();
        element = this.traverseXpathInnerElement(afterSvgXpath, element);
      }
    }
    return element;
  }

  public findElementByShId(elementType:string, shId : string) : Element {
    let evaluatedDocument = document.evaluate("//" + elementType + "[@sh-id='" + shId + "']", document, null, XPathResult.ANY_TYPE, null);
    return <Element>evaluatedDocument.iterateNext();
  }

  private traverseXpathInnerElement(xpath : string, element : Element) : Element {
    let currentXpath = xpath;
    let returnElement = element;
    while (currentXpath.length > 0) {
      if(currentXpath.startsWith('/')) {
        currentXpath = currentXpath.substring(1, currentXpath.length);
      }
      let currentElementXpath = currentXpath;
      if(currentElementXpath.indexOf('/') != -1) {
        currentElementXpath = currentElementXpath.substring(0, currentElementXpath.indexOf('/'));
      }
      let xpathElementName = currentElementXpath;
      if(xpathElementName.indexOf('[') != -1) {
        xpathElementName = xpathElementName.substring(0, xpathElementName.indexOf('['));
      }
      let xpathElementIndex = 0;
      if(currentElementXpath.indexOf('[') != -1) {
        xpathElementIndex = parseInt(currentElementXpath.substring(currentElementXpath.indexOf('[') + 1,
                                                                   currentElementXpath.indexOf(']'))) - 1;
      }
      let foundElementIndex = 0;
      for (let childElementIndex = 0; childElementIndex < returnElement.children.length; childElementIndex++) {
        if(returnElement.children[childElementIndex].nodeName == xpathElementName) {
          if(foundElementIndex < xpathElementIndex) {
            foundElementIndex++;
          } else {
            returnElement = returnElement.children[childElementIndex];
            if(currentXpath.indexOf('/') != -1) {
              currentXpath = currentXpath.substring(currentXpath.indexOf('/'), currentXpath.length);
            } else {
              currentXpath = '';
            }
            break;
          }
        }
      }
    }
    return returnElement;
  }
}
