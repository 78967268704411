import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';

export interface IApplicationConfiguration {
  apiUrl: string;
  clientVersion: string;
  useMockHttp: boolean;
  pingInterval: number;
  cloud: boolean;
  googleAnalyticsProd: boolean;
  testingServerUrl: string;
  oktaIssuerUrl: string;
  oktaClientId: string;
  branchIoKey: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private static readonly ConfigurationStorageKey: string = 'AppConfig';

  private static readonly JsonConfigFilePath: string = `assets/config/config.json`;

  private _configuration: IApplicationConfiguration;

  public disableAnimations: boolean = false;

  constructor(private httpClient: HttpClient) {

  }

  public loadApplicationConfiguration(): Promise<void> {
    const configJsonFilePath: string = ConfigService.JsonConfigFilePath + '?q=' + Date.now(); // Add timestamp to prevent caching in browser
    return new Promise(resolve => {
      this.httpClient.get<IApplicationConfiguration>(configJsonFilePath).toPromise()
        .then((configuration: IApplicationConfiguration) => {
          if(this.updateConfiguration(configuration)) {
            this.disableAnimations = this.configuration.useMockHttp;
            resolve();
          }
        });
    });
  }

  public get configuration(): IApplicationConfiguration {
    return this._configuration;
  }

  public set configuration(configuration: IApplicationConfiguration) {
    this._configuration = configuration;
  }

  public get storedConfiguration(): IApplicationConfiguration {
    let configurationJson = window.localStorage.getItem(ConfigService.ConfigurationStorageKey);
    if(configurationJson == null) {
      return null;
    }
    return JSON.parse(configurationJson);
  }

  private updateConfiguration(newConfiguration: IApplicationConfiguration) : boolean {
    let newConfigurationJson = JSON.stringify(newConfiguration);
    let storedConfigurationJson = window.localStorage.getItem(ConfigService.ConfigurationStorageKey);
    if(newConfigurationJson != storedConfigurationJson) {
      window.localStorage.setItem(ConfigService.ConfigurationStorageKey, newConfigurationJson);
      window.location.reload();
      return false;
    } else {
      this._configuration = newConfiguration;
      return true;
    }
  }
}
