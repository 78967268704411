import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DropdownListItem } from '../../dropdown-list/dropdown-list-model';

@Component({
  selector: 'simple-dropdown',
  templateUrl: './simple-dropdown.component.html',
  styleUrls: ['./simple-dropdown.component.scss'],
})
export class SimpleDropdownComponent implements OnChanges {
  @Input() public items: DropdownListItem[] = [];
  @Input() public selectedValue: DropdownListItem;
  @Input()
  public allowClear: boolean = false;
  @Input()
  public disabled: boolean = false;
  @Input()
  public showTooltips: boolean = false;
  @Input()
  public numberOfSymbolsToShowTooltip: number = 20;

  @Output() public onItemSelected: EventEmitter<DropdownListItem> =
    new EventEmitter();
  public isOpen: boolean = false;
  public isShowFullText: boolean = false;
  public textToShow: string = '';
  public cursorOnItemIndex: number = 0;
  private isClickInside: boolean = false;

  @HostListener('click')
  public clickInside() {
    this.isClickInside = true;
  }

  @HostListener('document:click')
  public clickOutside() {
    if (!this.isClickInside) {
      this.scrollToStart();
      this.isOpen = false;
    }
    this.isClickInside = false;
  }

  @HostListener('window:scroll')
  public scroll() {
    if (!this.isClickInside) {
      this.scrollToStart();
      this.isOpen = false;
    }
    this.isClickInside = false;
  }

  @ViewChild('list')
  public list: ElementRef;

  public ngOnChanges(changes: SimpleChanges) {
    if (!!this.selectedValue) {
      this.selectedValue = this.items.find(
        (item) => item.value === this.selectedValue.value
      );
    } else {
      this.selectedValue = this.items[0];
    }
  }

  public selectItem(value, event: Event) {
    if (event != null) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.selectedValue = value;
    this.closeDropdown();
    this.onItemSelected.emit(value);
  }

  public isChecked(value) {
    return value.value === this.selectedValue.value;
  }

  public closeDropdown() {
    this.isOpen = false;
  }

  public openDropdown() {
    if (this.items?.length > 0 && !this.disabled) {
      this.isOpen = true;
    }
  }

  public scrollToStart() {
    if (!!this.list) {
      this.list.nativeElement.scrollTop = 0;
    }
  }

  public showFullText(displayValue: string, i) {
    this.cursorOnItemIndex = i;
    this.textToShow = displayValue;
    if (this.showTooltips) {
      this.isShowFullText = true;
    }
  }

  hideFullText() {
    this.isShowFullText = false;
  }
}
