import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomHousesMultiselectComponent } from './custom-houses-multiselect.component';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
  declarations: [CustomHousesMultiselectComponent],
  imports: [CommonModule, PipesModule],
  exports: [CustomHousesMultiselectComponent],
})
export class CustomHousesMultiselectModule {}
