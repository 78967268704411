import { Component, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { IProfile } from '../profile-modal.component';
import { catchError, takeUntil } from 'rxjs/operators';
import { MyService } from '../../../../services/api/services/my.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from '../../../../services/api/services/user.service';
import { PASSWORD_REGEX } from '../../../../layouts/invite-user/invite-user.component';

export enum Errors {
  cannotChangePasswordToCurrentPassword = 'CannotChangePasswordToCurrentPassword',
  invalidPassword = 'InvalidPassword',
  passwordHasBeenUsedTooRecently = 'PasswordHasBeenUsedTooRecently'
}

@Component({
  selector: 'reset-password',
  templateUrl: 'reset-password.component.html',
  styleUrls: ['reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnDestroy {
  @Input() public profileData: IProfile;

  public isChangePassword: boolean = false;

  public Errors = Errors;

  public passwordNew: string = '';
  public passwordConfirm: string = '';

  public passwordCurrent: string = '';

  public visiblePassword: string = '';

  private destroyed$ = new Subject();

  public errorCode = null;

  public showSaveFail$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(public userService: UserService, private router: Router) {}

  public updateMyUserDetails() {
    this.userService
      .changePassword({
        body: {
          newPassword: this.passwordNew,
          currentPassword: this.passwordCurrent,
        },
      })
      .pipe(
        takeUntil(this.destroyed$),
        catchError(this.showSaveError.bind(this))
      )
      .subscribe((res) => {
        this.router.navigate(['/auth']);
      });
  }

  public showSaveError(error: HttpErrorResponse) {
    const err = JSON.parse(error.error);
    this.errorCode = err.ErrorCode;
    this.showSaveFail$.next(true);
    return throwError(error);
  }

  public showHidePass(password: string) {
    if (password === this.visiblePassword) {
      this.visiblePassword = '';
    } else {
      this.visiblePassword = password;
    }
  }

  public get isValidPassword() {
    return this.passwordNew.match(PASSWORD_REGEX);
  }

  public changePassword(value) {
    this.passwordCurrent = value;
    this.showSaveFail$.next(false);
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
