import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CustomerSelectorsService } from '../../../services/customer-selectors/customer-selectors.service';

@Component({
  selector: 'selectable-zones',
  templateUrl: './selectable-zones.component.html',
  styleUrls: ['./selectable-zones.component.scss'],
})
export class SelectableZonesComponent implements OnInit {
  @Input()
  public zonesInUse: string;
  @Input()
  public isLastBroodingEvent: boolean = true;
  @Input()
  public isActive: boolean = true;

  @Output()
  public onSelectedZones = new EventEmitter<Array<number>>();

  public numberOfZonesInHouse: number = 0;
  public selectedZones: number[] = [];
  public reservedSelectedZones: number[] = [];
  public zonesFirstLine: Array<number> = [];
  public zonesSecondLine: Array<number> = [];
  public numberOfVerticalLines: number = 0;

  constructor(public customerSelectorsService: CustomerSelectorsService) {}

  public ngOnInit() {
    this.numberOfZonesInHouse =
      this.customerSelectorsService.currentHouse.zones.filter(
        (zone) => zone.zoneIndex > 0 && zone.zoneIndex < 190
      ).length;
    this.fillZonesLines(this.numberOfZonesInHouse);
  }

  public setDefaultSelectedZones() {
    if (!this.zonesInUse) {
      for (let i = 1; i <= this.numberOfZonesInHouse / 4; i++) {
        this.selectedZones.push(i);
        this.selectedZones.push(i + this.numberOfZonesInHouse / 2);
        this.onSelectedZones.emit(this.selectedZones);
      }
    } else {
      console.log('zonesInUse', this.zonesInUse);
      this.selectedZones = this.zonesInUse?.split(',').map((item) => +item);
      this.onSelectedZones.emit(this.selectedZones);
    }
  }

  private fillZonesLines(numberOfZones) {
    const countOfZonesPerLine: number = numberOfZones / 2;
    this.numberOfVerticalLines = countOfZonesPerLine;

    this.setDefaultSelectedZones();

    this.zonesFirstLine = [];
    this.zonesSecondLine = [];
    for (let i = 1; i <= countOfZonesPerLine; i++) {
      this.zonesFirstLine.push(i);
    }
    for (let i = countOfZonesPerLine + 1; i <= countOfZonesPerLine * 2; i++) {
      this.zonesSecondLine.push(i);
    }
  }

  public isSelected(zoneNumber) {
    return !!this.selectedZones?.find((item) => item === zoneNumber);
  }

  public isReservedZone(zoneNumber) {
    return !!this.reservedSelectedZones?.find((item) => item === zoneNumber);
  }

  public toggleSelection(zoneNumber) {
    if (this.isActive) {
      if (this.isLastBroodingEvent) {
        if (!this.selectedZones.includes(zoneNumber)) {
          if (zoneNumber > this.numberOfZonesInHouse / 2) {
            this.selectedZones.push(zoneNumber);
            this.selectedZones.push(zoneNumber - this.numberOfZonesInHouse / 2);
          } else {
            this.selectedZones.push(zoneNumber);
            this.selectedZones.push(zoneNumber + this.numberOfZonesInHouse / 2);
          }
        } else {
          if (this.selectedZones.length > 2) {
            this.selectedZones.splice(
              this.selectedZones.indexOf(zoneNumber),
              1
            );
            this.selectedZones.splice(
              this.selectedZones.indexOf(
                zoneNumber + this.numberOfZonesInHouse / 2
              ),
              1
            );
          }
        }
        this.selectedZones = this.selectedZones.filter((item) => {
          return !this.reservedSelectedZones.includes(item);
        });
        this.selectedZones = [...this.selectedZones];
        this.onSelectedZones.emit(this.selectedZones);
      }
    }
  }
}
