import {Component, Input, OnInit} from '@angular/core';
import {DataRow} from '../model/sh-datatable.model';
import {ShDatatableRowColorMode} from '../sh-datatable.component';
import {ShDatatableUtils} from '../utils/sh-datatable.utils';

@Component({
  selector: 'sh-datatable-row',
  templateUrl: './sh-datatable-row.component.html',
  styleUrls: ['./sh-datatable-row.component.scss']
})
export class ShDatatableRowComponent implements OnInit {

  @Input()
  public row : DataRow;

  @Input()
  public rowIndex : 0;

  @Input()
  public rowColorMode: ShDatatableRowColorMode;

  constructor() { }

  public ngOnInit(): void {

  }

  public get rowBackground() : string {
    if(this.row.rowColor != null) {
      return this.row.rowColor;
    }
    if(this.rowColorMode == ShDatatableRowColorMode.None) {
      return 'unset';
    }
    if(this.isEvenColorRow) {
      return '#FFFFFF';
    } else if(this.isOddColorRow){
      return '#f4f4f4';
    }
  }

  public get isEvenColorRow() : boolean {
    return this.rowIndex % 2 == 0;
  }

  public get isOddColorRow() : boolean{
    return this.rowIndex % 2 == 1;
  }

  public get isCellBordered() : boolean {
    return this.rowColorMode == ShDatatableRowColorMode.EvenOdd;
  }

  public getRowHeight(row: DataRow) : number {
    return  ShDatatableUtils.getRowHeight(row);
  }
}
