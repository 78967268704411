import {NgModule} from '@angular/core';
import {MyFarmComponent} from './my-farm.component';
import {PipesModule} from '../../../pipes/pipes.module';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';

@NgModule({
    declarations: [MyFarmComponent],
    imports: [
        PipesModule,
        RouterModule,
        CommonModule
    ],
    exports: [MyFarmComponent]
})
export class MyFarmModule {

}
