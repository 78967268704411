import {ElementRef, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PopupViewStateService {

  private registeredComponents : Map<string, ElementRef> = new Map<string, ElementRef>();

  // tslint:disable-next-line:no-any
  private outsideClickIgnoreElements: Set<any> = new Set<any>();

  constructor() { }

  public registerComponent(name: string, element: ElementRef) {
    this.registeredComponents.set(name, element);
  }

  public unregisterComponent(name: string) {
    this.registeredComponents.delete(name);
  }

  public getComponent(name:string) : ElementRef {
    if(this.registeredComponents.has(name)) {
      return this.registeredComponents.get(name);
    }
    return null;
  }

  // tslint:disable-next-line:no-any
  public registerOutsideClickIgnoreElement(element: any) {
    this.outsideClickIgnoreElements.add(element);
  }

  // tslint:disable-next-line:no-any
  public unregisterOutsideClickIgnoreElement(element: any) {
    if(this.outsideClickIgnoreElements.has(element)) {
      this.outsideClickIgnoreElements.delete(element);
    }
  }

  // tslint:disable-next-line:no-any
  public partOfOutsideClickIgnoreElement(element: any) : boolean {
    if(this.outsideClickIgnoreElements.has(element)) {
      return true;
    } else {
      return Array.from(this.outsideClickIgnoreElements.values()).some(value => value.contains(element));
    }
  }
}
