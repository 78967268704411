import { NgModule } from '@angular/core';
import { NoDataAvailableModule } from '../no-data-available/no-data-available.module';
import { CommonModule } from '@angular/common';
import { DataGridModule } from '../data-grid/data-grid.module';
import { PipesModule } from '../../pipes/pipes.module';
import { DatePickerComponent } from './date-picker.component';
import {
  OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [DatePickerComponent],
  imports: [
    NoDataAvailableModule,
    CommonModule,
    DataGridModule,
    PipesModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [DatePickerComponent],
  providers: [
    {
      provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
    },
  ],
})
export class DatePickerModule {}
