import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges, ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { HarvestFullResponse } from '../../../../../services/api/models/harvest-full-response';
import { CustomerSelectorsService } from '../../../../../services/customer-selectors/customer-selectors.service';
import { PermissionService } from '../../../../../services/user/permission-service';
import {
  EventType,
  IExtendedHarvestEventDto,
} from '../add-event-modal.component';
import { HarvestEvent } from '../../../../../services/api/models/harvest-event';
import { EventFunctionalModes } from '../../events.component';
import { TranslationService } from '../../../../../services/translations/translation.service';
import { UtilsService } from '../../../../../services/utils/utils.service';
import { BroodingService } from '../../../../../services/brooding/brooding.service';
import {
  EVENT_DATE_FORMAT,
  MIDNIGHT_DATE_FORMAT,
} from '../../../../../utils/string-utils';

@Component({
  selector: 'brooding-event',
  templateUrl: 'brooding-event.component.html',
  styleUrls: ['brooding-event.component.scss'],
})
export class BroodingEventComponent implements OnInit, OnChanges {
  @Input() public currentHarvest: HarvestFullResponse;
  @Input() public day0Date: Date;
  @Input() public event: IExtendedHarvestEventDto | HarvestEvent;
  @Input() public currentEvents: IExtendedHarvestEventDto[] = [];
  @Input() public addedEvents: IExtendedHarvestEventDto[] = [];
  @Input() public rowIndex: number;
  @Input() public initialDuration: number = 1;
  @Input() public possibleDeleteFirst: boolean = false;
  @Input() public functionalMode: EventFunctionalModes =
    EventFunctionalModes.addEvent;
  @Output() public removeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() public dateChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public birdAgeChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() public durationChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public selectedZonesChanged: EventEmitter<string> =
    new EventEmitter<string>();

  public nowDate: Date;
  public minDate: Date;
  public maxDate: Date;
  public selectedDate: Date;
  public savedInitialDuration = null;
  public isTouched: boolean = false;
  public birdAge: number = 0;
  public selectedDuration: number = 10;
  public populatedZonesInUse: string;

  public isDateChanged: boolean = true;

  @ViewChild('inputContainer')
  public inputContainer: ElementRef;

  constructor(
    public customerSelectorsService: CustomerSelectorsService,
    public readonly permissionService: PermissionService,
    public readonly translationService: TranslationService,
    public utilsService: UtilsService,
    public broodingService: BroodingService
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (this.savedInitialDuration === null) {
      this.savedInitialDuration = this.event.values[1].value;
    }
    if (changes.currentEvents) {
      this.setDefaultValues();
    }
    if (
      changes.event ||
      changes.currentEvents ||
      changes.day0Date ||
      changes.initialDuration ||
      changes.startDate ||
      changes.maxDate
    ) {
      switch (this.functionalMode) {
        case EventFunctionalModes.editEvent: {
          this.setEditEventdate();
          break;
        }
        case EventFunctionalModes.addEvent: {
          console.log('EVENT TO ADD: ', this.event);
          this.setAddEventDate();
          break;
        }
        case EventFunctionalModes.wizard: {
          this.setWizardDate();
          break;
        }
      }
    }
  }

  public setDefaultValues() {
    console.log('functionalMode', this.functionalMode);
    if (this.functionalMode === EventFunctionalModes.addEvent) {
      if (+this.broodingService.broodingDurationRemaining > 10) {
        this.selectedDuration = 10;
        this.onDurationChanged(this.selectedDuration);
      } else {
        this.selectedDuration = +this.broodingService.broodingDurationRemaining;
        this.onDurationChanged(this.selectedDuration);
      }
    } else {
      this.selectedDuration = +this.event.values[1].value;
      this.onDurationChanged(this.selectedDuration);
    }
    const prepopulatedZones = this.event.values[0].value;
    console.log('prepopulatedZones', prepopulatedZones);
    if (
      prepopulatedZones.length === 0 &&
      this.functionalMode !== EventFunctionalModes.addEvent
    ) {
      this.populatedZonesInUse = '';
    } else {
      if (this.functionalMode === EventFunctionalModes.addEvent) {
        this.rowIndex = this.currentEvents.length;
        this.populatedZonesInUse =
          this.findPreviousBroodingEvent().values[0].value;
      } else {
        this.populatedZonesInUse = prepopulatedZones;
      }
    }
  }

  public findAllSelectedBroodingZonesFromPreviousEvents() {
    const allBroodingEvents = this.currentEvents.filter((ev) => {
      return ev.type === EventType.Brooding;
    });
    return allBroodingEvents
      .map((item) => {
        return item.values[0].value;
      })
      .join();
  }

  public ngOnInit() {
    this.nowDate = moment().toDate();
  }

  public setEditEventdate() {
    console.log('currentEvents', this.currentEvents);

    const previousBroodingEvent: HarvestEvent =
      this.findPreviousBroodingEvent();
    const nextBroodingEvent: HarvestEvent = this.findNextBroodingEvent();

    this.minDate = !!previousBroodingEvent
      ? moment(previousBroodingEvent.date)
          .add(+previousBroodingEvent.values[1].value, 'days')
          .toDate()
      : moment(this.currentHarvest.startDate).toDate();

    this.birdAge = !this.event.birdAge ? moment(this.selectedDate).diff(this.currentHarvest.startDate, 'days') : this.event.birdAge;
    this.event.birdAge = !this.event.birdAge ? this.birdAge : this.event.birdAge;
    this.selectedDate = moment(this.event.date).toDate();
    this.maxDate = this.selectedDate;
    this.changeSelectedDate(this.selectedDate);
  }

  public changeBirdAge(value) {
    const maxBirdAge = this.maxDate ? moment(this.maxDate).diff(this.currentHarvest.startDate, 'days') : 80;

    this.birdAge = Number(value) > maxBirdAge ? maxBirdAge : Number(value);
    this.isTouched = false;

    this.birdAgeChanged.emit(this.birdAge);
  }

  public findPreviousBroodingEvent(index?: number) {
    let previousBroodingEvent: any;
    let itemIndexStart: number;
    !!index ? (itemIndexStart = index) : (itemIndexStart = this.rowIndex);
    while (itemIndexStart >= 0) {
      if (this.currentEvents[itemIndexStart - 1]?.type === 'Brooding') {
        previousBroodingEvent = this.currentEvents[itemIndexStart - 1];
        break;
      }
      itemIndexStart -= 1;
    }
    console.log('previousBroodingEvent', previousBroodingEvent);
    if (!!previousBroodingEvent) {
      return previousBroodingEvent;
    } else {
      return {
        date: moment(this.day0Date).format(MIDNIGHT_DATE_FORMAT),
        type: EventType.Brooding,
        birdAge: 0,
        values: [
          {
            name: 'zonesIdx',
            value: '',
          },
          {
            name: 'Duration',
            value: '10',
          },
        ],
      };
    }
  }

  public findNextBroodingEvent() {
    let nextBroodingEvent: any;
    let i = this.rowIndex + 1;
    while (i <= this.currentEvents.length - 1) {
      if (this.currentEvents[i]?.type === 'Brooding') {
        nextBroodingEvent = this.currentEvents[i];
        break;
      }
      i += 1;
    }
    console.log('nextBroodingEvent', nextBroodingEvent);
    return nextBroodingEvent;
  }

  public setAddEventDate() {
    let previousBroodingEvent: any;
    let i = this.currentEvents.length;
    while (i >= 0) {
      if (this.currentEvents[i - 1]?.type === 'Brooding') {
        previousBroodingEvent = this.currentEvents[i - 1];
        break;
      }
      i -= 1;
    }
    this.minDate = moment(previousBroodingEvent?.date)
      .add(+previousBroodingEvent.values[1].value, 'days')
      .toDate();

    this.selectedDate = this.currentHarvest?.closeDate
      ? moment(this.maxDate).toDate()
      : moment(this.nowDate).toDate();

    if (!!this.findPreviousBroodingEvent()) {
      this.selectedDate = moment(this.findPreviousBroodingEvent().date)
        .add(this.findPreviousBroodingEvent().values[1].value, 'days')
        .toDate();
    }

    this.maxDate = this.selectedDate;

    this.birdAge = moment(
      this.currentHarvest?.closeDate ? this.maxDate : this.nowDate
    ).diff(this.minDate, 'days');
    this.changeSelectedDate(this.selectedDate);
  }

  public setWizardDate() {
    const previousBroodingEvent: HarvestEvent = this.findPreviousBroodingEvent(
      this.rowIndex
    );

    if (!previousBroodingEvent) {
      this.minDate = moment(this.day0Date)
        .add(+this.initialDuration, 'days')
        .toDate();
      this.selectedDate = this.minDate;
      this.maxDate = this.selectedDate;
    } else {
      this.minDate = moment(previousBroodingEvent.date)
        .add(+previousBroodingEvent.values[1].value, 'days')
        .toDate();
      this.selectedDate = this.minDate;
      this.maxDate = this.selectedDate;
    }
    this.birdAge = moment(this.selectedDate).diff(this.minDate, 'days');
    this.changeSelectedDate(this.selectedDate);
  }

  public changeSelectedDate(date: Date) {
    this.isTouched = true;
    this.dateChanged.emit(moment(date).format(MIDNIGHT_DATE_FORMAT));
    if (this.functionalMode !== EventFunctionalModes.wizard) {
      this.birdAge = moment(date).diff(this.currentHarvest.startDate, 'days');
    } else {
      this.birdAge = moment(date)
        .set({
          hour: +moment(this.day0Date).format('HH'),
          minute: +moment(this.day0Date).format('mm'),
          seconds: +moment(this.day0Date).format('ss'),
        })
        .diff(moment(this.day0Date).format(EVENT_DATE_FORMAT), 'days');
    }
  }

  public isLastBroodingEvent() {
    if (this.functionalMode === EventFunctionalModes.wizard) {
      let lastBroodingEventIndex: number;
      let i = this.currentEvents.length;
      while (i >= 0) {
        if (this.currentEvents[i - 1].type === 'Brooding') {
          lastBroodingEventIndex = i - 1;
          break;
        }
        i -= 1;
      }
      return lastBroodingEventIndex === this.rowIndex;
    } else if (this.functionalMode === EventFunctionalModes.addEvent) {
      return true;
    } else if (this.functionalMode === EventFunctionalModes.editEvent) {
      return true;
    }
  }

  public onDurationChanged(duration: number) {
    this.selectedDuration = this.utilsService.toNormalValue(duration);
    this.durationChanged.emit(duration.toString());
  }

  public setValueIfLessThanOne(value: number) {
    if (value < 1) {
      this.onDurationChanged(1);
    } else if (
      this.functionalMode === EventFunctionalModes.wizard &&
      value > this.broodingService.getMaxBroodingDuration(value)
    ) {
      this.onDurationChanged(
        this.broodingService.getMaxBroodingDuration(value)
      );
    } else if (
      this.functionalMode !== EventFunctionalModes.wizard &&
      value > this.broodingService.getMaxBroodingDuration(value)
    ) {
      this.onDurationChanged(
        this.broodingService.getMaxBroodingDuration(
          value,
          EventFunctionalModes.addEvent
        )
      );
    } else {
      this.onDurationChanged(value);
    }
  }

  public setSelectedBroodingZones(ev: Array<number>) {
    this.selectedZonesChanged.emit(ev.join());
  }

  public onRemoveEvent() {
    this.removeEvent.emit();
  }
}
