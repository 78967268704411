import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomMultiselectComponent } from './custom-multiselect.component';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
  declarations: [CustomMultiselectComponent],
  imports: [CommonModule, PipesModule],
  exports: [CustomMultiselectComponent],
})
export class CustomMultiselectModule {}
