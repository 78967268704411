import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs';

export enum EpochStateModelMinMaxMode {
  Date = 'Date',
  Time = 'Time',
}

export class EpochStateModel {
  private static readonly MaxDayDuration: number = moment
    .duration('23:59:59')
    .asSeconds();

  public static readonly MinValue: number = 0;

  public readonly updated: Subject<any> = new Subject();

  private _errorMinMax: boolean;

  private _epochDate: number;

  private _epochTime: number;

  private _momentDate: moment.Moment;

  private _date: Date;

  private _min: number = EpochStateModel.MinValue;

  private _max: number;

  private _minDate: Date;

  private _maxDate: Date;

  constructor(public readonly mode: EpochStateModelMinMaxMode, epoch?: number) {
    if (epoch != null) {
      this.epoch = epoch;
    }
    if (this.mode == EpochStateModelMinMaxMode.Time) {
      this.max = EpochStateModel.MaxDayDuration;
    } else {
      this._minDate = moment.unix(this._min).startOf('day').toDate();
    }
  }

  public get errorMinMax(): boolean {
    return this._errorMinMax;
  }

  public get min(): number {
    return this._min;
  }

  public set min(value: number) {
    this._errorMinMax = false;
    if (value == null || value < EpochStateModel.MinValue) {
      this._min = EpochStateModel.MinValue;
    } else {
      this._min = value;
    }
    if (this.mode == EpochStateModelMinMaxMode.Date) {
      this._minDate = moment.unix(this._min).startOf('day').toDate();
    }
    this.validateDateTimeMinMax();
  }

  public get max(): number {
    return this._max;
  }

  public set max(value: number) {
    this._errorMinMax = false;
    this._max = value;
    if (this.mode == EpochStateModelMinMaxMode.Date) {
      this._maxDate = moment.unix(this._max).endOf('day').toDate();
    } else {
      if (this._max == null || this._max > EpochStateModel.MaxDayDuration) {
        this._max = EpochStateModel.MaxDayDuration;
      }
    }
    this.validateDateTimeMinMax();
  }

  public get minDate(): Date {
    return this._minDate;
  }

  public get maxDate(): Date {
    return this._maxDate;
  }

  public get epoch(): number {
    if (this._epochDate != null && this._epochTime != null) {
      return this._epochDate + this._epochTime;
    } else {
      return null;
    }
  }

  public get hour(): number {
    return moment.unix(this._epochTime).utc().hour();
  }

  public get minute(): number {
    return moment.unix(this._epochTime).utc().minute();
  }

  public set epoch(epoch: number) {
    if (epoch != null && !this._errorMinMax) {
      if (this.epoch == epoch) {
        return;
      }
      this.epochDate = this.trimValueToMinMax(epoch);
      this.epochTime = this.trimValueToMinMax(epoch);
    } else {
      this.epochDate = null;
      this.epochTime = null;
    }
    this.updated.next(this.epoch);
  }

  public get epochDate(): number {
    return this._epochDate;
  }

  public set epochDate(epochDate: number) {
    if (epochDate != null && !this._errorMinMax) {
      let normalizedEpochDate = moment.unix(epochDate).startOf('day').unix();
      if (this._epochDate == normalizedEpochDate) {
        return;
      }
      this._epochDate = normalizedEpochDate;
      if (this.mode == EpochStateModelMinMaxMode.Date) {
        if (this.epoch != null) {
          this.epoch = this.trimValueToMinMax(this.epoch);
        } else {
          this.epochDate = this.trimValueToMinMax(this.epochDate);
        }
      }
      this._momentDate = moment.unix(this._epochDate);
      this._date = this._momentDate.toDate();
    } else {
      this._epochDate = null;
      this._date = null;
      this._momentDate = null;
    }
    this.updated.next(this.epoch);
  }

  public get epochTime(): number {
    return this._epochTime;
  }

  public set epochTime(epochTime: number) {
    if (epochTime != null && !this._errorMinMax) {
      let normalizedEpochTime = moment.unix(epochTime).startOf('day').unix();
      if (normalizedEpochTime > moment.duration(1, 'days').asSeconds()) {
        normalizedEpochTime = epochTime - normalizedEpochTime;
      } else {
        normalizedEpochTime = epochTime;
      }
      if (this._epochTime == normalizedEpochTime) {
        return;
      }
      this._epochTime = normalizedEpochTime;
      if (this.mode == EpochStateModelMinMaxMode.Time) {
        this._epochTime = this.trimValueToMinMax(this._epochTime);
      } else if (this.epoch != null) {
        this.epoch = this.trimValueToMinMax(this.epoch);
      }
    } else {
      this._epochTime = null;
    }
    this.updated.next(this.epoch);
  }

  public set epochTimeNoUtcOffset(epochTime: number) {
    this.epochTime = epochTime - this.utcOffsetSeconds;
  }

  public get momentDate(): moment.Moment {
    if (this._momentDate) {
      return this._momentDate.clone();
    }
    return null;
  }

  public get date(): Date {
    if (this._date) {
      return this._date;
    }
    return null;
  }

  public set date(date: Date) {
    if (date != null) {
      this.epochDate = moment(date).unix();
    } else {
      this.epochDate = null;
    }
    this.updated.next(this.epoch);
  }

  public addHour() {
    if (this.epochTime == null) {
      this.epochTime = 0;
      return;
    }
    let timeDuration = moment.duration(this.epochTime, 'seconds');
    if (timeDuration.asHours() >= 23) {
      this.epochTime = timeDuration.add(-23, 'hours').asSeconds();
    } else {
      this.epochTime = timeDuration.add(1, 'hours').asSeconds();
    }
  }

  public subtractHour(subtractHoursToZero: boolean) {
    if (this.epochTime == null) {
      this.epochTime = 0;
      return;
    }
    let timeDuration = moment.duration(this.epochTime, 'seconds');
    if (subtractHoursToZero) {
      if (timeDuration.asHours() < 1) {
        this.epochTime = timeDuration.add(23, 'hours').asSeconds();
      } else {
        this.epochTime = timeDuration.add(-1, 'hours').asSeconds();
      }
    } else {
      if (timeDuration.asHours() <= 1) {
        this.epochTime = timeDuration.add(23, 'hours').asSeconds();
      } else {
        this.epochTime = timeDuration.add(-1, 'hours').asSeconds();
      }
    }
  }

  public addMinute() {
    if (this.epochTime == null) {
      this.epochTime = 0;
      return;
    }
    let timeDuration = moment.duration(this.epochTime, 'seconds');
    if (timeDuration.minutes() == 59) {
      this.epochTime = timeDuration.add(-59, 'minutes').asSeconds();
    } else {
      this.epochTime = timeDuration.add(1, 'minutes').asSeconds();
    }
  }

  public subtractMinute() {
    if (this.epochTime == null) {
      this.epochTime = 0;
      return;
    }
    let timeDuration = moment.duration(this.epochTime, 'seconds');
    if (timeDuration.minutes() == 0) {
      this.epochTime = timeDuration.add(59, 'minutes').asSeconds();
    } else {
      this.epochTime = timeDuration.add(-1, 'minutes').asSeconds();
    }
  }

  public get utcOffsetSeconds(): number {
    return moment().utcOffset() * 60;
  }

  public clone(): EpochStateModel {
    return _.cloneDeep(this);
  }

  private validateDateTimeMinMax() {
    if (this.min != null && this.max != null && this.min > this.max) {
      this.epoch = null;
      this._errorMinMax = true;
      return;
    }
    if (this.mode == EpochStateModelMinMaxMode.Time) {
      this.epochTime = this.trimValueToMinMax(this.epochTime);
    } else if (this.mode == EpochStateModelMinMaxMode.Date) {
      if (this.epoch != null) {
        this.epoch = this.trimValueToMinMax(this.epoch);
      } else if (this.epochDate != null) {
        this.epochDate = this.trimValueToMinMax(this.epochDate);
      }
    }
  }

  private trimValueToMinMax(value: number): number {
    if (value != null) {
      if (this.min != null && value < this.min) {
        return this.min;
      }
      if (this.max != null && value > this.max) {
        return this.max;
      }
    }
    return value;
  }
}
