import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MortalityModalComponent } from './mortality-modal.component';
import { PipesModule } from '../../../../pipes/pipes.module';
import { AutonumericModule } from '@angularfy/autonumeric';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [MortalityModalComponent],
  imports: [
    RouterModule,
    CommonModule,
    PipesModule,
    AutonumericModule,
    FormsModule,
  ],
  exports: [MortalityModalComponent],
})
export class MortalityModalModule {}
