import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { inviteViews } from '../../../layouts/invite-user/invite-user.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfigService } from '../../../services/config/config.service';
import { HttpClient } from '@angular/common/http';

export enum privacyModes {
  info = 'info',
  invite = 'invite',
}

@Component({
  selector: 'privacy-component',
  templateUrl: 'privacy.component.html',
  styleUrls: ['privacy.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PrivacyComponent implements OnChanges {
  @Input() public privacyAgreementText: string;
  @Input() public EULAAgreementText: string;
  @Input() public inviteView: inviteViews = inviteViews.eula;
  @Input() public mode: privacyModes = privacyModes.info;
  @Output() public onGoToLogin: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onAcceptEULA: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onAcceptPrivacy: EventEmitter<void> =
    new EventEmitter<void>();

  public privacyModesEnum: typeof privacyModes = privacyModes;
  public isShowPrivacy: boolean = false;
  public isShowEULA: boolean = false;
  public isReadAllEULA: boolean = false;
  public isReadAllPRIVACY: boolean = false;
  public srcPrivacy: SafeResourceUrl = '';
  public srcEULA: SafeResourceUrl = '';

  constructor(
    private domSanitizer: DomSanitizer,
    private configService: ConfigService,
    private httpClient: HttpClient
  ) {}

  public ngOnChanges() {
    if (this.privacyAgreementText) {
      this.srcPrivacy = this.domSanitizer.bypassSecurityTrustHtml(
        this.privacyAgreementText
      );
    }
    if (this.EULAAgreementText) {
      this.srcEULA = this.domSanitizer.bypassSecurityTrustHtml(
        this.EULAAgreementText
      );
    }
    // SAVE it need to get data from local file system
    // if (this.privacyAgreementText) {
    //   this.httpClient
    //     .get(`assets/agreement/SenseHub Poultry Privacy Policy (2023)V1.htm`, {
    //       responseType: 'text',
    //     })
    //     .subscribe((res) => {
    //       this.srcPrivacy = this.domSanitizer.bypassSecurityTrustHtml(res);
    //     });
    // }
    // if (this.EULAAgreementText) {
    //   this.httpClient
    //     .get(`assets/agreement/SenseHub Poultry EULA (Template) (2023)V1.htm`, {
    //       responseType: 'text',
    //     })
    //     .subscribe(
    //       (res1) =>
    //         (this.srcEULA = this.domSanitizer.bypassSecurityTrustHtml(res1))
    //     );
    // }
    {
      if (this.inviteView === inviteViews.eula) {
        this.isShowEULA = true;
        this.isShowPrivacy = false;
      } else {
        this.isShowEULA = false;
        this.isShowPrivacy = true;
      }
    }
  }

  public isReadEverything(event, documentType) {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const offsetHeight = event.target.offsetHeight;
    if (scrollHeight - offsetHeight - 1 <= scrollTop) {
      if (documentType === 'EULA') {
        this.isReadAllEULA = true;
      } else if (documentType === 'PRIVACY') {
        this.isReadAllPRIVACY = true;
      }
    }
  }

  goToLogin() {
    this.onGoToLogin.emit();
  }

  acceptEULA() {
    this.isShowEULA = false;
    this.onAcceptEULA.emit();
    this.onGoToLogin.emit();
  }

  acceptPrivacy() {
    this.isShowPrivacy = false;
    this.onAcceptPrivacy.emit();
    this.onGoToLogin.emit();
  }

  toPrivacy() {
    this.isShowPrivacy = true;
    this.isShowEULA = false;
  }

  toEULA() {
    this.isShowPrivacy = false;
    this.isShowEULA = true;
  }
}
