import {NgModule} from '@angular/core';
import {DeliveryNotesUpdateComponent} from './delivery-notes-update.component';
import {PipesModule} from '../../../../pipes/pipes.module';
import {CommonModule} from '@angular/common';
import {NoDataAvailableModule} from '../../../no-data-available/no-data-available.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DatePickerModule} from '../../../date-picker/date-picker.module';
import { DropDownListsModule } from '../../../dropdown-list/dropdown-list.module';
import { AutonumericModule } from '@angularfy/autonumeric';
import {HatcheryVaccinesModule} from '../../../hatchery-vaccines/hatchery-vaccines.module';
import {AutoCompleteModule} from '@syncfusion/ej2-angular-dropdowns';
import {SelectableZonesModule} from '../../../selectable-zones/selectable-zones.module';
import {TimePickerModule} from '../../../time-picker/time-picker.module';
import {ErrorModalModule} from '../../error-modal/error-modal.module';
@NgModule({
  declarations: [DeliveryNotesUpdateComponent],
  imports: [
    PipesModule,
    CommonModule,
    NoDataAvailableModule,
    FormsModule,
    DatePickerModule,
    ReactiveFormsModule,
    DropDownListsModule,
    AutonumericModule,
    HatcheryVaccinesModule,
    AutonumericModule,
    AutoCompleteModule,
    SelectableZonesModule,
    TimePickerModule,
    ErrorModalModule,
  ],
  exports: [DeliveryNotesUpdateComponent],
})
export class DeliveryNotesUpdateModule {}
