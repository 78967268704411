import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ErrorsService } from '../../../../services/errors-service/errors.service';
import { INotificationContent } from '../notification/notification.component';

export interface IErrorContent {
  errorName: string;
  formattedMessage: string;
  message: string;
  params: string[];
}

@Component({
  selector: 'error-modal',
  templateUrl: 'error-modal.component.html',
  styleUrls: ['error-modal.component.scss'],
})
export class ErrorModalComponent implements OnInit {
  @Output() public onClose: EventEmitter<void> = new EventEmitter<void>();
  public errorContentList: IErrorContent[] = [];
  public content = {
    color: '#ffbbbb',
    fontColor: '#ad120d',
    IconName: 'error',
  };

  constructor(private errorsService: ErrorsService) {}

  ngOnInit() {
    this.errorContentList = this.errorsService.errorContentList;
    setTimeout(() => this.onClose.emit(), 3000);
  }

  public closeModal() {
    this.onClose.emit();
  }
}
