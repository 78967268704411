/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseServiceUrl } from '../base-service-url';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ClimateMapPageResponse } from '../models/climate-map-page-response';
import { DeviceEventPageDto } from '../models/device-event-page-dto';
import { InsightData } from '../models/insight-data';
import { IoTEventResponse } from '../models/io-t-event-response';
import { LocalDateTime } from '../models/local-date-time';
import { SensorPageDto } from '../models/sensor-page-dto';

@Injectable({
  providedIn: 'root',
})
export class TelemetryService extends BaseServiceUrl {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAnalysisPageData
   */
  static readonly GetAnalysisPageDataPath = '/api/telemetry/analysis';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAnalysisPageData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnalysisPageData$Response(params: {
    fillMissingDates?: boolean;
    fromDate?: LocalDateTime;
    groupIdx?: Array<number>;
    harvestId: number;
    targetsDays?: number;
    targetsFromDays?: number;
  }): Observable<StrictHttpResponse<SensorPageDto>> {

    const rb = new RequestBuilder(this.rootUrl, TelemetryService.GetAnalysisPageDataPath, 'get');
    if (params) {
      rb.query('fillMissingDates', params.fillMissingDates, {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('groupIdx', params.groupIdx, {});
      rb.query('harvestId', params.harvestId, {});
      rb.query('targetsDays', params.targetsDays, {});
      rb.query('targetsFromDays', params.targetsFromDays, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SensorPageDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAnalysisPageData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnalysisPageData(params: {
    fillMissingDates?: boolean;
    fromDate?: LocalDateTime;
    groupIdx?: Array<number>;
    harvestId: number;
    targetsDays?: number;
    targetsFromDays?: number;
  }): Observable<SensorPageDto> {

    return this.getAnalysisPageData$Response(params).pipe(
      map((r: StrictHttpResponse<SensorPageDto>) => r.body as SensorPageDto)
    );
  }

  /**
   * Path part for operation getAverageTelemetryDataByHouse
   */
  static readonly GetAverageTelemetryDataByHousePath = '/api/telemetry/averages/facilityId/{facilityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAverageTelemetryDataByHouse()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAverageTelemetryDataByHouse$Response(params: {
    facilityId: number;
    date?: string;
    groupIdx?: Array<number>;
  }): Observable<StrictHttpResponse<InsightData>> {

    const rb = new RequestBuilder(this.rootUrl, TelemetryService.GetAverageTelemetryDataByHousePath, 'get');
    if (params) {
      rb.path('facilityId', params.facilityId, {});
      rb.query('date', params.date, {});
      rb.query('groupIdx', params.groupIdx, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InsightData>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAverageTelemetryDataByHouse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAverageTelemetryDataByHouse(params: {
    facilityId: number;
    date?: string;
    groupIdx?: Array<number>;
  }): Observable<InsightData> {

    return this.getAverageTelemetryDataByHouse$Response(params).pipe(
      map((r: StrictHttpResponse<InsightData>) => r.body as InsightData)
    );
  }

  /**
   * Path part for operation getClimateMapPageData
   */
  static readonly GetClimateMapPageDataPath = '/api/telemetry/climate-map';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getClimateMapPageData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClimateMapPageData$Response(params?: {
    facilityId?: number;
    fillMissingData?: boolean;
    groupIdx?: Array<number>;
    startDate?: LocalDateTime;
  }): Observable<StrictHttpResponse<ClimateMapPageResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TelemetryService.GetClimateMapPageDataPath, 'get');
    if (params) {
      rb.query('facilityId', params.facilityId, {});
      rb.query('fillMissingData', params.fillMissingData, {});
      rb.query('groupIdx', params.groupIdx, {});
      rb.query('startDate', params.startDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ClimateMapPageResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getClimateMapPageData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClimateMapPageData(params?: {
    facilityId?: number;
    fillMissingData?: boolean;
    groupIdx?: Array<number>;
    startDate?: LocalDateTime;
  }): Observable<ClimateMapPageResponse> {

    return this.getClimateMapPageData$Response(params).pipe(
      map((r: StrictHttpResponse<ClimateMapPageResponse>) => r.body as ClimateMapPageResponse)
    );
  }

  /**
   * Path part for operation getDeviceIotEvents
   */
  static readonly GetDeviceIotEventsPath = '/api/telemetry/device/events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeviceIotEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceIotEvents$Response(params?: {
    endDate?: string;
    numberOfEvents?: number;
    sourceDeviceId?: number;
    startDate?: string;
  }): Observable<StrictHttpResponse<DeviceEventPageDto>> {

    const rb = new RequestBuilder(this.rootUrl, TelemetryService.GetDeviceIotEventsPath, 'get');
    if (params) {
      rb.query('endDate', params.endDate, {});
      rb.query('numberOfEvents', params.numberOfEvents, {});
      rb.query('sourceDeviceId', params.sourceDeviceId, {});
      rb.query('startDate', params.startDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeviceEventPageDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDeviceIotEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceIotEvents(params?: {
    endDate?: string;
    numberOfEvents?: number;
    sourceDeviceId?: number;
    startDate?: string;
  }): Observable<DeviceEventPageDto> {

    return this.getDeviceIotEvents$Response(params).pipe(
      map((r: StrictHttpResponse<DeviceEventPageDto>) => r.body as DeviceEventPageDto)
    );
  }

  /**
   * Path part for operation getFacilityIotEvents
   */
  static readonly GetFacilityIotEventsPath = '/api/telemetry/raw/events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFacilityIotEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFacilityIotEvents$Response(params?: {
    byCustomerTz?: boolean;
    endDate?: string;
    facilityGroupId?: number;
    facilityId?: number;
    numberOfEvents?: number;
    sourceDeviceId?: number;
    startDate?: string;
  }): Observable<StrictHttpResponse<Array<IoTEventResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, TelemetryService.GetFacilityIotEventsPath, 'get');
    if (params) {
      rb.query('byCustomerTz', params.byCustomerTz, {});
      rb.query('endDate', params.endDate, {});
      rb.query('facilityGroupId', params.facilityGroupId, {});
      rb.query('facilityId', params.facilityId, {});
      rb.query('numberOfEvents', params.numberOfEvents, {});
      rb.query('sourceDeviceId', params.sourceDeviceId, {});
      rb.query('startDate', params.startDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IoTEventResponse>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFacilityIotEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFacilityIotEvents(params?: {
    byCustomerTz?: boolean;
    endDate?: string;
    facilityGroupId?: number;
    facilityId?: number;
    numberOfEvents?: number;
    sourceDeviceId?: number;
    startDate?: string;
  }): Observable<Array<IoTEventResponse>> {

    return this.getFacilityIotEvents$Response(params).pipe(
      map((r: StrictHttpResponse<Array<IoTEventResponse>>) => r.body as Array<IoTEventResponse>)
    );
  }

  /**
   * Path part for operation getSensorPageData
   */
  static readonly GetSensorPageDataPath = '/api/telemetry/sensor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSensorPageData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSensorPageData$Response(params: {
    fillMissingDates?: boolean;
    fromDate?: LocalDateTime;
    groupIdx?: Array<number>;
    harvestId: number;
    limitMaxDayByPrediction?: boolean;
    targetsDays?: number;
    targetsFromDays?: number;
  }): Observable<StrictHttpResponse<SensorPageDto>> {

    const rb = new RequestBuilder(this.rootUrl, TelemetryService.GetSensorPageDataPath, 'get');
    if (params) {
      rb.query('fillMissingDates', params.fillMissingDates, {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('groupIdx', params.groupIdx, {});
      rb.query('harvestId', params.harvestId, {});
      rb.query('limitMaxDayByPrediction', params.limitMaxDayByPrediction, {});
      rb.query('targetsDays', params.targetsDays, {});
      rb.query('targetsFromDays', params.targetsFromDays, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SensorPageDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSensorPageData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSensorPageData(params: {
    fillMissingDates?: boolean;
    fromDate?: LocalDateTime;
    groupIdx?: Array<number>;
    harvestId: number;
    limitMaxDayByPrediction?: boolean;
    targetsDays?: number;
    targetsFromDays?: number;
  }): Observable<SensorPageDto> {

    return this.getSensorPageData$Response(params).pipe(
      map((r: StrictHttpResponse<SensorPageDto>) => r.body as SensorPageDto)
    );
  }

}
