import { NgModule } from '@angular/core';
import { PipesModule } from '../../../../common/pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { EditNotificationSettingsModalComponent } from './edit-notification-settings-modal.component';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { FormsModule } from '@angular/forms';
import { TimePickerModule } from '../../time-picker/time-picker.module';
import { NotificationModule } from '../notification/notification.module';
import { ConfirmationModalModule } from '../confirmation-modal/confirmation.module';
@NgModule({
  declarations: [EditNotificationSettingsModalComponent],
  imports: [
    PipesModule,
    CommonModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule,
    TimePickerModule,
    NotificationModule,
    ConfirmationModalModule,
  ],
  exports: [EditNotificationSettingsModalComponent],
})
export class EditNotificationSettingsModalModule {}
