import { Component } from '@angular/core';


@Component({
  selector: 'essential-logo',
  templateUrl: 'essential-logo.component.html',
  styleUrls: ['essential-logo.component.scss'],
})
export class EssentialLogoComponent {
  constructor() {}

  goToAccessibility() {
    window.open('https://www.msd.com/our-commitment-to-accessibility-for-all/', '_blank');
  }
}
